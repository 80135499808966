import useUserInfo from "../../Hooks/useUserInfo";
import useAuth from "../../Hooks/useAuth";
import { axiosApi } from "../../Api/Axios";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import VerifyCode from "../../Components/VerifyCode/VerifyCode";
import { useState } from "react";
import useGaEvent from "../../Hooks/useGaEvent";

const LoginVerify = () => {
  const { userInfo, setUserInfo } = useUserInfo();
  const { auth, setAuth } = useAuth();
  const [errMsg, setErrMsg] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const gaEvents = useGaEvent();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  // console.log(location.state?.from?.pathname);

  // console.log(userInfo);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(userInfo);

    if(userInfo.code?.trim() != null)
    {
      const vCode = userInfo.code?.trim();
      if(vCode.includes('.') || vCode.includes('-') || vCode.includes('e'))
      {
        setErrMsg("Only numbers 0-9 are allowed");
        return;
      }
    }

    try {
      setBtnDisable(true);
      const response = await axiosApi.post(
        "/api/auth/VerifyCode",
        {
          Code: userInfo.code?.trim(),
          VerifyToken: auth.accessToken,
        },
        {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth.accessToken,
          },
        }
      );

      // console.log(response);

      if (response.status === 200) {
        setAuth((prev) => {
          // console.log(JSON.stringify(prev));
          // console.log(response.data); //it is accessToken
          return { ...prev, accessToken: response?.data?.accessToken, isLogin: true, isAblePrintOrRegister:response?.data?.ablePrintOrRegister, isBypassPayment: response?.data?.byPassPayment, isPnaIdVerified: response?.data?.pnaIdVerified};
        });
        setUserInfo({});
        // console.log(from);
        gaEvents.authLoginSuccess();
        navigate(from, { replace: true });
      } else {
        // console.log("fail");
        setErrMsg(response.data);
        setBtnDisable(false);
      }
    } catch (err) {
      if (err.response.status === 401) {
        // setErrMsg("Unauthorized Access");
        setErrMsg("Session Expired");
      } else {
        setErrMsg(err.response.data);
      }
      // console.log(err);
      gaEvents.authLoginFail();
      setBtnDisable(false);
    }
  };

  return (
    <>
      {!userInfo.user ? (
        <Navigate to="/login" replace={true} />
      ) : (
        <div className="login-container-bg ">
          <VerifyCode handleSubmit={handleSubmit} errMsg={errMsg} btnDisable={btnDisable} />
        </div>
      )}
    </>
  );
};

export default LoginVerify;
