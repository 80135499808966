import PwdSuccess from "../../Components/PwdSuccess";
import useGaEvent from "../../Hooks/useGaEvent";
import { useEffect } from "react";

const PasswordResetSuccess = () => {

  const gaEvents = useGaEvent();

  useEffect(() => {
    gaEvents.authResetPwdSuccess();
  },[]);

  return (
    <div className="pwd-reset-bg">
      <PwdSuccess Subtitle={"Password reset!"}/>
    </div>
  );
};

export default PasswordResetSuccess;
