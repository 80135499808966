import TransferSellerForm from "../../Components/Transferaction/TransferSellerForm";
import { useEffect, useState } from "react";
import useAuth from "../../Hooks/useAuth";
import Dialog from "../../Components/Modal/Dialog";

//Seller form
const TransferOfRegistrationForm = () => {
  // const [resSellers, errGetSeller, isLoadingSellers, apiGetSellers, abtSeller] = useGet();

  // useEffect(() => {
  //   apiGetSellers("/api/transfer/sellerTransferDetails");
  // }, []);

  const [openPnaVerifiedDialog, setOpenPnaVerifiedDialog] = useState(false);
  const { auth } = useAuth();
  

  const isPnaIdVerified = () => {
    if (!auth?.isPnaIdVerified) {
      setOpenPnaVerifiedDialog(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    isPnaIdVerified();
  }, []);

  return (
    // <TransferSellerForm resSellers={resSellers} errGetSeller={errGetSeller} isLoadingSellers={isLoadingSellers} />
    <>
      {!openPnaVerifiedDialog && <TransferSellerForm />}

      {openPnaVerifiedDialog && (
        <Dialog
          setOpenDialog={setOpenPnaVerifiedDialog}
          title="ALERT"
          children="Your account with MAST is not yet Verified. You will be unable to complete any transactions involving boat registration until you provide identity information to Service Tasmania or MAST."
          redirectUrl="/recvessel"
        />
      )}
    </>
  );
};

export default TransferOfRegistrationForm;
