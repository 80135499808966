const RequireForRegistration = () => {
  return (
    <div className="br-box">
      <div className="br-title">Requirements for Registration</div>
      <ul>
        <li>
          Recreational motor boats (including personal water craft) with a motor
          of 2.9kw (4hp) or more must be registered before operating on
          Tasmanian Waters.
        </li>
        <li>
          The registered operator must declare they are satisfied the vessel
          will not endanger a person due to one or more of the following
          circumstances:
          <p>
            <ul>
              <li>
                The structural condition of the vessel, or a component of the
                vessel that renders the vessel unfit to undertake a voyage for
                which the vessel is otherwise designated and would be, if not in
                that condition, capable of undertaking;
              </li>
              <li>
                The engine power rating of the vessel, as recommended by the
                manufacturer or recorded on the ABP, is being exceeded;
              </li>
              <li>
                The absence of, or condition of, materials or items comprising
                the reserve buoyancy or part of the reserve buoyancy of the
                vessel.
              </li>
            </ul>
          </p>
        </li>
        <li>
          If the application for registration is for a vessel over 15m in
          length, the application must be assessed by MAST before the
          registration can be processed. Additional information will need to be
          completed as part of the application to assist in making a
          determination. MAST may contact you requesting further details.
        </li>
        <li>
          A motor boat that is propelled by machinery of less than 2.9kw (4hp),
          motorised tenders and vessels registered in another State visiting
          Tasmania are exempt from registration. For more information on the
          safety equipment required to be carried by non-registered vessels,
          please follow the link{" "}
          <a
            href="https://mast.tas.gov.au/safe-boating/safety-equipment/"
            target="_blank"
            rel="noreferrer"
          >
            Safety Equipment - Marine and Safety Tasmania
          </a>
          .
        </li>
        <li>
          Domestic Commercial Vessels (DCV) are regulated by the Commonwealth
          but are subject to State based Annual Infrastructure Fee. DCVs with
          current AMSA certification cannot also be registered as a recreational
          vessel. Information on State and Commonwealth requirements can be
          initially accessed through the MAST website.
        </li>
        <li>
          MAST may register a vessel to non-Tasmanian residents. However,
          documentary evidence in the form of a mooring or marina lease
          agreement proving the vessel is in Tasmania must be lodged with the
          application.
        </li>
        <li>
          A vessel registered by a competent interstate or overseas authority
          that complies with that authority’s requirements is not required to be
          registered if the vessel is in State waters for a continuous period of
          less than 3 months.
        </li>
        <li>
          Vessels carrying Australian Ship Registrtaion with AMSA and the vessel
          is in Tasmania for a period in excess of 3-months must also be
          registered with MAST.
        </li>
      </ul>
    </div>
  );
};

export default RequireForRegistration;
