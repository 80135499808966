import ProductList from "../../Components/ShoppingCart/ProductList";
import "../../Components/ShoppingCart/ShoppingCart.css";
import useShoppingCart from "../../Hooks/useShoppingCart";
import { useGet } from "../../Hooks/useFetch";
import { useEffect } from "react";
import SubmitLoader from "../../Components/SubmitLoader";
import useGaEvent from "../../Hooks/useGaEvent";
import { useNavigate, useLocation } from "react-router-dom";
import AlertMessage from "../../Components/AlertMessage";

const ShoppingStore = () => {
  const [res, err, isProductListLoading, apiGet, abort] = useGet();
  const gaEvents = useGaEvent();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    cartItems,
    setCartItems,
    cartTotalPrice,
    addToCart,
    removeFromCart,
    changeQtyFromCart,
  } = useShoppingCart();

  useEffect(() => {
    apiGet("/api/shop/productlist");
    gaEvents.ShopVisit();
  }, []);

  return (
    <div className="store-container">
      {!isProductListLoading ? (
        !err ? (
          res?.length === 0 ? (
            <div className="store-emptyItem-container">
              <AlertMessage
                message="No Products Available"
                level="info"
                closeEvt="false"
                mt="0"
                mb="0"
              />
            </div>
          ) : (
            <>
              <ProductList addToCart={addToCart} res={res} />
              <div className="btn-cart-box">
                <button
                  type="button"
                  className="btn-go-cart"
                  onClick={() =>
                    navigate("/checkcart", {
                      state: { from: location },
                      replace: false,
                    })
                  }
                >
                  Checkout
                </button>
              </div>
            </>
          )
        ) : (
          <div className="store-error-container">
            <AlertMessage message={err} />
          </div>
        )
      ) : (
        <div className="loader-container">
          <SubmitLoader isLoading={isProductListLoading} />
        </div>
      )}
    </div>
  );
};

export default ShoppingStore;
