import { Link } from "react-router-dom";
import "./Missing.css";

const Missing = () => {
  return (
    <>
      <div className="missing-container">
        <div>
          <div className="missing-title">404 Page Not Found</div>
          <div className="zoom-area">
          I'm afraid you've found a page that doesn't exist on MAST Online Portal. That can happen when you follow a link to something that has since been deleted. Or the link was incorrect to begin with.
          </div>
          <section className="error-container">
            <span className="four">
              <span className="screen-reader-text">4</span>
            </span>
            <span className="zero">
              <span className="screen-reader-text">0</span>
            </span>
            <span className="four">
              <span className="screen-reader-text">4</span>
            </span>
          </section>
          <div className="link-container">
            <Link to="/" className="more-link">Back to Homepage</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Missing;
