import { useParams } from "react-router-dom";
import MooringPaymentList from "./MooringPaymentList";
import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import { getddmmyyyyFormat } from "../../Helpers/Helper";
import MooringPrimaryVesselList from "./MooringPrimaryVesselList";
import MooringPositionList from "./MooringPositionList";
import DataLoader from "../DataLoader";
import AlertMessage from "../AlertMessage";

const MooringDetails = () => {
  let { id } = useParams(); //Mooring rego no

  const [res, err, isLoading, apiGet, abt] = useGet();

  useEffect(() => {
    apiGet("/api/mooring/MoMooringDetails/" + id);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-7">
          <div className="mooring-sub-container">
            <div className="mooring-title-group">
              <h3 className="mooring-title">Mooring Details</h3>
              {/* <div>{res?.statusReason}</div> */}
              <div className="">
              </div>
            </div>

            {!isLoading ? (
              !err ? (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mooring-input-group">
                        <label htmlFor="MoRegoNo">Mo Rego No</label>
                        <div className="mooringInfoField">{id}</div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mooring-input-group">
                        <label htmlFor="Transferable">Transferable</label>
                        <div className="mooringInfoField">
                          {res?.moTransferable}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="mooring-input-group">
                        <label htmlFor="MaxPermittedLen">
                          Max Permitted Length
                        </label>
                        <div className="mooringInfoField">
                          {res?.moMaxPermittedLen?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mooring-input-group">
                        <label htmlFor="">Location 1</label>
                        <div className="mooringInfoField">
                          {res?.moLocation1}
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mooring-input-group">
                        <label htmlFor="">Approval Length</label>
                        <div className="mooringInfoField">
                          {res?.moLenApprov?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mooring-input-group">
                        <label htmlFor="cableLength">Cable Length</label>
                        <div className="mooringInfoField">
                          {res?.moCableLength?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mooring-input-group">
                        <label htmlFor="">Expiry Date</label>
                        <div className="mooringInfoField">
                          {getddmmyyyyFormat(res?.moExpiryDate?.split("T")[0])}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mooring-input-group">
                        <label htmlFor="NewRegoDate">New Rego Date</label>
                        <div className="mooringInfoField">
                          {getddmmyyyyFormat(res?.moNewRegoDate?.split("T")[0])}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mooring-input-group">
                        <label htmlFor="RegoApplnDate">Rego Appln Date</label>
                        <div className="mooringInfoField">
                          {getddmmyyyyFormat(res?.moRegoApplnDate?.split("T")[0])}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <AlertMessage message={err}/>
              )
            ) : (
              <DataLoader isLoading={isLoading} />
            )}
          </div>

          {/* {res && <MooringUpdatedForm moDetails={res} setUpdated={SetIsUpdated} />} */}

        </div>
        <div className="col-md-5">
          <MooringPaymentList moRegoNo={id} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {res &&<MooringPositionList moRegoNo={id} /> }
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {res &&<MooringPrimaryVesselList itemId={res?.itemId} moRegoNo={id} moAppLen={res?.moLenApprov?.toFixed(2)} /> }
        </div>
      </div>
    </>
  );
};

export default MooringDetails;
