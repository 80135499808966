const ProofOfPurchaseStatement = () => {
  return (
    <div className="br-box">
      <div className="br-title">
        Proof of purchase
      </div>
      <p>Proof of purchase can be established by providing:</p>
      <ul>
        <li>
          the last Certificate of Registration either signed by the registered
          operator; or
        </li>
        <li>
          an official Bill of Sale signed by seller(s), buyer(s) and witnessed;
          or
        </li>
        <li>invoice and receipt of payment from Marine Dealership</li>
      </ul>
      <p>
        If you are unable to provide either of these documents a Statutory Declaration outlining the purchase that has been witnessed by an authorised person may be acceptable.
      </p>
    </div>
  );
};

export default ProofOfPurchaseStatement;

