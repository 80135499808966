import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UploadInput from "../UploadInput";

const UploadSupportDocInputField = ({
  register,
  getFieldState,
  index,
  FieldName,
  remove,
  validHandler,
  watch,
  setValue
}) => {
  return (
    <>
      <div
        className={
          getFieldState(`${FieldName}.${index}`).invalid
            ? "br-input-group error"
            : !getFieldState(`${FieldName}.${index}`).isDirty
            ? "br-input-group"
            : "br-input-group success"
        }
      >
        <div className="tsf-upload-file-field">
          <UploadInput register={register} onChangeEvent={validHandler} assignName={`${FieldName}.${index}`} watch={watch} setValue={setValue}/>
          {/* <input
            type="file"
            {...register(`${FieldName}.${index}`)}
            onChange={validHandler}
          /> */}
          {index !== 0 && (
            <FontAwesomeIcon
              icon={faTrash}
              className="tsf-upload-file-field-icon"
              onClick={() => remove(index)}
            />
          )}
        </div>

        <span className="msg">
          {getFieldState(`${FieldName}.${index}`).error?.message}
        </span>
      </div>
    </>
  );
};

export default UploadSupportDocInputField;
