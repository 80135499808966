import { useNavigate, useLocation } from "react-router-dom";

const TransferComplete = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <div className="br-container">
        <div className="br-form-container">
          <div className="br-box">
            <div className="br-title">Transfer/Disposal applied completed</div>
            <p>Thank you for your transfer/disposal process. </p>
            <p>
              <b>
                Please note that your application has been received and
                processed by MAST.
              </b>
            </p>
            <div className="br-btn-group-success-page">
              <button
                type="button"
                onClick={() =>
                  navigate("/", {
                    state: { from: location },
                    replace: false,
                  })
                }
                className="br-button"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransferComplete;
