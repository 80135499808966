import ChooseVerifyMethod from "../../Components/VerifyCode/ChooseVerifyMethod";
import useUserInfo from "../../Hooks/useUserInfo";
import { axiosApi } from "../../Api/Axios";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useGaEvent from "../../Hooks/useGaEvent";

const RegisterMethod = () => {
  const { userInfo, setUserInfo } = useUserInfo();
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const gaEvents = useGaEvent();
  const navigate = useNavigate();
  const dest = "/register/registerverify";

  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log(userInfo);

    try {
      setIsLoading(true);
      const response = await axiosApi.post("/api/auth/Register-request", {
        WWWId: null,
        PnaGNME: userInfo.firstName?.trim(),
        PnaSurn: userInfo.surName?.trim(),
        PnaDBTE: userInfo.dOB?.$d?.getFullYear()+'/'+(userInfo.dOB?.$d?.getMonth()+1)+'/'+userInfo.dOB?.$d?.getDate(),
        PnaStreet: userInfo.street?.trim(),
        PnaSuburb: userInfo.suburb?.trim(),
        PnaState: userInfo.state?.trim(),
        PnaPostcode: userInfo.postCode?.trim(),
        // IsSameAsResidentAddress: userInfo.checkPostal,
        // PnaStreetPo: userInfo.postalStreet.trim(),
        // PnaSuburbPo: userInfo.postalSuburb.trim(),
        // PnaStatePo: userInfo.postalState.trim(),
        // PnaPostcodePo: userInfo.postalPostCode.trim(),
        Email: userInfo.email?.trim(),
        Mobile: userInfo.mobile?.trim(),
        IsVerifyByEmail: userInfo.isSentByEmail,
        PnaDlLicenceNo: userInfo.dlLicenceNo?.trim(),
        PnaPcRegoNo: userInfo.pcRegoNo?.trim(),
        PnaMoRegoNo: userInfo.moRegoNo?.trim(),
        PnaFavAmsaUVI: userInfo.favAmsaUVI?.trim(),
      });
      // console.log(response);

      if (!response) {
        setErrMsg("No Server Response");
      } else if (response.status === 200) {
        // console.log("success");
        // setAuth({ user: response.data.tempWWWId, accessToken: response.data.accessToken});
        setUserInfo(() => {
          return {
            ...userInfo,
            tempWWWId: response.data?.tempId,
            accessToken: response.data?.tk,
            code: "",
            countDownTime: response.data?.timer
          };
        });
        navigate(dest, { replace: true });
      } else {
        navigate("/", { replace: true });
        setErrMsg(response.data);
      }
    } catch (err) {
      gaEvents.authRegisterFail();
      if (err.response.status === 401) {
        setErrMsg("Session Expired");
      } else {
        setErrMsg(err.response.data);
      }
      // console.error(err);
    }finally{
      setIsLoading(false);
    }
  };

  return (
    <>
      {!userInfo.firstName ? (
        <Navigate to="/register" replace={true} />
      ) : (
        <div className="register-container-bg">
          <ChooseVerifyMethod handleSubmit={handleSubmit} errMsg={errMsg} isLoading={isLoading}/>
        </div>
      )}
    </>
  );
};

export default RegisterMethod;
