import axios from "axios";

const axiosApi = axios.create(
    {
        // headers: {
        //     'Access-Control-Allow-Methods': 'GET, POST',
        // },
        withCredentials:true
    }
);
// axiosApi.interceptors.request.use((config) => {
//     config.url = process.env.REACT_APP_API_URL + config.url;
//     console.log(config.url);
//     return config;
// });

const axiosInterceptor = axios.create(
    {
        headers: {
            //'Accept': 'application / json',
            'Content-Type': 'application/json',
        },
        withCredentials:true
    });

// axiosInterceptor.interceptors.request.use((config) => {
//     config.url = process.env.REACT_APP_API_URL + config.url;
//     console.log(config.url);
//     return config;
// });

export {axiosApi, axiosInterceptor};