
const ManufacturerUpdated = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
}) => {
  return (
    <>
      <div className="">
        {/* <div className="recvessel-updated-subtitle">
          Manufacturer and Construction
        </div> */}
        <div className="recvessel-updated-field-group">
          <div className="row">
            <div className="col-md-6">
              <div className="recvessel-updated-input-group">
                <label htmlFor="hasNaviLight">
                  Navigation lights
                </label>
                <div className="recvessel-updated-field-radio-group">
                  <div className="recvessel-updated-radio-group">
                    <input
                      type="radio"
                      value="Y"
                      id="hasNaviLightYes"
                      {...register("hasNaviLight")}
                    />
                    <label htmlFor="hasNaviLightYes" className="unSelectable">Yes</label>
                  </div>
                  <div className="recvessel-updated-radio-group">
                    <input
                      type="radio"
                      value="N"
                      id="hasNaviLightNo"
                      {...register("hasNaviLight")}
                    />
                    <label htmlFor="hasNaviLightNo" className="unSelectable">No</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="recvessel-updated-input-group">
                <label htmlFor="hasLiferaft">
                  Life raft
                </label>
                <div className="recvessel-updated-field-radio-group">
                  <div className="recvessel-updated-radio-group">
                    <input
                      type="radio"
                      value="Y"
                      id="hasLiferaftYes"
                      {...register("hasLiferaft")}
                    />
                    <label htmlFor="hasLiferaftYes" className="unSelectable">Yes</label>
                  </div>
                  <div className="recvessel-updated-radio-group">
                    <input
                      type="radio"
                      value="N"
                      id="hasLiferaftNo"
                      {...register("hasLiferaft")}
                    />
                    <label htmlFor="hasLiferaftNo" className="unSelectable">No</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className={watch("hasEPIRB") === "Y" ? "col-md-2" : "col-md-12"}
            >
              <div className="recvessel-updated-input-group">
                <label htmlFor="hasEPIRB">EPIRB</label>
                <div className="recvessel-updated-field-radio-group">
                  <div className="recvessel-updated-radio-group">
                    <input
                      type="radio"
                      value="Y"
                      id="hasEPIRBYes"
                      {...register("hasEPIRB")}
                    />
                    <label htmlFor="hasEPIRBYes" className="unSelectable">Yes</label>
                  </div>
                  <div className="recvessel-updated-radio-group">
                    <input
                      type="radio"
                      value="N"
                      id="hasEPIRBNo"
                      {...register("hasEPIRB")}
                    />
                    <label htmlFor="hasEPIRBNo" className="unSelectable">No</label>
                  </div>
                </div>
              </div>
            </div>

            {watch("hasEPIRB") === "Y" ? (
              <>
                <div className="col-md-5">
                  <div className={
                      errors.epirbType?.message
                        ? "recvessel-updated-input-group error"
                        : !getFieldState("epirbType").isDirty
                        ? "recvessel-updated-input-group"
                        : "recvessel-updated-input-group success"
                    }>
                    <label htmlFor="epirbType">EPIRB Type<span className="text-danger">*</span></label>
                    <select
                      id="epirbType"
                      {...register("epirbType", {
                        required: {
                          value: watch("hasEPIRB") === "Y",
                          message: "Type is required",
                        },
                      })}
                    >
                      <option value="">Choose...</option>
                      <option value="406">406</option>
                      <option value="406 GPS">406 GPS</option>
                    </select>
                    <span className="msg">{errors.epirbType?.message}</span>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="recvessel-updated-input-group">
                    <label htmlFor="isEPIRBwithAMSA">
                      EPIRB registered with AMSA<span className="text-danger">*</span>
                    </label>
                    <div className="recvessel-updated-field-radio-group">
                      <div className="recvessel-updated-radio-group">
                        <input
                          type="radio"
                          value="Y"
                          id="isEPIRBwithAMSAYes"
                          {...register("isEPIRBwithAMSA")}
                        />
                        <label htmlFor="isEPIRBwithAMSAYes" className="unSelectable">Yes</label>
                      </div>
                      <div className="recvessel-updated-radio-group">
                        <input
                          type="radio"
                          value="N"
                          id="isEPIRBwithAMSANo"
                          {...register("isEPIRBwithAMSA")}
                        />
                        <label htmlFor="isEPIRBwithAMSANo" className="unSelectable">No</label>
                      </div>
                    </div>
                    {watch("isEPIRBwithAMSA") === "N" ? (
                      <span className="recvessel-updated-additional-text">
                        406Mhz EPIRBS must be registered with AMSA
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="recvessel-updated-input-group">
                <label htmlFor="clubName">Club Name</label>
                <input type="text" id="clubName" {...register("clubName")} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="recvessel-updated-input-group">
                <label htmlFor="sailNumber">Sail Number</label>
                <input
                  type="text"
                  id="sailNumber"
                  {...register("sailNumber")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManufacturerUpdated;
