import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import "./PnaDetails.css";
import { getddmmyyyyFormat } from "../../Helpers/Helper";
import DataLoader from "../DataLoader";
import { EditIcon } from "../Icons";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertMessage from "../AlertMessage";

const PnaDetails = () => {
  const [res, err, isLoading, apiGet, abt] = useGet();

  useEffect(() => {
    apiGet("/api/pna/PnaDecryptedData", true);
    
  }, []);

  return (
    <div className="pna-details-container">
      {(res?.status==0 || res?.status==2 || res?.status==3) && <AlertMessage message={res?.statusReason} level="warning" closeEvt="false" mt="0"/>}
      <div className="pna-details-title-group">
        <h3 className="pna-details-title">Person Details</h3>
        {res && <EditIcon url="/pnaupdate" iconSize="xl" HoverCaption="Edit Personal details" />}
      </div>
      {!isLoading ? (
        !err ? (
          res ? (
            <>
              {process.env.REACT_APP_TEST_DEFAULT_VALUE === 'on' ? (
                <div className="row">
                  <div className="col-md-6">
                    <div className="pna-details-input-group">
                      <label htmlFor="LoginId">Login ID</label>
                      <div className="pnaInfoField">{res?.loginId}</div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="pna-details-input-group">
                      <label htmlFor="OwnerCode">Owner Code</label>
                      <div className="pnaInfoField">{res?.ownerCode}</div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="row">
                <div className="col-md-6">
                  <div className="pna-details-input-group">
                    <label htmlFor="PNAWwwId">User ID</label>
                    <div className="pnaInfoField">{res?.pnaWwwId}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pna-details-input-group">
                    <label htmlFor="Birth">Birth</label>
                    <div className="pnaInfoField">
                      {getddmmyyyyFormat(res?.pnaBdte?.split("T")[0])}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <div className="pna-details-input-group">
                    <label htmlFor="Title">Title</label>
                    <div className="pnaInfoField">{res?.pnaTitl}</div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="pna-details-input-group">
                    <label htmlFor="LastName">Given Names</label>
                    <div className="pnaInfoField">{res?.pnaGnme}</div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="pna-details-input-group">
                    <label htmlFor="Surname">Surname</label>
                    <div className="pnaInfoField">{res?.pnaSurn}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="pna-details-input-group">
                    <label htmlFor="Tnh">Telephone Home</label>
                    <div className="pnaInfoField">{res?.pnaTnh}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pna-details-input-group">
                    <label htmlFor="Tnw">Telephone Work</label>
                    <div className="pnaInfoField">{res?.pnaTnw}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="pna-details-input-group">
                    <label htmlFor="Mobile">
                      Mobile
                      {res?.pnaMobileStatusDesc === "Valid External" ||
                      res?.pnaMobileStatusDesc === "Valid Internal" ? (
                        res?.pnaMobile && <FontAwesomeIcon icon={faCheck} className="valid" />
                      ) : (
                        <FontAwesomeIcon icon={faXmark} className="invalid" />
                      )}
                    </label>
                    <div className="pnaInfoField">{res?.pnaMobile}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="pna-details-input-group">
                    <label htmlFor="Email">
                      Email
                      {res?.pnaEmailStatusDesc === "Valid External" ||
                      res?.pnaEmailStatusDesc === "Valid Internal" ? (
                        res?.pnaEmail && <FontAwesomeIcon icon={faCheck} className="valid" />
                      ) : (
                        <FontAwesomeIcon icon={faXmark} className="invalid" />
                      )}
                    </label>
                    <div className="pnaInfoField">{res?.pnaEmail}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="pna-details-input-group">
                    <label htmlFor="address">Residential Address</label>
                    <div className="pnaInfoField">
                      {res?.pnaAdr1} {res?.pnaAdr2} {res?.pnaPostcode}{" "}
                      {res?.pnaAdr3}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="pna-details-input-group">
                    <label htmlFor="poAddress">Postal Address</label>
                    <div className="pnaInfoField">
                      {res?.pnaAdr1Po} {res?.pnaAdr2Po} {res?.pnaPostcodePo}{" "}
                      {res?.pnaAdr3Po}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <AlertMessage message="No Personal Details" level="info" closeEvt="false"/>
          )
        ) : (
          <AlertMessage message={err} />
        )
      ) : (
        <DataLoader isLoading={isLoading} />
      )}
    </div>
  );
};

export default PnaDetails;
