import { ReactComponent as VisaIcon } from "../../Assets/cc-visa.svg";
import { ReactComponent as MasterCardIcon } from "../../Assets/cc-mastercard.svg";

const SummaryPriceDisplay = ({ totalPrice }) => {
  return (
    <div className="cart-total-price-box">
      <div className="price-display-group">
        <div>Total price:</div>
        <div>AUD {totalPrice}</div>
      </div>
      <div className="price-display-group">
        <div>Discount:</div>
        <div>AUD 0.00</div>
      </div>
      <div className="price-display-group">
        <div>Total:</div>
        <div>
          <b>$ {totalPrice}</b>
        </div>
      </div>
      <div className="line-separate" />
      <div className="credit-card-icons-box">
        <div className="cart-card-icon">
          <VisaIcon />
        </div>
        <div className="cart-card-icon">
          <MasterCardIcon />
        </div>
      </div>
    </div>
  );
};

export default SummaryPriceDisplay;
