import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const PayIntro = ({ fee }) => {
  return (
    <div className="br-box">
      <div className="br-title">Payment</div>
      <p>
        Please note that all motor boat registrations have a common expiry date of 31 December each year. The registration fee payable is calculated on a daily basis according to the number of days from the date of application to 31 December.
      </p>
      <p>In addition, new registrations processed during November and December each year, the following years registration is also charged.</p>
      <p>
        The fee indicated also includes a once off Application Fee.
      </p>
      <p>
        If you choose to pay on-line using a credit card, MAST only accepts
        <strong> Mastercard</strong> or <strong> Visa</strong>.
      </p>
      <div className="br-title amount-group">
        <div>Amount payable</div>
        <div>${fee ? fee : <FontAwesomeIcon icon={faQuestion} />}</div>
      </div>
    </div>
  );
};

export default PayIntro;
