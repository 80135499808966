import RegistrationInputFields from "../../Components/Registration/RegistrationInputFields";
import { useForm } from "react-hook-form";
import useUserInfo from "../../Hooks/useUserInfo";
import { useNavigate } from "react-router-dom";
import "../../Components/Registration/Registration.css";
import dayjs from "dayjs";
import useGaEvent from "../../Hooks/useGaEvent";


const Register = () => {

  const gaEvents = useGaEvent();
  let initialValues = {};
  if(process.env.REACT_APP_TEST_DEFAULT_VALUE === 'on')
  {
    initialValues = {
      firstName: "HUGH",
      surName: "JACKMAN",
      street: "C/- AMC PO BOX 104",
      suburb: "MOWBRAY",
      state: "TAS",
      postCode: "7248",
      dOB: dayjs('2000-10-23'),
      // checkPostal: false,
      // postalStreet: "C/- AMC PO BOX 104",
      // postalSuburb: "MOWBRAY",
      // postalState: "TAS",
      // postalPostCode: "7248",
      mobile: "0451879579",
      email: "jl2@datawise.com.au",
      dlLicenceNo: "D1201203",
      pcRegoNo: "A1111111",
      moRegoNo: "M7777777",
      favAmsaUVI: "F3333333",
    };
  }
  

  const {
    control,
    register,
    formState: { errors, isDirty, isValid, dirtyFields },
    handleSubmit,
    watch,
    getFieldState,
    clearErrors,
  } = useForm({ defaultValues: initialValues, mode: "onChange" });

  const { setUserInfo } = useUserInfo();
  const navigate = useNavigate();
  const dest = "/register/registermethod";

  const onSubmit = (data) => {
    // e.preventDefault();

    // if (data.checkPostal) {
    //   data.postalStreet = data.street;
    //   data.postalSuburb = data.suburb;
    //   data.postalState = data.state;
    //   data.postalPostCode = data.postCode;
    // }

    // console.log(data);

    setUserInfo({ ...data, maskInfo: false, action: 2, isSentByEmail: true });
    gaEvents.authRegisterVisit();
    navigate(dest, { replace: true });
  };

  // const handleChange = (e) => {
  //   const type = e.target.type;
  //   const name = e.target.name;
  //   const value = type === "checkbox" ? e.target.checked : e.target.value;

  //   // () means object, and [] is mapping to type name
  //   setData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  // const handleDatePickerChange = (name, date) => {
  //   setData((prevData) => ({
  //     ...prevData,
  //     [name]: date,
  //   }));
  // };

  // const {pwdCheckPostal, pwdPostalStreet, pwdPostalSuburb, pwdPostalState, ...otherFields} = data
  // const canSave = [...Object.values(otherFields)].every(Boolean) && (!pwdCheckPostal&&pwdPostalStreet===""&&pwdPostalSuburb===""&&pwdPostalState==="")

  const content = (
    <>
      <div className="register-container-bg">
        <div className="register-form-container">
          <h2 className="register-form-title">Registration</h2>
          <form onSubmit={handleSubmit(onSubmit)} className="register-form">
            <RegistrationInputFields
              register={register}
              watch={watch}
              control={control}
              errors={errors}
              getFieldState={getFieldState}
              clearErrors={clearErrors}
            />
            <button className="register-form-button">Submit</button>
          </form>
        </div>
      </div>
    </>
  );

  return content;
};

export default Register;
