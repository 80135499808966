import '../../BoatRegistration/BoatRegistration.css';
import Introduction from '../Introductions/Introduction';
import PrivacyStatement from '../Introductions/PrivacyStatement';

import RequireForRegistration from '../Introductions/RequireForRegistration';
import ProofOfPurchaseStatement from '../Introductions/ProofOfPurchaseStatement';


// This step0 is for introduction components
const FormIntro = () => {
    return ( 
        <>
            <Introduction />
            <RequireForRegistration/>
            <ProofOfPurchaseStatement/>
            <PrivacyStatement/>
        </>
     );
}
 
export default FormIntro;