import { useEffect, useState } from "react";
import {isSafari, isMobileSafari} from 'react-device-detect';

const UploadInput = ({ register, onChangeEvent = null, assignName, watch, setValue }) => {
  const [fileName, setFileName] = useState("");
  const uploadWrap = {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    width: "100%",
  };

  const uploadInputFile = {
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0,
  };

  const fileWord = {
    display: "inline-block",
  };

//   console.log(watch(assignName));
  const changeEvent = (event) => {
    onChangeEvent(event);
    
    //there is a failure in onChangeEvent
    // console.log(event);
    if(event.target.files.length === 0)
    {
        setFileName('No file chosen');
    }else{
        setFileName(event.target.files[0].name);
    }
  }

  const clickEvent = (e) => {
    // console.log(e);
    if(isMobileSafari || isSafari)
    {
      console.log('Safari Detected');
      setValue(assignName, []);
    }
    
    setFileName('No file chosen');
  }




  //Initial value
  useEffect(() => {
    if(watch(assignName) === undefined)
      return;

    if(watch(assignName).length === 0)
    {
        setFileName('No file chosen');
    }else{
        setFileName(watch(assignName)[0].name);
    }
  }, []);

  return (
    <>
      <div className="upload-btn-wrap" style={uploadWrap}>
        <input
          id={assignName}
          type="file"
          {...register(assignName, {
            onChange: (e) => {changeEvent(e)},
          }
          )}
          style={uploadInputFile}
          className="upload-input-file"
          onClick={clickEvent}
        />
        <button className="upload-btn" type="button">
          CHOOSE FILE
        </button>
        <div style={fileWord}>{fileName}</div>
      </div>
    </>
  );
};

export default UploadInput;
