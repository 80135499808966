import { useForm } from "react-hook-form";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { usePost } from "../../Hooks/useFetch";
import { useState } from "react";

//Mooring update no longer use
const MooringUpdatedForm = ({ moDetails, setUpdated }) => {
  // console.log(moDetails);
  const [isEditMode, setIsEditMode] = useState(false);

  const initialValues = {
    moBoatLength: moDetails?.moBoatLength,
    moBoatName: moDetails?.moBoatName,
    moBoatRegoNo: moDetails?.moBoatRegoNo,
    // moCableLength: moDetails?.moCableLength, //real
    // moCategory: "MOORING",
    // moCurrentFlag: null,
    // moDateChanged: "2022-05-04T00:00:00",
    // moEastNorthDatum: null, //limit 8 chars
    // moEasting: moDetails?.moEasting, //float
    // moExpiryDate: "2023-06-30T00:00:00",
    // moFeeExempt: "N",
    // moGisDisplay: "Y",
    // moGisNameFlag: null,
    // moInvoiceToFlag: null,
    // moLatLongDatum: null,
    // moLatitude: moDetails?.moLatitude, //float
    // moLenApprov: moDetails?.moLenApprov,
    // moLocation1: moDetails?.moLocation1, //char 60
    // moLocation2: moDetails?.moLocation2,
    // moLocation3: moDetails?.moLocation3,
    // moLocation4: moDetails?.moLocation4,
    // moLongitude: moDetails?.moLongitude,
    // moMaxPermittedLen: moDetails?.moMaxPermittedLen,
    // moNewRegoDate: null,
    // moNorthing: moDetails?.moNorthing, //float
    // moNumTags: 1,
    // moOperatorCode: null,
    // moOwnerCode: "AALBRERF01",
    // moPermitNo: "2821",
    // moPrevOwnerCode: "CREESELE01",
    // moRegoApplnDate: null,
    // moRegoNo: "2821",
    // moRegoNoFrom: null,
    // moRenew: "Y",
    // moRenewalNo: "15682305",
    // moSurrendered: null,
    // moSurrenderedDate: null,
    // moSurveyEntryDate: null,
    // moTfrInProgress: null,
    // moTransferable: "Y",
    // moUserChanged: "rpearce",
  };

  const {
    control,
    register,
    formState: { errors, isDirty, isValid, dirtyFields },
    handleSubmit,
    watch,
    getFieldState,
    setValue,
    setError,
    clearErrors,
  } = useForm({ defaultValues: initialValues, mode: "onChange" });

  const [res, err, isLoadingPost, apiPost, abt] = usePost();

  const successFuc = () => {
    setUpdated(true);
    setIsEditMode(false);
  };
  const onSubmit = async (data, e) => {
    e.preventDefault();

    // console.log(data);
    await apiPost(
      "/api/mooring/updateMoMooringDetails",
      {
        MoRegoNo: moDetails.moRegoNo,
        MoBoatName: data.moBoatName,
        MoBoatLength: data.moBoatLength,
        MoBoatRegoNo: data.moBoatRegoNo,
        // MoCableLength: data.moCableLength,
        // MoEasting: data.moEasting,
        // MoNorthing: data.moNorthing,
        // MoLongitude: data.moLongitude,
        // MoLatitude: data.moLatitude,
        // MoLenApprov: data.moLenApprov,
        // MoMaxPermittedLen: data.moMaxPermittedLen,
        // MoLocation1: data.moLocation1,
        // MoLocation2: data.moLocation2,
        // MoLocation3: data.moLocation3,
        // MoLocation4: data.moLocation4,
      },
      false,
      null,
      successFuc
    );

  };

  return (
    <>
      {
      (!isEditMode ? (
        <div className="mooring-sub-container">
          <div className="mooring-title-group">
            <h3 className="mooring-title">MOORING BOAT</h3>
            <div>{moDetails?.statusReason}</div>
            <button
              type="button"
              className="mooring-button"
              onClick={() => setIsEditMode(true)}
            >
              Update
            </button>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="mooring-input-group">
                <label htmlFor="boatName">Boat Name</label>
                <div className="mooringInfoField">{moDetails?.moBoatName}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mooring-input-group">
                <label htmlFor="">Boat Rego No.</label>
                <div className="mooringInfoField">
                  {moDetails?.moBoatRegoNo}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mooring-input-group">
                <label htmlFor="">Boat Length</label>
                <div className="mooringInfoField">
                  {moDetails?.moBoatLength?.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mooring-updated-container">
          {!isLoadingPost ? (
            !err ? (
              <>
                <form
                  className="mooring-updated-form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="mooring-title-group">
                    <h3 className="mooring-title">Update</h3>
                    <div>
                      <button
                        type="button"
                        className="mo-cancel"
                        onClick={() => setIsEditMode(false)}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="mooring-button">
                        Save
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="mooring-updated-input-group">
                        <label htmlFor="boatName">Boat Name</label>
                        <input
                          type="text"
                          id="moBoatName"
                          {...register("moBoatName")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mooring-updated-input-group">
                        <label htmlFor="boatRegoNo">Boat Rego No</label>
                        <input
                          type="number"
                          id="moBoatRegoNo"
                          {...register("moBoatRegoNo")}
                          step=".01"
                        />
                        <span className="msg">
                          {errors.moBoatRegoNo?.message}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mooring-updated-input-group">
                        <label htmlFor="boatLength">Boat Length</label>
                        <input
                          type="number"
                          id="moBoatLength"
                          {...register("moBoatLength")}
                          step=".01"
                        />
                        <span className="msg">
                          {errors.moBoatLength?.message}
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            ) : (
              <>
                <div>{err}</div>
              </>
            )
          ) : (
            <div>Loading...</div>
          )}
        </div>
      ))
      }
    </>
  );
};

export default MooringUpdatedForm;
