import InputTooltip from "../../InputTooltip";

const VesselBasic = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  getValues,
  isReRegisterCase = false,
}) => {
  return (
    <div className="br-box">
      <div className="br-title">Vessel Details</div>
      <div className="br-field-group">
        <div className="br-input-group">
          <label htmlFor="hullIdNo">Hull Identification No (HIN)</label>
          <span className="br-hin-additional-text">
            If possible, please enter the HIN in the official format:{" "}
            <a
              href="https://www.ppsr.gov.au/glossary/hin-hull-identification-number"
              target="_blank"
            >
              Official HIN Format
            </a>
          </span>
          <div className="br-hin-group">
            <InputTooltip titleCaption="Country of origin">
              <div
                className={
                  errors.countryOfOrigin?.message
                    ? "br-hin-input-group error"
                    : !getFieldState("countryOfOrigin").isDirty
                    ? "br-hin-input-group"
                    : "br-hin-input-group success"
                }
              >
                <input
                  type="text"
                  id="countryOfOrigin"
                  {...register("countryOfOrigin", {
                    required: {
                      value: watch("hinRest"),
                      message:
                        "Country code is required if rest of HIN is filled",
                    },
                  })}
                  className="text-upper br-hin-country-code"
                  placeholder="Country of Origin"
                />
                <span className="msg br-hin-country-code">
                  {errors.countryOfOrigin?.message}
                </span>
              </div>
            </InputTooltip>
            <span>-</span>
            <InputTooltip titleCaption="Manufacturer’s Identity Code - Serial Number - Month and Year of Manufacture - Model year">
              <div
                className={
                  errors.hinRest?.message
                    ? "br-hin-input-group br-hin-rest-input error"
                    : !getFieldState("hinRest").isDirty
                    ? "br-hin-input-group br-hin-rest-input"
                    : "br-hin-input-group br-hin-rest-input success"
                }
              >
                <input
                  type="text"
                  id="hinRest"
                  {...register("hinRest", {
                    required: {
                      value: watch("countryOfOrigin"),
                      message: "HIN is required if Country code is filled ",
                    },
                  })}
                  className="text-upper  br-hin-width-100"
                  placeholder="MIC-Ser-Manuf-Model"
                />
                <span className="msg">{errors.hinRest?.message}</span>{" "}
              </div>
            </InputTooltip>
          </div>
          <span>
            A Hull Identification Number (HIN) is permanently affixed to a
            boat’s hull. A HIN includes a unique national serial number along
            with information regarding the country of origin, and either
            manufacturer, model and year of production for new boats, or an
            authorised agent code and the year it was affixed. A HIN is not
            mandatory for vessels to be registered in Tasmania. If your vessel
            has a HIN affixed, please provide the number above.
          </span>
        </div>
        <div className="br-input-group">
          <label htmlFor="hullSerialNo">Hull Serial No</label>
          <input
            type="text"
            id="hullSerialNo"
            {...register("hullSerialNo")}
            className="text-upper"
          />
          <span>
            Some manufacturers may attach a serial number to the vessel. The
            provision of the information is not mandatory, but it is useful for
            vessel identification. For example, to assist in the recovery of a
            vessel that has been reported lost or stolen etc.
          </span>
        </div>

        <div className="br-input-group">
          <label htmlFor="isVesselDiff">
            Was this vessel previously registered under a different registration
            number
          </label>
          {isReRegisterCase == true ? (
            <div className="br-field-radio-group br-radio-field-readonly">
              <div className="br-radio-group">
                <input
                  type="radio"
                  value="Yes"
                  id="isVesselDiffYes"
                  checked={watch("isVesselDiff") === "Yes"}
                  disabled
                />
                <label htmlFor="isVesselDiffYes">YES</label>
              </div>
              <div className="br-radio-group">
                <input
                  type="radio"
                  value="No"
                  id="isVesselDiffNo"
                  checked={watch("isVesselDiff") === "No"}
                  disabled
                />
                <label htmlFor="isVesselDiffNo">NO</label>
              </div>
            </div>
          ) : (
            <div className="br-field-radio-group">
              <div className="br-radio-group">
                <input
                  type="radio"
                  value="Yes"
                  id="isVesselDiffYes"
                  {...register("isVesselDiff")}
                />
                <label htmlFor="isVesselDiffYes">YES</label>
              </div>
              <div className="br-radio-group">
                <input
                  type="radio"
                  value="No"
                  id="isVesselDiffNo"
                  {...register("isVesselDiff")}
                />
                <label htmlFor="isVesselDiffNo">NO</label>
              </div>
            </div>
          )}
        </div>

        {watch("isVesselDiff") === "Yes" ? (
          isReRegisterCase === true ? (
            <div className="br-input-group">
              <label htmlFor="existRegiNo">Existing Registration No.</label>
              <div className="br-field-readonly unSelectable text-upper">
                {getValues("existRegiNo")}
              </div>
            </div>
          ) : (
            <div
              className={
                errors.existRegiNo?.message
                  ? "br-input-group error"
                  : !getFieldState("existRegiNo").isDirty
                  ? "br-input-group"
                  : "br-input-group success"
              }
            >
              <label htmlFor="existRegiNo">
                Existing Registration No.
                {watch("isVesselDiff") === "Yes" ? (
                  <span className="text-danger">*</span>
                ) : (
                  ""
                )}
              </label>
              <span>
                If known, please state the previous registration number.
              </span>
              <input
                type="text"
                id="existRegiNo"
                className="text-upper"
                {...register("existRegiNo", {
                  required: {
                    value: watch("isVesselDiff") === "Yes",
                    message: "Existing Registration is required",
                  },
                })}
              />
              <span className="msg">{errors.existRegiNo?.message}</span>
            </div>
          )
        ) : (
          ""
        )}

        {isReRegisterCase === true ? (
          <div className="br-input-group">
            <label htmlFor="isCommercialPurpose">
              Has your vessel been at any times used for a commercial purpose
            </label>
            <div className="br-field-radio-group br-radio-field-readonly">
              <div className="br-radio-group">
                <input
                  type="radio"
                  value="Yes"
                  id="isCommercialPurposeYes"
                  disabled
                  checked={getValues("isCommercialPurpose") === "Yes"}
                />
                <label htmlFor="isCommercialPurposeYes">YES</label>
              </div>
              <div className="br-radio-group">
                <input
                  type="radio"
                  value="No"
                  id="isCommercialPurposeNo"
                  disabled
                  checked={getValues("isCommercialPurpose") === "No"}
                />
                <label htmlFor="isCommercialPurposeNo">NO</label>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              errors.isCommercialPurpose?.message
                ? "br-input-group error"
                : !getFieldState("isCommercialPurpose").isDirty
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="isCommercialPurpose">
              Has your vessel been at any times used for a commercial purpose{" "}
              <span className="text-danger">*</span>
            </label>
            <span>E.g. Commercial fishing vessel</span>
            <div className="br-field-radio-group">
              <div className="br-radio-group">
                <input
                  type="radio"
                  value="Yes"
                  id="isCommercialPurposeYes"
                  {...register("isCommercialPurpose", {
                    required: {
                      value: true,
                      message: "This is required",
                    },
                  })}
                />
                <label htmlFor="isCommercialPurposeYes">YES</label>
              </div>
              <div className="br-radio-group">
                <input
                  type="radio"
                  value="No"
                  id="isCommercialPurposeNo"
                  {...register("isCommercialPurpose", {
                    required: {
                      value: true,
                      message: "This is required",
                    },
                  })}
                />
                <label htmlFor="isCommercialPurposeNo">NO</label>
              </div>
            </div>

            <span className="msg">{errors.isCommercialPurpose?.message}</span>
          </div>
        )}

        {watch("isCommercialPurpose") === "Yes" ? (
          isReRegisterCase === true ? (
            <div className="br-input-group">
              <label htmlFor="comRegoNo">
                Commercial Registration No./Unique Identifier
              </label>
              <div className="br-field-readonly unSelectable text-upper">
                {getValues("comRegoNo")}
              </div>
            </div>
          ) : (
            <div className="br-input-group">
              <label htmlFor="comRegoNo">
                Commercial Registration No./Unique Identifier
              </label>
              <span>
                If known, please state the previous registration number.
              </span>
              <input type="text" id="comRegoNo" {...register("comRegoNo")} />
            </div>
          )
        ) : (
          ""
        )}

        <div className="br-input-group">
          <label htmlFor="vesselName">Vessel Name</label>
          <input
            type="text"
            id="vesselName"
            {...register("vesselName")}
            className="text-upper"
          />
          <span>
            Names of an inappropriate nature will not be included on a
            Certificate of Registration.
          </span>
        </div>
        <div
          className={
            errors.whereVesselKept?.message
              ? "br-input-group error"
              : !getFieldState("whereVesselKept").isDirty
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="whereVesselKept">
            Where is the Vessel Kept <span className="text-danger">*</span>
          </label>
          <select
            id="whereVesselKept"
            {...register("whereVesselKept", {
              required: "This is required",
            })}
          >
            <option value="">CHOOSE...</option>
            <option value="RB_MARINA">MARINA</option>
            <option value="RB_BOATSHED">BOATSHED</option>
            <option value="RB_JETTY">JETTY</option>
            <option value="RB_TRAILER">TRAILER</option>
            <option value="RB_SLIPWAY">SLIPWAY</option>
            <option value="RB_MOORING">MOORING</option>
          </select>
          <span className="msg">{errors.whereVesselKept?.message}</span>
        </div>

        {watch("whereVesselKept") === "RB_MARINA" ? (
          <>
            <div
              className={
                errors.marinaName?.message
                  ? "br-input-group error"
                  : !getFieldState("marinaName").isDirty
                  ? "br-input-group"
                  : "br-input-group success"
              }
            >
              <label htmlFor="marinaName">
                Marina Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="marinaName"
                className="text-upper"
                {...register("marinaName", {
                  required: {
                    value: watch("whereVesselKept") === "RB_MARINA",
                    message: "Marina name is required",
                  },
                })}
              />
              <span className="msg">{errors.marinaName?.message}</span>
            </div>

            <div className="br-input-group">
              <label htmlFor="marinaBerthNum">
                Marina Berth Number (if known)
              </label>
              <input
                type="text"
                id="marinaBerthNum"
                className="text-upper"
                {...register("marinaBerthNum")}
              />
            </div>
          </>
        ) : (
          ""
        )}

        {watch("whereVesselKept") === "RB_MOORING" ? (
          <div className="br-input-group">
            <label htmlFor="mooringRegoNo">Mooring Rego Number</label>
            <input
              type="text"
              className="text-upper"
              id="mooringRegoNo"
              {...register("mooringRegoNo")}
            />
          </div>
        ) : (
          ""
        )}

        <div
          className={
            errors.pocodeOfStorage?.message
              ? "br-input-group error"
              : !getFieldState("pocodeOfStorage").isDirty
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="pocodeOfStorage">
            Postcode of place of storage <span className="text-danger">*</span>
          </label>
          <input
            type="number"
            id="pocodeOfStorage"
            {...register("pocodeOfStorage", {
              required: "Postcode is required",
              pattern: {
                value: /^[0-9]{4}$/,
                message: "4 digit number allowed",
              },
            })}
          />
          <span className="msg">{errors.pocodeOfStorage?.message}</span>
          {watch("pocodeOfStorage") ? (
            <span>
              Please note that additional documentation is required for vessels
              stored outside of Tasmania. Details will be attached to your
              registration form on completion of your application.
            </span>
          ) : (
            ""
          )}
        </div>
        <div
          className={
            errors.vesselUse?.message
              ? "br-input-group error"
              : !getFieldState("vesselUse").isDirty
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="vesselUse">
            Primary Vessel Use <span className="text-danger">*</span>
          </label>
          <div className="br-field-radio-group">
            <div className="br-radio-group">
              <input
                type="radio"
                value="RB_CRUISING"
                id="cruising"
                {...register("vesselUse", {
                  required: {
                    value: true,
                    message: "This is required.",
                  },
                })}
              />
              <label htmlFor="cruising">CRUISING</label>
            </div>
            <div className="br-radio-group">
              <input
                type="radio"
                value="RB_DIVING"
                id="diving"
                {...register("vesselUse", {
                  required: {
                    value: true,
                    message: "This is required.",
                  },
                })}
              />
              <label htmlFor="diving">DIVING</label>
            </div>
            <div className="br-radio-group">
              <input
                type="radio"
                value="RB_FISHING"
                id="fishing"
                {...register("vesselUse", {
                  required: {
                    value: true,
                    message: "This is required.",
                  },
                })}
              />
              <label htmlFor="fishing">FISHING</label>
            </div>
            <div className="br-radio-group">
              <input
                type="radio"
                value="RB_WATERSPORTS"
                id="watersports"
                {...register("vesselUse", {
                  required: {
                    value: true,
                    message: "This is required.",
                  },
                })}
              />
              <label htmlFor="watersports">WATERSPORTS</label>
            </div>
            <div className="br-radio-group">
              <input
                type="radio"
                value="RB_YACHT_RACING"
                id="yachtRacing"
                {...register("vesselUse", {
                  required: {
                    value: true,
                    message: "This is required.",
                  },
                })}
              />
              <label htmlFor="yachtRacing">YACHT RACING</label>
            </div>
          </div>

          <span className="msg">{errors.vesselUse?.message}</span>
        </div>

        <div
          className={
            errors.classVessel?.message
              ? "br-input-group error"
              : !getFieldState("classVessel").isDirty
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="classVessel">
            Class of Vessel <span className="text-danger">*</span>
          </label>
          <select
            id="classVessel"
            {...register("classVessel", {
              required: {
                value: true,
                message: "Class is required",
              },
            })}
          >
            <option value="">CHOOSE...</option>
            <option value="CRUISER">CRUISER</option>
            <option value="CUDDY CABIN">CUDDY CABIN</option>
            <option value="INFLATABLE">INFLATABLE</option>
            <option value="MOTOR LAUNCH">MOTOR LAUNCH</option>
            <option value="MOTOR SAILER">MOTOR SAILER</option>
            <option value="OPEN BOAT">OPEN BOAT</option>
            <option value="PWC">PWC (JET SKI)</option>
            <option value="RUNABOUT">RUNABOUT</option>
            <option value="YACHT">YACHT</option>
          </select>
          <span className="msg">{errors.classVessel?.message}</span>
        </div>

        {isReRegisterCase === true ? (
          <div
            className="br-input-group">
            <label htmlFor="hasFlyBridge">
              Does the Vessel have a Fly Bridge
            </label>
            <div className="br-field-radio-group br-radio-field-readonly">
              <div className="br-radio-group">
                <input
                  type="radio"
                  value="Yes"
                  id="hasFlyBridgeYes"
                  disabled
                  checked={getValues("hasFlyBridge") === "Yes"}
                />
                <label htmlFor="hasFlyBridgeYes">YES</label>
              </div>
              <div className="br-radio-group">
                <input
                  type="radio"
                  value="No"
                  id="hasFlyBridgeNo"
                  disabled
                  checked={getValues("hasFlyBridge") === "No"}
                />
                <label htmlFor="hasFlyBridgeNo">NO</label>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              errors.hasFlyBridge?.message
                ? "br-input-group error"
                : !getFieldState("hasFlyBridge").isDirty
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="hasFlyBridge">
              Does the Vessel have a Fly Bridge{" "}
              <span className="text-danger">*</span>
            </label>
            <div className="br-field-radio-group ">
              <div className="br-radio-group">
                <input
                  type="radio"
                  value="Yes"
                  id="hasFlyBridgeYes"
                  {...register("hasFlyBridge", {
                    required: {
                      value: true,
                      message: "This is required.",
                    },
                  })}
                />
                <label htmlFor="hasFlyBridgeYes">YES</label>
              </div>
              <div className="br-radio-group">
                <input
                  type="radio"
                  value="No"
                  id="hasFlyBridgeNo"
                  {...register("hasFlyBridge", {
                    required: {
                      value: true,
                      message: "This is required.",
                    },
                  })}
                />
                <label htmlFor="hasFlyBridgeNo">NO</label>
              </div>
            </div>
            <span className="msg">{errors.hasFlyBridge?.message}</span>
            <span>
              A Fly Bridge refers to motor boats that have upper helm station.
              If your vessel is not fitted with and Australian Builders Plate
              (see further information below), a Capacity Label must be affixed
              to the vessel. If the vessel has a Fly Bridge MAST will issue two
              Capacity Labels so they can bee seen by the skipper and crew at
              all times.
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default VesselBasic;
