import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import { useParams } from "react-router-dom";
import PeEngineUpdatedForm from "../../Components/PeEngine/PeEngineUpdatedForm";
import SubmitLoader from "../../Components/SubmitLoader";
import useGaEvent from "../../Hooks/useGaEvent";
import AlertMessage from "../../Components/AlertMessage";

const PeEngineUpdated = () => {
  let { regoNo, sequNo } = useParams();
  const [res, err, isGETLoading, apiGet, abt] = useGet();
  const gaEvents = useGaEvent();

  useEffect(() => {
    apiGet("/api/pcraft/PeEngineDetails/" + regoNo + "/" + sequNo);
    gaEvents.RecEngineDetailVisit();
  }, []);

  return (
    <>
      {isGETLoading ? (
        <SubmitLoader isLoading={isGETLoading} />
      ) : err ? (
        <div className="row">
          <div className="col-md-12">
            <AlertMessage message={err} />
          </div>
        </div>
      ) : res ? (
        <div className="row">
          <div className="col-md-12">
            <PeEngineUpdatedForm peDetails={res} />
          </div>
        </div>
      ) : (
        <div>No data</div>
      )}
    </>
  );
};

export default PeEngineUpdated;
