import { usePost } from "../../Hooks/useFetch";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { validatePickDateNotEarlierDateFrom } from "../../Helpers/Helper";
import SubmitLoader from "../SubmitLoader";
import useGaEvent from "../../Hooks/useGaEvent";
import useToast from "../../Hooks/useToast";
import AlertMessage from "../AlertMessage";
import 'react-native-get-random-values';
import { v4 as uuidv4 } from "uuid";

const MooringPrimaryVesselUpdatedForm = ({
  primaryVessel,
  pcList,
  faList,
  moRegoNo,
  surname,
  firstname,
  moAppLen,
}) => {
  const [res, err, isLoadingPost, apiPost, abt] = usePost();
  const gaEvents = useGaEvent();
  const toastEmitter = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  // console.log(primaryVessel);
  // console.log(pcList);
  // console.log(faList);

  const initialValues = {
    moPvDateFrom: dayjs(primaryVessel.moPvDateFrom),
    //   ? new Date(primaryVessel.moPvDateFrom)
    //   : new Date(),
    moPvDateTo:
      primaryVessel.moPvDateTo == null || primaryVessel.moPvDateTo == undefined
        ? null
        : dayjs(primaryVessel.moPvDateTo),
    //   ? new Date(primaryVessel.moPvDateTo)
    //   : null,
    regoNo:
      !pcList?.find((p) => p.pcRegoNo === primaryVessel.regoNo) &&
      !faList?.find((f) => f.favRegoNo === primaryVessel.regoNo)
        ? "other"
        : primaryVessel.regoNo,
    typeRegoNo:
      !pcList?.find((p) => p.pcRegoNo === primaryVessel.regoNo) &&
      !faList?.find((f) => f.favRegoNo === primaryVessel.regoNo)
        ? primaryVessel.regoNo
        : "",
    length: primaryVessel.vesselLength,
    lengthOverall: primaryVessel.vesselLengthOverall,
    surname: surname,
    firstname: firstname,
    vesselName: primaryVessel.vesselName,
    vesselType: primaryVessel.vesselType,
  };

  const {
    control,
    register,
    formState: { errors, isDirty, isValid, dirtyFields },
    handleSubmit,
    watch,
    getFieldState,
    setValue,
    setError,
    clearErrors,
  } = useForm({ defaultValues: initialValues, mode: "onChange" });

  // console.log(getFieldState("typeRegoNo"));
  // console.log(!watch('typeRegoNo'));
  // console.log(watch('moPvDateFrom'));
  // console.log(watch('moPvDateTo'));
  // console.log(errors);

  const updateSuccess = () => {
    gaEvents.MooringPrimaryVesselUpdatedSuccess();
    toastEmitter("Primary vessel is successful updated!", "success");
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    // console.log(primaryVessel);
    // console.log(data);
    if (data.regoNo === "other") {
      await apiPost(
        "/api/mooring/UpdateMoPrimaryVesselDetails",
        {
          ItemId: primaryVessel.itemId,
          MoSeqId: primaryVessel.moSeqId,
          DateFromDay: data.moPvDateFrom.$d.getDate(),
          DateFromMonth: data.moPvDateFrom.$d.getMonth() + 1,
          DateFromYear: data.moPvDateFrom.$d.getFullYear(),
          DateToDay: data.moPvDateTo?.$d.getDate(),
          DateToMonth: data.moPvDateTo?.$d.getMonth() + 1,
          DateToYear: data.moPvDateTo?.$d.getFullYear(),
          RegoNo: data.typeRegoNo,
          VesselType: data.vesselType,
          PreRegoNo: primaryVessel.regoNo,
          VesselOwnerSurname: data.surname,
          VesselOwnerFirstname: data.firstname,
          VesselLength: data.length,
          VesselLengthOverall: data.lengthOverall,
          VesselName: data.vesselName,
          VesselOwnerIsMooringOwner: false,
        },
        false,
        "/mooring/mooringdetails/" + moRegoNo, //redirect to home page
        updateSuccess,
        gaEvents.MooringPrimaryVesselUpdatedFail
      );
    } else {
      //Checking if boat length is over than mooring approval length
      if (pcList.find((p) => p.pcRegoNo === data.regoNo)) {
        let pcobj = pcList.find((p) => p.pcRegoNo === data.regoNo);
        if (
          (pcobj.pcLengthOverall && pcobj.pcLengthOverall > moAppLen) ||
          (pcobj.pcLength && pcobj.pcLength > moAppLen)
        ) {
          return;
        }
      } else if (faList.find((f) => f.favRegoNo === data.regoNo)) {
        let faobj = faList.find((f) => f.favRegoNo === data.regoNo);
        if (
          (faobj.favLengthOverall && faobj.favLengthOverall > moAppLen) ||
          (faobj.favLength && faobj.favLength > moAppLen)
        ) {
          return;
        }
      }

      await apiPost(
        "/api/mooring/UpdateMoPrimaryVesselDetails",
        {
          ItemId: primaryVessel.itemId,
          MoSeqId: primaryVessel.moSeqId,
          DateFromDay: data.moPvDateFrom.$d.getDate(),
          DateFromMonth: data.moPvDateFrom.$d.getMonth() + 1,
          DateFromYear: data.moPvDateFrom.$d.getFullYear(),
          DateToDay: data.moPvDateTo?.$d.getDate(),
          DateToMonth: data.moPvDateTo?.$d.getMonth() + 1,
          DateToYear: data.moPvDateTo?.$d.getFullYear(),
          RegoNo: data.regoNo,
          VesselType: pcList.find((p) => p.pcRegoNo === data.regoNo)
            ? "Rec Ves"
            : "DCV",
          PreRegoNo: primaryVessel.regoNo,
          PcItemId: pcList.find((p) => p.pcRegoNo === data.regoNo)
            ? pcList.find((p) => p.pcRegoNo === data.regoNo).itemId
            : null,
          FavSysId: faList.find((f) => f.favRegoNo === data.regoNo)
            ? faList.find((f) => f.favRegoNo === data.regoNo).favSysId
            : null,
          VesselOwnerIsMooringOwner: true,
        },
        false,
        "/mooring/mooringdetails/" + moRegoNo, //redirect to home page
        updateSuccess,
        gaEvents.MooringPrimaryVesselUpdatedFail
      );
    }
  };

  return (
    <>
      <div className="mooring-updated-container">
        {!isLoadingPost ? (
          <>
            <form
              className="mooring-updated-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mooring-title-group">
                <h3 className="mooring-title">Primary Vessel</h3>
              </div>
              <AlertMessage message={err} />
              <div className="row">
                <div className="col-md-6">
                  <div
                    className={
                      errors.moPvDateFrom?.message
                        ? "mooring-updated-input-group error"
                        : !getFieldState("moPvDateFrom").isDirty
                        ? "mooring-updated-input-group"
                        : "mooring-updated-input-group success"
                    }
                  >
                    <label htmlFor="pvDateFrom">
                      Date From<span className="text-danger">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="moPvDateFrom"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <DatePicker
                            value={value}
                            onChange={onChange}
                            defaultValue={dayjs(primaryVessel.moPvDateFrom)}
                          />
                        );
                      }}
                      rules={{
                        required: "Date From is required",
                        validate: {
                          min: (v) =>
                            new Date(v) >= new Date(1900, 0, 1) ||
                            "Selected year must be greater than 1900",
                        },
                      }}
                      errors={errors}
                    />
                    <span className="msg">{errors.moPvDateFrom?.message}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className={
                      errors.moPvDateTo?.message
                        ? "mooring-updated-input-group error"
                        : !getFieldState("moPvDateTo").isDirty
                        ? "mooring-updated-input-group"
                        : "mooring-updated-input-group success"
                    }
                  >
                    <label htmlFor="pvDateTo">Date To</label>
                    <Controller
                      control={control}
                      name="moPvDateTo"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <DatePicker
                            value={value}
                            onChange={onChange}
                            defaultValue={dayjs(primaryVessel.moPvDateTo)}
                          />
                        );
                      }}
                      rules={{
                        validate: {
                          dateToAfterDateTo: (d) =>
                            validatePickDateNotEarlierDateFrom(
                              watch("moPvDateFrom"),
                              d
                            ),
                          min: (v) =>
                            new Date(v) >= new Date(1900, 0, 1) ||
                            "Selected year must be greater than 1900",
                        },
                      }}
                      errors={errors}
                    />
                    <span className="msg">{errors.moPvDateTo?.message}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div
                    className={
                      errors.regoNo?.message
                        ? "mooring-updated-input-group error"
                        : !getFieldState("regoNo").isDirty
                        ? "mooring-updated-input-group"
                        : "mooring-updated-input-group success"
                    }
                  >
                    <label htmlFor="regoNo">
                      Rego No<span className="text-danger">*</span>
                    </label>
                    <select id="regoNo" {...register("regoNo")}>
                      {pcList &&
                        pcList?.map((p) => (
                          <option
                            key={uuidv4()}
                            value={p?.pcRegoNo}
                            selected={p?.pcRegoNo === watch("regoNo")}
                          >
                            {p?.pcRegoNo} {"(Rec Ves)"}
                          </option>
                        ))}
                      {faList &&
                        faList?.map((f) => (
                          <option
                            key={uuidv4()}
                            value={f?.favRegoNo}
                            selected={f?.favRegoNo === watch("regoNo")}
                          >
                            {f?.favRegoNo} {"(DCV)"}
                          </option>
                        ))}
                      <option value="other">Other vessel</option>
                    </select>
                    <span className="msg">{errors.regoNo?.message}</span>
                  </div>
                </div>

                {watch("regoNo") !== "other" ? (
                  <div className="col-md-6">
                    <div className="mooring-input-group">
                      <label htmlFor="vesselType">Type</label>
                      <div className="mooringInfoField">
                        {pcList &&
                        pcList?.find((p) => p.pcRegoNo === watch("regoNo"))
                          ? "Rec Ves"
                          : faList &&
                            faList?.find((f) => f.favRegoNo === watch("regoNo"))
                          ? "DCV"
                          : ""}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-6">
                    <div className="mooring-updated-input-group">
                      <label htmlFor="vesselType">Type</label>
                      <select id="vesselType" {...register("vesselType")}>
                        <option value="Rec Ves">Rec Ves</option>
                        <option value="DCV">DCV</option>
                      </select>
                    </div>
                  </div>
                )}
              </div>

              {watch("regoNo") !== "other" ? (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mooring-input-group">
                        <label htmlFor="firstname">First Name</label>
                        <div className="mooringInfoField">{firstname}</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mooring-input-group">
                        <label htmlFor="surname">Surname</label>
                        <div className="mooringInfoField">{surname}</div>
                      </div>
                    </div>
                  </div>

                  {pcList &&
                    pcList?.map((p) =>
                      p?.pcRegoNo === watch("regoNo") ? (
                        <>
                          <div key={uuidv4()}>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mooring-input-group">
                                  <label htmlFor="vesselName">Boat Name</label>
                                  <div className="mooringInfoField">
                                    {p?.pcName}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mooring-input-group">
                                  <label htmlFor="status">Status</label>
                                  <div className="mooringInfoField">
                                    {p?.pcStatus}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mooring-input-group">
                                  <label htmlFor="length">Length</label>
                                  <div className="mooringInfoField">
                                    {p?.pcLength}
                                  </div>
                                  <span className="msg text-danger">
                                    {p?.pcLength &&
                                      p?.pcLength >= moAppLen &&
                                      "Over mooring approval length"}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mooring-input-group">
                                  <label htmlFor="lengthOverall">
                                    Length Overall
                                  </label>
                                  <div className="mooringInfoField">
                                    {p?.pcLengthOverall}
                                  </div>
                                  <span className="msg text-danger">
                                    {p?.pcLengthOverall &&
                                      p?.pcLengthOverall >= moAppLen &&
                                      "Over mooring approval length"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    )}

                  {faList &&
                    faList?.map((f) =>
                      f?.favRegoNo === watch("regoNo") ? (
                        <>
                          <div key={uuidv4()}>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mooring-input-group">
                                  <label htmlFor="vesselName">Boat Name</label>
                                  <div className="mooringInfoField">
                                    {f?.favVesName}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mooring-input-group">
                                  <label htmlFor="status">Status</label>
                                  <div className="mooringInfoField">
                                    {f?.favStatusCode}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mooring-input-group">
                                  <label htmlFor="length">Length</label>
                                  <div className="mooringInfoField">
                                    {f?.favLength}
                                  </div>
                                  <span className="msg text-danger">
                                    {f?.favLength &&
                                      f?.favLength >= moAppLen &&
                                      "Over mooring approval length"}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mooring-input-group">
                                  <label htmlFor="lengthOverall">
                                    Length Overall
                                  </label>
                                  <div className="mooringInfoField">
                                    {f?.favLengthOverall}
                                  </div>
                                  <span className="msg text-danger">
                                    {f?.favLengthOverall &&
                                      f?.favLengthOverall >= moAppLen &&
                                      "Over mooring approval length"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    )}
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className={
                          errors.typeRegoNo?.message
                            ? "mooring-updated-input-group error"
                            : !getFieldState("typeRegoNo").isDirty
                            ? "mooring-updated-input-group"
                            : "mooring-updated-input-group success"
                        }
                      >
                        <label htmlFor="typeRegoNo">
                          Other Rego No{" "}
                          {!(watch("typeRegoNo") || watch("vesselName")) && (
                            <span className="text-danger">*</span>
                          )}
                        </label>
                        <input
                          type="text"
                          id="typeRegoNo"
                          {...register("typeRegoNo", {
                            required: {
                              value: !(
                                watch("typeRegoNo") || watch("vesselName")
                              ),
                              message: "Either Rego or Boat name is required.",
                            },
                          })}
                        />
                        <span className="msg">
                          {errors.typeRegoNo?.message}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className={
                          errors.vesselName?.message
                            ? "mooring-updated-input-group error"
                            : !getFieldState("vesselName").isDirty
                            ? "mooring-updated-input-group"
                            : "mooring-updated-input-group success"
                        }
                      >
                        <label htmlFor="vesselName">
                          Boat Name
                          {!(watch("typeRegoNo") || watch("vesselName")) && (
                            <span className="text-danger">*</span>
                          )}
                        </label>
                        <input
                          type="text"
                          id="vesselName"
                          {...register("vesselName", {
                            required: {
                              value: !(
                                watch("typeRegoNo") || watch("vesselName")
                              ),
                              message: "Either Rego or Boat name is required.",
                            },
                          })}
                        />
                        <span className="msg">
                          {errors.vesselName?.message}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mooring-updated-input-group">
                        <label htmlFor="firstname">Owner First Name</label>
                        <input
                          type="text"
                          id="firstname"
                          {...register("firstname")}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mooring-updated-input-group">
                        <label htmlFor="surname">Owner Surname</label>
                        <input
                          type="text"
                          id="surname"
                          {...register("surname")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className={
                          errors.length?.message
                            ? "mooring-updated-input-group error"
                            : !getFieldState("length").isDirty
                            ? "mooring-updated-input-group"
                            : "mooring-updated-input-group success"
                        }
                      >
                        <label htmlFor="length">
                          Length
                          {!(watch("length") || watch("lengthOverall")) && (
                            <span className="text-danger">*</span>
                          )}
                        </label>
                        <input
                          type="number"
                          step="0.01"
                          id="length"
                          {...register("length", {
                            required: {
                              value: !(
                                watch("length") || watch("lengthOverall")
                              ),
                              message:
                                "Either Hull length or overall is required.",
                            },
                            pattern: {
                              value: /^[0-9.,]+$/,
                              message: "Only number allowed",
                            },
                            max: {
                              value:
                                moAppLen == null || moAppLen <= 0
                                  ? Number.MAX_VALUE
                                  : moAppLen,
                              message: `Length must be less than Mooring Approved Length (${moAppLen})`,
                            },
                          })}
                        />
                        <span className="msg">{errors.length?.message}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className={
                          errors.lengthOverall?.message
                            ? "mooring-updated-input-group error"
                            : !getFieldState("lengthOverall").isDirty
                            ? "mooring-updated-input-group"
                            : "mooring-updated-input-group success"
                        }
                      >
                        <label htmlFor="lengthOverall">
                          Length Overall
                          {!(watch("length") || watch("lengthOverall")) && (
                            <span className="text-danger">*</span>
                          )}
                        </label>
                        <input
                          type="number"
                          step="0.01"
                          id="lengthOverall"
                          {...register("lengthOverall", {
                            required: {
                              value: !(
                                watch("length") || watch("lengthOverall")
                              ),
                              message:
                                "Either Hull length or overall is required.",
                            },
                            pattern: {
                              value: /^[0-9.,]+$/,
                              message: "Only number allowed",
                            },
                            max: {
                              value:
                                moAppLen == null || moAppLen <= 0
                                  ? Number.MAX_VALUE
                                  : moAppLen,
                              message: `Length must be less than Mooring Approved Length (${moAppLen})`,
                            },
                          })}
                        />
                        <span className="msg">
                          {errors.lengthOverall?.message}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="row">
                <div className="col-md-3">
                  <button
                    type="button"
                    className="cancel-btn"
                    onClick={() =>
                      navigate("/mooring/mooringdetails/" + moRegoNo, {
                        state: { from: location },
                        replace: true,
                      })
                    }
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-md-6" />
                <div className="col-md-3">
                  <button
                    type="submit"
                    className="pna-updated-form-button"
                    disabled={Object.keys(errors).length > 0}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </>
        ) : (
          <SubmitLoader isLoading={isLoadingPost} />
        )}
      </div>
    </>
  );
};

export default MooringPrimaryVesselUpdatedForm;
