import EngineReRegiDescription from "../EngineDetails/EngineReRegiDescription";
import { useFieldArray } from "react-hook-form";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FormEngineReRegiDetail = ({
  register,
  watch,
  errors,
  getFieldState,
  control,
  engineArrayPropName,
}) => {
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: engineArrayPropName,
    defaultValues: {
      [engineArrayPropName]: [],
    },
  });

    const initialEngine = {
        PePropulsion: null,
        PeFuel: null,
        PeMake: null,
        PeSerialNo: null,
        PeCapacity: null,
        PeYearMade: null,
        PeCapacityType: null,
        PeSequNo: null,
    };

  return (
    <>
      <div className="br-title">Engine List</div>
      <p>
        Please confirm the detail for each motor installed on the vessel
        (including auxiliary). Use the “Click to add” button if you need to
        include additional motors.
      </p>

      {fields.map((field, index) => (
        <div key={field.id} className="br-engine-desc-container">
          <EngineReRegiDescription
            control={control}
            index={index}
            engineArrayPropName={engineArrayPropName}
            register={register}
            watch={watch}
            getFieldState={getFieldState}
            remove={remove}
            fields={fields}
          />
        </div>
      ))}

      <div className="br-add-engine-box">
        <span>Click to add an additional engine</span>
        <FontAwesomeIcon
          icon={faCirclePlus}
          className="br-engine-add-icon"
          size="2x"
          beat
          onClick={() => {
            append(initialEngine);
          }}
        />
      </div>
    </>
  );
};

export default FormEngineReRegiDetail;
