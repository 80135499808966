import { useGet } from "../../Hooks/useFetch";
import { useEffect } from "react";
import DataLoader from "../../Components/DataLoader";
import TransferPopupMessage from "./TransferPopupMessage";

const TransferExistedCheck = ({ element, requestUrl, approvedUser, approvingUser }) => {
  const [res, err, isLoading, apiGet, abt] = useGet();
  
  useEffect(() => {
    apiGet(requestUrl);
  }, []);

  return (
    <>
      {!isLoading ? (
        !err ? (
          res?.length > 0 ? (
            <TransferPopupMessage res={res} approvedUser={approvedUser} approvingUser={approvingUser}/>
          ) : (
            <div>{ element }</div>
          )
        ) : (
            <div>{ element }</div>
        )
      ) : (
        <div className="center-block">
          <DataLoader isLoading={isLoading} />
        </div>
      )}
    </>
  );
};

export default TransferExistedCheck;
