import { useNavigate, useLocation } from "react-router-dom";

const NotUnsafeSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="br-container">
      <div className="br-form-container">
        <div className="br-box">
          <div className="br-title">Declaration Successful</div>
          <p>Thank you for completing declaration and MAST have been notified. </p>
          <div className="br-btn-group-success-page">
            <button
              type="button"
              onClick={() =>
                navigate("/", {
                  state: { from: location },
                  replace: false,
                })
              }
              className="br-button"
            >
              Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotUnsafeSuccess;
