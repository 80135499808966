import OrganisationOperator from "./OrganisationOperator";
import PnaContactReadOnly from "./PnaContactReadOnly";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useWatch } from "react-hook-form";
import AddressFilter from "../AddressFilter";
import { useEffect } from "react";
import UploadInput from "../UploadInput";
import { validateMiniAge, getAuMobileRegex } from "../../Helpers/Helper";

const PrimaryContact = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  clearErrors,
  isCompanyFieldName,
  prefixIsCompanyField = "",
  resPna,
  isDocNeededForOrg = false,
  setError,
  uploadSizeLimit,
  title = "New Registered Operator",
  setValue
}) => {
  let email = useWatch({
    control,
    name: `${prefixIsCompanyField}.individualEmail`,
  });
  let mobile = useWatch({
    control,
    name: `${prefixIsCompanyField}.individualMobile`,
  });
  useEffect(() => {
    if (email) {
      clearErrors(`${prefixIsCompanyField}.individualMobile`);
    }

    if (mobile) {
      clearErrors(`${prefixIsCompanyField}.individualEmail`);
    }
  }, [email, mobile]);


  const validHandler = (event) => {
    let file = event.target.files[0];
    // console.log(file);
    clearErrors(event.target.name);
    if (file?.size > uploadSizeLimit) {
      // console.log("over size");
      setError(event.target.name, {
        type: "oversize",
        message:
          "File is over the limit size (" +
          Math.floor(uploadSizeLimit / 1000000) +
          "MB).",
      });
    }
  };

  return (
    <>
      <div className="br-box">
        {watch(`${isCompanyFieldName}`) === "Organisation" ? (
          <OrganisationOperator
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            IsDocNeeded={isDocNeededForOrg}
            setError={setError}
            clearErrors={clearErrors}
            uploadSizeLimit={uploadSizeLimit}
            prefixIsCompanyField={prefixIsCompanyField}
            title={title}
            setValue={setValue}
          />
        ) : resPna != null ? (
          <PnaContactReadOnly title={title} pna={resPna} />
        ) : (
          <div className="br-box">
            <div className="br-title">{title}</div>
            <div className="br-field-group">
              <div
                className={
                  getFieldState(`${prefixIsCompanyField}.individualGivenName`)
                    .invalid
                    ? "br-input-group error"
                    : !getFieldState(`${prefixIsCompanyField}.individualGivenName`)
                        .isDirty
                    ? "br-input-group"
                    : "br-input-group success"
                }
              >
                <label htmlFor="individualGivenName">
                  Given Name(s)<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="individualGivenName"
                  {...register(`${prefixIsCompanyField}.individualGivenName`, {
                    required: "Given Name(s) is required",
                    pattern: {
                      value: /^[A-Z][A-Za-z-.&]{0,}(\s[A-Z][A-Za-z-.&]{0,})*$/,
                      message:
                        "First letter uppercase. Number, special characters and last space are not allowed. Only (- . &) allowed",
                    },
                  })}
                />
                <span className="msg">
                  {
                    getFieldState(`${prefixIsCompanyField}.individualGivenName`)
                      .error?.message
                  }
                </span>
              </div>
              <div
                className={
                  getFieldState(`${prefixIsCompanyField}.individualSurName`)
                    .invalid
                    ? "br-input-group error"
                    : !getFieldState(`${prefixIsCompanyField}.individualSurName`)
                        .isDirty
                    ? "br-input-group"
                    : "br-input-group success"
                }
              >
                <label htmlFor="individualSurName">
                  Surname<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="individualSurName"
                  {...register(`${prefixIsCompanyField}.individualSurName`, {
                    required: "Surname is required",
                    pattern: {
                      value: /^[A-Z]{1}[A-Za-z-.]{0,}$/,
                      message:
                        "First letter uppercase. Number and special characters are not allowed. Only (- .) allowed",
                    },
                  })}
                />
                <span className="msg">
                  {
                    getFieldState(`${prefixIsCompanyField}.individualSurName`)
                      .error?.message
                  }
                </span>
              </div>
              <div
                className={
                  getFieldState(`${prefixIsCompanyField}.individualDOB`).invalid
                    ? "br-input-group error"
                    : "br-input-group"
                }
              >
                <label htmlFor="individualDOB">Date of Birth</label>
                <Controller
                  control={control}
                  name={`${prefixIsCompanyField}.individualDOB`}
                  render={({ field: { onChange, value } }) => {
                    return <DatePicker value={value} onChange={onChange} />;
                  }}
                  rules={{
                    validate: {
                      greaterThanSixteen: (d) => validateMiniAge(d),
                      min: (v) =>
                        v == undefined ||
                        v == null ||
                        new Date(v) >= new Date(1900, 0, 1) ||
                        "Selected year must be greater than 1900",
                      max: (v) =>
                        v == undefined ||
                        v == null ||
                        new Date(v) <= new Date() ||
                        "Selected date must be less than today",
                    },
                  }}
                  errors={errors}
                />
                <span className="msg">
                  {
                    getFieldState(`${prefixIsCompanyField}.individualDOB`).error
                      ?.message
                  }
                </span>
              </div>
              <div
                className={
                  getFieldState(`${prefixIsCompanyField}.individualResAddress`)
                    .invalid
                    ? "br-input-group error"
                    : "br-input-group"
                }
              >
                <label htmlFor="individualResAddress">Residential</label>
                <Controller
                  control={control}
                  name={`${prefixIsCompanyField}.individualResAddress`}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <AddressFilter
                        onChange={(val) => onChange(val?.value)}
                        defaultValue={value}
                      />
                    );
                  }}
                  errors={errors}
                />
                <span className="msg">
                  {
                    getFieldState(`${prefixIsCompanyField}.individualResAddress`)
                      .error?.message
                  }
                </span>
              </div>

              {/* {watch(`${prefixIsCompanyField}.individualResAddress`) && (
                <div className="br-checkbox-group">
                  <input
                    type="checkbox"
                    id="individualCheckPostal"
                    {...register(`${prefixIsCompanyField}.individualCheckPostal`)}
                  />
                  <label htmlFor="individualCheckPostal" className="unSelectable">
                    Postal Same as Residential
                  </label>
                </div>
              )}

              {watch(`${prefixIsCompanyField}.individualCheckPostal`) === true ? (
                ""
              ) : (
                <div
                  className={
                    getFieldState(`${prefixIsCompanyField}.individualPoAddress`)
                      .invalid
                      ? "br-input-group error"
                      : "br-input-group"
                  }
                >
                  <label htmlFor="individualPoAddress">Postal</label>
                  <Controller
                    control={control}
                    name={`${prefixIsCompanyField}.individualPoAddress`}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <AddressFilter
                          onChange={(val) => onChange(val?.value)}
                          defaultValue={value}
                        />
                      );
                    }}
                    errors={errors}
                  />
                  <span className="msg">
                    {
                      getFieldState(`${prefixIsCompanyField}.individualPoAddress`)
                        .error?.message
                    }
                  </span>
                </div>
              )} */}

              <div
                className={
                  getFieldState(`${prefixIsCompanyField}.individualEmail`).invalid
                    ? "br-input-group error"
                    : !getFieldState(`${prefixIsCompanyField}.individualEmail`)
                        .isDirty
                    ? "br-input-group"
                    : "br-input-group success"
                }
              >
                <label htmlFor="individualEmail">
                  Email{" "}
                  {watch(`${prefixIsCompanyField}.individualMobile`) ? (
                    ""
                  ) : (
                    <span className="text-danger">*</span>
                  )}
                </label>
                <input
                  type="email"
                  id="individualEmail"
                  {...register(`${prefixIsCompanyField}.individualEmail`, {
                    required: {
                      value: watch(`${prefixIsCompanyField}.individualMobile`)
                        ? false
                        : true,
                      message: "Email or Mobile is required",
                    },
                    pattern: {
                      value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                      message: "Email format error",
                    },
                  })}
                />
                <span className="tsf-sub-text">
                  Either email or mobile is required
                </span>
                <span className="msg">
                  {
                    getFieldState(`${prefixIsCompanyField}.individualEmail`).error
                      ?.message
                  }
                </span>
              </div>
              <div
                className={
                  getFieldState(`${prefixIsCompanyField}.individualMobile`).invalid
                    ? "br-input-group error"
                    : !getFieldState(`${prefixIsCompanyField}.individualMobile`)
                        .isDirty
                    ? "br-input-group"
                    : "br-input-group success"
                }
              >
                <label htmlFor="individualMobile">
                  Mobile{" "}
                  {watch(`${prefixIsCompanyField}.individualEmail`) ? (
                    ""
                  ) : (
                    <span className="text-danger">*</span>
                  )}
                </label>
                <input
                  type="tel"
                  id="mobile"
                  {...register(`${prefixIsCompanyField}.individualMobile`, {
                    required: {
                      value: watch(`${prefixIsCompanyField}.individualEmail`)
                        ? false
                        : true,
                      message: "Email or Mobile is required",
                    },
                    pattern: {
                      value: getAuMobileRegex(),
                      message: "Mobile format error",
                    },
                  })}
                  placeholder="04XX XXX XXX"
                />
                <span className="tsf-sub-text">
                  Either email or mobile is required
                </span>
                <span className="msg">
                  {
                    getFieldState(`${prefixIsCompanyField}.individualMobile`).error
                      ?.message
                  }
                </span>
              </div>
              {isDocNeededForOrg && (
                <div
                  className={
                    getFieldState(`${prefixIsCompanyField}.individualFile`).invalid
                      ? "br-input-group error"
                      : !getFieldState(`${prefixIsCompanyField}.individualFile`)
                          .isDirty
                      ? "br-input-group"
                      : "br-input-group success"
                  }
                >
                  <label htmlFor="individualFile">
                    Scanned copy or photo of completed paper Transfer of
                    Registration form
                  </label>
                  <UploadInput
                    register={register}
                    onChangeEvent={validHandler}
                    assignName={`${prefixIsCompanyField}.individualFile`}
                    watch={watch}
                    setValue={setValue}
                  />
                  <span className="msg">
                    {
                      getFieldState(`${prefixIsCompanyField}.individualFile`).error
                        ?.message
                    }
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PrimaryContact;
