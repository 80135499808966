import DialogForList from "../../Components/Modal/DialogForList";
import { useNavigate, useLocation } from "react-router-dom";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { getddmmyyyyFormat } from "../../Helpers/Helper";
import '../../Components/Modal/Dialog.css';


const TransferPopupMessage = ({res, approvedUser, approvingUser}) => {

    const [isOpen, setIsOpen] = useState(res?.length > 0);

    const navigate = useNavigate();
    const location = useLocation();
  
    const clickNav = (destination) => {
      // console.log(destination);
      navigate("/" + destination, { state: { from: location }, replace: false });
    };

    const subTitleMessage = (endUser, ingUser) => {
      return (
        <div>The {endUser} of following vessel is waiting for your {ingUser} approval. Please complete the approval process by pressing <b>Approve</b> below:</div>
      )
    }

    return ( 
        <div>
              {isOpen && (
                <DialogForList
                  setOpenDialog={setIsOpen}
                  title="ALERT - Waiting for approval"
                  subTitle={subTitleMessage(approvedUser, approvingUser)}
                  children={res?.map((t, index) => (
                    <div
                      className="dialog-list-row"
                      key={index}
                      onClick={() =>
                        clickNav(t?.tsfSourceLink + "/" + t?.regoNo)
                      }
                    >
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        beat
                        className="text-danger"
                      />{" "}
                      {t?.tsfSourceLink === "transferseller"
                        ? "(B) Approve"
                        : "(S) Approve"}{" "}
                      {"-"}{" "}
                      {t?.dateOfTransfer
                        ? getddmmyyyyFormat(t?.dateOfTransfer?.split("T")[0])
                        : "?"}
                      {" - "}
                      {t?.regoNo ? t?.regoNo : "?"}
                      {" - "}
                      {t?.fullName ? t?.fullName : "?"}
                    </div>
                  ))}
                  redirectUrl="/"
                />
              )}
            </div>
     );
}
 
export default TransferPopupMessage;