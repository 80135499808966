import BoatReNewRegistrationProcess from "../../Components/BoatRegistration/BoatReNewRegistrationProcess";
import { useGet } from "../../Hooks/useFetch";
import { useEffect } from "react";
import DataLoader from "../../Components/DataLoader";
import AlertMessage from "../../Components/AlertMessage";
import { decryptText } from "../../Helpers/Helper";

const BoatNewReRegistrationForm = () => {
  const [resKey, err, isLoadingKey, apiGetKey, abtKey] = useGet();

  useEffect(() => {
    apiGetKey("/api/pcraft/PayWayKey");
  }, []);

  return (
    <>
      {!isLoadingKey ? (
        !err ? (
          <BoatReNewRegistrationProcess
            payWayPublicKey={decryptText(
              resKey,
              process.env.REACT_APP_Encrypt_Key,
              process.env.REACT_APP_Encrypt_IV
            )}
          />
        ) : (
          <AlertMessage message={err} />
        )
      ) : (
        <DataLoader isLoading={isLoadingKey} />
      )}
    </>
  );
};

export default BoatNewReRegistrationForm;
