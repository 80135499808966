import { useEffect, useState } from "react";
import { usePdf, useGet, usePost } from "../../Hooks/useFetch";
import { useForm } from "react-hook-form";
import LicencePWCendorsementList from "./LicencePWCendorsementList";
import { getddmmyyyyFormat, validatedExpiredDate } from "../../Helpers/Helper";
import DataLoader from "../DataLoader";
import { EditClickEventIcon, PrintIcon } from "../Icons";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import Dialog from "../Modal/Dialog";
import {
  faTriangleExclamation,
  faIdCard,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import useGaEvent from "../../Hooks/useGaEvent";
import useToast from "../../Hooks/useToast";
import AlertMessage from "../AlertMessage";

const LicenceDetail = () => {
  const [resLic, errLic, isLoadingLic, apiGet, abtLic] = useGet();
  const [errPdf, loadingPdf, apiPdf, abortPdf] = usePdf();
  const [resUpdate, errUpdate, isUpdateLoading, apiPost, abt] = usePost();
  const [isEdit, setIsEdit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { auth } = useAuth();
  const gaEvents = useGaEvent();
  const toastEmitter = useToast();

  const printPdf = async () => {
    if (!auth?.isAblePrintOrRegister) {
      setOpenDialog(true);
      return;
    }
    await apiPdf(
      "/api/licence/licence-pdf",
      gaEvents.licencePrintSuccess,
      gaEvents.licencePrintFail
    );
    // if (errPdf) console.log(errPdf);
  };

  useEffect(() => {
    apiGet("/api/licence/LicenceDecryptedData");
  }, []);

  // console.log(resLic);
  const initialValues = {
    visualAids: resLic?.dlVisualAids ?? "N",
    colourBlind: resLic?.dlColourBlind ?? "N",
  };

  const {
    register,
    formState: { errors, isDirty, isValid, dirtyFields },
    handleSubmit,
    reset,
    watch,
  } = useForm({ defaultValues: initialValues, mode: "onChange" });

  useEffect(() => {
    reset(initialValues);
  }, [resLic?.dlVisualAids, resLic?.dlColourBlind]);

  const afterUpdate = () => {
    if (!errUpdate) {
      apiGet("/api/licence/LicenceDecryptedData");
    }
    setIsEdit(false);
    gaEvents.licenceUpdateSuccess();
    toastEmitter("Licence is successful updated!", "success");
  };

  const navigate = useNavigate();
  const location = useLocation();

  const clickNav = (destination) => {
    // console.log(destination);
    navigate("/" + destination, { state: { from: location }, replace: false });
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    // console.log(data);

    await apiPost(
      "/api/licence/updateLicence",
      {
        VisualAids: data.visualAids,
        ColourBlind: data.colourBlind,
      },
      false,
      null,
      afterUpdate,
      gaEvents.licenceUpdateFail
    );
  };

  return (
    <>
      <div className="licence-sub-container">
        <div>
          <AlertMessage message={errPdf} mt={0} />
          <div className="licence-title-group">
            <h3 className="licence-title">
              <span>Licence</span>
              {resLic && !validatedExpiredDate(resLic?.dlExpiryDate) && (
                <PrintIcon
                  iconSize="1x"
                  classLists="licence-printer icon"
                  clickEvent={printPdf}
                  isLoading={loadingPdf}
                  HoverCaption="Print Licence"
                />
              )}
            </h3>
            {resLic && !validatedExpiredDate(resLic?.dlExpiryDate) && (
              <div className="tooltip-2">
                <button
                  type="button"
                  className="licence-button"
                  onClick={() => clickNav("shoppingcart")}
                >
                  <span className="hidden-lic-less-700">
                    {" "}
                    Purchase Licence Card
                  </span>
                  <span className="show-lic-less-700">
                    {" "}
                    Buy <FontAwesomeIcon icon={faIdCard} />
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>

        {!isLoadingLic ? (
          !errLic ? (
            !resLic ? (
              <AlertMessage
                message="No Licence"
                level="info"
                closeEvt="false"
                mt="0"
                mb="0"
              />
            ) : (
              <>
                <div className="row">
                  <div className="col-md-12">
                    <div className="licence-input-group">
                      <label htmlFor="LicenceNo">Licence No.</label>
                      <div className="licenceInfoField">
                        {resLic?.dlLicenceNo}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="licence-input-group">
                      <label htmlFor="DateIssued">Date Issued</label>
                      <div className="licenceInfoField">
                        {getddmmyyyyFormat(resLic?.dlDateIssued?.split("T")[0])}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="licence-input-group">
                      <label htmlFor="ExpiryDate">Expiry Date</label>
                      <div className="licenceInfoField unSelectable">
                        {validatedExpiredDate(resLic?.dlExpiryDate) ? (
                          <Tooltip title="EXPIRED" placement="top" arrow>
                            <span className="text-danger">
                              {getddmmyyyyFormat(
                                resLic?.dlExpiryDate?.split("T")[0]
                              )}{" "}
                              <FontAwesomeIcon icon={faTriangleExclamation} />
                            </span>
                          </Tooltip>
                        ) : (
                          getddmmyyyyFormat(resLic?.dlExpiryDate?.split("T")[0])
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="licence-input-group">
                      <label htmlFor="Provisional">Provisional</label>
                      <div className="licenceInfoField">
                        {resLic?.dlProvisional}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="licence-input-group">
                      <label htmlFor="Restricted">Restricted</label>
                      <div className="licenceInfoField">
                        {resLic?.dlRestricted}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="licence-input-group">
                      <label htmlFor="dlCancelled">Cancelled</label>
                      <div className="licenceInfoField">
                        {resLic?.dlCancelled}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="licence-input-group">
                      <label htmlFor="CancelledDate">Cancelled Date</label>
                      <div className="licenceInfoField">
                        {getddmmyyyyFormat(
                          resLic?.dlCancelledDate?.split("T")[0]
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          ) : (
            <AlertMessage message={errLic} />
          )
        ) : (
          <DataLoader isLoading={isLoadingLic} />
        )}
      </div>

      <LicencePWCendorsementList />

      {resLic && (
        <div className="licence-sub-container">
          {(resLic?.status==0 || resLic?.status==2 || resLic?.status==3) && <AlertMessage message={resLic?.statusReason} level="warning" closeEvt="false" mt="0"/>}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="licence-title-group">
              <h3 className="licence-title">Licence Update</h3>
              <div>
                {!isEdit ? (
                  resLic?.dlVisualAids === "Y" &&
                  resLic?.dlColourBlind === "Y" ? (
                    ""
                  ) : (
                    <EditClickEventIcon
                      clickEvent={() => setIsEdit(true)}
                      iconSize="xl"
                      HoverCaption="Edit Licence"
                    />
                  )
                ) : (
                  <>
                    <div className="licence-btn-gp">
                      <button
                        type="button"
                        className="cancel-licence-button"
                        onClick={() => setIsEdit(false)}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="licence-button">
                        Save
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <AlertMessage message={errUpdate} mt={0} />
            {!isUpdateLoading ? (
              !isLoadingLic ? (
                !errLic ? (
                  <>
                    {!isEdit ? (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="licence-input-group">
                              <label htmlFor="VisualAids">Visual Aids</label>
                              <div className="licenceInfoField">
                                {resLic?.dlVisualAids === "Y" ? "YES" : "NO"}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="licence-input-group">
                              <label htmlFor="ColourBlind">
                                Eyesight Colour Deficiency
                              </label>
                              <div className="licenceInfoField">
                                {resLic?.dlColourBlind === "Y" ? "YES" : "NO"}
                              </div>
                            </div>
                          </div>
                        </div>
                        {resLic?.dlVisualAids === "Y" &&
                        resLic?.dlColourBlind === "Y" ? (
                          <span className="licence-sub-title">
                            You cannot change Visual Aids or Eyesight Colour
                            Defficiency without providing documention to MAST.
                            Please contact MAST to discuss this further or
                            request changes.
                          </span>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="licence-input-group">
                              <label htmlFor="VisualAids">Visual Aids</label>
                              <select
                                id="visualAids"
                                {...register("visualAids", {
                                  required: "This is required",
                                })}
                                disabled={resLic?.dlVisualAids === "Y"}
                              >
                                <option value="Y">YES</option>
                                <option value="N">NO</option>
                              </select>
                              <span className="msg">
                                {errors.visualAids?.message}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="licence-input-group">
                              <label htmlFor="ColourBlind">
                                Eyesight Colour Deficiency
                              </label>
                              <select
                                id="colourBlind"
                                {...register("colourBlind", {
                                  required: "This is required",
                                })}
                                disabled={resLic?.dlColourBlind === "Y"}
                              >
                                <option value="Y">YES</option>
                                <option value="N">NO</option>
                              </select>
                              <span className="msg">
                                {errors.colourBlind?.message}
                              </span>
                            </div>
                          </div>
                        </div>
                        <span className="licence-sub-title">
                          If you want to change Visual Aid from YES to NO,
                          please contact MAST.
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <AlertMessage message={errLic} />
                  </>
                )
              ) : (
                <DataLoader isLoading={isLoadingLic} />
              )
            ) : (
              <DataLoader isLoading={isUpdateLoading} />
            )}
          </form>
        </div>
      )}

      {openDialog && (
        <Dialog
          setOpenDialog={setOpenDialog}
          title="ALERT"
          children="You have recently made changes to personal details. Some functions (Printing Certificates, New Vessel Registrations) are unavailable until MAST have confirmed these changes."
        />
      )}
    </>
  );
};

export default LicenceDetail;
