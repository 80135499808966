import "../RecVessel_PWC/RecVesselUpdated.css";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { usePost } from "../../Hooks/useFetch";
import PeEngineUpdatedFields from "./PeEngineUpdatedFields";
import SubmitLoader from "../SubmitLoader";
import useGaEvent from "../../Hooks/useGaEvent";
import useToast from "../../Hooks/useToast";
import AlertMessage from "../AlertMessage";

const PeEngineUpdatedForm = ({ peDetails }) => {
  // console.log(peDetails);
  const gaEvents = useGaEvent();
  const toastEmitter = useToast();
  const initialValues = {
    // peRegoNo
    // peSequNo
    pePropulsion: peDetails?.pePropulsion,
    peFuel: peDetails?.peFuel,
    peMake: peDetails?.peMake,
    peModel: peDetails?.peModel,
    peSerialNo: peDetails?.peSerialNo,
    peYearMade: peDetails?.peYearMade,
    peCapacity: peDetails?.peCapacity,
    peCapacityType: peDetails?.peCapacityType,
    peCc: peDetails?.peCc,
    peType: peDetails?.peType,
    peCurrent: peDetails?.peCurrent,
  };

  const {
    control,
    register,
    formState: { errors, isDirty, isValid, dirtyFields },
    handleSubmit,
    watch,
    getFieldState,
    setValue,
    setError,
    clearErrors,
  } = useForm({ defaultValues: initialValues, mode: "onChange" });

  const [res, err, isLoadingPost, apiPost, abt] = usePost();
  const navigate = useNavigate();
  const location = useLocation();

  const updateSuccess = () => {
    gaEvents.RecEngineDetailUpdateSuccess();
    toastEmitter("Engine is successful updated!", "success");
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    await apiPost(
      "/api/pcraft/updatePeEngineDetails",
      {
        PeRegoNo: peDetails?.peRegoNo,
        PeSequNo: peDetails?.peSequNo,
        PeMake: data?.peMake,
        PeModel: data?.peModel,
        PeType: data?.peType,
        PeSerialNo: data?.peSerialNo,
        PeCapacity: data?.peCapacity,
        PeCapacityType: data?.peCapacityType,
        PeCurrent: data?.peCurrent,
        PeFuel: data?.peFuel,
        PeYearMade: data?.peYearMade,
        PeCc: data?.peCc?.toString(),
        PePropulsion: data?.pePropulsion,
      },
      false,
      "/recvessel/peenginedetails/" +
        peDetails?.peRegoNo +
        "/" +
        peDetails?.peSequNo,
      updateSuccess,
      gaEvents.RecEngineDetailUpdateFail
    );

  };

  return (
    <div className="recvessel-updated-form-container">
      <div className="recvessel-title-group ">
        <h3 className="recvessel-title">Vessel Engine</h3>
      </div>
      <AlertMessage message={err} />
      {!isLoadingPost ? (
        <form
          className="recvessel-updated-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <PeEngineUpdatedFields
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
          />
          <div className="row">
            <div className="col-md-3">
              <button
                type="button"
                className="cancel-btn"
                onClick={() =>
                  navigate(
                    "/recvessel/recvesseldetails/" + peDetails?.peRegoNo,
                    { state: { from: location }, replace: true }
                  )
                }
              >
                Cancel
              </button>
            </div>
            <div className="col-md-6" />
            <div className="col-md-3">
              <button
                type="submit"
                className="pna-updated-form-button"
                disabled={Object.keys(errors).length > 0}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      ) : (
        <SubmitLoader isLoading={isLoadingPost} />
      )}
    </div>
  );
};

export default PeEngineUpdatedForm;
