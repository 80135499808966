import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PayWay from "../BoatRegistration/Payments/PayWay";

const BuyerPayProcess = ({
  errPayWayFrame,
  errPayWayToken,
  tokenId,
  creditCard,
  createFrame,
  stateDestroy,
  appFee,
  dateFrom,
  setValue,
}) => {
  return (
    <>
      <div className="br-box">
        <div className="br-title">Payment</div>
        <p>
            The fee is a flat fee for all boats (not based on length or vessel value) – the fee applicable from {dateFrom} is ${appFee?.toFixed(2)}.
        </p>
        <p>The fee indicated a once off Application Fee.</p>
        <p>
            The fee is indexed each year and will go up essentially by CPI each July.
        </p>
        <p>
          If you choose to pay on-line using a credit card, MAST only accepts
          <strong> Mastercard</strong> or <strong> Visa</strong>.
        </p>
        <div className="br-title amount-group">
          <div>Amount payable</div>
          <div>${appFee ? appFee?.toFixed(2) : <FontAwesomeIcon icon={faQuestion} />}</div>
        </div>
      </div>
      <PayWay
        errPayWayFrame={errPayWayFrame}
        errPayWayToken={errPayWayToken}
        tokenId={tokenId}
        creditCard={creditCard}
        createFrame={createFrame}
        stateDestroy={stateDestroy}
        setValue={setValue}
      />
    </>
  );
};

export default BuyerPayProcess;
