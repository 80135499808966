import BuyerVesselSoldInterstate from "./BuyerVesselSoldInterstate";

const Disposal = ({ register, watch, control, errors, getFieldState }) => {
  return (
    <>
      <div className="br-box">
        <div className="br-title">
          The reason of disposal<span className="text-danger">*</span>{" "}
        </div>
        <div
          className={
            errors.disposalReason?.message
              ? "br-input-group error"
              : "br-input-group"
          }
        >
          <div className="br-field-radio-group">
            <div className="br-radio-group">
              <input
                type="radio"
                value="Vessel sold interstate"
                id="vesselSoldInterstate"
                {...register("disposalReason", {
                  required: {
                    value: true,
                    message: "It is required",
                  },
                })}
              />
              <label htmlFor="vesselSoldInterstate">
                Vessel sold interstate
              </label>
            </div>
            <div className="br-radio-group">
              <input
                type="radio"
                value="Vessel undergoing refit or upgraded and out of the water"
                id="vesselRefit"
                {...register("disposalReason", {
                  required: {
                    value: true,
                    message: "It is required",
                  },
                })}
              />
              <label htmlFor="vesselRefit">
                Vessel undergoing refit/upgraded and out of the water
              </label>
            </div>
            <div className="br-radio-group">
              <input
                type="radio"
                value="Vessel no longer in use"
                id="vesselNoUse"
                {...register("disposalReason", {
                  required: {
                    value: true,
                    message: "It is required",
                  },
                })}
              />
              <label htmlFor="vesselNoUse">Vessel no longer in use</label>
            </div>
            <div className="br-radio-group">
              <input
                type="radio"
                value="Motor changed - now less than 4hp"
                id="motorLess4hp"
                {...register("disposalReason", {
                  required: {
                    value: true,
                    message: "It is required",
                  },
                })}
              />
              <label htmlFor="motorLess4hp">
                Motor changed-now less than 4hp
              </label>
            </div>
            <div className="br-radio-group">
              <input
                type="radio"
                value="Vessel scrapped"
                id="vesselScrapped"
                {...register("disposalReason", {
                  required: {
                    value: true,
                    message: "It is required",
                  },
                })}
              />
              <label htmlFor="vesselScrapped">Vessel scrapped – unsafe</label>
            </div>
            <div className="br-radio-group">
              <input
                type="radio"
                value="other"
                id="other"
                {...register("disposalReason", {
                  required: {
                    value: true,
                    message: "It is required",
                  },
                })}
              />
              <label htmlFor="other">Other</label>
            </div>
          </div>
          <span className="msg">{errors.disposalReason?.message}</span>
          {watch("disposalReason") === "other" ? (
            <div
              className={
                errors.otherDisposalReason?.message
                  ? "br-input-group error"
                  : !getFieldState("otherDisposalReason").isDirty
                  ? "br-input-group"
                  : "br-input-group success"
              }
            >
              <label htmlFor="otherDisposalReason">
                Reason{" "}
                {watch("disposalReason") === "other" ? (
                  <span className="text-danger">*</span>
                ) : (
                  ""
                )}
              </label>
              <input
                type="text"
                id="otherDisposalReason"
                {...register("otherDisposalReason", {
                  required: {
                    value: watch("disposalReason") === "other",
                    message: "Reason is required",
                  },
                })}
              />
              <span className="msg">{errors.otherDisposalReason?.message}</span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {watch("disposalReason") === "Vessel sold interstate" && (
        <BuyerVesselSoldInterstate
          register={register}
          watch={watch}
          control={control}
          errors={errors}
          getFieldState={getFieldState}
        />
      )}
    </>
  );
};

export default Disposal;
