import BusinessType from "../Contacts/BusinessType";
import PrimaryContact from "../Contacts/PrimaryContact";

const BuyerFormSellerContacts = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  clearErrors,
  isCompanyFieldName,
  setError,
  uploadSizeLimit = 0,
  setValue
}) => {
  return (
    <>
      <div className="br-box">
        <div className="br-title">Details of Seller</div>
        <BusinessType
          register={register}
          watch={watch}
          getFieldState={getFieldState}
          isCompanyFieldName={isCompanyFieldName}
        />
      </div>

      <PrimaryContact
        register={register}
        watch={watch}
        control={control}
        errors={errors}
        getFieldState={getFieldState}
        clearErrors={clearErrors}
        isCompanyFieldName={isCompanyFieldName}
        isDocNeededForOrg={false}
        setError={setError}
        uploadSizeLimit={uploadSizeLimit}
        prefixIsCompanyField="seller"
        title="Current Registered Operator"
        setValue={setValue}
      />

      {/* {watch(`${isCompanyFieldName}`) == "Organisation" && (
        <OrganisationContact 
          register={register}
          watch={watch}
          control={control}
          errors={errors}
          getFieldState={getFieldState}
          clearErrors={clearErrors}
          prefixIsCompanyField="seller"
        />
      )} */}
    </>
  );
};

export default BuyerFormSellerContacts;
