import { Controller, useWatch } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import { validateMiniAge, getAuMobileRegex } from "../../Helpers/Helper";
import { useEffect } from "react";

const PasswordResetInputFields = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  clearErrors,
}) => {
  let email = useWatch({
    control,
    name: "pwdEmail",
  });
  let mobile = useWatch({
    control,
    name: "pwdMobile",
  });
  useEffect(() => {
    if (email) {
      clearErrors("pwdMobile");
    }

    if (mobile) {
      clearErrors("pwdEmail");
    }
  }, [email, mobile]);

  const content = (
    <>
      <div className="row">
        <div className="col-md-12 pwd-reset-notification">
          <span>
            Only existing MAST clients can register for access to the online
            portal at present. Please contact MAST if you have any questions
            about password reset.
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 field-gap">
          <div
            className={
              errors.pwdFirstName?.message
                ? "pwd-reset-form-input-group error"
                : !getFieldState("pwdFirstName").isDirty
                ? "pwd-reset-form-input-group"
                : "pwd-reset-form-input-group success"
            }
          >
            <label htmlFor="pwdFirstName">
              First Name(s)<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="pwdFirstName"
              {...register("pwdFirstName", {
                required: "First Name(s) is required",
                pattern: {
                  value: /^[A-Za-z-.&]{0,}(\s[A-Z][A-Za-z-.&]{0,})*$/,
                  message:
                    "Number, special characters and last space are not allowed. Only (- . &) allowed",
                },
                maxLength: {
                  value: 36,
                  message: "Max length is 36",
                },
              })}
            />
            <span className="msg">{errors.pwdFirstName?.message}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div
            className={
              errors.pwdSurName?.message
                ? "pwd-reset-form-input-group error"
                : !getFieldState("pwdSurName").isDirty
                ? "pwd-reset-form-input-group"
                : "pwd-reset-form-input-group success"
            }
          >
            <label htmlFor="pwdSurName">
              Surname<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="pwdSurName"
              {...register("pwdSurName", {
                required: "Surname is required",
                pattern: {
                  value: /^[A-Za-z-.]{0,}$/,
                  message:
                    "Number and special characters are not allowed. Only (- .) allowed",
                },
                maxLength: {
                  value: 60,
                  message: "Max length is 60",
                },
              })}
            />
            <span className="msg">{errors.pwdSurName?.message}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-7 field-gap">
          <div
            className={
              errors.pwdStreet?.message
                ? "pwd-reset-form-input-group error"
                : !getFieldState("pwdStreet").isDirty
                ? "pwd-reset-form-input-group"
                : "pwd-reset-form-input-group success"
            }
          >
            <label htmlFor="pwdStreet">
              Street<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="pwdStreet"
              {...register("pwdStreet", {
                required: "Street is required",
                maxLength: {
                  value: 30,
                  message: "Max length is 30",
                },
              })}
            />
            <span className="msg">{errors.pwdStreet?.message}</span>
          </div>
        </div>
        <div className="col-md-5">
          <div
            className={
              errors.pwdSuburb?.message
                ? "pwd-reset-form-input-group error"
                : !getFieldState("pwdSuburb").isDirty
                ? "pwd-reset-form-input-group"
                : "pwd-reset-form-input-group success"
            }
          >
            <label htmlFor="pwdSuburb">
              Suburb<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="pwdSuburb"
              {...register("pwdSuburb", {
                required: "Suburb is required",
                maxLength: {
                  value: 30,
                  message: "Max length is 30",
                },
              })}
            />
            <span className="msg">{errors.pwdSuburb?.message}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 field-gap">
          <div
            className={
              !getFieldState("pwdState").isDirty
                ? "pwd-reset-form-input-group"
                : "pwd-reset-form-input-group success"
            }
          >
            <label htmlFor="pwdState">
              State<span className="text-danger">*</span>
            </label>
            <select id="pwdState" {...register("pwdState")}>
              <option value="TAS">Tasmania</option>
              <option value="ACT">Australian Capital Territory</option>
              <option value="QU">Queensland</option>
              <option value="WA">Western Australia</option>
              <option value="NT">Northern Territory</option>
              <option value="SA">South Australia</option>
              <option value="NSW">New South Wales</option>
              <option value="VIC">Victoria</option>
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div
            className={
              errors.pwdPostCode?.message
                ? "pwd-reset-form-input-group error"
                : !getFieldState("pwdPostCode").isDirty
                ? "pwd-reset-form-input-group"
                : "pwd-reset-form-input-group success"
            }
          >
            <label htmlFor="pwdPostCode">
              PostCode<span className="text-danger">*</span>
            </label>
            <input
              type="number"
              id="pwdPostCode"
              {...register("pwdPostCode", {
                required: "Postcode is required",
                pattern: {
                  value: /^[0-9]{4}$/,
                  message: "4 digit number allowed",
                },
              })}
            />
            <span className="msg">{errors.pwdPostCode?.message}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 field-gap">
          <div
            className={
              errors.pwdMobile?.message
                ? "pwd-reset-form-input-group error"
                : !getFieldState("pwdMobile").isDirty
                ? "pwd-reset-form-input-group"
                : "pwd-reset-form-input-group success"
            }
          >
            <label htmlFor="pwdMobile">
              Mobile
              {watch("pwdEmail") ? "" : <span className="text-danger">*</span>}
            </label>
            <input
              type="tel"
              id="pwdMobile"
              {...register("pwdMobile", {
                required: {
                  value: watch("pwdEmail") ? false : true,
                  message: "Email or Mobile is required",
                },
                pattern: {
                  value: getAuMobileRegex(),
                  message: "Mobile format error",
                },
              })}
              placeholder="04XX XXX XXX"
            />
            <span className="msg">{errors.pwdMobile?.message}</span>
          </div>
        </div>
        <div className="col-md-8">
          <div
            className={
              errors.pwdEmail?.message
                ? "pwd-reset-form-input-group error"
                : !getFieldState("pwdEmail").isDirty
                ? "pwd-reset-form-input-group"
                : "pwd-reset-form-input-group success"
            }
          >
            <label htmlFor="pwdEmail">
              Email
              {watch("pwdMobile") ? "" : <span className="text-danger">*</span>}
            </label>
            <input
              type="email"
              id="pwdEmail"
              {...register("pwdEmail", {
                required: {
                  value: watch("pwdMobile") ? false : true,
                  message: "Email or Mobile is required",
                },
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  message: "Email format error",
                },
                maxLength: {
                  value: 60,
                  message: "Max length is 60",
                },
              })}
            />
            <span className="msg">{errors.pwdEmail?.message}</span>
          </div>
        </div>
        <div className="col-md-12 pwd-reset-mobile-email-caption">
          <span>
            You can enter either your mobile number or email address (or both).
            Mobile number and email address entered here must match MAST records
            to successfully complete your password reset.
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5">
          <div
            className={
              errors.pwdDOB?.message
                ? "pwd-reset-form-input-group error"
                : !getFieldState("pwdDOB").isDirty
                ? "pwd-reset-form-input-group"
                : "pwd-reset-form-input-group success"
            }
          >
            <label htmlFor="pwdDOB">
              Date of Birth<span className="text-danger">*</span>
            </label>
            <Controller
              control={control}
              name="pwdDOB"
              render={({ field: { onChange, value } }) => {
                return (
                  <DatePicker value={value} onChange={onChange} />
                );
              }}
              rules={{
                required: "Date of Birth is required",
                validate: {
                  greaterThanSixteen: (d) => validateMiniAge(d),
                  min: (v) =>
                    new Date(v) >= new Date(1900, 0, 1) ||
                    "Selected year must be greater than 1900",
                  max: (v) =>
                    new Date(v) <= new Date() ||
                    "Selected date must be less than today",
                },
              }}
              errors={errors}
            />
            <span className="msg">{errors.pwdDOB?.message}</span>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <div className="col-md-12">
          <div className="pwd-reset-form-input-group">
            <label>Postal Address</label>
            <div className="pwd-reset-form-input-group-checkbox">
              <input
                type="checkbox"
                id="pwdCheckPostal"
                {...register("pwdCheckPostal")}
              />
              <label htmlFor="pwdCheckPostal">
                Same as residential address
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className={!watch("pwdCheckPostal") ? "show row" : "hide"}>
        <div className="col-md-7 field-gap">
          <div
            className={
              errors.pwdPostalStreet?.message
                ? "pwd-reset-form-input-group error"
                : !getFieldState("pwdPostalStreet").isDirty
                ? "pwd-reset-form-input-group"
                : "pwd-reset-form-input-group success"
            }
          >
            <label htmlFor="pwdPostalStreet">
              Postal Street
              {!watch("pwdCheckPostal") ? (
                <span className="text-danger">*</span>
              ) : (
                ""
              )}
            </label>
            <input
              type="text"
              id="pwdPostalStreet"
              {...register("pwdPostalStreet", {
                required: {
                  value: !watch("pwdCheckPostal"),
                  message: "Postal street is required",
                },
              })}
            />
            <span className="msg">{errors.pwdPostalStreet?.message}</span>
          </div>
        </div>
        <div className="col-md-5">
          <div
            className={
              errors.pwdPostalSuburb?.message
                ? "pwd-reset-form-input-group error"
                : !getFieldState("pwdPostalSuburb").isDirty
                ? "pwd-reset-form-input-group"
                : "pwd-reset-form-input-group success"
            }
          >
            <label htmlFor="pwdPostalSuburb">
              Postal Suburb
              {!watch("pwdCheckPostal") ? (
                <span className="text-danger">*</span>
              ) : (
                ""
              )}
            </label>
            <input
              type="text"
              id="pwdPostalSuburb"
              {...register("pwdPostalSuburb", {
                required: {
                  value: !watch("pwdCheckPostal"),
                  message: "Postal Suburb is required",
                },
              })}
            />
            <span className="msg">{errors.pwdPostalSuburb?.message}</span>
          </div>
        </div>
      </div>
      <div className={!watch("pwdCheckPostal") ? "show row" : "hide"}>
        <div className="col-md-8 field-gap">
          <div
            className={
              !getFieldState("pwdPostalState").isDirty
                ? "pwd-reset-form-input-group"
                : "pwd-reset-form-input-group success"
            }
          >
            <label htmlFor="pwdPostalState">
              Postal State
              {!watch("pwdCheckPostal") ? (
                <span className="text-danger">*</span>
              ) : (
                ""
              )}
            </label>
            <select id="pwdPostalState" {...register("pwdPostalState")}>
              <option value="TAS">Tasmania</option>
              <option value="ACT">Australian Capital Territory</option>
              <option value="QU">Queensland</option>
              <option value="WA">Western Australia</option>
              <option value="NT">Northern Territory</option>
              <option value="SA">South Australia</option>
              <option value="NSW">New South Wales</option>
              <option value="VIC">Victoria</option>
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div
            className={
              errors.pwdPostalPostCode?.message
                ? "pwd-reset-form-input-group error"
                : !getFieldState("pwdPostalPostCode").isDirty
                ? "pwd-reset-form-input-group"
                : "pwd-reset-form-input-group success"
            }
          >
            <label htmlFor="pwdPostalPostCode">
              Postal PostCode
              {!watch("pwdCheckPostal") ? (
                <span className="text-danger">*</span>
              ) : (
                ""
              )}
            </label>
            <input
              type="number"
              id="pwdPostalPostCode"
              {...register("pwdPostalPostCode", {
                required: {
                  value: !watch("pwdCheckPostal"),
                  message: "Postal Suburb is required",
                },
                pattern: {
                  value: /^[0-9]{4}$/,
                  message: "4 digit number allowed",
                },
              })}
            />
            <span className="msg">{errors.pwdPostalPostCode?.message}</span>
          </div>
        </div>
      </div> */}

      <div className="row">
        <div className="col-md-12 pwd-reset-additional-info-title">
          <label>
            To assist in the password reset process you can optionally check the
            boxes below for items you hold with MAST and enter the details
            (licence/rego number) as shown on your licence, registration papers
            or payment notices. It is not mandatory to complete these items.
          </label>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="pwd-reset-form-input-group-checkbox pwd-reset-form-input-group-checkbox-rego">
            <input
              type="checkbox"
              id="pwdHasDlLicence"
              {...register("pwdHasDlLicence")}
            />
            <label htmlFor="pwdHasDlLicence" className="unSelectable">
              Motor Boat Licence No
            </label>
          </div>
        </div>
        <div className="col-md-6 ">
          <div
            className={
              errors.pwdDlLicenceNo?.message
                ? "pwd-reset-form-rego-input-group error"
                : !getFieldState("pwdDlLicenceNo").isDirty
                ? "pwd-reset-form-rego-input-group"
                : "pwd-reset-form-rego-input-group success"
            }
          >
            <div
              className={
                watch("pwdHasDlLicence") ? "inline-show fullWidth" : "hide"
              }
            >
              <input
                type="text"
                id="pwdDlLicenceNo"
                placeholder="Licence No."
                {...register("pwdDlLicenceNo", {
                  required: {
                    value: watch("pwdHasDlLicence"),
                    message: "Licence is required if ticked",
                  },
                  maxLength: {
                    value: 8,
                    message: "Max length is 8",
                  },
                })}
              />
              <span className="msg">{errors.pwdDlLicenceNo?.message}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="pwd-reset-form-input-group-checkbox pwd-reset-form-input-group-checkbox-rego">
            <input
              type="checkbox"
              id="pwdHasPwcRego"
              {...register("pwdHasPwcRego")}
            />
            <label htmlFor="pwdHasPwcRego" className="unSelectable">
              Motor Boat Registration No
            </label>
          </div>
        </div>
        <div className="col-md-6 ">
          <div
            className={
              errors.pwdPcRegoNo?.message
                ? "pwd-reset-form-rego-input-group error"
                : !getFieldState("pwdPcRegoNo").isDirty
                ? "pwd-reset-form-rego-input-group"
                : "pwd-reset-form-rego-input-group success"
            }
          >
            <div
              className={
                watch("pwdHasPwcRego") ? "inline-show fullWidth" : "hide"
              }
            >
              <input
                type="text"
                id="pwdPcRegoNo"
                placeholder="Registration No."
                {...register("pwdPcRegoNo", {
                  required: {
                    value: watch("pwdHasPwcRego"),
                    message: "Motor Boat Rego is required if ticked",
                  },
                  maxLength: {
                    value: 8,
                    message: "Max length is 8",
                  },
                })}
              />
              <span className="msg">{errors.pwdPcRegoNo?.message}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="pwd-reset-form-input-group-checkbox pwd-reset-form-input-group-checkbox-rego">
            <input
              type="checkbox"
              id="pwdHasMoRego"
              {...register("pwdHasMoRego")}
            />
            <label htmlFor="pwdHasMoRego" className="unSelectable">
              Mooring Permit Number
            </label>
          </div>
        </div>
        <div className="col-md-6 ">
          <div
            className={
              errors.pwdMoRegoNo?.message
                ? "pwd-reset-form-rego-input-group error"
                : !getFieldState("pwdMoRegoNo").isDirty
                ? "pwd-reset-form-rego-input-group"
                : "pwd-reset-form-rego-input-group success"
            }
          >
            <div
              className={
                watch("pwdHasMoRego") ? "inline-show fullWidth" : "hide"
              }
            >
              <input
                type="text"
                id="pwdMoRegoNo"
                placeholder="Permit No."
                {...register("pwdMoRegoNo", {
                  required: {
                    value: watch("pwdHasMoRego"),
                    message: "Mooring Permit No is required if ticked",
                  },
                  maxLength: {
                    value: 8,
                    message: "Max length is 8",
                  },
                })}
              />
              <span className="msg">{errors.pwdMoRegoNo?.message}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="pwd-reset-form-input-group-checkbox pwd-reset-form-input-group-checkbox-rego">
            <input
              type="checkbox"
              id="pwdHasFavAmsaUVI"
              {...register("pwdHasFavAmsaUVI")}
            />
            <label htmlFor="pwdHasFavAmsaUVI" className="unSelectable">
              Commercial Vessel UVI
            </label>
          </div>
        </div>
        <div className="col-md-6 ">
          <div
            className={
              errors.pwdFavAmsaUVI?.message
                ? "pwd-reset-form-rego-input-group error"
                : !getFieldState("pwdFavAmsaUVI").isDirty
                ? "pwd-reset-form-rego-input-group"
                : "pwd-reset-form-rego-input-group success"
            }
          >
            <div
              className={
                watch("pwdHasFavAmsaUVI") ? "inline-show fullWidth" : "hide"
              }
            >
              <input
                type="text"
                id="pwdFavAmsaUVI"
                placeholder="UVI"
                {...register("pwdFavAmsaUVI", {
                  required: {
                    value: watch("pwdHasFavAmsaUVI"),
                    message: "AMSA UVI is required if ticked",
                  },
                  maxLength: {
                    value: 16,
                    message: "Max length is 16",
                  },
                })}
              />
              <span className="msg">{errors.pwdFavAmsaUVI?.message}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return content;
};

export default PasswordResetInputFields;
