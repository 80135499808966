import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import MooringPrimaryVesselUpdatedForm from "../../Components/Mooring/MooringPrimaryVesselUpdatedForm";
import SubmitLoader from "../../Components/SubmitLoader";
import useGaEvent from "../../Hooks/useGaEvent";
import AlertMessage from "../../Components/AlertMessage";

const MooringPrimaryVesselUpdated = () => {
  let { itemId, seqId, moRegoNo, moAppLen } = useParams();
  const [res, err, isGETLoading, apiGet, abt] = useGet();
  const gaEvents = useGaEvent();

  useEffect(() => {
    apiGet("/api/mooring/MoPrimaryVesselDetails/" + itemId + "/" + seqId);
    gaEvents.MooringPrimaryVesselUpdatedVisit();
  }, []);

  // console.log(res);

  return (
    <>
      {isGETLoading ? (
        <SubmitLoader isLoading={isGETLoading} />
      ) : err ? (
        <AlertMessage message={err} />
      ) : res ? (
        <div className="row">
          <div className="col-md-12">
            <MooringPrimaryVesselUpdatedForm
              primaryVessel={res?.moPrimaryVessel}
              pcList={res?.pcCrafts}
              faList={res?.faVessels}
              moRegoNo={moRegoNo}
              surname={res?.lastName}
              firstname={res?.firstName}
              moAppLen={moAppLen}
            />
          </div>
        </div>
      ) : (
        <div>No data</div>
      )}
    </>
  );
};

export default MooringPrimaryVesselUpdated;
