import { useGet } from "../../Hooks/useFetch";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import BackRedirect from "../BackRedirect";
import DataLoader from "../DataLoader";
import { EditIcon } from "../Icons";
import AlertMessage from "../AlertMessage";

const PeEngineDetails = () => {
  let { regoNo, sequNo } = useParams();
  const [res, err, isLoading, apiGet, abt] = useGet();

  useEffect(() => {
    apiGet("/api/pcraft/PeEngineDetails/" + regoNo + "/" + sequNo);
  }, []);

  // console.log(res);

  return (
    <>
      <div className="recvessel-sub-container">
        <div className="recvessel-title-group">
          <h3 className="recvessel-title">Engine Details</h3>
          <div className="text-danger">{res?.statusReason}</div>
          {res?.isEditable == true && (
            <EditIcon
              url={"/recvessel/peengineupdated/" + regoNo + "/" + sequNo}
              iconSize="xl"
              HoverCaption="Edit Engine Details"
            />
          )}
        </div>

        {!isLoading ? (
          !err ? (
            <>
              <div className="row">
                <div className="col-md-4">
                  <div className="recvessel-input-group">
                    <label htmlFor="PcRegoNo">Rego No</label>
                    <div className="recvesselInfoField">{regoNo}</div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="recvessel-input-group">
                    <label htmlFor="peYearMade">Year Made</label>
                    <div className="recvesselInfoField">
                      {res?.peYearMade?.split("T")[0]}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="recvessel-input-group">
                    <label htmlFor="peCurrent">Current</label>
                    <div className="recvesselInfoField">{res?.peCurrent}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="recvessel-input-group">
                    <label htmlFor="peMake">Make</label>
                    <div className="recvesselInfoField">{res?.peMake}</div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="recvessel-input-group">
                    <label htmlFor="peModel">Model</label>
                    <div className="recvesselInfoField">{res?.peModel}</div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="recvessel-input-group">
                    <label htmlFor="peType">Type</label>
                    <div className="recvesselInfoField">{res?.peType}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="recvessel-input-group">
                    <label htmlFor="peCapacity">Capacity</label>
                    <div className="recvesselInfoField">{res?.peCapacity}</div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="recvessel-input-group">
                    <label htmlFor="peCapacityType">Capacity Type</label>
                    <div className="recvesselInfoField">
                      {res?.peCapacityType}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="recvessel-input-group">
                    <label htmlFor="pePropulsion">Propulsion</label>
                    <div className="recvesselInfoField">
                      {res?.pePropulsionDesc}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="recvessel-input-group">
                    <label htmlFor="peFuel">Fuel</label>
                    <div className="recvesselInfoField">{res?.peFuelDesc}</div>
                  </div>
                </div>
                {res?.peCc && (
                  <div className="col-md-4">
                    <div className="recvessel-input-group">
                      <label htmlFor="peCc">CC</label>
                      <div className="recvesselInfoField">{res?.peCc}</div>
                    </div>
                  </div>
                )}
              </div>
              <BackRedirect url={"/recvessel/recvesseldetails/" + regoNo} />
            </>
          ) : (
            <AlertMessage message={err} />
          )
        ) : (
          <DataLoader isLoading={isLoading} />
        )}
      </div>
    </>
  );
};

export default PeEngineDetails;
