import useShoppingCart from "../../Hooks/useShoppingCart";
import NormalPayProcess from "../../Components/ShoppingCart/NormalPayProcess";
import ZeroPayProcess from "../../Components/ShoppingCart/ZeroPayProcess";
import { useGet } from "../../Hooks/useFetch";
import { useEffect } from "react";
import DataLoader from "../../Components/DataLoader";
import AlertMessage from "../../Components/AlertMessage";
import { decryptText } from "../../Helpers/Helper";

const CartPaymentProcess = () => {
  const { setCartItems, cartItems, cartTotalPrice } = useShoppingCart();
  const [resKey, err, isLoadingKey, apiGetKey, abtKey] = useGet();

  useEffect(() => {
    apiGetKey("/api/pcraft/PayWayKey");
  }, []);

  return (
    <>
      {!isLoadingKey ? (
        !err ? (
          cartTotalPrice <= 0 ? (
            <ZeroPayProcess
              setCartItems={setCartItems}
              cartItems={cartItems}
              cartTotalPrice={cartTotalPrice}
            />
          ) : (
            <NormalPayProcess
              setCartItems={setCartItems}
              cartItems={cartItems}
              cartTotalPrice={cartTotalPrice}
              payWayPublicKey={decryptText(resKey, process.env.REACT_APP_Encrypt_Key, process.env.REACT_APP_Encrypt_IV)}
            />
          )
        ) : (
          <AlertMessage message={err} />
        )
      ) : (
        <DataLoader isLoading={isLoadingKey} />
      )}
    </>
  );
};

export default CartPaymentProcess;
