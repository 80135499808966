import useUserInfo from "../../Hooks/useUserInfo";
import { axiosApi } from "../../Api/Axios";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import VerifyCode from "../../Components/VerifyCode/VerifyCode";
import { useState } from "react";
import useGaEvent from "../../Hooks/useGaEvent";

const PasswordResetVerify = () => {
  const { userInfo, setUserInfo } = useUserInfo();
  const [errMsg, setErrMsg] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const gaEvents = useGaEvent();
  const navigate = useNavigate();
  const dest = "/passwordreset/pwdresetresult";

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(userInfo);

    if(userInfo.code?.trim() != null)
    {
      const vCode = userInfo.code?.trim();
      if(vCode.includes('.') || vCode.includes('-') || vCode.includes('e'))
      {
        setErrMsg("Only numbers 0-9 are allowed");
        return;
      }
    }

    try {
        setBtnDisable(true);
        const response = await axiosApi.post(
          "/api/auth/passwordReset-verifyCode",
          {
            Code: userInfo.code?.trim(),
            VerifyToken: userInfo.accessToken,
            PwdTable: userInfo.pwdTable,
          },
          {
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization": "Bearer " + userInfo.accessToken,
            },
          }
        );

        if (response.status === 200) {
          if(response.data.resultPass === false)
          {
              //No user found
              setUserInfo((prev) => {
                  // console.log(JSON.stringify(prev));
                  // console.log(response.data); //it is registration request number
                  return {
                    accessToken: prev.accessToken,
                    regiReqNo: response.data.requestId,
                    failReason: response.data.statusDesc,
                  };
                });
              gaEvents.authResetPwdFailOnMultipleUsers();
          }else
          {
              //Found user
              setUserInfo((prev) => {
                  // console.log(JSON.stringify(prev));
                  // console.log(response.data.accessToken); //it is accessToken
                  return {
                    ...prev,
                    accessToken: response.data.accessToken,
                    wwwId: response.data.wwwId,
                    regiReqNo: ""
                  };
                });
          }

          navigate(dest, { replace: true });
        } else {
          setErrMsg(response.data);
          setBtnDisable(false);
        }
      } catch (err) {
        gaEvents.authResetPwdFail();
        if(err.response.status === 401)
        {
          setErrMsg("Session Expired");
        }else
        {
          setErrMsg(err.response.data);
        }
        // console.log(err);
        setBtnDisable(false);
      }
    
  };

  return (
    <>
      {!userInfo.accessToken ? (
        <Navigate to="/passwordreset" replace={true} />
      ) : (
        <div className="pwd-reset-bg">
          <VerifyCode handleSubmit={handleSubmit} errMsg={errMsg} btnDisable={btnDisable}/>
        </div>
      )}
    </>
  );
};

export default PasswordResetVerify;
