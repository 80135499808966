import { useFieldArray } from "react-hook-form";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UploadSupportDocInputField from "./UploadSupportDocInputField";
import Tooltip from "@mui/material/Tooltip";
import UploadInput from "../UploadInput";

const UploadSupportDoc = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  setError,
  clearErrors,
  uploadSizeLimit,
  setValue,
}) => {
  const validHandler = (event) => {
    let file = event.target.files[0];
    // console.log(errors);
    clearErrors(event.target.name);
    if (file?.size > uploadSizeLimit) {
      // console.log(event.target.name + ": over size");
      setError(event.target.name, {
        type: "oversize",
        message: "File is over the limit size ("+ Math.floor(uploadSizeLimit/1000000) +"MB).",
      });
    }
  };

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: "purchaseFiles",
  });

  return (
    <>
      <div className="br-box">
        <div className="br-title">Supporting Documents</div>
        <div className="tsf-doc-title-gp">
          <span>
            Upload Proof of Purchase <b>(Multiple files can be uploaded)</b>{" "}
            <span className="text-danger">*</span>
          </span>
          <ol>
            <li>Receipt from a seller (or boat broker or dealer)</li>
            <li>
              Or Bill of Sale – tends to mainly larger higher value vessels and
              is a more formal form of receipt
            </li>
            <li>
              Or Scanned copy or photo of completed paper Transfer of
              Registration form
            </li>
            <li>
              Or Statutory Declaration – used if the buyer does not have 1 or 2
              above
            </li>
          </ol>
          <div className="doc-hint">(Maximum size is {Math.floor(uploadSizeLimit/1000000)}MB, and Only PDF/JPG/JPEG/PNG allowed)</div>
        </div>
        <div className="br-field-group">
          <UploadSupportDocInputField
            register={register}
            getFieldState={getFieldState}
            index={0}
            FieldName="purchaseFiles"
            remove={remove}
            validHandler={validHandler}
            watch={watch}
            setValue={setValue}
          />

          {fields.map((field, index) =>
            index !== 0 ? (
              <div key={field.id} className="">
                <UploadSupportDocInputField
                  register={register}
                  getFieldState={getFieldState}
                  index={index}
                  FieldName="purchaseFiles"
                  remove={remove}
                  validHandler={validHandler}
                  watch={watch}
                  setValue={setValue}
                />
              </div>
            ) : (
              ""
            )
          )}
          <span className="center-block">
            <Tooltip title="Add Upload File" placement="top" arrow>
              <FontAwesomeIcon
                icon={faPlus}
                className="br-engine-add-icon"
                size="3x"
                onClick={() => {
                  append();
                }}
              />
            </Tooltip>
          </span>

          <div
            className={
              errors.nonTasFiles?.message
                ? "br-input-group error"
                : !getFieldState("nonTasFiles").isDirty
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <div>
              <span>Proof of Vessel/owner outside of Tasmania</span>
              <ul>
                <li>Non-Tasmania residents</li>
                <li>Or where the vessel is not located in Tasmania</li>
              </ul>
              <div className="doc-hint">(Maximum size is {Math.floor(uploadSizeLimit/1000000)}MB, and Only PDF/JPG/JPEG/PNG allowed)</div>
            </div>
            {/* <input
              type="file"
              {...register("nonTasFiles")}
              onChange={validHandler}
            /> */}
            <div className="tsf-upload-file-field">
              <UploadInput
                register={register}
                onChangeEvent={validHandler}
                assignName={"nonTasFiles"}
                watch={watch}
                setValue={setValue}
              />
            </div>
            <span className="msg">{errors.nonTasFiles?.message}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadSupportDoc;
