import { Controller } from "react-hook-form";
import AddressFilter from "../AddressFilter";
import UploadInput from "../UploadInput";
import { getAuPhoneRegex } from "../../Helpers/Helper";

const OrganisationOperator = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  setError,
  clearErrors,
  uploadSizeLimit,
  IsDocNeeded = false,
  prefixIsCompanyField = "",
  title = "New Registered Operator",
  setValue
}) => {
  const validHandler = (event) => {
    let file = event.target.files[0];
    // console.log(file);
    clearErrors(event.target.name);
    if (file?.size > uploadSizeLimit) {
      // console.log("over size");
      setError(event.target.name, {
        type: "oversize",
        message:
          "File is over the limit size (" +
          Math.floor(uploadSizeLimit / 1000000) +
          "MB).",
      });
    }
  };

  return (
    <>
      <div className="br-title">{title}</div>
      <div className="br-field-group">
        <div
          className={
            getFieldState(`${prefixIsCompanyField}.organisationName`).invalid
              ? "br-input-group error"
              : !getFieldState(`${prefixIsCompanyField}.organisationName`)
                  .isDirty
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="organisationName">
            Organisation Name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="organisationName"
            {...register(`${prefixIsCompanyField}.organisationName`, {
              required: "Organisation Name is required",
            })}
          />
          <span className="msg">
            {
              getFieldState(`${prefixIsCompanyField}.organisationName`).error
                ?.message
            }
          </span>
        </div>
        <div
          className={
            getFieldState(`${prefixIsCompanyField}.orgType`).invalid
              ? "br-input-group error"
              : !watch(`${prefixIsCompanyField}.orgType`)
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="orgType">
            ABN/ACN/Inc <span className="text-danger">*</span>
          </label>
          <select
            id="orgType"
            {...register(`${prefixIsCompanyField}.orgType`, {
              required: "Organisation type is required",
            })}
          >
            <option value="">Choose...</option>
            <option value="ABN">ABN</option>
            <option value="ACN">ACN</option>
            <option value="Inc">Inc. No.</option>
          </select>
          <span className="msg">
            {getFieldState(`${prefixIsCompanyField}.orgType`).error?.message}
          </span>
        </div>
        <div
          className={
            getFieldState(`${prefixIsCompanyField}.orgTypeNumber`).invalid
              ? "br-input-group error"
              : !getFieldState(`${prefixIsCompanyField}.orgTypeNumber`).isDirty
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="orgTypeNumber">
            ABN/ACN/Inc. No. <span className="text-danger">*</span>
          </label>
          <input
            type="number"
            step="0.01"
            id="orgTypeNumber"
            {...register(`${prefixIsCompanyField}.orgTypeNumber`, {
              required: {
                value: true,
                message: "ABN/ACN number is required",
              },
              pattern: {
                value: /^[0-9]+$/,
                message: "Only number allowed",
              },
            })}
          />
          <span className="msg">
            {
              getFieldState(`${prefixIsCompanyField}.orgTypeNumber`).error
                ?.message
            }
          </span>
        </div>
        <div
          className={
            getFieldState(`${prefixIsCompanyField}.orgResAddress`).invalid
              ? "br-input-group error"
              : "br-input-group"
          }
        >
          <label htmlFor="orgResAddress">Residential</label>
          <Controller
            control={control}
            name={`${prefixIsCompanyField}.orgResAddress`}
            render={({ field: { onChange, value } }) => {
              return (
                <AddressFilter
                  onChange={(val) => onChange(val?.value)}
                  defaultValue={value}
                />
              );
            }}
            errors={errors}
          />
          <span className="msg">
            {
              getFieldState(`${prefixIsCompanyField}.orgResAddress`).error
                ?.message
            }
          </span>
        </div>

        <div
          className={
            getFieldState(`${prefixIsCompanyField}.orgEmail`).invalid
              ? "br-input-group error"
              : !getFieldState(`${prefixIsCompanyField}.orgEmail`).isDirty
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="orgEmail">
            Email <span className="text-danger">*</span>
          </label>
          <input
            type="email"
            id="orgEmail"
            {...register(`${prefixIsCompanyField}.orgEmail`, {
              required: {
                value: true,
                message: "Email is required",
              },
              pattern: {
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                message: "Email format error",
              },
            })}
          />
          <span className="msg">
            {
              getFieldState(`${prefixIsCompanyField}.orgEmail`).error
                ?.message
            }
          </span>
        </div>
        <div
          className={
            getFieldState(`${prefixIsCompanyField}.orgMobile`).invalid
              ? "br-input-group error"
              : !getFieldState(`${prefixIsCompanyField}.orgMobile`).isDirty
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="orgMobile">
            Work Phone <span className="text-danger">*</span>
          </label>
          <input
            type="tel"
            id="mobile"
            {...register(`${prefixIsCompanyField}.orgMobile`, {
              required: {
                value: true,
                message: "(W)Phone is required",
              },
              pattern: {
                value: getAuPhoneRegex(),
                message: "Phone format error",
              },
            })}
          />
          <span className="msg">
            {
              getFieldState(`${prefixIsCompanyField}.orgMobile`).error
                ?.message
            }
          </span>
        </div>

        {IsDocNeeded && (
          <div
            className={
              getFieldState(`${prefixIsCompanyField}.orgFile`).invalid
                ? "br-input-group error"
                : !getFieldState(`${prefixIsCompanyField}.orgFile`).isDirty
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="orgFile">
              Scanned copy or photo of completed paper Transfer of Registration
              form
            </label>
            <UploadInput
              register={register}
              onChangeEvent={validHandler}
              assignName={`${prefixIsCompanyField}.orgFile`}
              watch={watch}
              setValue={setValue}
            />
            <span className="msg">
              {
                getFieldState(`${prefixIsCompanyField}.orgFile`).error
                  ?.message
              }
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default OrganisationOperator;
