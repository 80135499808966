const PrivacyStatement = () => {
  return (
    <div className="br-box">
      <div className="br-title">Privacy Statement</div>
      <p>
        The personal information that we collect from you will only be used for
        the purposes for which it is provided and access to it will be given
        only to those whose function it is to deal with it or who are otherwise
        entitled by law to receive it. Failure to provide this information may
        result in your application not being processed or the service not being
        able to be provided.
      </p>
    </div>
  );
};

export default PrivacyStatement;
