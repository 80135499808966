import "./CreditCardPattern.css";
import CardChip from "../../../Assets/CreditCardChip.svg";
import CardVisaMark from "../../../Assets/CreditCardVisaMark.svg";
import CardMastercardMark from "../../../Assets/Mastercard-logo.svg";
import { faCaretRight, faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { getFullCardNumber } from "../../../Helpers/Helper";

const CreditCardPattern = ({ creditCard }) => {
  const toggleFlip = () => {
    let base = document.querySelector(".Base");
    base.classList.toggle("flipped");
  };


  const getCardTypeMark = () => {
    switch (creditCard.cardScheme) {
      case "visa":
        return (
          <>
            <img src={CardVisaMark} alt="" className="visa-card-type" />
          </>
        );
      case "mastercard":
        return (
          <>
            <img src={CardMastercardMark} alt="" className="master-card-type" />
          </>
        );
      default:
        return <>{creditCard.maskedCardNumber && <FontAwesomeIcon icon={faBan} size="3x" beat className="icon-red"  />}</>
    }
  };

  return (
    <>
      <div className="card-container">
        <div className="Wrap">
          <div className="Base" onClick={toggleFlip}>
            <div className="front">
              <div className="Inner-wrap front-group">
                <div className="Inner-header">
                  <img src={CardChip} className="card-chip" alt="" />
                  {getCardTypeMark()}
                </div>
                <div className="row Inner-card-number">
                  <div className="col-xs-12 ">
                    <div className="card-group">
                      <label htmlFor="">
                        <code>card number</code>
                      </label>
                      <div className="input-display card-number">
                        {!creditCard.maskedCardNumber
                          ? "XXXX XXXX XXXX XXXX"
                          : getFullCardNumber(creditCard.maskedCardNumber)}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-6">
                    <div className="card-group">
                      <code className="card-title">cardholder</code>
                      <div className="input-display card-username">
                        {!creditCard.cardholderName ? "XXX" : creditCard.cardholderName}
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-6 ">
                    <div className="card-group">
                      <code className="card-title">expiration</code>
                      <div className="expire-group">
                        <div className="valid-thru-group">
                          <code className="valid-thru">VALID</code>
                          <code className="valid-thru">THRU</code>
                        </div>
                        <FontAwesomeIcon icon={faCaretRight} />
                        <div className="input-display card-exp">
                          {(!creditCard.expiryDateMonth || !creditCard.expiryDateYear)
                            ? "MM/YY"
                            : creditCard.expiryDateMonth+'/'+creditCard.expiryDateYear}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="back ">
              <div className="Inner-wrap card-back-padding-bottom">
                <div className="black-bar"></div>
                <div className="strip-bar">
                  <div className="signature">{creditCard.cardholderName}</div>
                  <div className="secure-code">
                    ***
                  </div>
                </div>
                <div className="text-align-right">
                  <code>security code</code>
                </div>
                <div className="down-bars">
                  <div className="first-white-bar"></div>
                  <div className="sec-white-bar"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditCardPattern;
