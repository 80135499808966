import EngineDescription from "../EngineDetails/EngineDescription";
import { useFieldArray } from "react-hook-form";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FormEngineDetail = ({
  register,
  watch,
  errors,
  getFieldState,
  control,
  engineArrayPropName,
}) => {
  // useEffect(() => {
  //   console.log(watch("engineDetails"));
  // });

  // const initialEngine = {
  //   PePropulsion: "RB_OUTBOARD",
  //   PeFuel: "RB_DIESEL",
  //   PeMake: "HONDA",
  //   PeSerialNo: "T12345",
  //   // PeYearMade: 1900,
  //   PeCapacity: "2.2",
  //   PeCapacityType: "hp",
  // };

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: engineArrayPropName,
    defaultValues: {
      [engineArrayPropName]: [],
    },
  });

  return (
    <>
      <div className="br-title">Engine Details</div>
      <p>
        Please enter the detail for each motor installed on the vessel
        (including auxiliary). Use the “Click to add” button if you need to
        include additional motors.
      </p>

      <div className="br-engine-desc-container">
      <EngineDescription
        control={control}
        index={0}
        engineArrayPropName={engineArrayPropName}
        register={register}
        watch={watch}
        getFieldState={getFieldState}
      />
      </div>
      

      {fields.map((field, index) =>
        index !== 0 ? (
          <div key={field.id} className="br-engine-desc-container">
            <EngineDescription
              control={control}
              index={index}
              engineArrayPropName={engineArrayPropName}
              register={register}
              watch={watch}
              getFieldState={getFieldState}
              remove={remove}
            />
          </div>
        ) : (
          ""
        )
      )}

      <div className="br-add-engine-box">
        <span>Click to add an additional engine</span>
        <FontAwesomeIcon
          icon={faCirclePlus}
          className="br-engine-add-icon"
          size="2x"
          beat
          onClick={() => {
            append();
          }}
        />
      </div>
    </>
  );
};

export default FormEngineDetail;
