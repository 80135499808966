import React from "react";
import { useGet } from "../Hooks/useFetch";
import { useEffect, useState } from "react";
import DataLoader from "./DataLoader";
import { RenewIcon, ErrorIcon } from "./Icons";
import "./Modal/Modal.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WestpacPayment = ({ url, isLicenceRenewal = false }) => {
  const [res, err, isLoading, apiGet, abt] = useGet();
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    apiGet(url);
  }, [url]);

  // console.log(res);

  const onSubmitEvent = (needCard) => {
    setIsOpenModal(false);

    let formHiddenData = {
      cd_community: "SERVICETAS",
      cd_currency: "AUD",
      CP_billerCode: "8223",
      no_reference: res?.reference,
      am_pre_surcharge: needCard === true ? (res?.amount + res?.cardAmount).toFixed(2) : res?.amount?.toFixed(2),
      CP_emailAddress: res?.email,
    };

    const tempForm = document.createElement("form");
    tempForm.action = "https://quickweb.westpac.com.au/OnlinePaymentServlet";
    tempForm.method = "post";
    tempForm.target = "_blank";

    // Append the form data to the temporary form
    for (let key in formHiddenData) {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = formHiddenData[key];
      tempForm.appendChild(input);
    };

    // Append the temporary form to the document and submit it
    document.body.appendChild(tempForm);
    tempForm.submit();
    // Remove the temporary form from the document after submit
    document.body.removeChild(tempForm);


  };

  return (
    <>
      {!isLoading ? (
        !err ? (
          res?.isRenewAble ? (
            isLicenceRenewal ? (
              <button
                type="button"
                className="quickWeb-btn"
                onClick={() => setIsOpenModal(true)}
              >
                <RenewIcon />
              </button>
            ) : (
              <form
                action="https://quickweb.westpac.com.au/OnlinePaymentServlet"
                method="post"
                target="_blank"
              >
                <input type="hidden" name="cd_community" value="SERVICETAS" />
                <input type="hidden" name="cd_currency" value="AUD" />
                <input type="hidden" name="CP_billerCode" value="8223" />
                <input
                  type="hidden"
                  name="no_reference"
                  value={res?.reference}
                />
                <input
                  type="hidden"
                  name="am_pre_surcharge"
                  value={res?.amount?.toFixed(2)}
                />
                <input
                  type="hidden"
                  name="CP_emailAddress"
                  value={res?.email}
                />
                <button type="submit" className="quickWeb-btn">
                  <RenewIcon />
                </button>
              </form>
            )
          ) : (
            <span className="quickWeb-sign">{res?.reason}</span>
          )
        ) : (
          <ErrorIcon HoverCaption={err} />
        )
      ) : (
        <DataLoader isLoading={isLoading} />
      )}

      {isOpenModal && (
        <div className="modalBackground">
          <div className="modalContainer">
            <div className="modal-header">
              <div className="modal-title">Licence Card</div>
              <div className="titleCloseBtn">
                <FontAwesomeIcon
                  icon={faXmark}
                  className="xmark-icon"
                  size="2x"
                  onClick={() => {
                    setIsOpenModal(false);
                  }}
                />
              </div>
            </div>
            <div className="modal-body">
              <div className="modal-licence">

                Do you also want to purchase a licence card?
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => {
                  onSubmitEvent(false);
                }}
                className="cancelBtn"
                type="button"
              >
                No (+$0.00)
              </button>
              <button
                type="button"
                className="approvalBtn"
                onClick={() => {
                  onSubmitEvent(true);
                }}
              >
                Yes (+${res?.cardAmount?.toFixed(2)})
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WestpacPayment;
