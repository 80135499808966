import { DatePicker } from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";
import AddressFilter from "../AddressFilter";
import { getAuMobileRegex } from "../../Helpers/Helper";

const BuyerVesselSoldInterstate = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
}) => {
  return (
    <>
      <div className="br-box">
        <div className="br-title">Details of Buyer</div>
        <div className="br-field-group">
          <div
            className={
              getFieldState("buyer.individualGivenName").invalid
                ? "br-input-group error"
                : !getFieldState("buyer.individualGivenName").isDirty
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="individualGivenName">
              Given Name(s)<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="individualGivenName"
              {...register("buyer.individualGivenName", {
                required: {
                  value: watch("disposalReason") === "Vessel sold interstate",
                  message: "Given Name(s) is required",
                },
                pattern: {
                  value: /^[A-Z][A-Za-z-.&]{0,}(\s[A-Z][A-Za-z-.&]{0,})*$/,
                  message:
                    "First letter uppercase. Number, special characters and last space are not allowed. Only (- . &) allowed",
                },
              })}
            />
            <span className="msg">
              {getFieldState("buyer.individualGivenName").error?.message}
            </span>
          </div>
          <div
            className={
              getFieldState("buyer.individualSurName").invalid
                ? "br-input-group error"
                : !getFieldState("buyer.individualSurName").isDirty
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="individualSurName">
              Surname<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="individualSurName"
              {...register("buyer.individualSurName", {
                required: {
                  value: watch("disposalReason") === "Vessel sold interstate",
                  message: "Surname is required",
                },
                pattern: {
                  value: /^[A-Z]{1}[A-Za-z-.]{0,}$/,
                  message:
                    "First letter uppercase. Number and special characters are not allowed. Only (- .) allowed",
                },
              })}
            />
            <span className="msg">
              {getFieldState("buyer.individualSurName").error?.message}
            </span>
          </div>
          <div
            className={
              getFieldState("buyer.individualDOB").invalid
                ? "br-input-group error"
                : "br-input-group"
            }
          >
            <label htmlFor="individualDOB">Date of Birth</label>
            <Controller
              control={control}
              name="buyer.individualDOB"
              render={({ field: { onChange, value } }) => {
                return <DatePicker value={value} onChange={onChange} />;
              }}
              rules={{
                validate: {
                  min: (v) =>
                    v == undefined ||
                    v == null ||
                    new Date(v) >= new Date(1900, 0, 1) ||
                    "Selected year must be greater than 1900",
                  max: (v) =>
                    v == undefined ||
                    v == null ||
                    new Date(v) <= new Date() ||
                    "Selected date must be less than today",
                },
              }}
              errors={errors}
            />
            <span className="msg">{getFieldState("buyer.individualDOB").error?.message}</span>
          </div>
          <div
            className={
              getFieldState("buyer.individualResAddress").invalid
                ? "br-input-group error"
                : "br-input-group"
            }
          >
            <label htmlFor="individualResAddress">Residential</label>
            <Controller
              control={control}
              name="buyer.individualResAddress"
              render={({ field: { onChange, value } }) => {
                return (
                  <AddressFilter
                    onChange={(val) => onChange(val?.value)}
                    defaultValue={value}
                  />
                );
              }}
              errors={errors}
            />
            <span className="msg">
              {getFieldState("buyer.individualResAddress").error?.message}
            </span>
          </div>
          {/* <div className="br-checkbox-group">
            <input
              type="checkbox"
              id="checkPostal"
              {...register("checkPostal")}
            />
            <label htmlFor="checkPostal" className="unSelectable">
              Postal Same as Residential
            </label>
          </div>
          {watch("checkPostal") === true ? (
            ""
          ) : (
            <div
              className={
                errors.poAddress?.message
                  ? "br-input-group error"
                  : "br-input-group"
              }
            >
              <label htmlFor="poAddress">
                Postal
              </label>
              <Controller
                control={control}
                name="poAddress"
                render={({ field: { onChange, value } }) => {
                  return (
                    <AddressFilter
                      onChange={(val) => onChange(val?.value)}
                      defaultValue={value}
                    />
                  );
                }}
                errors={errors}
              />
              <span className="msg">{errors.poAddress?.message}</span>
            </div>
          )} */}

          <div
            className={
              getFieldState("buyer.individualEmail").invalid
                ? "br-input-group error"
                : "br-input-group"
            }
          >
            <label htmlFor="individualEmail">Email</label>
            <input
              type="email"
              id="individualEmail"
              {...register("buyer.individualEmail", {
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  message: "Email format error",
                },
              })}
            />
            <span className="msg">{getFieldState("buyer.individualEmail").error?.message}</span>
          </div>
          <div
            className={
              getFieldState("buyer.individualMobile").invalid
                ? "br-input-group error"
                : "br-input-group"
            }
          >
            <label htmlFor="individualMobile">Mobile / Contact Phone</label>
            <input
              type="tel"
              id="individualMobile"
              {...register("buyer.individualMobile", {
                pattern: {
                  value: getAuMobileRegex(),
                  message: "Mobile format error",
                },
              })}
              placeholder="04XX XXX XXX"
            />
            <span className="msg">
              {getFieldState("buyer.individualMobile").error?.message}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyerVesselSoldInterstate;
