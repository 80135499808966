const BuilderPlate = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  getValues,
  isReRegisterCase = false,
}) => {
  return (
    <div className="br-box">
      <div className="br-title">Australian Builders Plate</div>
      <p>
        Most power-driven vessels built from 1 July 2006, including imported
        vessels, must have an ABP affixed before they are registered for the
        first time.
      </p>
      <p>
        There are two types of ABP, one for vessels under six metres and one for
        vessels over six metres. The plate must be permanently affixed in a
        position where it is clearly visible.
      </p>
      <div>
        An ABP enhances the safety of passengers by providing key safety
        information that includes:
        <ul>
          <li>Maximum weight and power rating of the engine</li>
          <li>Maximum number of people capacity</li>
          <li>
            Maximum load (weight) that the boat can carry (including people and
            equipment)
          </li>
          <li>Buoyancy statement (for boats up to six metres in length)</li>
          <li>Warning statements.</li>
        </ul>
      </div>
      <p>
        For more information on Australian Builders Plates visit{" "}
        <a
          href="https://www.anzsbeg.com.au/index.php/boating-safely/australian-builders-plate/"
          target="_blank"
          rel="noreferrer"
        >
          Australia and New Zealand Safe Boating Education Group (ANZSBEG)
        </a>{" "}
        website.
      </p>

      <div className="br-field-group">
        {isReRegisterCase === true ? (
          <div
            className="br-input-group"
          >
            <label htmlFor="hasBuilderPlate">
              Is there an Australian Builder's Plate fixed to the Vessel{" "}
            </label>
            <div className="br-field-radio-group br-radio-field-readonly">
              <div className="br-radio-group">
                <input
                  type="radio"
                  value="Yes"
                  id="hasBuilderPlateYes"
                  disabled
                  checked={getValues("hasBuilderPlate") === "Yes"}
                />
                <label htmlFor="hasBuilderPlateYes">YES</label>
              </div>
              <div className="br-radio-group">
                <input
                  type="radio"
                  value="No"
                  disabled
                  checked={getValues("hasBuilderPlate") === "No"}
                />
                <label htmlFor="hasBuilderPlateNo">NO</label>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              errors.hasBuilderPlate?.message
                ? "br-input-group error"
                : !getFieldState("hasBuilderPlate").isDirty
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="hasBuilderPlate">
              Is there an Australian Builder's Plate fixed to the Vessel{" "}
              <span className="text-danger">*</span>
            </label>
            <div className="br-field-radio-group">
              <div className="br-radio-group">
                <input
                  type="radio"
                  value="Yes"
                  id="hasBuilderPlateYes"
                  {...register("hasBuilderPlate", {
                    required: "This is required",
                  })}
                />
                <label htmlFor="hasBuilderPlateYes">YES</label>
              </div>
              <div className="br-radio-group">
                <input
                  type="radio"
                  value="No"
                  id="hasBuilderPlateNo"
                  {...register("hasBuilderPlate", {
                    required: "This is required",
                  })}
                />
                <label htmlFor="hasBuilderPlateNo">NO</label>
              </div>
            </div>
            <span className="msg">{errors.hasBuilderPlate?.message}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default BuilderPlate;
