import TransferSellerForm from "../../Components/Transferaction/TransferSellerForm";
import { useGet } from "../../Hooks/useFetch";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useAuth from "../../Hooks/useAuth";
import Dialog from "../../Components/Modal/Dialog";

const TransferOfRegistrationApprovalForm = () => {
  let { regoNo } = useParams();

  const [resSellers, errGetSeller, isLoadingSellers, apiGetSellers, abtSeller] =
    useGet();

  const [openPnaVerifiedDialog, setOpenPnaVerifiedDialog] = useState(false);
  const { auth } = useAuth();

  const isPnaIdVerified = () => {
    if (!auth?.isPnaIdVerified) {
      setOpenPnaVerifiedDialog(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    isPnaIdVerified();
    apiGetSellers("/api/transfer/sellerTransferDetails/" + regoNo);
  }, []);

  return (
    <>
      {!openPnaVerifiedDialog && (
        <TransferSellerForm
          resSellers={resSellers}
          errGetSeller={errGetSeller}
          isLoadingSellers={isLoadingSellers}
        />
      )}

      {openPnaVerifiedDialog && (
        <Dialog
          setOpenDialog={setOpenPnaVerifiedDialog}
          title="ALERT"
          children="Your account with MAST is not yet Verified. You will be unable to complete any transactions involving boat registration until you provide identity information to Service Tasmania or MAST."
          redirectUrl="/recvessel"
        />
      )}
    </>
  );
};

export default TransferOfRegistrationApprovalForm;
