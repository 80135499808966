import ChooseVerifyMethod from "../../Components/VerifyCode/ChooseVerifyMethod";
import useUserInfo from "../../Hooks/useUserInfo";
import useAuth from "../../Hooks/useAuth";
import { axiosApi } from "../../Api/Axios";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useGaEvent from "../../Hooks/useGaEvent";

const LoginMethod = () => {
  const { userInfo } = useUserInfo();
  const {setAuth} = useAuth();
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const gaEvents = useGaEvent();
  const navigate = useNavigate();
  const dest = "/login/loginverify";

  
  const handleSubmit = async (e) => {
      e.preventDefault();
      
      // console.log(userInfo);
    try {
      setIsLoading(true);
      const response = await axiosApi.post("/api/auth/login", {
        WWWId: userInfo.user.trim(),
        IsVerifyByEmail: userInfo.isSentByEmail,
      });

      // console.log(response);

      if (!response) {  
        setErrMsg("No Server Response");
      } else if (response.status === 200) {
        // console.log("accessToken: ", response.data);
        gaEvents.authLoginVisit();
        setAuth({ user: userInfo.user, accessToken: response.data.tk, countDownTime: response.data.timer });
        // console.log("success");
        navigate(dest, { replace: true });
      } else {
        setErrMsg(response.data);
      }
    } catch (err) {
      gaEvents.authLoginFail();
      if (err.response.status === 401) {
        setErrMsg("Session Expired");
      } else {
        setErrMsg(err.response.data);
      }
      // console.error(err);
    }finally{
      setIsLoading(false);
    }
  };

  return (
    <>
      {!userInfo.user? (
        <Navigate to="/login" replace={true} />
      ) : (
        <div className="login-container-bg ">
            <ChooseVerifyMethod handleSubmit={handleSubmit} errMsg={errMsg} isLoading={isLoading}/>
        </div>
      )}
    </>
    );
};

export default LoginMethod;
