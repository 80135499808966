import { useNavigate, Link } from "react-router-dom";
import "./Navbar.css";
import NotificationBadge from "react-notification-badge";
import { Effect } from "react-notification-badge";
import logo from "../Assets/mast_logo.png";
import {
  faRightToBracket,
  faRightFromBracket,
  faUser,
  faCaretDown,
  faIdBadge,
  faAnchor,
  faSailboat,
  faShip,
  faRegistered,
  faCartShopping,
  faGift,
  faFileAlt,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAuth from "../Hooks/useAuth";
import useLogout from "../Hooks/useLogout";
import useShoppingCart from "../Hooks/useShoppingCart";
import { useEffect, useState, useRef } from "react";
import NewBoatPopupMessage from "../Components/BoatRegistration/NewBoatPopupMessage";

const Navbar = () => {
  const { auth } = useAuth();
  const { cartItems } = useShoppingCart();

  const [open, setOpen] = useState(false);

  const [newBoatMsgOpen, setNewBoatMsgOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMsg, setDialogMsg] = useState('');

  const checkPopupMsg = () => {
    if (auth?.isAblePrintOrRegister === true) {
      setNewBoatMsgOpen(true);
      setOpenDialog(false);
    } else {
      setNewBoatMsgOpen(false);
      setOpenDialog(true);
      setDialogMsg("You have recently made changes to personal details. Some functions (Printing Certificates, New Vessel Registrations) are unavailable until MAST have confirmed these changes.");
    }

    if(auth?.isPnaIdVerified === true) {
      setNewBoatMsgOpen(true);
      setOpenDialog(false);
    }else {
      setNewBoatMsgOpen(false);
      setOpenDialog(true);
      setDialogMsg("Your account with MAST is not yet Verified. You will be unable to complete any transactions involving boat registration until you provide identity information to Service Tasmania or MAST.");
    }
  };

  const navigate = useNavigate();
  const logout = useLogout();

  // console.log(auth);

  const signOut = async () => {
    await logout();
    navigate("/login");
  };

  // const clickHamburger = () => {
  //   let navbar = document.querySelector(".navbar");
  //   navbar.classList.toggle("active");
  //   let navbarTogger = document.querySelector(".navbar-toggler");
  //   navbarTogger.classList.toggle("collapsed");
  // };
  let menuRef = useRef();
  let burgerRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (
        !menuRef.current.contains(e.target) &&
        !burgerRef.current.contains(e.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <>
      <header>
        <Link to="/" className="site-logo">
          <img src={logo} alt="Marine and Safety Tasmania" />
        </Link>
        <button
          type="button"
          className={`navbar-toggler ${open ? "collapsed" : ""}`}
          // className="navbar-toggler"
          onClick={() => setOpen(!open)}
          ref={burgerRef}
        >
          <span className="menu-icon-bar"></span>
        </button>
        <Link to="/checkcart" className="navbar-toggler-cart">
          <FontAwesomeIcon icon={faCartShopping} size="lg" />
          <span className="cart">
            {cartItems?.length > 0 && (
              <NotificationBadge
                count={cartItems?.length}
                effect={Effect.SCALE}
                style={{
                  top: "-29px",
                  left: "",
                  bottom: "",
                  right: "1px",
                  "padding-top": "3px",
                  "padding-bottom": "3px",
                  "padding-left": "5px",
                  "padding-right": "5px",
                }}
              />
            )}
          </span>
        </Link>

        <nav className={`navbar ${open ? "active" : ""}`} ref={menuRef}>
          <ul className="navbar-nav">
            {auth?.isLogin === true ? (
              <>
                <li>
                  <Link to="/pna" className="nav-item nav-link">
                    <FontAwesomeIcon icon={faUser} /> Person
                  </Link>
                </li>
                <li className="dropdown">
                  <Link
                    to="#"
                    className="nav-item nav-link"
                    data-toggle="dropdown"
                  >
                    Transfer/Cancel Registration{" "}
                    <FontAwesomeIcon icon={faCaretDown} />
                  </Link>
                  <div className="dropdown-menu">
                    <div className="dropdown-item">
                      <Link to="/transferseller" className="nav-item nav-link">
                        <FontAwesomeIcon icon={faFileAlt} /> Seller Form
                      </Link>
                    </div>
                    <div className="dropdown-item">
                      <Link to="/transferbuyer" className="nav-item nav-link">
                        <FontAwesomeIcon icon={faFileAlt} /> Buyer Form
                      </Link>
                    </div>
                    <div className="dropdown-item">
                      <Link
                        to="/transferdisposal"
                        className="nav-item nav-link"
                      >
                        <FontAwesomeIcon icon={faFileAlt} /> Cancel – Reg not
                        required
                      </Link>
                    </div>
                  </div>
                </li>
                <li className="dropdown">
                  <Link
                    to="#"
                    className="nav-item nav-link"
                    data-toggle="dropdown"
                  >
                    Services <FontAwesomeIcon icon={faCaretDown} />
                  </Link>
                  <div className="dropdown-menu">
                    <div className="dropdown-item">
                      <Link to="/licence" className="nav-item nav-link">
                        <FontAwesomeIcon icon={faIdBadge} /> Licence
                      </Link>
                    </div>

                    <div className="dropdown-item">
                      <Link to="/recvessel" className="nav-item nav-link ">
                        <FontAwesomeIcon icon={faSailboat} />{" "}
                        <span className="hidden-less-570">
                          Recreational Vessels
                        </span>
                        <span className="show-less-570">Rec-Vessel</span>
                      </Link>
                    </div>
                    <div className="dropdown-item">
                      <Link to="#" className="" onClick={checkPopupMsg}>
                        <FontAwesomeIcon icon={faRegistered} /> New Rec Vessel
                      </Link>
                    </div>
                    <div className="dropdown-item">
                      <Link to="/mooring" className="nav-item nav-link">
                        <FontAwesomeIcon icon={faAnchor} /> Moorings
                      </Link>
                    </div>

                    <div className="dropdown-item">
                      <Link
                        to="/comvessel"
                        className="nav-item nav-link hidden-less-570"
                      >
                        <FontAwesomeIcon icon={faShip} />{" "}
                        <span className="hidden-less-570">
                          Commercial Vessels
                        </span>
                        <span className="show-less-570">Com-Vessel</span>
                      </Link>
                    </div>
                  </div>
                </li>
                <li>
                  <Link to="/shoppingcart" className="nav-item nav-link">
                    <FontAwesomeIcon icon={faGift} /> Store
                  </Link>
                </li>
                <li>
                  <Link to="/checkcart" className="nav-item nav-link">
                    <FontAwesomeIcon icon={faCartShopping} />{" "}
                    <span className="cart">
                      {cartItems?.length > 0 && (
                        <NotificationBadge
                          count={cartItems?.length}
                          effect={Effect.SCALE}
                          style={{
                            top: "-10px",
                            left: "",
                            bottom: "",
                            right: "-10px",
                            "padding-top": "3px",
                            "padding-bottom": "3px",
                            "padding-left": "5px",
                            "padding-right": "5px",
                          }}
                        />
                      )}
                      Cart
                    </span>
                    {/* <FontAwesomeIcon icon={faCartShopping} /> <span className="cart">Cart</span>  */}
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}

            {auth?.isLogin === true ? (
              <li className="dropdown">
                <Link
                  to="#"
                  className="nav-item nav-link"
                  data-toggle="dropdown"
                >
                  My Account <FontAwesomeIcon icon={faCaretDown} />
                </Link>
                <div className="dropdown-menu">
                  <div className="dropdown-item">
                    <Link to="/pwdchange" className="nav-item nav-link ">
                      <div className="nav-chg-pwd-outer">
                        <FontAwesomeIcon icon={faLock} />
                        <div className="nav-chg-pwd-inner">
                          <span>Change</span>
                          <span>Password</span>
                        </div>
                      </div>
                        
                    </Link>
                  </div>
                  <div className="dropdown-item">
                    <Link
                      to="#"
                      className="nav-item nav-link"
                      onClick={signOut}
                    >
                      <FontAwesomeIcon icon={faRightFromBracket} /> Log out
                    </Link>
                  </div>
                </div>
              </li>
            ) : (
              <>
                <Link to="/login" className="nav-item nav-link">
                  <FontAwesomeIcon icon={faRightToBracket} /> Login
                </Link>
              </>
            )}
          </ul>
        </nav>
      </header>

      {(auth?.isLogin === true) && <NewBoatPopupMessage
        isOpen={newBoatMsgOpen}
        setIsOpen={setNewBoatMsgOpen}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        dialogMsg={dialogMsg}
      />}
    </>
  );
};

export default Navbar;
