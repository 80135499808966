import { axiosApi } from '../Api/Axios';
import useAuth from './useAuth';


const useRefreshToken = () => {
    const { auth, setAuth } = useAuth();
    //console.log(auth);
    const refresh = async () => {
        const response = await axiosApi.get('/api/auth/refresh-token', {
            withCredentials: true
        });
        setAuth(prev => {
            // console.log(JSON.stringify(prev));
            // console.log(response.data); //it is accessToken
            return { ...prev, accessToken: response.data }
        });
        return response.data; //accessToken
    }
    return refresh;
};

export default useRefreshToken;