import PayIntro from "../Payments/PayIntro";
// import CardWay from "../Payments/CardWay";
import PayWay from "../Payments/PayWay";

const FormPayProcess = ({
  errPayWayFrame,
  errPayWayToken,
  tokenId,
  creditCard,
  createFrame,
  stateDestroy,
  regoFee,
  setValue
}) => {
  return (
    <>
      <PayIntro fee={regoFee}/>
      {/* <CardWay
        register={register}
        watch={watch}
        control={control}
        errors={errors}
        getFieldState={getFieldState}
        setValue={setValue}
        setError={setError}
        clearErrors={clearErrors}
      /> */}
      <PayWay
        errPayWayFrame={errPayWayFrame}
        errPayWayToken={errPayWayToken}
        tokenId={tokenId}
        creditCard={creditCard}
        createFrame={createFrame}
        stateDestroy={stateDestroy}
        setValue = {setValue}
      />
    </>
  );
};

export default FormPayProcess;
