import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import { getddmmyyyyFormat } from "../../Helpers/Helper";
import { ViewIcon } from "../Icons";
import DataLoader from "../DataLoader";
import WestpacPayment from "../WestpacPayment";
import AlertMessage from "../AlertMessage";
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";

const LicencePaymentList = () => {
  const [res, err, isLoading, apiGet, abt] = useGet();

  useEffect(() => {
    apiGet("/api/licence/DlPaymentList");
  }, []);

  // console.log(res);

  return (
    <>
      <div className="licence-sub-container">
        <div className="licence-title-group">
          <h3 className="licence-title">Payment History</h3>
          <WestpacPayment url="/api/licence/RenewData" isLicenceRenewal={true} />
        </div>

        {!isLoading ? (
          !err ? (
            res?.length === 0 ? (
              <AlertMessage
                message="No Records"
                level="info"
                closeEvt="false"
                mt="0"
                mb="0"
              />
            ) : (
              res?.map((d) => {
                return (
                  <>
                    <div className="row" key={uuidv4()}>
                      <div className="col-md-5">
                        <div className="licence-input-group">
                          <label htmlFor="DatePay">Date Paid</label>
                          <div className="licenceInfoField">
                            {getddmmyyyyFormat(d?.dlDate?.split("T")[0])}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="licence-input-group">
                          <label htmlFor="Amount">Amount</label>
                          <div className="licenceInfoField">
                            {Math.abs(d?.dlAmt).toFixed(2)}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="licence-input-group">
                          <label htmlFor="Action">Action</label>
                          <div className="icon-group">
                            <ViewIcon
                              url={
                                "/licence/licencepaymentdetails/" +
                                d?.dlRegoNo +
                                "/" +
                                d?.dlSequNo
                              }
                              HoverCaption="View Payment History"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            )
          ) : (
            <AlertMessage message={err} />
          )
        ) : (
          <DataLoader isLoading={isLoading} />
        )}
      </div>
    </>
  );
};

export default LicencePaymentList;
