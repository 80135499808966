import { useParams } from "react-router-dom";
import ComVesselPaymentList from "./ComVesselPaymentList";
import { useEffect, useState } from "react";
import { useGet } from "../../Hooks/useFetch";
import { getddmmyyyyFormat } from "../../Helpers/Helper";
import ComVesselUpdatedForm from "./ComVesselUpdatedForm";
import DataLoader from "../DataLoader";
import AlertMessage from "../AlertMessage";

const ComVesselDetails = () => {
  let { id } = useParams();
  const [res, err, isLoading, apiGet, abt] = useGet();
  const [canFresh, setCanFresh] = useState(false);
  useEffect(() => {
    apiGet("/api/fa/FaVesselDetails/" + id);
  }, []);

  useEffect(() => {
    apiGet("/api/fa/FaVesselDetails/" + id);
    setCanFresh(false);
  }, [canFresh]);

  // console.log(res);

  return (
    <>
      <div className="row">
        <div className="col-md-7">
          <div className="comvessel-sub-container">
            <div className="comvessel-title-group">
              <h3 className="comvessel-title">Commercial Vessel Details</h3>
              {/* <div>{res?.statusReason}</div> */}
            </div>

            {!isLoading ? (
              !err ? (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="comvessel-input-group">
                        <label htmlFor="regoNo">Rego</label>
                        <div className="comvesselInfoField">{res?.faVessel?.favRegoNo}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="comvessel-input-group">
                        <label htmlFor="amsaUvi">AMSA UVI</label>
                        <div className="comvesselInfoField">
                          {res?.faVessel?.favAmsaUvi}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="comvessel-input-group">
                        <label htmlFor="status">Status</label>
                        <div className="comvesselInfoField">
                          {res?.statusCodeDesc}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="comvessel-input-group">
                        <label htmlFor="length">Length</label>
                        <div className="comvesselInfoField">
                          {res?.faVessel?.favLength?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="comvessel-input-group">
                        <label htmlFor="lengthOverall">Length Overall</label>
                        <div className="comvesselInfoField">
                          {res?.faVessel?.favLengthOverall?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                    <div className="comvessel-input-group">
                        <label htmlFor="grossTn">Gross</label>
                        <div className="comvesselInfoField">{res?.faVessel?.favGrossTn?.toFixed(2)} (Tons)</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="comvessel-input-group">
                        <label htmlFor="beam">Beam</label>
                        <div className="comvesselInfoField">{res?.faVessel?.favBeam?.toFixed(2)}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="comvessel-input-group">
                        <label htmlFor="draft">Draft</label>
                        <div className="comvesselInfoField">
                          {res?.faVessel?.favDraft?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="comvessel-input-group">
                        <label htmlFor="hullClass">Hull Class</label>
                        <div className="comvesselInfoField">
                          {res?.faVessel?.favHullClassif}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="comvessel-input-group">
                        <label htmlFor="mateCode">Material</label>
                        <div className="comvesselInfoField">
                          {res?.faVessel?.favMateCode}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="comvessel-input-group">
                        <label htmlFor="yearBuilt">Year Built</label>
                        <div className="comvesselInfoField">
                          {(res?.faVessel?.favYearBuilt == null || res?.faVessel?.favYearBuilt === 0) ? '' : res?.faVessel?.favYearBuilt}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="comvessel-input-group">
                        <label htmlFor="expiry">Expiry</label>
                        <div className="comvesselInfoField">
                          {getddmmyyyyFormat(res?.faVessel?.favExpiryDate?.split("T")[0])}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <AlertMessage message={err}/>
              )
            ) : (
              <DataLoader isLoading={isLoading} />
            )}
          </div>

          {res?.faVessel && <ComVesselUpdatedForm faDetails={res?.faVessel} setCanFresh={setCanFresh} vesselTypeDesc={res?.vesselTypeDesc}/>}    

        </div>
        <div className="col-md-5">
          <ComVesselPaymentList favSysId={id} favAmsaUvi={res?.faVessel?.favAmsaUvi} />
        </div>
      </div>
    </>
  );
};

export default ComVesselDetails;
