import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { useState, useEffect } from "react";

const InputTooltip = ({ children, titleCaption }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (open) {
      setTimeout(handleClose, 1800);
    }
  }, [open]);

  return (
    <Tooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 800 }}
      title={titleCaption}
      arrow
      disableHoverListener
      placement="top"
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
    >
        {children}
    </Tooltip>
  );
};

export default InputTooltip;
