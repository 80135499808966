import PasswordResetInputFields from "../../Components/PasswordReset/PasswordResetInputFields";
import useUserInfo from "../../Hooks/useUserInfo";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import dayjs from "dayjs"

const PasswordResetForm = () => {


  let initialValues = {};
  if(process.env.REACT_APP_TEST_DEFAULT_VALUE === 'on')
  {
    initialValues = {
      pwdFirstName: "JENNY",
      pwdSurName: "CHENG",
      pwdStreet: "PO BOX 110",
      pwdSuburb: "SMITHTON",
      pwdState: "TAS",
      pwdPostCode: "7330",
      pwdDOB: dayjs("2000-03-02"),
      // pwdCheckPostal: false,
      // pwdPostalStreet: "PO BOX 110",
      // pwdPostalSuburb: "SMITHTON",
      // pwdPostalState: "TAS",
      // pwdPostalPostCode: "7330",
      pwdMobile: "0423456789",
      pwdEmail: "jl1@datawise.com.au",
      pwdDlLicenceNo: "A1234567",
      pwdPcRegoNo: "P3456789",
      pwdMoRegoNo: "M9876543",
      pwdFavAmsaUVI: "F1234567",
    };
  }

  const {
    control,
    register,
    formState: { errors, isDirty, isValid, dirtyFields },
    handleSubmit,
    watch,
    getFieldState,
    clearErrors,
  } = useForm({ defaultValues: initialValues, mode: "onChange" });

  const { setUserInfo } = useUserInfo();
  const navigate = useNavigate();
  const dest = "/passwordreset/pwdresetmethod";

  const onSubmit = (data) => {
    // e.preventDefault();
    // console.log(data);

    // if (data.pwdCheckPostal) {
    //   data.pwdPostalStreet = data.pwdStreet;
    //   data.pwdPostalSuburb = data.pwdSuburb;
    //   data.pwdPostalState = data.pwdState;
    //   data.pwdPostalPostCode = data.pwdPostCode;
    // }

    setUserInfo({ ...data, maskInfo: false, action: 3, isSentByEmail: true });
    navigate(dest, { replace: true });
  };

  // const handleChange = (e) => {
  //   const type = e.target.type;
  //   const name = e.target.name;
  //   const value = type === "checkbox" ? e.target.checked : e.target.value;

  //   // () means object, and [] is mapping to type name
  //   setData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  // const handleDatePickerChange = (name, date) => {
  //   setData((prevData) => ({
  //     ...prevData,
  //     [name]: date,
  //   }));
  // };

  // const {pwdCheckPostal, pwdPostalStreet, pwdPostalSuburb, pwdPostalState, ...otherFields} = data
  // const canSave = [...Object.values(otherFields)].every(Boolean) && (!pwdCheckPostal&&pwdPostalStreet===""&&pwdPostalSuburb===""&&pwdPostalState==="")

  const content = (
    <>
      <div className="pwd-reset-bg">
        <div className="pwd-reset-form-container">
          <h2 className="pwd-reset-form-title">Password Reset</h2>
          <form onSubmit={handleSubmit(onSubmit)} className="pwd-reset-form">
            <PasswordResetInputFields
              register={register}
              watch={watch}
              control={control}
              errors={errors}
              getFieldState={getFieldState}
              clearErrors={clearErrors}
            />
            <button className="pwd-reset-form-button">Submit</button>
          </form>
        </div>
      </div>
    </>
  );

  return content;
};

export default PasswordResetForm;
