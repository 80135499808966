import { useForm } from "react-hook-form";
import SummaryPriceDisplay from "./SummaryPriceDisplay";
import { useNavigate, useLocation } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePost } from "../../Hooks/useFetch";
import SubmitLoader from "../SubmitLoader";
import ClientAddress from "./ClientAddressField";
import useGaEvent from "../../Hooks/useGaEvent";
import useToast from "../../Hooks/useToast";
import AlertMessage from "../AlertMessage";

const ZeroPayProcess = ({ setCartItems, cartItems, cartTotalPrice }) => {
  const gaEvents = useGaEvent();
  const toastEmitter = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const initialValues = {
    payId: "",
    shipTo: "P",
  };
  const {
    control,
    register,
    formState: { errors, isDirty, isValid, dirtyFields },
    handleSubmit,
    watch,
    getFieldState,
    setValue,
    setError,
    clearErrors,
  } = useForm({ defaultValues: initialValues, mode: "onChange" });

  const [res, err, isLoadingPost, apiPost, abt] = usePost();

  const successFuc = () => {
    setCartItems([]);
    gaEvents.ShopPaidSuccess();
    toastEmitter("Your order han been successfully received!", "success");
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    // console.log(cartItems);

    await apiPost(
      "/api/shop/productpayprocess",
      {
        SingleUseTokenId: data.payId,
        PayAmount: cartTotalPrice.toFixed(2),
        OrderDetails: cartItems,
        ShipTo: data.shipTo,
        IsZeroAmount: true,
      },
      false,
      "/checkcart",
      successFuc,
      gaEvents.ShopPaidFail
    );
  };

  return (
    <>
      {!isLoadingPost ? (
        <>
          <div className="cart-container">
            <div className="row cart-title">
              <div className="col-md-12">
                <h1>Pay Process</h1>
              </div>
            </div>
            <div className="cart-err-container">
              <AlertMessage message={err} mb={0}/>
            </div>
            <div className="cart-sub-container">
              <div className="row">
                <div className="col-md-3 col-md-push-9">
                  <SummaryPriceDisplay totalPrice={cartTotalPrice.toFixed(2)} />
                </div>
                <div className="col-md-9 col-md-pull-3">
                  <form
                    className="cart-pay-form-container"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <ClientAddress
                      register={register}
                      watch={watch}
                      control={control}
                      errors={errors}
                      getFieldState={getFieldState}
                    />
                    <div className="pay-btn-group">
                      <button
                        className="continue-btn"
                        onClick={() =>
                          navigate("/checkcart", {
                            state: { from: location },
                            replace: false,
                          })
                        }
                      >
                        <span className="hide-long-word">
                          <FontAwesomeIcon icon={faChevronLeft} /> Back Cart
                        </span>
                        <span className="display-short-word">
                          <FontAwesomeIcon icon={faChevronLeft} /> Cart
                        </span>
                      </button>
                      <button type="submit" className="purchase-btn">
                        Order
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <SubmitLoader isLoading={isLoadingPost} />
      )}
    </>
  );
};

export default ZeroPayProcess;
