import OwnerType from "../OwnershipDetails/OwnerType";
import PrimaryContact from "../../Contacts/PrimaryContact";
import SecondContacts from "../../Contacts/SecondContacts";

const FormOwnerDetail = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  clearErrors,
  isCompanyFieldName,
  SecondContactArrayPropName,
  resPna
}) => {
  return (
    <>
      <OwnerType
        register={register}
        watch={watch}
        getFieldState={getFieldState}
        isCompanyFieldName={isCompanyFieldName}
      />

      <PrimaryContact
        register={register}
        watch={watch}
        control={control}
        errors={errors}
        getFieldState={getFieldState}
        clearErrors={clearErrors}
        isCompanyFieldName={isCompanyFieldName}
        resPna={resPna}
        prefixIsCompanyField="rb"
      />

      <SecondContacts
        register={register}
        watch={watch}
        getFieldState={getFieldState}
        errors={errors}
        clearErrors={clearErrors}
        control={control}
        SecondContactArrayPropName={SecondContactArrayPropName}
        isCompanyFieldName={isCompanyFieldName}
        resPna={resPna}
      />
    </>
  );
};

export default FormOwnerDetail;
