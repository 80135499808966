import { useRef, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import useUserInfo from "../../Hooks/useUserInfo";
import { axiosApi } from "../../Api/Axios";
import DataLoader from "../DataLoader";
import CapsLockTooltip from "../CapsLockTooltip";
import useCapsLock from "../../Hooks/useCapsLock";
import { secureText, decryptText } from "../../Helpers/Helper";

const LoginForm = () => {
  const {isCapsLockOn, checkCapsLock} = useCapsLock();
  const { userInfo, setUserInfo } = useUserInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [remember, setRemember] = useState(
    localStorage.getItem("remember") == null ? false : true
  );
  const userRef = useRef();
  const errRef = useRef();

  // console.log(localStorage.getItem("remember"));

  // const [user, setUser] = useState(process.env.REACT_APP_LOGIN_USER.trim());
  const [user, setUser] = useState(localStorage.getItem("remember") || '');
  const [pwd, setPwd] = useState(process.env.REACT_APP_LOGIN_PWD.trim());
  const [errMsg, setErrMsg] = useState("");

  const navigate = useNavigate();
  const dest = "/login/loginmethod";

  // console.log(userInfo);

  useEffect(() => {
    userRef.current.focus();
  }, []); //happen when component load

  const toggleRemember = () => {
    setRemember((prev) => !prev);
  };

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      WWWId: user.trim(),
      NewPassword: secureText(pwd.trim(), process.env.REACT_APP_Encrypt_Key, process.env.REACT_APP_Encrypt_IV),
    };

    if (remember) {
      localStorage.setItem("remember", user?.trim());
    } else {
      localStorage.removeItem("remember");
    }

    try {
      setIsLoading(true);
      const response = await axiosApi.post("/api/auth/login-check", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Methods": "GET, POST",
        },
      });

      // console.log(response);
      if (!response) {
        setErrMsg("No Server Response");
      } else if (response.status === 200) {
        // console.log(response.data);
        setUserInfo(() => ({
          user: user.trim(),
          pwd: pwd.trim(),
          // email: response.data.email.trim(),
          email: decryptText(response.data.email, process.env.REACT_APP_Encrypt_Key, process.env.REACT_APP_Encrypt_IV)?.trim(),
          // mobile: response.data.mobile.trim(),
          mobile: decryptText(response.data.mobile, process.env.REACT_APP_Encrypt_Key, process.env.REACT_APP_Encrypt_IV)?.trim(),
          maskInfo: true,
          action: 1,
          isSentByEmail: true,
          code: "",
        }));
        setUser("");
        setPwd("");
        // console.log(userInfo);
        navigate(dest, { replace: true });
      } else {
        setErrMsg(response.data);
        errRef.current.focus();
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response.status === 401) {
        setErrMsg("Unauthorized Access");
      } else {
        // console.log(err);
        setErrMsg(err.response.data);
      }
      errRef.current.focus();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <h2 className="login-title">MAST Login</h2>
      <p
        ref={errRef}
        className={errMsg ? "errmsg" : "offscreen"}
        aria-live="assertive"
      >
        {errMsg}
      </p>
      <form onSubmit={handleSubmit} className="login-form">
        <div className="login-input-group">
          <label htmlFor="PNA_WWW_ID">Login ID</label>
          <input
            type="text"
            id="username"
            ref={userRef}
            autoComplete="off"
            onChange={(e) => setUser(e.target.value)}
            value={user}
            required
          />
        </div>
        <div className="login-input-group">
          <label htmlFor="password">Password</label>
          <CapsLockTooltip isCapsLockOn={isCapsLockOn}>
            <input
              type="password"
              id="password"
              onKeyUp={checkCapsLock}
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
            />
          </CapsLockTooltip>
          
        </div>
        <div className="persistCheck unSelectable">
          <input
            type="checkbox"
            id="remember"
            onChange={toggleRemember}
            checked={remember}
          />
          <label htmlFor="remember">Remember Me</label>
        </div>
        <div className="login-links align-right">
          <Link to="/passwordreset">Forgotten your password?</Link>
        </div>
        <button className="login-button" disabled={!user || !pwd}>
          {!isLoading ? "Login" : <DataLoader isLoading={isLoading} />}
        </button>
      </form>
    </>
  );
};

export default LoginForm;
