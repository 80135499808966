import Modal from "react-modal";
import { useState } from "react";
import NoImgAvailable from "../../Assets/no-image-available.png";

const ProductImage = ({ imageUrl}) => {
    
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
        },
      };
    const handleModal = () => {
        setModalIsOpen(!modalIsOpen);
    };
    
    return ( 
    <>
      <img src={imageUrl} onClick={handleModal} alt="NoImg" width="100" height="125" onError={(e) => (e.target.src = NoImgAvailable)} className="product-img"/>
      <Modal isOpen={modalIsOpen} onRequestClose={handleModal} style={customStyles}>
        <img src={imageUrl} className="ex-product-img"/>
      </Modal>
      
    </>
     );
}
 
export default ProductImage;