import { useRef, useEffect, useState } from "react";

const useEllipsis = (text, lineLimits = 2) => {
  const [hasEllipsis, setHasEllipsis] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      const computedStyle = window.getComputedStyle(ref.current);
      const lineHeight = parseFloat(computedStyle.lineHeight);
      const maxHeight = lineHeight * lineLimits; // Two lines of text
      const hasEllipsis = ref.current.scrollHeight > maxHeight;
      setHasEllipsis(hasEllipsis);
    }
  }, [text]);

  return { ref, hasEllipsis };
};

export default useEllipsis;
