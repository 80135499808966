import { createContext, useState } from "react";

export const ShoppingCartContext = createContext({});

export const ShoppingCartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);

    const addToCart = (product, qty = 1) => {
        // console.log(product);
        const itemExists = cartItems.find((item) => item.id === product.id);
        if (itemExists) {
            setCartItems(
                cartItems.map((item) =>
                    item.id === product.id ? { ...item, qty: parseInt(qty) } : item
                )
            );
        } else {
            setCartItems([...cartItems, { ...product, qty: parseInt(qty) }]);
        }
    };

    const changeQtyFromCart = (product, qty = 1) => {
        setCartItems(
            cartItems.map((item) =>
                item.id === product.id ? { ...item, qty: parseInt(qty) } : item
            )
        );
    };

    const removeFromCart = (product) => {
        const newCartItems = cartItems.filter((cartItem) => cartItem.id !== product.id);
        setCartItems(newCartItems);
      };


    const cartTotalPrice = cartItems.reduce((total, item) => total + item.price * item.qty, 0);

  return (
    <ShoppingCartContext.Provider
      value={{
        cartItems,
        setCartItems,
        cartTotalPrice,
        addToCart,
        removeFromCart,
        changeQtyFromCart,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
};
