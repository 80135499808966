import { useEffect } from "react";
import PnaUpdatedForm from "../../Components/Pna/PnaUpdatedForm";
import { useGet } from "../../Hooks/useFetch";
import SubmitLoader from "../../Components/SubmitLoader";
import useGaEvent from "../../Hooks/useGaEvent";
import AlertMessage from "../../Components/AlertMessage";

const PnaUpdated = () => {
  const [response, error, isGetLoading, apiGet, abort] = useGet();
  const gaEvents = useGaEvent();

  useEffect(() => {
    apiGet("/api/pna/PnaDecryptedData");
    gaEvents.pnaDetailUpdateVisit();
  }, []);

  return (
    <>
      {isGetLoading ? (
        <SubmitLoader isLoading={isGetLoading} />
      ) : error ? (
        <div className="home-container">
          <div className="row">
            <div className="col-md-12">
              <AlertMessage message={error} />
            </div>
          </div>
        </div>
      ) : response ? (
        <div className="home-container">
          <div className="row">
            <div className="col-md-12">
              <PnaUpdatedForm pnaDetails={response} />
            </div>
          </div>
        </div>
      ) : (
        <div>No data</div>
      )}
    </>
  );
};

export default PnaUpdated;
