import { useRef, useEffect } from "react";
import useUserInfo from "../../Hooks/useUserInfo";
// import useAuth from "../../Hooks/useAuth";
import "./VerifyCode.css";
import DataLoader from "../DataLoader";
import TimerCountDown from "../TimerCountDown";

const VerifyCode = ({ handleSubmit, errMsg, btnDisable }) => {
  // const { persist, setPersist } = useAuth();
  const { userInfo, setUserInfo } = useUserInfo();
  const codeRef = useRef();
  const errRef = useRef();

  // console.log(userInfo);

  useEffect(() => {
    codeRef.current.focus();
  }, []); //happen when component load

  // const togglePersist = () => {
  //   setPersist((prev) => !prev);
  // };

  // useEffect(() => {
  //   localStorage.setItem("persist", persist);
  // }, [persist]);

  return (
    <>
      <div className="verify-code-container">
        <h2 className="verify-code-title">Verification</h2>
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <form onSubmit={handleSubmit} className="verify-code-form">
          <div className={errMsg? "verify-code-input-group error":"verify-code-input-group"}>
            <label htmlFor="verifyCode">Verify Code</label>
            <input
              type="number"
              id="verifyCode"
              ref={codeRef}
              onChange={(e) =>
                setUserInfo((prev) => ({
                  ...prev,
                  code: e.target.value,
                }))
              }
              value={userInfo.code}
              required
              placeholder="6 digit numbers"
            />
          </div>
          {/* <div className={userInfo.action === 1 ? "persistCheck" : "hide"}>
            <input
              type="checkbox"
              id="persist"
              onChange={togglePersist}
              checked={persist}
            />
            <label htmlFor="persist">Trust this device</label>
          </div> */}
          <button className="verify-code-button" disabled={!userInfo.code || btnDisable 
            || userInfo.code?.includes('.') || userInfo.code?.includes('-') || userInfo.code?.includes('e')
            }>
            {
              !btnDisable ?(      
                !errMsg ? (<span>Submit ({<TimerCountDown min={userInfo.countDownTime}/> })</span>):("Submit")    
              ):(
                  <DataLoader isLoading={true}/>
              )
            }
          </button>
        </form>
      </div>
    </>
  );
};

export default VerifyCode;