//This page is either showing unidentified user or register password page
import useUserInfo from "../../Hooks/useUserInfo";
import UnidentifiedUser from "../../Components/VerifyCode/UnidentifiedUser";
import UpdatePasswordResetForm from "../../Components/PasswordReset/UpdatePasswordResetForm";
import { Navigate } from "react-router-dom";

const PasswordResetResult = () => {
  const { userInfo } = useUserInfo();

  return (
    <>
      {!userInfo.accessToken ? (
        <Navigate to="/passwordreset" replace={true} />
      ) : (
        <div className="pwd-reset-bg">
          {userInfo.regiReqNo ? (
            <UnidentifiedUser
              RegistrationNo={userInfo.regiReqNo}
              From={"Password Reset"}
              FailReason={userInfo.failReason}
            />
          ) : (
            <UpdatePasswordResetForm />
          )}
        </div>
      )}
    </>
  );
};

export default PasswordResetResult;
