import { useEffect } from "react";

const VesselBasicUpdated = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  clearErrors
}) => {


  useEffect(() => {
    if(watch("whereVesselKept") !== "RB_MARINA")
    {
      clearErrors("marinaName");
    }
  }, [watch("whereVesselKept")])

  return (
    <>
      <div className="">
        {/* <div className="recvessel-updated-subtitle">Vessel Details</div> */}

        <div className="recvessel-updated-field-group">
          <div className="row">
            <div className="col-md-6">
              <div className="recvessel-updated-input-group">
                <label htmlFor="vesselName">Boat Name</label>
                <input
                  type="text"
                  id="vesselName"
                  {...register("vesselName")}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className={
                  errors.pocodeOfStorage?.message
                    ? "recvessel-updated-input-group error"
                    : !getFieldState("pocodeOfStorage").isDirty
                    ? "recvessel-updated-input-group"
                    : "recvessel-updated-input-group success"
                }
              >
                <label htmlFor="pocodeOfStorage">
                  Postcode of storage<span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  id="pocodeOfStorage"
                  {...register("pocodeOfStorage", {
                    required: "Postcode is required",
                    pattern: {
                      value: /^[0-9]{4}$/,
                      message: "4 digit number allowed",
                    },
                  })}
                />
                <span className="msg">{errors.pocodeOfStorage?.message}</span>
                {watch("pocodeOfStorage") ? (
                  <span className="recvessel-updated-desc-text">
                    Additional documentation is required for vessels stored
                    outside of Tasmania.
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div
                className={
                  errors.whereVesselKept?.message
                    ? "recvessel-updated-input-group error"
                    : !getFieldState("whereVesselKept").isDirty
                    ? "recvessel-updated-input-group"
                    : "recvessel-updated-input-group success"
                }
              >
                <label htmlFor="whereVesselKept">
                  Vessel Kept<span className="text-danger">*</span>
                </label>
                <select
                  id="whereVesselKept"
                  {...register("whereVesselKept", {
                    required: "This is required",
                  })}
                >
                  <option value="">Choose...</option>
                  <option value="RB_MARINA">Marina</option>
                  <option value="RB_BOATSHED">Boatshed</option>
                  <option value="RB_JETTY">Jetty</option>
                  <option value="RB_TRAILER">Trailer</option>
                  <option value="RB_SLIPWAY">Slipway</option>
                  <option value="RB_MOORING">Mooring</option>
                </select>
                <span className="msg">{errors.whereVesselKept?.message}</span>
              </div>
            </div>
          </div>

          {watch("whereVesselKept") === "RB_MARINA" ? (
            <>
              <div className="row">
                <div className="col-md-6">
                  <div
                    className={
                      errors.marinaName?.message
                        ? "recvessel-updated-input-group error"
                        : !getFieldState("marinaName").isDirty
                        ? "recvessel-updated-input-group"
                        : "recvessel-updated-input-group success"
                    }
                  >
                    <label htmlFor="marinaName">
                      Marina Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="marinaName"
                      {...register("marinaName", {
                        required: {
                          value: watch("whereVesselKept") === "RB_MARINA",
                          message: "Marina name is required",
                        },
                      })}
                    />
                    <span className="msg">{errors.marinaName?.message}</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="recvessel-updated-input-group">
                    <label htmlFor="marinaBerthNum">
                      Marina Berth Number (if known)
                    </label>
                    <input
                      type="text"
                      id="marinaBerthNum"
                      {...register("marinaBerthNum")}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {watch("whereVesselKept") === "RB_MOORING" ? (
            <div className="row">
              <div className="col-md-12">
                <div className="recvessel-updated-input-group">
                  <label htmlFor="mooringRegoNo">Mooring Rego Number</label>
                  <input
                    type="text"
                    id="mooringRegoNo"
                    {...register("mooringRegoNo")}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="row">
            <div className="col-md-6">
              <div
                className={
                  errors.vesselUse?.message
                    ? "recvessel-updated-input-group error"
                    : !getFieldState("vesselUse").isDirty
                    ? "recvessel-updated-input-group"
                    : "recvessel-updated-input-group success"
                }
              >
                <label htmlFor="vesselUse">
                  Vessel Use<span className="text-danger">*</span>
                </label>
                <div className="recvessel-updated-field-radio-group">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="recvessel-updated-radio-group">
                        <input
                          type="radio"
                          value="RB_CRUISING"
                          id="cruising"
                          {...register("vesselUse", {
                            required: {
                              value: true,
                              message: "This is required.",
                            },
                          })}
                        />
                        <label htmlFor="cruising" className="unSelectable">
                          Cruising
                        </label>
                      </div>
                      <div className="recvessel-updated-radio-group">
                        <input
                          type="radio"
                          value="RB_DIVING"
                          id="diving"
                          {...register("vesselUse", {
                            required: {
                              value: true,
                              message: "This is required.",
                            },
                          })}
                        />
                        <label htmlFor="diving" className="unSelectable">
                          Diving
                        </label>
                      </div>
                      <div className="recvessel-updated-radio-group">
                        <input
                          type="radio"
                          value="RB_FISHING"
                          id="fishing"
                          {...register("vesselUse", {
                            required: {
                              value: true,
                              message: "This is required.",
                            },
                          })}
                        />
                        <label htmlFor="fishing" className="unSelectable">
                          Fishing
                        </label>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="recvessel-updated-radio-group">
                        <input
                          type="radio"
                          value="RB_WATERSPORTS"
                          id="watersports"
                          {...register("vesselUse", {
                            required: {
                              value: true,
                              message: "This is required.",
                            },
                          })}
                        />
                        <label htmlFor="watersports" className="unSelectable">
                          Watersports
                        </label>
                      </div>
                      <div className="recvessel-updated-radio-group">
                        <input
                          type="radio"
                          value="RB_YACHT_RACING"
                          id="yachtRacing"
                          {...register("vesselUse", {
                            required: {
                              value: true,
                              message: "This is required.",
                            },
                          })}
                        />
                        <label htmlFor="yachtRacing" className="unSelectable">
                          Yacht Racing
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <span className="msg">{errors.vesselUse?.message}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className={
                  errors.classVessel?.message
                    ? "recvessel-updated-input-group error"
                    : !getFieldState("classVessel").isDirty
                    ? "recvessel-updated-input-group"
                    : "recvessel-updated-input-group success"
                }
              >
                <label htmlFor="classVessel">
                  Class<span className="text-danger">*</span>
                </label>
                <select
                  id="classVessel"
                  {...register("classVessel", {
                    required: {
                      value: true,
                      message: "Class is required",
                    },
                  })}
                >
                  <option value="">Choose...</option>
                  <option value="CRUISER">Cruiser</option>
                  <option value="CUDDY CABIN">Cuddy Cabin</option>
                  <option value="INFLATABLE">Inflatable</option>
                  <option value="MOTOR LAUNCH">Motor Launch</option>
                  <option value="MOTOR SAILER">Motor Sailer</option>
                  <option value="OPEN BOAT">Open Boat</option>
                  <option value="PWC">PWC (Jet Ski)</option>
                  <option value="RUNABOUT">Runabout</option>
                  <option value="YACHT">Yacht</option>
                </select>
                <span className="msg">{errors.classVessel?.message}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div
                className={
                  errors.hullColour?.message
                    ? "recvessel-updated-input-group error"
                    : !getFieldState("hullColour").isDirty
                    ? "recvessel-updated-input-group"
                    : "recvessel-updated-input-group success"
                }
              >
                <label htmlFor="hullColour">
                  Hull Colour<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="hullColour"
                  className="text-upper"
                  {...register("hullColour", {
                    required: {
                      value: true,
                      message: "Hull colour is required",
                    },
                  })}
                />
                <span className="msg">{errors.hullColour?.message}</span>
              </div>
            </div>

            <div className="col-md-6">
              <div className="recvessel-updated-input-group">
                <label htmlFor="otherColour">Cabin/Other Colour</label>
                <input
                  type="text"
                  id="otherColour"
                  className="text-upper"
                  {...register("otherColour")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VesselBasicUpdated;
