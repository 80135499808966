import { useState } from "react";
import useAxiosInterceptor from "./useAxiosInterceptor";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "./useAuth";

export const usePdf = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [abort, setAbort] = useState(() => {});
  const axiosInterceptor = useAxiosInterceptor();

  const apiPdf = async (url, func = null, errFunc = null) => {
    const controller = new AbortController();
    try {
      setLoading(true);
      const response = await axiosInterceptor.get(url, {
        signal: controller.signal,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
        responseType: "arraybuffer",
      });

      const parts = response.headers["content-disposition"].split(";");
      const filename = parts[1].split("=")[1].replaceAll('"', "");

      //Create a Blob from the PDF Stream
      // console.log(response.data);
      const data = new Uint8Array(response.data);
      const file = new Blob([data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      // console.log(isPopupBlock())
      if (isPopupBlock()) {
        //Straightly download file
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.download = filename;
        fileLink.click();
        fileLink.remove();
      } else {
        //Open the URL on new Window
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.open(fileURL);
        }, 500);
      }
      if (func) {
        func();
      }
    } catch (err) {
      //if (!axios.isCancel(err)) {
      if (errFunc) {
        errFunc();
      }
      if (err.name !== "CanceledError") {
        console.error(err);
        setError(err);
      }
    } finally {
      setLoading(false);
      setAbort(controller.abort());
    }
  };

  return [error, loading, apiPdf, abort];
};

export const useGet = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [abort, setAbort] = useState(() => {});

  const { setAuth } = useAuth();
  const axiosInterceptor = useAxiosInterceptor();
  const navigate = useNavigate();
  const location = useLocation();

  const apiGet = async (url, isBackLogin = false) => {
    let isMounted = true;
    const controller = new AbortController();
    // console.log(url);

    try {
      setLoading(true);
      const response = await axiosInterceptor.get(url, {
        signal: controller.signal,
      });

      // console.log(response);
      // console.log(response.data);
      isMounted && setResponse(response.data);

      //Test displaying Status reason
      // if(Array.isArray(response.data))
      // {
      //   const newRes = response.data?.map(res => (
      //     {...res, status: 0, statusReason: "Not Yet Confirmed"}
      //   ));
      //   setResponse(newRes);
      // }else{
      //   setResponse((prev) => {
      //     return {...prev, status: 0, statusReason: "Not Yet Confirmed"};
      //   });
      // }

      //This function is used to check whether user is able to print pdf or boat register if user update their personal data (email, address etc)
      if (
        url === "/api/pna/PnaDecryptedData" &&
        (response.data?.status === 0 || response.data?.status === 3)
      ) {
        //not yet confirm or more documents support
        setAuth((prev) => {
          // console.log(JSON.stringify(prev));
          return { ...prev, isAblePrintOrRegister: false};
        });
      }
    } catch (err) {
      //if (!axios.isCancel(err)) {
      if (err.name !== "CanceledError") {
        // console.error(err);
        setError(err?.response?.data);
        if (isBackLogin) {
          setAuth({});
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
      //Unauthorized: when refresh token expired
      if(err.response?.status === 401)
      {
        setAuth({});
        navigate("/login", { state: { from: location }, replace: true });
      }
    } finally {
      isMounted = false;
      setLoading(false);
      setAbort(controller.abort());

    }
  };

  return [response, error, loading, apiGet, abort];
};

export const usePost = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [abort, setAbort] = useState(() => {});

  const { setAuth } = useAuth();
  const axiosInterceptor = useAxiosInterceptor();
  const navigate = useNavigate();
  const location = useLocation();

  const apiPost = async (
    url,
    data,
    isBackLogin = true,
    dest = null,
    func = null,
    errFunc = null
  ) => {
    let isMounted = true;
    const controller = new AbortController();
    try {
      setLoading(true);
      const response = await axiosInterceptor.post(url, data, {
        signal: controller.signal,
      });

      // console.log(response.data);
      if (response.status === 200) {
        isMounted && setResponse(response.data);
        if (func) {
          func();
        }
        if (dest) {
          navigate(dest, {
            state: { from: location },
            replace: true,
          });
        }
      } else {
        if (errFunc) {
          errFunc();
        }
        setError(response.data);
      }
    } catch (err) {
      //if (!axios.isCancel(err)) {
      if (errFunc) {
        errFunc();
      }
      if (err.name !== "CanceledError") {
         //console.error(err);
        setError(err?.response?.data);
        if (isBackLogin) {
          setAuth({});
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
      //Unauthorized: when refresh token expired
      if(err.response?.status === 401)
      {
        setAuth({});
        navigate("/login", { state: { from: location }, replace: true });
      }
    } finally {
      isMounted = false;
      setLoading(false);
      setAbort(controller.abort());
    }
  };

  return [response, error, loading, apiPost, abort];
};

export const useMultipartFormPost = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [abort, setAbort] = useState(() => {});

  const { setAuth } = useAuth();
  const axiosInterceptor = useAxiosInterceptor();
  const navigate = useNavigate();
  const location = useLocation();

  const apiMultipartFormPost = async (
    url,
    data,
    isBackLogin = true,
    dest = null,
    func = null,
    errFunc = null
  ) => {
    let isMounted = true;
    const controller = new AbortController();
    try {
      setLoading(true);
      const response = await axiosInterceptor.post(url, data, {
        signal: controller.signal,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
        // responseType: "arraybuffer",
      });

      // console.log(response.data);
      if (response.status === 200) {
        isMounted && setResponse(response.data);
        if (func) {
          func();
        }
        if (dest) {
          navigate(dest, {
            state: { from: location },
            replace: true,
          });
        }
      } else {
        if (errFunc) {
          errFunc();
        }
        setError(response.data);
      }
    } catch (err) {
      //if (!axios.isCancel(err)) {
      if (errFunc) {
        errFunc();
      }
      if (err.name !== "CanceledError") {
        // console.error(err);
        setError(err?.response?.data);
        if (isBackLogin) {
          setAuth({});
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
      //Unauthorized: when refresh token expired
      if(err.response?.status === 401)
      {
        setAuth({});
        navigate("/login", { state: { from: location }, replace: true });
      }
    } finally {
      isMounted = false;
      setLoading(false);
      setAbort(controller.abort());
    }
  };

  return [response, error, loading, apiMultipartFormPost, abort];
};

const isPopupBlock = () => {
  try {
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
      width=10,height=10,left=0,top=0`;
    var newWindow = window.open(null, "", params);
    newWindow.close();
    return false;
  } catch (error) {
    // console.log(error);
    return true;
  }
};
