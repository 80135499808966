import { useForm } from "react-hook-form";
import { usePost } from "../../Hooks/useFetch";
import { useSearchParams } from "react-router-dom";
import SubmitLoader from "../../Components/SubmitLoader";
import AlertMessage from "../../Components/AlertMessage";
import useGaEvent from "../../Hooks/useGaEvent";
import NotUnsafeFields from "../../Components/NotUnsafe/NotUnsafeFields";

const TransferNotUnsafeForm = () => {
  const gaEvents = useGaEvent(); //TODO: Apply Boat Regi not unsafe form => need to confirm

  const [queryParams] = new useSearchParams();
  const regoNo = queryParams.get("rn");
  const actionName = queryParams.get("ac"); //action: seller or buyer
  const firstName = queryParams.get("fn"); //first name
  const surName = queryParams.get("sn"); //surname
  const vesselName = queryParams.get("vn"); //vessel name
  const vesselMake = queryParams.get("vm"); //vessel make
  const expiry = queryParams.get("ex"); //expiry 
  const email = queryParams.get("em"); //email 
  const mobile = queryParams.get("mb"); //mobile 

  const [res, err, isPostLoading, apiPost, abt] = usePost();

  let urlParams = {
    regoNo: regoNo,
    action : actionName,
    vesselName : vesselName,
    firstName : firstName,
    surName : surName,
    vesselMake : vesselMake,
    expiry : expiry,
    email : email,
    mobile : mobile,
  }

  let initialValues = {
    pcRegNo: null,
    firstName: null,
    surName: null,
    vesName: null,
    vesMake: null,
    mobile: null,
    email: null,
    declareCheckedSafe: null,
    declareCheckedUnsafe: null,
  };
  if (process.env.REACT_APP_TEST_DEFAULT_VALUE === "on") {
    initialValues = {
      pcRegNo: null,
      firstName: null,
      surName: null,
      vesName: null,
      vesMake: null,
      mobile: null,
      email: null,
      declareCheckedSafe: null,
      declareCheckedUnsafe: null,
    };
  }

  const {
    control,
    register,
    formState: { errors, isDirty, isValid, dirtyFields },
    handleSubmit,
    watch,
    getFieldState,
    setValue,
    setError,
    clearErrors,
  } = useForm({ defaultValues: initialValues, mode: "onChange" });

  const onSubmit = async (data, e) => {
    e.preventDefault();

    // console.log(data);

    await apiPost(
      "/api/transfer/notunsafe",
      {
        PcRegNo: regoNo == null || regoNo == "" ? data.pcRegNo : regoNo,
        FirstName: firstName == null || firstName == "" ? data.firstName : firstName,
        SurName: surName == null || surName == "" ? data.surName : surName,
        VesselName: vesselName == null || vesselName == "" ? data.vesName : vesselName,
        VesselMake: vesselMake == null || vesselMake == "" ? data.vesMake : vesselMake,
        Expiry: expiry == null || expiry == "" ?  data.expiry?.$d?.getDate()+"/"+(data.expiry?.$d?.getMonth() + 1)+"/"+ data.expiry?.$d?.getFullYear(): expiry,
        Mobile: mobile == null || mobile == "" ? data.mobile : mobile,
        Email: email == null || email == "" ? data.email : email,
        NotUnsafeCheckSafe: data.declareCheckedSafe,
        NotUnsafeCheckUnsafe: data.declareCheckedUnsafe,
      },
      false,
      "/notunsafe/tsf/success"
    );
  };

  return !isPostLoading ? (
    <div className="br-container">
      <div className="br-form-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <AlertMessage message={err} />
          <NotUnsafeFields
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            clearErrors={clearErrors}
            params={urlParams}
          />
          <div className="br-safe-btn-group">
            <button type="submit" className="br-button">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  ) : (
    <SubmitLoader isLoading={isPostLoading} />
  );
};

export default TransferNotUnsafeForm;
