import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import DataLoader from "../DataLoader";
import AlertMessage from "../AlertMessage";

const LicencePWCendorsementDetails = () => {
  let { id } = useParams();

  const [res, err, isLoading, apiGet, abt] = useGet();

  useEffect(() => {
    apiGet("/api/licence/DlPwcEndorsement/" + id);
  }, []);

  // console.log(res);

  return (
    <div className="licence-sub-container">
      <div className="licence-title-group">
        <h3 className="licence-title">PWC endorsement</h3>
      </div>

      {!isLoading ? (
        !err ? (
          <>
            <div className="row">
              <div className="col-md-4">
                <div className="licence-input-group">
                  <label htmlFor="PwcId">PWC ID</label>
                  <div className="licenceInfoField">{id}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="licence-input-group">
                  <label htmlFor="OwnerCode">Owner Code</label>
                  <div className="licenceInfoField">{res?.dlOwnerCode}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="licence-input-group">
                  <label htmlFor="PwcCurrent">PWC Current Valid</label>
                  <div className="licenceInfoField">{res?.dlPwcCurrent}</div>
                </div>
              </div>
            </div>
            <div className="row">
              
              <div className="col-md-4">
                <div className="licence-input-group">
                  <label htmlFor="PwcStatusDate">PWC Status Date</label>
                  <div className="licenceInfoField">{res?.dlPwcStatusDate?.split("T")[0]}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="licence-input-group">
                  <label htmlFor="PwcChangedDate">PWC Changed Date</label>
                  <div className="licenceInfoField">{res?.dlDateChanged?.split("T")[0]}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="licence-input-group">
                  <label htmlFor="CourseOrg">Course Organization</label>
                  <div className="licenceInfoField">{res?.dlPwcCourseOrg}</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="licence-input-group">
                  <label htmlFor="CourseDate">Course Date</label>
                  <div className="licenceInfoField">{res?.dlPwcCourseDate?.split("T")[0]}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="licence-input-group">
                  <label htmlFor="CourseInstr">
                    Course Instruction/Instructor
                  </label>
                  <div className="licenceInfoField">{res?.dlPwcCourseInstr}</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="licence-input-group">
                  <label htmlFor="UserChanged">
                    Licence User changed
                  </label>
                  <div className="licenceInfoField">{res?.dlUserChanged}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="licence-input-group">
                  <label htmlFor="Comment">Comment</label>
                  <div className="licenceInfoField">{res?.dlPwcComment}</div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <AlertMessage message={err}/>
        )
      ) : (
        <DataLoader isLoading={isLoading} />
      )}
    </div>
  );
};

export default LicencePWCendorsementDetails;
