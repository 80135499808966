import { Outlet, useLocation } from "react-router-dom";
import '../Components/RecVessel_PWC/RecVessel.css';
import RecVesselList from "../Components/RecVessel_PWC/RecVesselList";

const RecVessel = () => {
 
    const location = useLocation();
    const path = '/recvessel';
  
    return (
        <div className="recvessel-container">
          {location.pathname === path ? (
            <>
              <div className="row">
                <div className="col-md-12">
                  <RecVesselList />
                </div>
              </div>
            </>
          ) : (
            <Outlet />
          )}
        </div>
    );
  };
  
  export default RecVessel;