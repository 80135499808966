import { useEffect, useState } from "react";
import CreditCardPattern from "./CreditCardPattern";
import AlertMessage from "../../AlertMessage";


const PayWay = ({
  errPayWayFrame,
  errPayWayToken,
  tokenId,
  creditCard,
  createFrame,
  stateDestroy,
  setValue
}) => {

  const [payError, setPayError] = useState(null);
  useEffect(() => {
    try {
      if(process.env.NODE_ENV === "development")
      {
        return () => {
          createFrame();
        }
      }else{
        createFrame();
      }
      
    } catch (error) {
      setPayError('A problem occurred while connecting to payment gateway.');
    }
      
  }, []);

  useEffect(() => {
    setValue("payId", tokenId);
  },[tokenId])

  const changeCard = () => {
    stateDestroy();
  };

  // console.log('tokenId: ',tokenId);
  // console.log('creditCard:',creditCard);
  // console.log('errPayWayToken: ', errPayWayToken);
  // console.log('display: ', tokenId && creditCard && !errPayWayToken ? "hide" : "show");


  return (
    <>
      <div className="row br-box">
        <div className="payway-group">
          <div
            id="payway-credit-card"
            className={
              tokenId && creditCard && !errPayWayToken ? "hide" : "show"
            }
          ></div>
          {tokenId && creditCard && !errPayWayToken ? (
            <>
              <button type="button" onClick={changeCard} className="br-button">
                Change
              </button>
              <CreditCardPattern creditCard={creditCard} />
            </>
          ) : (
            ""
          )}
        </div>
        <AlertMessage message={errPayWayFrame} />  
        <AlertMessage message={errPayWayToken} />  
        <AlertMessage message={payError} />  
      </div>
    </>
  );
};

export default PayWay;
