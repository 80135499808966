import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import { getddmmyyyyFormat } from "../../Helpers/Helper";
import DataLoader from "../DataLoader";
import AlertMessage from "../AlertMessage";
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

const LicencePWCendorsementList = () => {
  const [res, err, isLoading, apiGet, abt] = useGet();

  useEffect(() => {
    apiGet("/api/licence/DlPwcEndorsementList");
  }, []);

  // console.log(res);

  return (
    <div className="licence-sub-container">
      <div className="licence-title-group">
        <h3 className="licence-title">PWC endorsements</h3>
      </div>

      {!isLoading ? (
        !err ? (
          res?.length === 0 ? (
            <AlertMessage
                message="No Endorsements"
                level="info"
                closeEvt="false"
                mt="0"
                mb="0"
              />
          ) : (
            res?.map((p) => (
              <>
                <div className="row" key={uuidv4()}>
                  <div className="col-md-3">
                    <div className="licence-input-group">
                      <label htmlFor="PwcCourseDate">Course Date</label>
                      <div className="licenceInfoField">
                        {getddmmyyyyFormat(p?.dlPwcCourseDate?.split("T")[0])}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="licence-input-group">
                      <label htmlFor="PwcCourseOrg">Org</label>
                      <div className="licenceInfoField">
                        {p?.dlPwcCourseOrg}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="licence-input-group">
                      <label htmlFor="PwcCourseInstr">Instructor</label>
                      <div className="licenceInfoField">
                        {p?.dlPwcCourseInstr}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="licence-input-group">
                      <label htmlFor="PwcCurrent">Current</label>
                      <div className="licenceInfoField">{p?.dlPwcCurrent}</div>
                    </div>
                  </div>
                </div>
              </>
            ))
          )
        ) : (
          <AlertMessage message={err} />
        )
      ) : (
        <DataLoader isLoading={isLoading} />
      )}
    </div>
  );
};

export default LicencePWCendorsementList;
