import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import DataLoader from "../DataLoader";
import AlertMessage from "../AlertMessage";
import { RegisterOperatorIcon, PersonContactIcon, CompanyContactIcon } from "../Icons";
import { v4 as uuidv4 } from 'uuid';

const RecVesselContacts = ({ regoNo }) => {
  const [res, err, isLoading, apiGet, abt] = useGet();

  useEffect(() => {
    apiGet("/api/pcraft/PcraftContact/" + regoNo);
  }, []);

  // console.log(res);

  return (
    <div className="recvessel-sub-container">
      <div className="recvessel-title-group">
        <h3 className="recvessel-title">Reg Operators/Org Contact</h3>
      </div>

      {!isLoading ? (
        !err ? (
           res?.length === 0 ? (
            <AlertMessage
              message="No Records"
              level="info"
              closeEvt="false"
              mt="0"
              mb="0"
            />
          ) : (
            res?.map((o) => (
                <>
                  <div className="row" key={uuidv4()}>
                    <div className="col-xs-7">
                      <div className="recvessel-input-group">
                        <label htmlFor="contact">
                          <span>{o?.isRegOperator ? "Reg Operator" : (o?.hasOrgRecord? "Org Contact":"Sec Operator")} {o?.isRegOperator && <RegisterOperatorIcon/>} {o?.typeCode == "I" ? <PersonContactIcon/> : <CompanyContactIcon/> }</span>
                        </label>
                        <div className="recvesselInfoField">
                          {o?.fullName}
                        </div>
                      </div>
                    </div>
  
                    <div className="col-xs-5">
                      <div className="recvessel-input-group">
                        <label htmlFor="Type">Contact</label>
                        <div className="recvesselInfoField">
                          {o?.contact}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))
          )
        ) : (
          <AlertMessage message={err} />
        )
      ) : (
        <DataLoader isLoading={isLoading} />
      )}
    </div>
  );
};

export default RecVesselContacts;
