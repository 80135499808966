import { useGet } from "../../Hooks/useFetch";
import { useEffect, useState } from "react";
import MooringPositionUpdatedForm from "./MooringPositionUpdatedForm";
import { formatDDM } from "../../Helpers/Helper";
import DataLoader from "../DataLoader";
import { EditClickEventIcon } from "../Icons";
import Tooltip from "@mui/material/Tooltip";
import AlertMessage from "../AlertMessage";
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";

const MooringPositionList = ({ moRegoNo }) => {
  const [res, err, isLoading, apiGet, abt] = useGet();
  const [editMode, setEditMode] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectMoPos, setSelectMoPos] = useState();

  useEffect(() => {
    apiGet("/api/mooring/MoPositions/" + moRegoNo);
  }, [reload]);

  //   console.log(res);
  //   console.log(editMode);

  const modeChange = (pickMoPos) => {
    setSelectMoPos(pickMoPos);
    setEditMode(true);
    setReload(false);
  };

  return (
    <div className="mooring-sub-container">
      <div className="mooring-title-group">
        <h3 className="mooring-title">Mooring Positions</h3>
      </div>

      {!editMode &&
        (!isLoading ? (
          !err ? (
            res?.length === 0 ? (
              <AlertMessage
                message="No Records"
                level="info"
                closeEvt="false"
                mt="0"
                mb="0"
              />
            ) : (
              <>
                {res?.map((p) => (
                  <>
                    {p?.status == 0 || p?.status == 2 || p?.status == 3 ? (
                      <div className="statusReason-format text-danger">
                        ({p?.statusReason})
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="row" key={uuidv4()}>
                      <div className="col-md-2">
                        <div className="mooring-input-group">
                          {p?.status == 0 ||
                          p?.status == 2 ||
                          p?.status == 3 ? (
                            <Tooltip
                              title={p?.statusReason}
                              placement="top-start"
                            >
                              <label htmlFor="type" className="tooltip">
                                Type
                              </label>
                            </Tooltip>
                          ) : (
                            <label htmlFor="type">Type</label>
                          )}
                          <div className="mooringInfoField">
                            {p?.moMoorType}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="mooring-input-group">
                          <label htmlFor="desc">Desc</label>
                          <div className="mooringInfoField">{p?.moDescr}</div>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="mooring-input-group">
                          <label htmlFor="latddm">Lat DDM</label>
                          <div className="mooringInfoField">
                            {formatDDM(p?.moDecLatitude)}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="mooring-input-group">
                          <label htmlFor="longddm">Long DDM</label>
                          <div className="mooringInfoField">
                            {formatDDM(p?.moDecLongitude)}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="mooring-input-group">
                          <label htmlFor="colour">Colour</label>
                          <div className="mooringInfoField">
                            {p?.moBouyColour}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1">
                        <div className="mooring-input-group">
                          <label htmlFor="action">Action</label>
                          <div className="icon-group">
                            <EditClickEventIcon
                              clickEvent={() => modeChange(p)}
                              HoverCaption="Edit Mooring Position"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
                {/* <span className="red-text-explain"><span className="text-danger"><b>* Red Type:</b></span> Not confirmed / reject / more docs required from MAST.</span> */}
              </>
            )
          ) : (
            <AlertMessage message={err} />
          )
        ) : (
          <DataLoader isLoading={isLoading} />
        ))}

      {editMode && (
        <MooringPositionUpdatedForm
          moPosition={selectMoPos}
          setEditMode={setEditMode}
          setReload={setReload}
        />
      )}
    </div>
  );
};

export default MooringPositionList;
