const RadioDetailUpdated = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
}) => {
  return (
    <>
      <div className="">
        {/* <div className="recvessel-updated-subtitle">Radio Details</div> */}
        <div className="recvessel-updated-field-group">
          <div className="row">
            <div className="col-md-5">
              <div className="recvessel-updated-input-group">
                <label>Radios</label>
                <div className="recvessel-updated-field-checkbox-group">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="recvessel-updated-checkbox-group">
                        <input
                          type="checkbox"
                          id="VHF"
                          {...register("sortRadio")}
                          value="VHF"
                        />
                        <label htmlFor="VHF" className="unSelectable">VHF</label>
                      </div>
                      <div className="recvessel-updated-checkbox-group">
                        <input
                          type="checkbox"
                          id="HF"
                          {...register("sortRadio")}
                          value="HF"
                        />
                        <label htmlFor="HF" className="unSelectable">HF</label>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="recvessel-updated-checkbox-group">
                        <input
                          type="checkbox"
                          id="27MHz"
                          {...register("sortRadio")}
                          value="27MHz"
                        />
                        <label htmlFor="27MHz" className="unSelectable">27MHz</label>
                      </div>
                      <div className="recvessel-updated-checkbox-group">
                        <input
                          type="checkbox"
                          id="SatComm"
                          {...register("sortRadio")}
                          value="SatComm"
                        />
                        <label htmlFor="SatComm" className="unSelectable">Sat Comm</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="recvessel-updated-input-group">
                <label htmlFor="vesselSign">
                  Call sign or MMSI
                </label>
                <input
                  type="text"
                  id="vesselSign"
                  {...register("vesselSign")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RadioDetailUpdated;
