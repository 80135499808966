import './Footer.css'

const Footer = () => {

  return (
    <footer>
        <div className='footer-gp'>
            <div>
            Copyright &copy; {new Date().getFullYear()}. MAST - Marine & Safety Tasmania - {process.env.REACT_APP_ENV === 'Staging' ? process.env.REACT_APP_ENV : process.env.NODE_ENV}
            </div>
            <div className='icon-author'><a href="http://www.onlinewebfonts.com" target='_blank'>oNline Web Fonts</a></div>
        </div>
    </footer>
  );
};

export default Footer;
