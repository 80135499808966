import { getddmmyyyyFormat } from "../../Helpers/Helper";

const SellerDetailReadOnly = ({ PrimaryTitle, client}) => {

  return (
    <>
      <div className="br-box">
        <div className="br-title">{PrimaryTitle}</div>
        {client?.sellerIsOrganisation === true ? (
          <div className="br-field-group">
            <div className="br-input-group">
              <label htmlFor="orgName">Organisation Name</label>
              <div className="br-field-readonly unSelectable">
                {client?.sellerOrganisationName}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="orgType">ABN/ACN/Inc NO</label>
              <div className="br-field-readonly unSelectable">
                {client?.sellerAcnAbnInc}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="orgTypeNo">ABN/ACN/Inc NO Number</label>
              <div className="br-field-readonly unSelectable">
                {client?.sellerAcnAbnIncNumber}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="residentialAddress">Residential</label>
              <div className="br-field-readonly unSelectable">
                {client?.sellerAdr1 +
                  " " +
                  client?.sellerAdr2 +
                  " " +
                  client?.sellerAdr3 +
                  " " +
                  client?.sellerPostcode}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="clientEmail">Email</label>
              <div className="br-field-readonly unSelectable">
                {client?.sellerEmail}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="clientMobile">Mobile / Contact Phone</label>
              <div className="br-field-readonly unSelectable">
                {client?.sellerMobile}
              </div>
            </div>
          </div>
        ) : (
          <div className="br-field-group">
            <div className="br-input-group">
              <label htmlFor="clientFullName">Full Names</label>
              <div className="br-field-readonly unSelectable">
                {client?.sellerGivenName + ", " + client?.sellerSurname}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="clientDOB">Date of Birth</label>
              <div className="br-field-readonly unSelectable">
                {getddmmyyyyFormat(client?.sellerDob?.split("T")[0])}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="residentialAddress">Residential</label>
              <div className="br-field-readonly unSelectable">
                {client?.sellerAdr1 +
                  " " +
                  client?.sellerAdr2 +
                  " " +
                  client?.sellerAdr3 +
                  " " +
                  client?.sellerPostcode}
              </div>
            </div>

            <div className="br-input-group">
              <label htmlFor="clientEmail">Email</label>
              <div className="br-field-readonly unSelectable">
                {client?.sellerEmail}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="clientMobile">Mobile / Contact Phone</label>
              <div className="br-field-readonly unSelectable">
                {client?.sellerMobile}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* {client?.secondGivenName && (
          <div className="br-box">
            <div className="br-title">{client?.sellerIsOrganisation === true ? "Secondary Contact":"Secondary Operator"}</div>
            <div className="br-field-group">
            <div className="br-input-group">
              <label htmlFor="secondFullName">Full Names</label>
              <div className="br-field-readonly unSelectable">
                {client?.secondGivenName + ", " + client?.secondSurname}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="secondDOB">Date of Birth</label>
              <div className="br-field-readonly unSelectable">
                {getddmmyyyyFormat(client?.secondDob?.split("T")[0])}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="secondResAddress">Residential</label>
              <div className="br-field-readonly unSelectable">
                {client?.secondAdr1 +
                  " " +
                  client?.secondAdr2 +
                  " " +
                  client?.secondAdr3 +
                  " " +
                  client?.secondPostcode}
              </div>
            </div>

            <div className="br-input-group">
              <label htmlFor="secondEmail">Email</label>
              <div className="br-field-readonly unSelectable">
                {client?.secondEmail}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="secondMobile">Mobile / Contact Phone</label>
              <div className="br-field-readonly unSelectable">
                {client?.secondMobile}
              </div>
            </div>
          </div>
          </div>
        )} */}
    </>
  );
};

export default SellerDetailReadOnly;
