import { usePost, useGet } from "../../Hooks/useFetch";
import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import "./PnaUpdated.css";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { splitFullAddress, getTelPhoneRegex } from "../../Helpers/Helper";
import { useNavigate, useLocation } from "react-router-dom";
import AddressFilter from "../AddressFilter";
import { useKleberVerify } from "../../Hooks/useKleber";
import SubmitLoader from "../SubmitLoader";
import useGaEvent from "../../Hooks/useGaEvent";
import useToast from "../../Hooks/useToast";
import AlertMessage from "../AlertMessage";

const PnaUpdatedForm = ({ pnaDetails }) => {
  const [res, err, isPostLoading, apiPost, abt] = usePost();
  const gaEvents = useGaEvent();
  const toastEmitter = useToast();
  const [apiVerifyMobile, apiVerifyEmail, isKleberLoading] = useKleberVerify();
  const [emailTyping, setEmailTyping] = useState("");
  const [mobileTyping, setMobileTyping] = useState("");
  const [showEmailBtn, setShowEmailBtn] = useState(false);
  const [showMobileBtn, setShowMobileBtn] = useState(false);

  const [verifyCode, setVerifyCode] = useState("");
  const [errMsg, setErrMsg] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [mobileChangeVerified, setMobileChangeVerified] = useState(null);
  const [emailChangeVerified, setEmailChangeVerified] = useState(null);
  const [currVerified, setCurrVerified] = useState(null);
  const [currMobile, setCurrMobile] = useState(pnaDetails.pnaMobile);
  const [currEmail, setCurrEmail] = useState(pnaDetails.pnaEmail);
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(pnaDetails);

  // console.log('show mobile btn', showMobileBtn);
  // console.log('show email btn', showEmailBtn);
  // console.log('mobileChangeVerified', mobileChangeVerified);
  // console.log('emailChangeVerified', emailChangeVerified);

  const initialValues = {
    streetAddress:
      pnaDetails.pnaAdr1 +
      ", " +
      pnaDetails.pnaAdr2 +
      ", " +
      pnaDetails.pnaAdr3 +
      ", " +
      pnaDetails.pnaPostcode,
    poStreetAddress:
      pnaDetails.pnaAdr1Po === null ||
      pnaDetails.pnaAdr1Po === "" ||
      pnaDetails.pnaAdr2Po === null ||
      pnaDetails.pnaAdr2Po === "" ||
      pnaDetails.pnaAdr3Po === null ||
      pnaDetails.pnaAdr3Po === "" ||
      pnaDetails.pnaPostcodePo === null ||
      pnaDetails.pnaPostcodePo === ""
        ? null
        : pnaDetails.pnaAdr1Po +
          ", " +
          pnaDetails.pnaAdr2Po +
          ", " +
          pnaDetails.pnaAdr3Po +
          ", " +
          pnaDetails.pnaPostcodePo,
    telHome: pnaDetails.pnaTnh,
    telWork: pnaDetails.pnaTnw,
    height: pnaDetails.pnaHeight,
    eyeColor: pnaDetails.pnaEyeColour,
    hairColor: pnaDetails.pnaHairColour,
    birthPlace: pnaDetails.pnaBirthPlace,
    fax: pnaDetails.pnaFax,
    mobile: pnaDetails.pnaMobile,
    email: pnaDetails.pnaEmail,
  };

  const {
    control,
    register,
    formState: { errors, isDirty, isValid, dirtyFields },
    handleSubmit,
    watch,
    getFieldState,
    setValue,
    setError,
    clearErrors,
  } = useForm({ defaultValues: initialValues, mode: "onChange" });

  const provideEmailError = (msg) => {
    setError("email", { type: "externalValid", message: msg });
  };

  const provideMobileError = (msg) => {
    setError("mobile", { type: "externalValid", message: msg });
  };

  useEffect(() => {
    setEmailChangeVerified(true);
    setMobileChangeVerified(true);
  }, []);

  // useEffect(() => {
  //     if (watch("mobile") !== currMobile) setMobileChangeVerified(false);
  //     else if (watch("mobile") === currMobile) setMobileChangeVerified(true);
  // }, [watch("mobile")]);

  // useEffect(() => {
  //   if (watch("email") !== currEmail) setEmailChangeVerified(false);
  //   else if (watch("email") === currEmail) setEmailChangeVerified(true);
  // }, [watch("email")]);

  useEffect(() => {
    if (emailTyping === "") return;
    const delayEmailDebounceFn = setTimeout(() => {
      // console.log(emailTyping);
      apiVerifyEmail(
        emailTyping,
        currEmail,
        provideEmailError,
        setEmailChangeVerified,
        setShowEmailBtn
      );
    }, 800);
    return () => clearTimeout(delayEmailDebounceFn);
  }, [emailTyping]);

  useEffect(() => {
    if (mobileTyping === "") return;
    const delayMobileDebounceFn = setTimeout(() => {
      // console.log(mobileTyping);
      apiVerifyMobile(
        mobileTyping,
        currMobile,
        provideMobileError,
        setMobileChangeVerified,
        setShowMobileBtn
      );
    }, 800);
    return () => clearTimeout(delayMobileDebounceFn);
  }, [mobileTyping]);

  const updateSuccess = () => {
    gaEvents.pnaDetailUpdateSuccess();
    toastEmitter("Person details is successful updated!", "success");
  };

  //console.log(Object.keys(errors).length === 0);
  // console.log(errors);
  const onSubmit = async (data, e) => {
    e.preventDefault();

    // console.log(data);
    if (!mobileChangeVerified) {
      setError(
        "mobile",
        {
          type: "custom",
          message: "New mobile has to be verified",
        },
        { shouldFocus: true }
      );
      return;
    }
    if (!emailChangeVerified) {
      setError(
        "email",
        {
          type: "custom",
          message: "New email has to be verified",
        },
        { shouldFocus: true }
      );
      return;
    }
    // console.log(data);
    let ads = splitFullAddress(data.streetAddress);
    let adsPo = splitFullAddress(data.poStreetAddress);
    // console.log(ads);
    await apiPost(
      "/api/pna/updatePnaDetails",
      {
        Mobile: data.mobile,
        Email: data.email,
        PnaStreet: ads[0].trim(),
        PnaSuburb: ads[1].trim(),
        PnaState: ads[2].trim(),
        PnaPostcode: ads[3].trim(),
        PnaStreetPo: adsPo[0].trim(),
        PnaSuburbPo: adsPo[1].trim(),
        PnaStatePo: adsPo[2].trim(),
        PnaPostcodePo: adsPo[3].trim(),
        PnaTnh: data.telHome,
        PnaTnw: data.telWork,
        PnaHeight: data.height,
        PnaEyeColour: data.eyeColor,
        PnaHairColour: data.hairColor,
        PnaBirthPlace: data.birthPlace,
        PnaFax: data.fax,
      },
      false,
      "/",
      updateSuccess,
      gaEvents.pnaDetailUpdateFail
    );
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    // console.log(res);
    if (currVerified === "mobile") {
      if (err) {
        setErrMsg(err);
      } else if (verifyCode === res.code) {
        setMobileChangeVerified(true);
        setErrMsg(null);
        setIsOpen(false);
        setCurrVerified(null);
        setCurrMobile(watch("mobile"));
        clearErrors("mobile");
      } else {
        setErrMsg("Incorrect code");
      }
    } else if (currVerified === "email") {
      if (err) {
        setErrMsg(err);
      } else if (verifyCode === res.code) {
        setEmailChangeVerified(true);
        setErrMsg(null);
        setIsOpen(false);
        setCurrVerified(null);
        setCurrEmail(watch("email"));
        clearErrors("email");
      } else {
        setErrMsg("Incorrect code");
      }
    } else {
      setCurrVerified(null);
    }
  };

  // console.log(getFieldState("telWork"));

  return (
    <>
      {!isPostLoading ? (
        <div className="pna-updated-form-container">
          <div className="pna-details-title-group">
            <h3 className="pna-details-title">Person Details</h3>
          </div>
          <AlertMessage message={err} />
          <form onSubmit={handleSubmit(onSubmit)} className="pna-updated-form">
            <div className="row">
              <div className="col-md-12">
                <div
                  className={
                    errors.streetAddress?.message
                      ? "pna-updated-form-input-group error"
                      : !getFieldState("streetAddress").isDirty
                      ? "pna-updated-form-input-group"
                      : "pna-updated-form-input-group success"
                  }
                >
                  <label htmlFor="streetAddress">
                    Residential Address
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    control={control}
                    name="streetAddress"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <AddressFilter
                          onChange={(val) => onChange(val?.value)}
                          defaultValue={value}
                        />
                      );
                    }}
                    rules={{ required: "Residential is required" }}
                    errors={errors}
                  />
                  <span className="msg">{errors.streetAddress?.message}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div
                  className={
                    errors.poStreetAddress?.message
                      ? "pna-updated-form-input-group error"
                      : !getFieldState("poStreetAddress").isDirty
                      ? "pna-updated-form-input-group"
                      : "pna-updated-form-input-group success"
                  }
                >
                  <label htmlFor="poStreetAddress">
                    Postal Address<span className="text-danger">*</span>
                  </label>
                  <Controller
                    control={control}
                    name="poStreetAddress"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <AddressFilter
                          onChange={(val) => onChange(val?.value)}
                          defaultValue={value}
                        />
                      );
                    }}
                    rules={{ required: "Postal is required" }}
                    errors={errors}
                  />
                  <span className="msg">{errors.poStreetAddress?.message}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 ">
                <div
                  className={
                    errors.telHome?.message
                      ? "pna-updated-form-input-group error"
                      : !getFieldState("telHome").isDirty
                      ? "pna-updated-form-input-group"
                      : "pna-updated-form-input-group success"
                  }
                >
                  <label htmlFor="telHome">Telephone Home</label>
                  <input
                    type="tel"
                    id="telHome"
                    {...register("telHome", {
                      pattern: {
                        value: getTelPhoneRegex(),
                        message: "Telephone format error",
                      },
                    })}
                    placeholder="0(2378) XXXX XXXX"
                  />
                  <span className="msg">{errors.telHome?.message}</span>
                </div>
              </div>
              <div className="col-md-6 ">
                <div
                  className={
                    errors.telWork?.message
                      ? "pna-updated-form-input-group error"
                      : !getFieldState("telWork").isDirty
                      ? "pna-updated-form-input-group"
                      : "pna-updated-form-input-group success"
                  }
                >
                  <label htmlFor="telWork">Telephone Work</label>
                  <input
                    type="tel"
                    id="telWork"
                    {...register("telWork", {
                      pattern: {
                        value: getTelPhoneRegex(),
                        message: "Telephone format error",
                      },
                    })}
                    placeholder="0(2378) XXXX XXXX"
                  />
                  <span className="msg">{errors.telWork?.message}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div
                  className={
                    errors.mobile?.message
                      ? "pna-updated-form-input-group error"
                      : !getFieldState("mobile").isDirty
                      ? "pna-updated-form-input-group"
                      : "pna-updated-form-input-group success"
                  }
                >
                  <label htmlFor="mobile">
                    Mobile
                    {watch("email") ? (
                      ""
                    ) : (
                      <span className="text-danger">*</span>
                    )}
                    {mobileChangeVerified === true ? (
                      watch("mobile") && <FontAwesomeIcon icon={faCheck} className="valid" />
                    ) : (
                      showMobileBtn && (
                        <button
                          type="button"
                          className="pna-updated-form-verify"
                          onClick={() => {
                            if (
                              !errors.mobile ||
                              (errors.mobile &&
                                errors.mobile?.type === "custom")
                            ) {
                              setIsOpen(true);
                              setVerifyCode(null);
                              setErrMsg(null);
                              setMobileChangeVerified(false);
                              setCurrVerified("mobile");
                              apiPost("/api/pna/reqCode", {
                                Method: "Mobile",
                                NewMobileOrEmail: watch("mobile"),
                              });
                            }
                          }}
                        >
                          Send verification
                        </button>
                      )
                    )}
                  </label>
                  <input
                    type="tel"
                    id="mobile"
                    {...register("mobile", {
                      required: {
                        value: watch("email") ? false : true,
                        message: "Email or Mobile is required",
                      },
                      onChange: (e) => {
                        setMobileTyping(e.target.value);
                      },
                    })}
                    placeholder="04XX XXX XXX"
                  />
                  <span className="msg">{errors.mobile?.message}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div
                  className={
                    errors.email?.message
                      ? "pna-updated-form-input-group error"
                      : !getFieldState("email").isDirty
                      ? "pna-updated-form-input-group"
                      : "pna-updated-form-input-group success"
                  }
                >
                  <label htmlFor="email">
                    Email
                    {watch("mobile") ? (
                      ""
                    ) : (
                      <span className="text-danger">*</span>
                    )}
                    {emailChangeVerified === true ? (
                      watch("email") && <FontAwesomeIcon icon={faCheck} className="valid" />
                    ) : (
                      showEmailBtn && (
                        <button
                          type="button"
                          className="pna-updated-form-verify"
                          onClick={() => {
                            if (
                              !errors.email ||
                              (errors.email && errors.email?.type === "custom")
                            ) {
                              setIsOpen(true);
                              setVerifyCode(null);
                              setErrMsg(null);
                              setEmailChangeVerified(false);
                              setCurrVerified("email");
                              apiPost("/api/pna/reqCode", {
                                Method: "Email",
                                NewMobileOrEmail: watch("email"),
                              });
                            }
                          }}
                        >
                          Send verification
                        </button>
                      )
                    )}
                  </label>
                  <input
                    type="email"
                    id="email"
                    {...register("email", {
                      required: {
                        value: watch("mobile") ? false : true,
                        message: "Email or Mobile is required",
                      },
                      pattern: {
                        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                        message: "Email format error",
                      },
                      onChange: (e) => {
                        setEmailTyping(e.target.value);
                      },
                    })}
                  />
                  <span className="msg">{errors.email?.message}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <button
                  type="button"
                  className="cancel-btn"
                  onClick={() =>
                    navigate("/", { state: { from: location }, replace: true })
                  }
                >
                  Cancel
                </button>
              </div>
              <div className="col-md-6" />
              <div className="col-md-3">
                <button
                  type="submit"
                  className="pna-updated-form-button"
                  disabled={Object.keys(errors).length > 0 || isKleberLoading}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <SubmitLoader isLoading={isPostLoading} />
      )}

      {isOpen && (
        <form onSubmit={handleModalSubmit}>
          <Modal setOpenModal={setIsOpen} title={"Verification"}>
            <div className="row pna-updated-modal-form">
              <div className="col-md-12">
                <div
                  className={
                    errMsg
                      ? "pna-updated-form-input-group error"
                      : "pna-updated-form-input-group "
                  }
                >
                  <label htmlFor="code">Code</label>
                  <input
                    type="number"
                    id="code"
                    onChange={(e) => {
                      setVerifyCode(e.target.value);
                    }}
                    value={verifyCode}
                    placeholder="Enter 6 digit numbers"
                  />

                  <span className="msg">{errMsg}</span>
                </div>
              </div>
            </div>
          </Modal>
        </form>
      )}
    </>
  );
};

export default PnaUpdatedForm;
