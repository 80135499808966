import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import { getddmmyyyyFormat } from "../../Helpers/Helper";
import BackRedirect from "../BackRedirect";
import DataLoader from "../DataLoader";
import useGaEvent from "../../Hooks/useGaEvent";
import AlertMessage from "../AlertMessage";

const MooringPaymentDetails = () => {
  let { id, sequNo } = useParams();
  const [res, err, isLoading, apiGet, abt] = useGet();
  const gaEvents = useGaEvent();
  useEffect(() => {
    apiGet("/api/mooring/MoPaymentDetail/" + id + "/" + sequNo);
    gaEvents.MooringPaymentVisit();
  }, []);

  return (
    <div className="mooring-sub-container">
      <div className="mooring-title-group">
        <h3 className="mooring-title">Payment Detail</h3>
      </div>

      {!isLoading ? (
        !err ? (
          <>
            <div className="row">
              <div className="col-md-4">
                <div className="mooring-input-group">
                  <label htmlFor="">Mo Sequence No.</label>
                  <div className="mooringInfoField">{id}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="mooring-input-group">
                  <label htmlFor="RenewNo">Renew No.</label>
                  <div className="mooringInfoField">{res?.myRenewNo}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="mooring-input-group">
                  <label htmlFor="PayType">Pay Type</label>
                  <div className="mooringInfoField">{res?.myType}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="mooring-input-group">
                  <label htmlFor="DatePay">Date Paid</label>
                  <div className="mooringInfoField">{getddmmyyyyFormat(res?.myDate?.split("T")[0])}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="mooring-input-group">
                  <label htmlFor="Amount">Amount</label>
                  <div className="mooringInfoField">{Math.abs(res?.myAmt).toFixed(2)}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="mooring-input-group">
                  <label htmlFor="AppLnAmt">App Amount</label>
                  <div className="mooringInfoField">{Math.abs(res?.myApplnAmt).toFixed(2)}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mooring-input-group">
                  <label htmlFor="Ref">Reference No</label>
                  <div className="mooringInfoField">{res?.myRef}</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mooring-input-group">
                  <label htmlFor="Source">Source</label>
                  <div className="mooringInfoField">{res?.mySource}</div>
                </div>
              </div>
            </div>
            <BackRedirect url={"/mooring/mooringdetails/" + id} />
          </>
        ) : (
          <AlertMessage message={err}/>
        )
      ) : (
        <DataLoader isLoading={isLoading} />
      )}
    </div>
  );
};

export default MooringPaymentDetails;
