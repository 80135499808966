import { useParams } from "react-router-dom";
import RecVesselPaymentList from "./RecVesselPaymentList";
import { useEffect, useState } from "react";
import { useGet, usePdf } from "../../Hooks/useFetch";
import PeEngineList from "../PeEngine/PeEngineList";
import { getddmmyyyyFormat, validatedExpiredDate } from "../../Helpers/Helper";
import DataLoader from "../DataLoader";
import { EditIcon, PrintIcon } from "../Icons";
import useAuth from "../../Hooks/useAuth";
import Dialog from "../Modal/Dialog";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import useGaEvent from "../../Hooks/useGaEvent";
import AlertMessage from "../AlertMessage";
import RecVesselContacts from "./RecVesselContacts";

const RecVesselDetails = () => {
  let { id } = useParams(); //rego

  const [res, err, isLoading, apiGet, abt] = useGet();
  const [errPdf, loadingPdf, apiPdf, abortPdf] = usePdf();
  const [openDialog, setOpenDialog] = useState(false);
  const [openWebPendingDialog, setOpenWebPendingDialog] = useState(false);
  const { auth } = useAuth();
  const gaEvents = useGaEvent();

  useEffect(() => {
    apiGet("/api/pcraft/PcCraftDetails/" + id);
    gaEvents.RecreationalDetailVisit();
  }, []);

  useEffect(() => {
    if (res?.isVesselViewAble == false) {
      setOpenWebPendingDialog(true);
    }
  }, [res?.isVesselViewAble]);

  const printPdf = async () => {
    if (!auth?.isAblePrintOrRegister) {
      setOpenDialog(true);
      return;
    }
    await apiPdf(
      "/api/pcraft/certificate-pdf/" + id,
      gaEvents.RecreationalPrintSuccess,
      gaEvents.RecreationalPrintFail
    );
    // if (errPdf) console.log(errPdf);
  };

  // console.log(res);

  return (
    <>
      <div className="row">
        <div className="col-md-7">
          <div className="recvessel-sub-container">
            {(res?.status==0 || res?.status==2 || res?.status==3) && <AlertMessage message={res?.statusReason} level="warning" closeEvt="false" mt="0"/>}
            <div>
              <AlertMessage message={errPdf} mt={0}/>
              <div className="recvessel-title-group">
              <h3 className="recvessel-title">
                Recreational Vessel Details &nbsp;
                {res?.isVesselEditable == true && (
                  <PrintIcon
                    iconSize="1x"
                    classLists="recvessel-printer icon"
                    clickEvent={printPdf}
                    isLoading={loadingPdf}
                    HoverCaption="Print Rego"
                  />
                )}
              </h3>
              {res?.isVesselEditable == true && (
                <EditIcon
                  url={"/recvessel/recvesselupdated/" + id}
                  iconSize="xl"
                  HoverCaption="Edit Recreational Vessel"
                />
              )}
            </div>
            </div>
            {!openWebPendingDialog && !isLoading ? (
              !err ? (
                <>
                  <div className="row">
                    <div
                      className={
                        res?.pcPersonalisedId ? "col-md-6" : "col-md-12"
                      }
                    >
                      <div className="recvessel-input-group">
                        <label htmlFor="regoNo">RegoNo</label>
                        <div className="recvesselInfoField">
                          {res?.pcRegoNo}
                        </div>
                      </div>
                    </div>
                    {res?.pcPersonalisedId ? (
                      <div className="col-md-6">
                        <div className="recvessel-input-group">
                          <label htmlFor="personalId">Personalised ID</label>
                          <div className="recvesselInfoField">
                            {res?.pcPersonalisedId}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="recvessel-input-group">
                        <label htmlFor="hin">Hin</label>
                        <div className="recvesselInfoField">
                          {res?.pcHin}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="recvessel-input-group">
                        <label htmlFor="serialNo">Serial No</label>
                        <div className="recvesselInfoField">
                          {res?.pcSerialNo}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="recvessel-input-group">
                        <label htmlFor="pname">Boat Name</label>
                        <div className="recvesselInfoField">
                          {res?.pcName}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="recvessel-input-group">
                        <label htmlFor="manuf">Year Manufacture</label>
                        <div className="recvesselInfoField">
                          {res?.pcDateManuf}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="recvessel-input-group">
                        <label htmlFor="abpFitted">ABP Fitted</label>
                        <div className="recvesselInfoField">
                          {res?.pcAbpFitted}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="recvessel-input-group">
                        <label htmlFor="storage">Where Kept</label>
                        <div className="recvesselInfoField">
                          {
                            res?.pcStorageDesc
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="recvessel-input-group">
                        <label htmlFor="storagePc">PostCode</label>
                        <div className="recvesselInfoField">
                          {res?.pcStoragePc}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="recvessel-input-group">
                        <label htmlFor="usage">Usage</label>
                        <div className="recvesselInfoField">
                          {
                            res?.pcUsageDesc
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  {res?.pcStorage === "RB_MARINA" && (
                    <>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="recvessel-input-group">
                            <label htmlFor="marinaBerth">Marina & Berth</label>
                            <div className="recvesselInfoField">
                              {res?.pcMarinaBerth}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="row">
                    <div className="col-md-4">
                      <div className="recvessel-input-group">
                        <label htmlFor="class">Class</label>
                        <div className="recvesselInfoField">
                          {res?.pcClassCode}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="recvessel-input-group">
                        <label htmlFor="hullModel">Model</label>
                        <div className="recvesselInfoField">
                          {res?.pcHullModel}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="recvessel-input-group">
                        <label htmlFor="flyBridge">Fly Bridge</label>
                        <div className="recvesselInfoField">
                          {res?.pcFlyBridge}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="recvessel-input-group">
                        <label htmlFor="hullMake">Hull Make</label>
                        <div className="recvesselInfoField">
                          {res?.pcHullMake}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="recvessel-input-group">
                        <label htmlFor="hullCode">Hull Material</label>
                        <div className="recvesselInfoField">
                          {res?.pcHullCode}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="recvessel-input-group">
                        <label htmlFor="hullColour">Hull Colour</label>
                        <div className="recvesselInfoField">
                          {res?.pcHullColour}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="recvessel-input-group">
                        <label htmlFor="otherColour">Other Colour</label>
                        <div className="recvesselInfoField">
                          {res?.pcOtherColour}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="recvessel-input-group">
                        <label htmlFor="navLights">Nav Lights</label>
                        <div className="recvesselInfoField">
                          {res?.pcNavLights}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3">
                      <div className="recvessel-input-group">
                        <label htmlFor="">Length</label>
                        <div className="recvesselInfoField">
                          {res?.pcLength <= 0
                            ? ""
                            : res?.pcLength}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="recvessel-input-group">
                        <label htmlFor="">Beam</label>
                        <div className="recvesselInfoField">
                          {res?.pcBeam <= 0
                            ? ""
                            : res?.pcBeam}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="recvessel-input-group">
                        <label htmlFor="">Draft</label>
                        <div className="recvesselInfoField">
                          {res?.pcDraft <= 0
                            ? ""
                            : res?.pcDraft}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="recvessel-input-group">
                        <label htmlFor="">Overall</label>
                        <div className="recvesselInfoField">
                          {res?.pcLengthOverall <= 0
                            ? ""
                            : res?.pcLengthOverall}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="recvessel-input-group">
                        <label htmlFor="pcEpirb">Epirb</label>
                        <div className="recvesselInfoField">
                          {res?.pcEpirb}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="recvessel-input-group">
                        <label htmlFor="pcEpirbType">Epirb Type</label>
                        <div className="recvesselInfoField">
                          {res?.pcEpirbType}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="recvessel-input-group">
                        <label htmlFor="pcEpirb406Reg">406 Reg</label>
                        <div className="recvesselInfoField">
                          {res?.pcEpirb406Reg}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="recvessel-input-group">
                        <label htmlFor="clubName">Club Name</label>
                        <div className="recvesselInfoField">
                          {res?.pcClubName}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="recvessel-input-group">
                        <label htmlFor="clubNumber">Club Number</label>
                        <div className="recvesselInfoField">
                          {res?.pcClubNumber}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="recvessel-input-group">
                        <label htmlFor="liferaft">Liferaft</label>
                        <div className="recvesselInfoField">
                          {res?.pcLiferaft}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="recvessel-input-group">
                        <label htmlFor="radioType">Radio Type</label>
                        <div className="recvesselInfoField">
                          {res?.pcRadioType}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="recvessel-input-group">
                        <label htmlFor="licenced">Licenced</label>
                        <div className="recvesselInfoField">
                          {res?.hdLicNum}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="recvessel-input-group">
                        <label htmlFor="callSign">Call Sign</label>
                        <div className="recvesselInfoField">
                          {res?.pcCallSign}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="recvessel-input-group">
                        <label htmlFor="newRegoDate">New Rego Date</label>
                        <div className="recvesselInfoField">
                          {getddmmyyyyFormat(
                            res?.pcNewRegoDate?.split("T")[0]
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="recvessel-input-group">
                        <label htmlFor="expiryDate">Expiry Date</label>
                        <div className="recvesselInfoField">
                          {validatedExpiredDate(res?.pcExpiryDate) ? (
                            <Tooltip title="EXPIRED" placement="top" arrow>
                              <span className="text-danger">
                                {getddmmyyyyFormat(
                                  res?.pcExpiryDate?.split("T")[0]
                                )}{" "}
                                <FontAwesomeIcon icon={faTriangleExclamation} />
                              </span>
                            </Tooltip>
                          ) : (
                            getddmmyyyyFormat(
                              res?.pcExpiryDate?.split("T")[0]
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="recvessel-input-group">
                        <label htmlFor="status">Status</label>
                        <div className="recvesselInfoField">
                          {validatedExpiredDate(res?.pcExpiryDate) ? (
                            <span className="text-danger">EXPIRED</span>
                          ) : (
                            res?.pcStatus == null ? (<span className="text-danger">UNKNOWN</span>) : res?.pcStatusDesc
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <AlertMessage message={err}/>
              )
            ) : (
              <DataLoader isLoading={isLoading} />
            )}
          </div>

          {!openWebPendingDialog && <PeEngineList regoNo={id} isCreateOrEditAble={res?.isVesselEditable}/>}
        </div>
        <div className="col-md-5">
          {!openWebPendingDialog && <RecVesselPaymentList regoNo={id} isRenewAble={res?.isVesselEditable}/>}
          {!openWebPendingDialog && <RecVesselContacts regoNo={id} />}
        </div>
      </div>

      {openDialog && (
        <Dialog
          setOpenDialog={setOpenDialog}
          title="ALERT"
          children="You have recently made changes to personal details. Some functions (Printing Certificates, New Vessel Registrations) are unavailable until MAST have confirmed these changes."
        />
      )}

      {openWebPendingDialog && (
        <Dialog
          setOpenDialog={setOpenWebPendingDialog}
          title="ALERT"
          children="You have recently added a new recreational vessel. It is not viewable until MAST have confirmed."
          redirectUrl="/recvessel"
        />
      )}
    </>
  );
};

export default RecVesselDetails;
