//This page is either showing unidentified user or register password page
import useUserInfo from "../../Hooks/useUserInfo";
import UnidentifiedUser from "../../Components/VerifyCode/UnidentifiedUser";
import RegisterPassword from "../../Components/Registration/RegisterPassword";
import { Navigate } from "react-router-dom";

const RegisterResult = () => {
  const { userInfo } = useUserInfo();
  // console.log(userInfo);
  return (
    <>
      {!userInfo.tempWWWId ? (
        <Navigate to="/register" replace={true} />
      ) : (
        <div className="register-container-bg">
          {userInfo.regiReqNo ? (
            <UnidentifiedUser
              RegistrationNo={userInfo.regiReqNo}
              From={"Registration"}
              FailReason={userInfo.failReason}
            />
          ) : (
            <RegisterPassword />
          )}
        </div>
      )}
    </>
  );
};

export default RegisterResult;
