import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import SellerDetailReadOnly from "./SellerDetailReadOnly";
import { getddmmyyyyFormat } from "../../Helpers/Helper";
import BuyerFormSellerContacts from "./BuyerFormSellerContacts";

const SellerPopulated = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  sellerInfo,
  clearErrors,
  isCompanyFieldName,
  setError,
  uploadSizeLimit,
  setValue
}) => {
  return (
    <>
      {sellerInfo != null && sellerInfo.length > 0 ? (
        <>
          <div className="br-box">
            <div className="br-title">Purchase of Vessel</div>
            <div className="br-field-group">
              <div className="br-input-group">
                <label htmlFor="dateOfPurchase">Date of Purchase</label>
                <div className="br-field-readonly unSelectable">
                  {getddmmyyyyFormat(
                    sellerInfo[0]?.dateOfPurchase?.split("T")[0]
                  )}
                </div>
              </div>
              <div className="br-input-group">
                <label htmlFor="sellerRegoNo">Rego No.</label>
                <div className="br-field-readonly unSelectable">
                  {sellerInfo[0]?.pcRegoNo}
                </div>
              </div>
              <div className="br-input-group">
                <label htmlFor="sellerBoatName">Boat Name</label>
                <div className="br-field-readonly unSelectable">
                  {sellerInfo[0]?.pcName}
                </div>
              </div>
            </div>
          </div>
          <SellerDetailReadOnly
            PrimaryTitle="Registered Operator (Seller)"
            client={sellerInfo[0]}
          />
        </>
      ) : (
        <>
          <div className="br-box">
            <div className="br-title">Purchase of Vessel</div>
            <div className="br-field-group">
              <div
                className={
                  errors.dateOfPurchase?.message
                    ? "br-input-group error"
                    : !getFieldState("dateOfPurchase").isDirty
                    ? "br-input-group"
                    : "br-input-group success"
                }
              >
                <label htmlFor="dateOfPurchase">
                  Date of Purchase<span className="text-danger">*</span>
                </label>
                <Controller
                  control={control}
                  name="dateOfPurchase"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <DatePicker
                        value={value}
                        onChange={onChange}
                        defaultValue={dayjs()}
                        disableFuture
                        views={["year", "month", "day"]}
                        
                      />
                    );
                  }}
                  rules={{
                    required: "Purchase Date is required",
                    validate: {
                      min: (v) =>
                        new Date(v) >= new Date(1900, 0, 1) ||
                        "Selected year must be greater than 1900",
                      max: (v) =>
                        new Date(v) <= new Date() ||
                        "Selected date must be less than today",
                    },
                  }}
                  errors={errors}
                />
                <span className="msg">{errors.dateOfPurchase?.message}</span>
              </div>
              <div
                className={
                  errors.sellerRegoNo?.message
                    ? "br-input-group error"
                    : !getFieldState("sellerRegoNo").isDirty
                    ? "br-input-group"
                    : "br-input-group success"
                }
              >
                <label htmlFor="sellerRegoNo">
                  Rego No.<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="sellerRegoNo"
                  {...register("sellerRegoNo", {
                    required: "Rego is required",
                  })}
                />
                <span className="msg">{errors.sellerRegoNo?.message}</span>
              </div>
              <div className="br-input-group">
                <label htmlFor="sellerBoatName">Boat Name</label>
                <input
                  type="text"
                  id="sellerBoatName"
                  {...register("sellerBoatName")}
                />
              </div>

              <div
                className={
                  errors.pcLength?.message
                    ? "br-input-group error"
                    : "br-input-group"
                }
              >
                <label htmlFor="pcLength">Boat Length(m)</label>
                <input
                  type="number"
                  step="0.01"
                  id="pcLength"
                  {...register("pcLength", {
                    pattern: {
                      value: /^[0-9.,]+$/,
                      message: "Only number allowed",
                    },
                  })}
                />
                <span className="msg">{errors.pcLength?.message}</span>
              </div>
              <div className="br-input-group">
                <label htmlFor="pcSerialNo">Hull Serial No</label>
                <input
                  type="text"
                  id="pcSerialNo"
                  {...register("pcSerialNo")}
                  className="text-upper"
                />
              </div>
              <div className="br-input-group">
                <label htmlFor="pcHin">Hull Identification No (HIN)</label>
                <input
                  type="text"
                  id="pcHin"
                  {...register("pcHin")}
                  className="text-upper"
                />
              </div>
            </div>
          </div>
          <BuyerFormSellerContacts
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            clearErrors={clearErrors}
            isCompanyFieldName={isCompanyFieldName}
            setError={setError}
            uploadSizeLimit={uploadSizeLimit}
            setValue={setValue}
          />
        </>
      )}
    </>
  );
};

export default SellerPopulated;
