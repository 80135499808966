const EpirbPart = ({ register, watch, control, errors, getFieldState }) => {
  return (
    <div className="br-box">
      <div className="br-title">EPIRB</div>
      <div className="br-field-group">
        <div className="br-input-group">
          <label htmlFor="hasNaviLight">
            Does the Vessel have navigation lights
          </label>
          <div className="br-field-radio-group">
            <div className="br-radio-group">
              <input
                type="radio"
                value="Yes"
                id="hasNaviLightYes"
                {...register("hasNaviLight")}
              />
              <label htmlFor="hasNaviLightYes">YES</label>
            </div>
            <div className="br-radio-group">
              <input
                type="radio"
                value="No"
                id="hasNaviLightNo"
                {...register("hasNaviLight")}
              />
              <label htmlFor="hasNaviLightNo">NO</label>
            </div>
          </div>
        </div>

        <div className="br-input-group">
          <label htmlFor="hasEPIRB">Does the Vessel have an EPIRB</label>
          <div className="br-field-radio-group">
            <div className="br-radio-group">
              <input
                type="radio"
                value="Yes"
                id="hasEPIRBYes"
                {...register("hasEPIRB")}
              />
              <label htmlFor="hasEPIRBYes">YES</label>
            </div>
            <div className="br-radio-group">
              <input
                type="radio"
                value="No"
                id="hasEPIRBNo"
                {...register("hasEPIRB")}
              />
              <label htmlFor="hasEPIRBNo">NO</label>
            </div>
          </div>
          {watch("hasEPIRB") === "No" ? (
            <>
              <span className="br-additional-text">
                An EPIRB is mandatory if operating beyond sheltered waters.
              </span>
            </>
          ) : (
            ""
          )}
        </div>

        {watch("hasEPIRB") === "Yes" ? (
          <>
            <div
              className={
                errors.epirbType?.message
                  ? "br-input-group error"
                  : !getFieldState("epirbType").isDirty
                  ? "br-input-group"
                  : "br-input-group success"
              }
            >
              <label htmlFor="epirbType">
                EPIRB Type<span className="text-danger">*</span>
              </label>
              <select
                id="epirbType"
                {...register("epirbType", {
                  required: {
                    value: watch("hasEPIRB") === "Yes",
                    message: "EPIRB Type is required",
                  },
                })}
              >
                <option value="">CHOOSE...</option>
                <option value="406">406</option>
                <option value="406 GPS">406 GPS</option>
              </select>
              <span className="msg">{errors.epirbType?.message}</span>
            </div>
            <div className="br-input-group">
              <label htmlFor="isEPIRBwithAMSA">
                Is the EPIRB registered with AMSA
              </label>
              <div className="br-field-radio-group">
                <div className="br-radio-group">
                  <input
                    type="radio"
                    value="Yes"
                    id="isEPIRBwithAMSAYes"
                    {...register("isEPIRBwithAMSA")}
                  />
                  <label htmlFor="isEPIRBwithAMSAYes">YES</label>
                </div>
                <div className="br-radio-group">
                  <input
                    type="radio"
                    value="No"
                    id="isEPIRBwithAMSANo"
                    {...register("isEPIRBwithAMSA")}
                  />
                  <label htmlFor="isEPIRBwithAMSANo">NO</label>
                </div>
              </div>
              {watch("isEPIRBwithAMSA") === "No" ? (
                <span className="br-additional-text">
                  406Mhz EPIRBS must be registered with the Australian Maritime
                  Safety Authority (AMSA) Please visit{" "}
                  <a
                    href="https://beacons.amsa.gov.au/registration/index.asp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    the AMSA site
                  </a>{" "}
                  for more information.
                </span>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          ""
        )}

        <div className="br-input-group">
          <label htmlFor="clubName">Club Name</label>
          <span className="br-desc-text">if applicable</span>
          <input type="text" id="clubName" className="text-upper" {...register("clubName")} />
        </div>

        <div className="br-input-group">
          <label htmlFor="sailNumber">Sail Number</label>
          <span className="br-desc-text">if applicable</span>
          <input type="text" id="sailNumber" className="text-upper" {...register("sailNumber")} />
          <span className="br-additional-text">
            A sail number is not a replacement for the MAST issued registration
            number.
          </span>
        </div>
      </div>
    </div>
  );
};

export default EpirbPart;
