import {
  faCircleMinus,
  faAngleDown,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getYearRangeRegex } from "../../../Helpers/Helper";
import { Collapse } from "@mui/material";
import { useState } from "react";

const EngineReRegiDescription = ({
  register,
  watch,
  getFieldState,
  control,
  index,
  engineArrayPropName,
  remove,
  fields
}) => {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className="br-box">
      <input
        type="hidden"
        {...register(`${engineArrayPropName}.${index}.PeSequNo`)}
      />
      <div className="br-title amount-group">
        <div style={{width:"100%"}} onClick={handleClick}>
          <span>
            {open ? (
              <FontAwesomeIcon icon={faAngleRight} size="1x" />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} size="1x" />
            )}
          </span>
          {" "}Engine {index + 1}{watch(`${engineArrayPropName}.${index}.PeMake`) ? ':':''} {watch(`${engineArrayPropName}.${index}.PeMake`)}{watch(`${engineArrayPropName}.${index}.PeMake`) && watch(`${engineArrayPropName}.${index}.PeCapacity`) ? '-':''}{watch(`${engineArrayPropName}.${index}.PeCapacity`)}{ watch(`${engineArrayPropName}.${index}.PeCapacityType`)}
        </div>
        {fields?.length > 1 ? (
          <FontAwesomeIcon
            icon={faCircleMinus}
            className="br-engine-bin-icon"
            size="1x"
            onClick={() => remove(index)}
          />
        ) : (
          ""
        )}
      </div>
      {open &&
        (getFieldState(`${engineArrayPropName}.${index}.PePropulsion`)
          .invalid ||
          getFieldState(`${engineArrayPropName}.${index}.PeFuel`).invalid ||
          getFieldState(`${engineArrayPropName}.${index}.PeMake`).invalid ||
          getFieldState(`${engineArrayPropName}.${index}.PeYearMade`).invalid ||
          getFieldState(`${engineArrayPropName}.${index}.PeCapacity`).invalid ||
          getFieldState(`${engineArrayPropName}.${index}.PeCapacityType`)
            .invalid) && (
          <div className="invalid">
            Invalid fields. Please expand to see details.
          </div>
        )}
      <Collapse in={!open} timeout="auto">
        <div className="br-field-group">
          <div
            className={
              getFieldState(`${engineArrayPropName}.${index}.PePropulsion`)
                .invalid
                ? "br-input-group error"
                : !watch(`${engineArrayPropName}.${index}.PePropulsion`)
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="PePropulsion">
              Propulsion <span className="text-danger">*</span>
            </label>
            <select
              id="PePropulsion"
              {...register(`${engineArrayPropName}.${index}.PePropulsion`, {
                required: "Propulsion is required",
              })}
            >
              <option value="">Choose...</option>
              <option value="RB_OUTBOARD">Outboard</option>
              <option value="RB_INBOARD">Inboard</option>
              <option value="RB_STERNDRIVE">Sterndrive</option>
              <option value="RB_JET">Jet</option>
            </select>
            <span className="msg">
              {
                getFieldState(`${engineArrayPropName}.${index}.PePropulsion`)
                  .error?.message
              }
            </span>
          </div>

          <div
            className={
              getFieldState(`${engineArrayPropName}.${index}.PeFuel`).invalid
                ? "br-input-group error"
                : !watch(`${engineArrayPropName}.${index}.PeFuel`)
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="PeFuel">
              Fuel <span className="text-danger">*</span>
            </label>
            <select
              id="PeFuel"
              {...register(`${engineArrayPropName}.${index}.PeFuel`, {
                required: "Fuel is required",
              })}
            >
              <option value="">Choose...</option>
              <option value="RB_PETROL">Petrol</option>
              <option value="RB_DIESEL">Diesel</option>
              <option value="RB_ELECTRIC">Electric</option>
              <option value="RB_FUEL_OTHER">Other</option>
            </select>
            <span className="msg">
              {
                getFieldState(`${engineArrayPropName}.${index}.PeFuel`).error
                  ?.message
              }
            </span>
          </div>

          <div
            className={
              getFieldState(`${engineArrayPropName}.${index}.PeMake`).invalid
                ? "br-input-group error"
                : !watch(`${engineArrayPropName}.${index}.PeMake`)
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="PeMake">
              Make <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="PeMake"
              {...register(`${engineArrayPropName}.${index}.PeMake`, {
                required: "Engine made is required",
              })}
            />
            <span className="msg">
              {
                getFieldState(`${engineArrayPropName}.${index}.PeMake`).error
                  ?.message
              }
            </span>
          </div>

          <div
            className={
              !watch(`${engineArrayPropName}.${index}.PeSerialNo`)
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="PeSerialNo">Serial Number</label>
            <input
              type="text"
              id="PeSerialNo"
              {...register(`${engineArrayPropName}.${index}.PeSerialNo`)}
            />
          </div>

          <div
            className={
              getFieldState(`${engineArrayPropName}.${index}.PeYearMade`)
                .invalid
                ? "br-input-group error"
                : !watch(`${engineArrayPropName}.${index}.PeYearMade`)
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="PeYearMade">Year of Manufacture</label>
            <input
              type="number"
              id="PeYearMade"
              {...register(`${engineArrayPropName}.${index}.PeYearMade`, {
                pattern: {
                  value: getYearRangeRegex(),
                  message: "Invalid year",
                },
              })}
            />
            <span className="msg">
              {
                getFieldState(`${engineArrayPropName}.${index}.PeYearMade`)
                  .error?.message
              }
            </span>
          </div>

          <div
            className={
              getFieldState(`${engineArrayPropName}.${index}.PeCapacity`)
                .invalid
                ? "br-input-group error"
                : !watch(`${engineArrayPropName}.${index}.PeCapacity`)
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="PeCapacity">
              Engine Capacity <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              step=".01"
              id="PeCapacity"
              {...register(`${engineArrayPropName}.${index}.PeCapacity`, {
                required: "Engine capacity is required",
              })}
            />
            <span className="msg">
              {
                getFieldState(`${engineArrayPropName}.${index}.PeCapacity`)
                  .error?.message
              }
            </span>
          </div>

          <div
            className={
              getFieldState(`${engineArrayPropName}.${index}.PeCapacityType`)
                .invalid
                ? "br-input-group error"
                : !watch(`${engineArrayPropName}.${index}.PeCapacityType`)
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="PeCapacityType">
              Unit of Measurement <span className="text-danger">*</span>
            </label>
            <select
              id="PeCapacityType"
              {...register(`${engineArrayPropName}.${index}.PeCapacityType`, {
                required: "Unit of measure is required",
              })}
            >
              <option value="">Choose...</option>
              <option value="hp">hp</option>
              <option value="kW">kW</option>
              <option value="cc">cc</option>
            </select>
            <span className="msg">
              {
                getFieldState(`${engineArrayPropName}.${index}.PeCapacityType`)
                  .error?.message
              }
            </span>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default EngineReRegiDescription;
