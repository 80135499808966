import { useGet } from "../../Hooks/useFetch";
import { useEffect } from "react";
import { getddmmyyyyFormat } from "../../Helpers/Helper";
import DataLoader from "../DataLoader";
import { EditIcon, AddIcon } from "../Icons";
import Tooltip from "@mui/material/Tooltip";
import AlertMessage from "../AlertMessage";
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";

const MooringPrimaryVesselList = ({ itemId, moRegoNo, moAppLen }) => {
  const [res, err, isLoading, apiGet, abt] = useGet();

  useEffect(() => {
    apiGet("/api/mooring/MoPrimaryVessels/" + itemId);
  }, []);

  // console.log(res);

  return (
    <div className="mooring-sub-container">
      <div className="mooring-title-group">
        <h3 className="mooring-title">Primary Vessels</h3>
        <AddIcon
          url={
            "/mooring/mooringprimaryvesselinsert/" +
            itemId +
            "/" +
            moRegoNo +
            "/" +
            moAppLen
          }
          iconSize="2x"
          isBeat="true"
          HoverCaption="Add New Primary Vessel"
        />
      </div>

      {!isLoading ? (
        !err ? (
          res?.length === 0 ? (
            <AlertMessage
              message="No Records. Please add Primary Vessel details here by pressing the Plus(+) button."
              level="info"
              closeEvt="false"
              mt="0"
              mb="0"
            />
          ) : (
            <>
              {res?.map((p) => (
                <>
                  {p?.status == 0 || p?.status == 2 || p?.status == 3 ? (
                    <div className="statusReason-format text-danger">
                      ({p?.statusReason})
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="row" key={uuidv4()}>
                    <div className="col-md-2">
                      <div className="mooring-input-group">
                        {p?.status == 0 || p?.status == 2 || p?.status == 3 ? (
                          <Tooltip
                            title={p?.statusReason}
                            placement="top-start"
                          >
                            <label htmlFor="pvDateFrom" className="tooltip">
                              From
                            </label>
                          </Tooltip>
                        ) : (
                          <label htmlFor="pvDateFrom">From</label>
                        )}
                        <div className="mooringInfoField">
                          {getddmmyyyyFormat(p?.moPvDateFrom?.split("T")[0])}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="mooring-input-group">
                        <label htmlFor="pvDateTo">To</label>
                        <div className="mooringInfoField">
                          {getddmmyyyyFormat(p?.moPvDateTo?.split("T")[0])}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="mooring-input-group">
                        <label htmlFor="regoNo">Rego</label>
                        <div className="mooringInfoField">{p?.regoNo}</div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mooring-input-group">
                        <label htmlFor="boatName">Boat</label>
                        <div className="mooringInfoField">{p?.vesselName}</div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="mooring-input-group">
                        <label htmlFor="vType">Type</label>
                        <div className="mooringInfoField">
                          {((p?.pcItemId && "Rec Ves") ||
                            (p?.favSysId && "DCV")) ??
                            p?.vesselType}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1">
                      <div className="mooring-input-group">
                        <label htmlFor="action">Action</label>
                        <div className="icon-group">
                          <EditIcon
                            url={
                              "/mooring/mooringprimaryvesselupdated/" +
                              p?.itemId +
                              "/" +
                              p?.moSeqId +
                              "/" +
                              moRegoNo +
                              "/" +
                              moAppLen
                            }
                            HoverCaption="Edit Primary Vessel"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
              {/* <span className="red-text-explain"><span className="text-danger"><b>* Red From:</b></span> Not confirmed / reject / more docs required from MAST.</span> */}
            </>
          )
        ) : (
          <AlertMessage message={err} />
        )
      ) : (
        <DataLoader isLoading={isLoading} />
      )}
    </div>
  );
};

export default MooringPrimaryVesselList;
