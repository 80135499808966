import PnaContactReadOnly from "../../Contacts/PnaContactReadOnly";
import SecondContactReadOnly from "../../Contacts/SecondContactReadOnly";
import OrganisationContactReadOnly from "../../Contacts/OrganisationContactReadOnly";

const FormOwnerDetailReadOnly = ({ isCompany, resPna, rb, secondContact }) => {
  
  return (
    <div className="br-box">
      {isCompany === "Organisation" ? (
        <div>
          <OrganisationContactReadOnly
            title="New Registered Operator"
            oeLink={rb}
          />
          <PnaContactReadOnly title="Organisation Contact" pna={resPna} />
        </div>
      ) : (
        <div>
          <PnaContactReadOnly title="New Registered Operator" pna={resPna} />
          {secondContact && <SecondContactReadOnly
            title="Secondary Operator"
            secondContact={secondContact}
          />}
        </div>
      )}
    </div>
  );
};

export default FormOwnerDetailReadOnly;
