import BoatReRegistrationProcess from "../../Components/BoatRegistration/BoatReRegistrationProcess";
import { useGet } from "../../Hooks/useFetch";
import { useEffect } from "react";
import DataLoader from "../../Components/DataLoader";
import AlertMessage from "../../Components/AlertMessage";
import { decryptText } from "../../Helpers/Helper";
import { useParams } from "react-router-dom";

const BoatReRegistrationForm = () => {
  let { regoNo } = useParams();
  const [resKey, err, isLoadingKey, apiGetKey, abtKey] = useGet();
  const [resPcraft, errPcraft, isLoadingPcraft, apiGetPcraft, abtPcraft] = useGet();

  useEffect(() => {
    apiGetKey("/api/pcraft/PayWayKey");
    apiGetPcraft("/api/pcraft/PcCraftFullDetails/" + regoNo);
  }, []);

  return (
    <>
      {!isLoadingKey ? (
        !err ? (
          !isLoadingPcraft ? (
            !errPcraft ? (
              <BoatReRegistrationProcess
                payWayPublicKey={decryptText(
                  resKey,
                  process.env.REACT_APP_Encrypt_Key,
                  process.env.REACT_APP_Encrypt_IV
                )}
                pcCraft={resPcraft}
                hullCodes={resPcraft?.hullCodes}
              />
            ) : (
              <AlertMessage message={errPcraft} />
            )
          ) : (
            <DataLoader isLoading={isLoadingPcraft} />
          )
        ) : (
          <AlertMessage message={err} />
        )
      ) : (
        <DataLoader isLoading={isLoadingKey} />
      )}
    </>
  );
};

export default BoatReRegistrationForm;
