const FormApplicationComments = ({ register }) => {
  return (
    <div className="br-box">
      <div className="br-title">
        Application Comments
      </div>
      <div className="br-input-group">
        <label htmlFor="appComments">
        Please enter previous user details as much as possible if remember (Use comma to separate)
        </label>
        <textarea {...register("appComments")} id="appComments" rows="6" cols="100"/>
      </div>
    </div>
  );
};

export default FormApplicationComments;
