import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { useRef } from "react";

const FormProgressBar = ({ currentIndex, totalSteps }) => {
  let currPercentage = ((currentIndex) / (totalSteps.length - 1)) * 100;
  const progBarRef = useRef();
//   console.log(currPercentage);
  return (
    // <ProgressBar completed={percentage} bgColor="#22c0ff" labelColor="#fff"/>
    <div className="col-md-12 progress-bar-container">
      <ProgressBar percent={currPercentage} ref={progBarRef}>
        {totalSteps.map(() => {
          return (
            <Step>
              {({ accomplished, index }) => (
                <div
                  className={`indexedStep ${
                    accomplished ? "accomplished" : null
                  }`}
                key={index}>
                  {index + 1}
                </div>
              )}
            </Step>
          );
        })}
      </ProgressBar>
    </div>
  );
};

export default FormProgressBar;
