
import { Outlet, useLocation, Navigate } from "react-router-dom";
import "../Components/Licence/Licence.css";
import LicenceDetail from "../Components/Licence/LicenceDetails";
import LicencePaymentList from "../Components/Licence/LicencePaymentList";
import LicenceConditionList from "../Components/Licence/LicenceConditionList";

const Licence = () => {
 
  const location = useLocation();
  const path = '/licence';
  // console.log(location);

  return (
      <div className="licence-container">
        {location.pathname === path ? (
          <>
            <div className="row">
              <div className="col-md-7">
                <LicenceDetail />
                <LicenceConditionList />
              </div>
              <div className="col-md-5">
                <LicencePaymentList />
              </div>
            </div>
            
          </>
        ) : (
          <Outlet />
        )}
        
      </div>
  );
};

export default Licence;
