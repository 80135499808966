import { Alert } from "@mui/material";
import { useState } from "react";

//level: error, warning, info, success
const AlertMessage = ({
  message,
  level = "error",
  closeEvt = true,
  mt = 0.8,
  mb = 0.8,
  ml = 0,
  mr = 0,
}) => {
  const marginStyle = {
    marginTop: mt + "rem",
    marginBottom: mb + "rem",
    marginLeft: ml + "rem",
    marginRight: mr + "rem",
  };
  const [open, setOpen] = useState(true);
  return (
    <>
      {closeEvt == true
        ? open &&
          typeof message === "string" && message && (
            <div style={marginStyle}>
              <Alert
                severity={level}
                onClose={() => setOpen(false)}
                className="msg-scroll-screen-y"
              >
                {message}
              </Alert>
            </div>
          )
        : typeof message === "string" && message && (
            <div style={marginStyle}>
              <Alert severity={level} className="msg-scroll-screen-y">
                {message}
              </Alert>
            </div>
          )}
    </>
  );
};

export default AlertMessage;
