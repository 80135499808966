import { axiosApi } from '../Api/Axios';
import { useState } from 'react';


export const useKleberVerify = () => {
  const [loading, setLoading] = useState(false);

  const apiVerify = async (path, inputValue, currValue, provideValueError, setValueChangeVerified, setShowVerifyBtn) => {
    try {
        // console.log(inputEmail);
        setLoading(true);
        setShowVerifyBtn(false);
        
        if (inputValue === currValue)
        {
            setValueChangeVerified(true);
            return;
        } 
    
        const response = await axiosApi.get(path + inputValue);
        // console.log(response);
        if(!response.data.isValid) 
        {
            provideValueError(response.data?.description);
            setValueChangeVerified(false);
        }else
        {
            setValueChangeVerified(false);
            setShowVerifyBtn(true);
        }

      } catch (error) {
        // console.error(error);
      }finally {
        setLoading(false);
      }
  }

  const apiVerifyEmail = (inputEmail, currEmail, provideEmailError, setEmailChangeVerified, setShowEmailBtn) => 
    apiVerify("/api/pna/verify-email/", inputEmail, currEmail, provideEmailError, setEmailChangeVerified, setShowEmailBtn)
  

  const apiVerifyMobile = (inputMobile, currMobile, provideMobileError, setMobileChangeVerified, setShowMobileBtn) => 
    apiVerify("/api/pna/verify-mobile/", inputMobile, currMobile, provideMobileError, setMobileChangeVerified, setShowMobileBtn)
  

  return [apiVerifyMobile, apiVerifyEmail, loading];

  
};
