
const OrganisationContactReadOnly = ({ title, oeLink }) => {
  return (
    <div className="br-box">
      <div className="br-title">{title}</div>
      <div className="br-field-group">
        <div className="br-input-group">
          <label htmlFor="">Organisation Name</label>
          <div className="br-field-readonly unSelectable">
            {oeLink?.fullname}
          </div>
        </div>
        <div className="br-input-group">
          <label htmlFor="">ABN/ACN/Inc. No.</label>
          <div className="br-field-readonly unSelectable">
            {oeLink?.pnaABNACN}
          </div>
        </div>
        <div className="br-input-group">
          <label htmlFor="residentialAddress">Residential</label>
          <div className="br-field-readonly unSelectable">
            {oeLink?.resAddr}
          </div>
        </div>

        <div className="br-input-group">
          <label htmlFor="clientEmail">Email</label>
          <div className="br-field-readonly unSelectable">
            {oeLink?.pnaEmail}
          </div>
        </div>
        <div className="br-input-group">
          <label htmlFor="clientMobile">Mobile / Contact Phone</label>
          <div className="br-field-readonly unSelectable">
            {oeLink?.pnaMobile}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganisationContactReadOnly;
