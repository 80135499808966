import { useForm } from "react-hook-form";
import useMultiStepForm from "../../Hooks/useMultiStepForm";
import FormIntro from "./StepForms/FormIntro";
import FormOwnerDetail from "./StepForms/FormOwnerDetail";
import FormVesselDetail from "./StepForms/FormVesselDetail";
import FormEngineDetail from "./StepForms/FormEngineDetail";
import FormPayProcess from "./StepForms/FormPayProcess";
import FormUploadDoc from "./StepForms/FormUploadDoc";
import FormDeclarationLaw from "./StepForms/FormDeclarationLaw";
import usePayWay from "../../Hooks/usePayWay";
import { useGet, useMultipartFormPost } from "../../Hooks/useFetch";
import { useEffect, useState } from "react";
import SubmitLoader from "../SubmitLoader";
import FormProgressBar from "../FormProgressBar";
import useAuth from "../../Hooks/useAuth";
import Dialog from "../Modal/Dialog";
import useGaEvent from "../../Hooks/useGaEvent";
import AlertMessage from "../AlertMessage";
import DataLoader from "../DataLoader";
import { splitFullAddress, joinMarinaString } from "../../Helpers/Helper";
import FormApplicationComments from "./StepForms/FormApplicationComments";

const BoatReNewRegistrationProcess = ({ payWayPublicKey }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [resPna, errPna, isLoadingPna, apiGetPna, abtPna] = useGet();
  const [contactError, setContactError] = useState(false);
  const [openFeeNotReadyDialog, setOpenFeeNotReadyDialog] = useState(false);
  const { auth } = useAuth();
  const gaEvents = useGaEvent();

  const uploadSizeLimit = 5242880; //5MB

  let initialValues = {
    isEPIRBwithAMSA: null,
    hasEPIRB: null,
    PcEpirbType: null,

    mooringRegoNo: null,
    existRegiNo: null,
    vesselName: null,
    classVessel: null,
    sailNumber: null,
    clubName: null,
    hullLength: null,
    beam: null,
    draft: null,
    hasNaviLight: null,
    hullConstruction: null,
    manufacturerName: null,
    hullColour: null,
    otherColour: null,

    vesselSign: null,
    hullSerialNo: null,
    yearOfMade: null,
    hasBuilderPlate: null,
    hullIdNo: null,
    whereVesselKept: null,
    pocodeOfStorage: null,
    comRegoNo: null,
    vesselUse: null,
    model: null,
    hasFlyBridge: null,
    isVesselHomemade: null,
    builderName: null,
    hullLengthOverall: null,
    marinaName: null,
    marinaBerthNum: null,

    ownedVessel3mths: null,
    chgLocationLast3mths: null,
    chgLocationNext3mths: null,
    newLocation: null,
    vesselUnseaworthy: null,

    rb: {
      orgType: null,
      orgTypeNumber: null,
      organisationName: null,
      orgEmail: null,
      orgMobile: null,
      orgResAddress: null,
    },

    sortRadio: [],
    //file
    file: [],
    nonTasFile: [],
    isCompany: "Individual",
    payId: "",
    appComments: "",
  };
  if (process.env.REACT_APP_TEST_DEFAULT_VALUE === "on") {
    initialValues = {
      hullSerialNo: "ik123",
      hullIdNo: "88888",
      isVesselDiff: "No",
      existRegiNo: "",
      isCommercialPurpose: "No",
      comRegoNo: "",
      vesselName: "dreamcometrue",
      whereVesselKept: "RB_MARINA",
      marinaName: "mmchocolate",
      marinaBerthNum: "222222",
      mooringRegoNo: "",
      pocodeOfStorage: 7001,
      vesselUse: "RB_CRUISING",
      classVessel: "CRUISER",
      hasFlyBridge: "Yes",

      isVesselHomemade: "No",
      builderName: "gg wood",
      manufacturerName: "toyota", //hull_make
      model: "yaris",
      yearOfMade: "1899",
      hullConstruction: "ALUMINIUM", //hull_code
      otherDescHullConstr: "",
      hullColour: "green",
      otherColour: "blue",
      hullLength: "12.6",
      hullLengthOverall: "13.9",
      beam: "11.2",
      draft: "15.8",
      hasNaviLight: "Yes",
      epirbType: "406",
      hasEPIRB: "Yes",
      isEPIRBwithAMSA: "Yes",
      clubName: "fan club",
      sailNumber: "123321",

      hasBuilderPlate: "Yes",

      // multi-checkbox
      sortRadio: ["VHF", "HF", "27MHz"],
      // vesselSign: "cos theta",

      //Additional questions for vessel over length 15m
      // describeVessel: "MOTOR SAILER", //Remove by Bill - BB General Comments 17/05/23
      ownedVessel3mths: "No",
      chgLocationLast3mths: "Yes",
      vesselKeptNow: "Heaven",
      chgLocationNext3mths: "No",
      newLocation: "4 Country Road",
      vesselUnseaworthy: "Yes",

      rb: {
        orgType: "ACN",
        orgTypeNumber: "1233689",
        organisationName: "GGT",
        orgEmail: "jl1@datawise.com.au",
        orgMobile: "0452736456",
        orgResAddress: "4 Cart St, BOX HILL, NSW, 2765",
      },

      //file
      file: [],
      nonTasFile: [],
      isCompany: "Individual",

      payId: "",

      appComments:"",
    };
  }

  const engineArrayPropName = "engineDetails";
  initialValues[engineArrayPropName] = [];

  const SecondContactArrayPropName = "secondContactDetails";
  initialValues[SecondContactArrayPropName] = [];

  const {
    control,
    register,
    formState: { errors, isDirty, isValid, dirtyFields },
    handleSubmit,
    watch,
    getFieldState,
    setValue,
    setError,
    clearErrors,
    getValues,
  } = useForm({ defaultValues: initialValues, mode: "onChange" });

  const {
    errFrame, //err message when frame is not created
    errToken, //err message when toke is not created
    tokenId, //single token id
    creditCard, //credit card
    btnDisable, //button disable status
    frameDestroy, //it is called after send to server
    requestToken, // fc to get token
    createFrame, // fc to create card frame
    stateDestroy, //clean states
  } = usePayWay(
    //process.env.REACT_APP_PAYWAY_ID.trim()
    payWayPublicKey
  );

  const [
    resRegoFee,
    errorRegoFee,
    isLoadingRegoFee,
    apiGetRegoFee,
    abortRegoFee,
  ] = useGet();

  const isAbleRegister = () => {
    if (!auth?.isAblePrintOrRegister) {
      setOpenDialog(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    apiGetPna("/api/pna/PnaDecryptedData");
    apiGetRegoFee("/api/pcraft/BoatRegoFee");
    isAbleRegister();
    gaEvents.RecreationalAddVisit();
  }, []);

  useEffect(() => {
    if (!isLoadingPna && errPna) {
      setContactError(true);
    }
  }, [isLoadingPna, errPna]);

  useEffect(() => {
    if (resRegoFee != null && resRegoFee <= 0) {
      setOpenFeeNotReadyDialog(true);
    }
  }, [resRegoFee]);

  const formContent =
    auth?.isBypassPayment === true
      ? [
          <FormIntro
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
          />,
          <FormOwnerDetail
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            clearErrors={clearErrors}
            isCompanyFieldName="isCompany"
            SecondContactArrayPropName={SecondContactArrayPropName}
            resPna={resPna}
          />,
          <FormVesselDetail
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            getValues={getValues}
          />,
          <FormEngineDetail
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            engineArrayPropName={engineArrayPropName}
          />,
          <FormUploadDoc
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            setError={setError}
            clearErrors={clearErrors}
            uploadSizeLimit={uploadSizeLimit}
            setValue={setValue}
          />,
          <FormApplicationComments register={register} />,
          <FormDeclarationLaw register={register} errors={errors} />,
        ]
      : [
          <FormIntro
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
          />,
          <FormOwnerDetail
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            clearErrors={clearErrors}
            isCompanyFieldName="isCompany"
            SecondContactArrayPropName={SecondContactArrayPropName}
            resPna={resPna}
          />,
          <FormVesselDetail
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            getValues={getValues}
          />,
          <FormEngineDetail
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            engineArrayPropName={engineArrayPropName}
          />,
          <FormUploadDoc
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            setError={setError}
            clearErrors={clearErrors}
            uploadSizeLimit={uploadSizeLimit}
            setValue={setValue}
          />,
          <FormApplicationComments register={register} />,
          <FormDeclarationLaw register={register} errors={errors} />,
          <FormPayProcess
            errPayWayFrame={errFrame}
            errPayWayToken={errToken}
            tokenId={tokenId}
            creditCard={creditCard}
            createFrame={createFrame}
            stateDestroy={stateDestroy}
            regoFee={resRegoFee}
            setValue={setValue}
          />,
        ];

  const {
    steps,
    currentStepIndex,
    stepContent,
    isFirstStep,
    isLastStep,
    back,
    next,
  } = useMultiStepForm(formContent);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStepIndex]);

  const [
    resFormPost,
    errorMsgFormPost,
    isLoadingFormPost,
    apiMultipartFormPost,
    abortFormPost,
  ] = useMultipartFormPost();
  // const [errPdf, loadingPdf, apiPdf, abortPdf] = usePdf();

  // const printBoatRegiPdf = async () => {
  //   console.log('print pdf');
  //   await apiPdf("/api/pcraft/boatregi-pdf/" + resFormPost, true);
  //   if (errPdf) console.log(errPdf);
  // };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    // console.log(data);
 
    if (
      data.file !== undefined &&
      data.file?.length <= 0 &&
      currentStepIndex === formContent.findIndex(f => f.type.name === "FormUploadDoc")
    ) {
      // console.log('file over size');
      setError("file", {
        type: "required",
        message: "Proof of Purchase is required.",
      });
      return;
    }

    if (data.file !== undefined && data.file[0]?.size > uploadSizeLimit) {
      // console.log('file over size');
      setError("file", {
        type: "oversize",
        message:
          "File is over the limit size (" +
          Math.floor(uploadSizeLimit / 1000000) +
          "MB).",
      });
      return;
    }

    if (
      data.nonTasFile !== undefined &&
      data.nonTasFile[0]?.size > uploadSizeLimit
    ) {
      // console.log('nonTasFile over size');
      setError("nonTasFile", {
        type: "oversize",
        message:
          "File is over the limit size (" +
          Math.floor(uploadSizeLimit / 1000000) +
          "MB).",
      });
      return;
    }

    if (!isLastStep) return next();

    if (!tokenId && !auth?.isBypassPayment) {
      requestToken();
    } else {
      //Uploading document
      // const formData = new FormData();
      // formData.append("file", data.file[0]);
      // formData.append("nonTasFile", data.nonTasFile[0]);
      let adrs = splitFullAddress(data.rb?.orgResAddress);

      await apiMultipartFormPost(
        "/api/pcraft/BoatReNewRegi",
        {
          SingleUseTokenId: data.payId,
          PcEpirb406Reg:
            data.isEPIRBwithAMSA === "Yes"
              ? "Y"
              : data.isEPIRBwithAMSA === "No"
              ? "N"
              : null,
          PcEpirb:
            data.hasEPIRB === "Yes" ? "Y" : data.hasEPIRB === "No" ? "N" : null,
          PcEpirbType: data.hasEPIRB === "Yes" ? data.epirbType : null,
          // HdLicNum: null,
          // PcRadarType: null,
          PcRadioOpLic:
            data.sortRadio.includes("HF") || data.sortRadio.includes("VHF")
              ? "Y"
              : "N",
          MoRegoNo: data.mooringRegoNo,
          PcPrevRegoNo:
            data.isCommercialPurpose === "Yes" ? data.comRegoNo : null,
          PcName: data.vesselName,
          PcClassCode: data.classVessel,
          PcClubNumber: data.sailNumber,
          PcClubName: data.clubName,
          PcLength: data.hullLength,
          PcBeam: data.beam,
          PcDraft: data.draft,
          PcNavLights:
            data.hasNaviLight === "Yes"
              ? "Y"
              : data.hasNaviLight === "No"
              ? "N"
              : null,
          PcHullCode:
            data.hullConstruction === "OTHER"
              ? data.otherDescHullConstr
              : data.hullConstruction,
          PcHullMake: data.manufacturerName,
          PcHullColour: data.hullColour,
          PcOtherColour: data.otherColour,
          PcRadioType: data.sortRadio.join(","),
          PcCallSign: data.vesselSign,
          PcSerialNo: data.hullSerialNo,
          PcDateManuf: data.yearOfMade,
          PcAbpFitted:
            data.hasBuilderPlate === "Yes"
              ? "Y"
              : data.hasBuilderPlate === "No"
              ? "N"
              : null,
          PcHin: (data.countryOfOrigin == '' || data.countryOfOrigin == null || data.hinRest == '' || data.hinRest == null) ? null : data.countryOfOrigin +"-"+ data.hinRest,
          PcStorage: data.whereVesselKept,
          PcStoragePc: data.pocodeOfStorage,
          PcExistingRegoNo:
            data.isVesselDiff === "Yes" ? data.existRegiNo : null,
          PcUsage: data.vesselUse,
          PcHullModel: data.model,
          PcFlyBridge:
            data.hasFlyBridge === "Yes"
              ? "Y"
              : data.hasFlyBridge === "No"
              ? "N"
              : null,
          PcHomemade:
            data.isVesselHomemade === "Yes"
              ? "Y"
              : data.isVesselHomemade === "No"
              ? "N"
              : null,
          PcBuilder: data.builderName,
          PcLengthOverall: data.hullLengthOverall,
          PcMarinaBerth: joinMarinaString(data.marinaName, data.marinaBerthNum),
          ProofOfPurchase: data.file?.length > 0 ? data.file[0] : null,
          ProofOfVesselOutSide:
            data.nonTasFile?.length > 0 ? data.nonTasFile[0] : null,
          SerializePeEngines: JSON.stringify(data.engineDetails),

          //PcTypeOverLength: data.describeVessel === "OTHER" ? data.otherDesc15mVessel : data.describeVessel,
          PcOwned3Mths:
            data.ownedVessel3mths == null
              ? null
              : data.ownedVessel3mths === "Yes"
              ? "Y"
              : "N",
          PcChangedLoc3Mths:
            data.chgLocationLast3mths == null
              ? null
              : data.chgLocationLast3mths === "Yes"
              ? "Y"
              : "N",
          // PcStorageNow: data.vesselKeptNow,
          PcMoveNewLoc3Mths:
            data.chgLocationNext3mths == null
              ? null
              : data.chgLocationNext3mths === "Yes"
              ? "Y"
              : "N",
          PcNewLocDetail: data.newLocation == null ? null : data.newLocation,
          PcUnseaworthyCond:
            data.vesselUnseaworthy == null
              ? null
              : data.vesselUnseaworthy === "Yes"
              ? "Y"
              : "N",

          SerializeSecondContacts:
            data.isCompany == "Individual" &&
            data.secondContactDetails?.length > 0
              ? JSON.stringify(data.secondContactDetails)
              : null,
          IsCompany: data.isCompany,
          OrgName: data.rb?.organisationName,
          OrgTypeNumber: data.rb?.orgTypeNumber,
          OrgType: data.rb?.orgType,
          OrgAdr1: adrs != null ? adrs[0]?.trim() : null,
          OrgAdr2: adrs != null ? adrs[1]?.trim() : null,
          OrgAdr3: adrs != null ? adrs[2]?.trim() : null,
          OrgPostcode: adrs != null ? adrs[3]?.trim() : null,
          OrgEmail: data.rb?.orgEmail,
          OrgMobile: data.rb?.orgMobile,
          ApplicationComments: data.appComments,
        },
        // formData,
        false,
        "/boatregistration/boatregisuccess",
        // printBoatRegiPdf
        gaEvents.RecreationalReNewRegistrationAddSuccess,
        gaEvents.RecreationalReNewRegistrationAddFail
      );
    }
  };

  return (
    <>
      {!isLoadingPna ? (
        !openDialog && !openFeeNotReadyDialog && !isLoadingFormPost ? (
          <div className="br-container">
            <div className="br-form-container">
              <form
                enctype="multipart/form-data"
                onSubmit={handleSubmit(onSubmit)}
              >
                {/* {currentStepIndex + 1} / {steps.length} */}
                <FormProgressBar
                  currentIndex={currentStepIndex}
                  totalSteps={steps}
                />
                <AlertMessage message={errorMsgFormPost} />
                {stepContent}
                <div className="br-btn-group">
                  <button
                    type="button"
                    onClick={back}
                    className="br-button"
                    disabled={isFirstStep}
                  >
                    Back
                  </button>
                  {
                    <button
                      type="submit"
                      className="br-button"
                      disabled={
                        isLastStep &&
                        (btnDisable || errorRegoFee == null ? false : true)
                      }
                    >
                      {isLastStep ? (!tokenId ? "Next" : "Pay") : "Next"}
                    </button>
                  }
                </div>
              </form>
            </div>
          </div>
        ) : (
          <SubmitLoader isLoading={isLoadingFormPost} />
        )
      ) : (
        <DataLoader isLoading={isLoadingPna} />
      )}

      {openDialog && (
        <Dialog
          setOpenDialog={setOpenDialog}
          title="ALERT"
          children="You have recently made changes to personal details. Some functions (Printing Certificates, New Vessel Registrations) are unavailable until MAST have confirmed these changes."
          redirectUrl="/recvessel"
        />
      )}

      {openFeeNotReadyDialog && (
        <Dialog
          setOpenDialog={setOpenFeeNotReadyDialog}
          title="ALERT"
          children="A system configuration error has occurred. Please contact MAST."
          redirectUrl="/recvessel"
        />
      )}

      {contactError && (
        <Dialog
          setOpenDialog={setContactError}
          title="ALERT"
          children="System cannot get login user contact, please retry again later or contact MAST."
          redirectUrl="/pna"
        />
      )}
    </>
  );
};

export default BoatReNewRegistrationProcess;
