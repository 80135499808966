import useMultiStepForm from "../../Hooks/useMultiStepForm";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import SubmitLoader from "../SubmitLoader";
import useAuth from "../../Hooks/useAuth";
import Dialog from "../Modal/Dialog";
import FormProgressBar from "../FormProgressBar";
import SaleVessel from "../Transferaction/SaleVessel";
import Buyer from "../Transferaction/Buyer";
import { usePost, useGet } from "../../Hooks/useFetch";
import { splitFullAddress } from "../../Helpers/Helper";
import dayjs from "dayjs";
import Modal from "../Modal/Modal";
import "../Transferaction/Transfer.css";
import useGaEvent from "../../Hooks/useGaEvent";
import AlertMessage from "../AlertMessage";
import SellerFormSellerContacts from "../Transferaction/SellerFormSellerContacts";

const TransferSellerForm = ({resSellers, errGetSeller, isLoadingSellers}) => {
  const uploadSizeLimit = 5242880; //5MB
  const [res, errPost, isPostLoading, apiPost, abt] = usePost();
  const [resVessels, errGetVessels, isLoadingVessels, apiGet, abort] = useGet();
//   const [resSellers, errGetSeller, isLoadingSellers, apiGetSellers, abtSeller] = useGet();
  const [openDialog, setOpenDialog] = useState(false);
  const [openVesselsDialog, setOpenVesselsDialog] = useState(false);
  const [openSelectVesselExpired3MonthsDialog, setOpenSelectVesselExpired3MonthsDialog] = useState(false);
  const [openSellersDialog, setOpenSellersDialog] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { auth } = useAuth();
  const gaEvents = useGaEvent();

  // console.log(auth);
  // console.log(resSellers);
  const isAbleRegister = () => {
    if (!auth?.isAblePrintOrRegister) {
      setOpenDialog(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    isAbleRegister();
    apiGet("/api/transfer/sellerPcCrafts");
    // apiGetSellers("/api/transfer/sellerTransferDetails");
  }, []);

  useEffect(() => {
    if (!isLoadingVessels && errGetVessels) {
      setOpenVesselsDialog(true);
    }
  }, [isLoadingVessels, errGetVessels]);

  useEffect(() => {
    if (!isLoadingSellers && errGetSeller) {
      setOpenSellersDialog(true);
    }
  }, [isLoadingSellers, errGetSeller]);

  let initialValues = {
    dateOfSale: null,
    saleVesselRego: "",

    buyer: {
      individualFile: [], //for individual buyer
      orgFile: [],
    },

    declareChecked: "",
    isCompany: "Individual",
  };

  if (process.env.REACT_APP_TEST_DEFAULT_VALUE === "on") {
    initialValues = {
      dateOfSale: dayjs("2022-10-05"),
      saleVesselRego: "23795",

      buyer: {
        individualFile: [], //for individual buyer
        orgFile: [],
      },

      declareChecked: true,
      isCompany: "Individual",
    };
  }

  const {
    control,
    register,
    formState: { errors, isDirty, isValid, dirtyFields },
    handleSubmit,
    watch,
    getFieldState,
    setValue,
    setError,
    clearErrors,
  } = useForm({ defaultValues: initialValues, mode: "onChange" });

  const {
    steps,
    currentStepIndex,
    stepContent,
    isFirstStep,
    isLastStep,
    back,
    next,
  } = useMultiStepForm([
    <SaleVessel
      register={register}
      watch={watch}
      control={control}
      errors={errors}
      getFieldState={getFieldState}
      vesselLists={resVessels}
      resBuyers={resSellers}
      SaleOrDisposal="Sale"
    />,
    <SellerFormSellerContacts
      watch={watch}
      PrimaryTitle={"Registered Operator (Seller)"}
      resSellers={resSellers}
    />,
    <Buyer
      register={register}
      watch={watch}
      control={control}
      errors={errors}
      getFieldState={getFieldState}
      setError={setError}
      clearErrors={clearErrors}
      uploadSizeLimit={uploadSizeLimit}
      resBuyers={resSellers}
      isCompanyFieldName="isCompany"
      setValue={setValue}
    />,
  ]);

  const onReject = async (data, e) => {
    e.preventDefault();
    // console.log(data);

    //Seller transfer Reject
    const formData = new FormData();
    formData.append("ClientAction", "Reject");
    formData.append("SellerId", resSellers[0]?.sellerId);
    await apiPost(
      "/api/transfer/sellerUpdatedTransfer",
      formData,
      false,
      "/transferseller/trasnsferdone",
      gaEvents.transferSellerRejectSuccess,
      gaEvents.transferSellerRejectFail
    );
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    // console.log(data);
    
    //Check selected vessel expired more than 3 months
    const selectVesselRes = resVessels?.find(({pcRegoNo}) => pcRegoNo === data.saleVesselRego);
    if(selectVesselRes != undefined)
    {
      if(selectVesselRes?.isVesselExpiredOverThan3Months === true)
      {
        setOpenSelectVesselExpired3MonthsDialog(true);
        return;
      }
    }

    //This is individual buyer file
    if (data.buyer?.individualFile[0]?.size > uploadSizeLimit) {
      // console.log('file over size');
      setError("buyer.individualFile", {
        type: "oversize",
        message:
          "File is over the limit size (" +
          Math.floor(uploadSizeLimit / 1000000) +
          "MB).",
      });
      return;
    }

    //This is org buyer file
    if (data.buyer?.orgFile[0]?.size > uploadSizeLimit) {
      // console.log('file over size');
      setError("buyer.orgFile", {
        type: "oversize",
        message:
          "File is over the limit size (" +
          Math.floor(uploadSizeLimit / 1000000) +
          "MB).",
      });
      return;
    }

    if (!isLastStep) return next();

    const formData = new FormData();

    //Seller transfer Approve
    if (resSellers != null && resSellers.length > 0) {
      formData.append("ClientAction", "Approve");
      formData.append("SellerId", resSellers[0]?.sellerId);
      await apiPost(
        "/api/transfer/sellerUpdatedTransfer",
        formData,
        false,
        "/transferseller/trasnsferdone",
        gaEvents.transferSellerApproveSuccess,
        gaEvents.transferSellerApproveFail
      );
    } //Seller transfer submit first
    else {
      formData.append("ClientAction", "Submit");
      Object.keys(data).forEach((key) => {
        if (key === "buyer") {
          Object.keys(data.buyer).forEach((k) => {
            if (k === "individualResAddress") {
              if (
                data.buyer?.individualResAddress == null ||
                data.buyer?.individualResAddress == "" ||
                data.buyer?.individualResAddress == undefined
              )
                return;
              let adrs = splitFullAddress(data.buyer?.individualResAddress);
              formData.append("IndividualAdr1", adrs[0]?.trim());
              formData.append("IndividualAdr2", adrs[1]?.trim());
              formData.append("IndividualAdr3", adrs[2]?.trim());
              formData.append("IndividualPostcode", adrs[3]?.trim());
            } else if (k === "orgResAddress") {
              if (
                data.buyer?.orgResAddress == null ||
                data.buyer?.orgResAddress == "" ||
                data.buyer?.orgResAddress == undefined
              )
                return;
              let adrs = splitFullAddress(data.buyer?.orgResAddress);
              formData.append("OrgAdr1", adrs[0]?.trim());
              formData.append("OrgAdr2", adrs[1]?.trim());
              formData.append("OrgAdr3", adrs[2]?.trim());
              formData.append("OrgPostcode", adrs[3]?.trim());
            } else if (k === "secondResAddress") {
              if (
                data.buyer?.secondResAddress == null ||
                data.buyer?.secondResAddress == "" ||
                data.buyer?.secondResAddress == undefined
              )
                return;
              let adrs = splitFullAddress(data.buyer?.secondResAddress);
              formData.append("secondAdr1", adrs[0]?.trim());
              formData.append("secondAdr2", adrs[1]?.trim());
              formData.append("secondAdr3", adrs[2]?.trim());
              formData.append("secondPostcode", adrs[3]?.trim());
            } else if (
              k === "individualDOB" &&
              data.buyer?.individualDOB &&
              data.buyer?.individualDOB !== undefined
            ) {
              formData.append(
                k,
                data.buyer?.individualDOB?.$d.getFullYear() +
                  "/" +
                  (data.buyer?.individualDOB?.$d.getMonth() + 1) +
                  "/" +
                  data.buyer?.individualDOB?.$d.getDate()
              );
            } else if (
              k === "secondDOB" &&
              data.buyer?.secondDOB &&
              data.buyer?.secondDOB !== undefined
            ) {
              formData.append(
                k,
                data.buyer?.secondDOB?.$d.getFullYear() +
                  "/" +
                  (data.buyer?.secondDOB?.$d.getMonth() + 1) +
                  "/" +
                  data.buyer?.secondDOB?.$d.getDate()
              );
            } else if (k === "individualFile" || k === "orgFile") {
              if (
                data.isCompany === "Individual" &&
                data.buyer?.individualFile[0]?.size > 0
              ) {
                // console.log('files over size');
                formData.append(
                  "CopiedTransferForm",
                  data.buyer?.individualFile[0]
                );
              }
              if (
                data.isCompany === "Organisation" &&
                data.buyer?.orgFile[0]?.size > 0
              ) {
                // console.log('files over size');
                formData.append("CopiedTransferForm", data.buyer?.orgFile[0]);
              }
              //console.log(formData.getAll("CopiedTransferForm")); // log all uploaded files
            } else {
              formData.append(k, data.buyer[k]);
            }
          });
        } else if (key === "dateOfSale") {
          formData.append(
            key,
            data.dateOfSale?.$d.getFullYear() +
              "/" +
              (data.dateOfSale?.$d.getMonth() + 1) +
              "/" +
              data.dateOfSale?.$d.getDate()
          );
        } else {
          formData.append(key, data[key]);
        }
      });
      await apiPost(
        "/api/transfer/sellerTransfer",
        formData,
        false,
        "/transferseller/trasnsferdone",
        gaEvents.transferSellerInitialSuccess,
        gaEvents.transferSellerInitialFail
      );
    }
  };

  return (
    <>
      {!openDialog && !isPostLoading && !openSelectVesselExpired3MonthsDialog ? (
        !isLoadingVessels && !isLoadingSellers ? (
          resVessels?.length > 0 ? (
            <div className="br-container">
              <div className="br-form-container">
                <form>
                  <div className="tsf-title-container unSelectable">
                    <h3>
                      TRANSFER OF A VESSEL – Seller to Complete{" "}
                      {resSellers != null && resSellers.length > 0
                        ? "(" + resSellers[0]?.pcRegoNo + ")"
                        : ""}
                    </h3>
                  </div>
                  <FormProgressBar
                    currentIndex={currentStepIndex}
                    totalSteps={steps}
                  />
                  <AlertMessage message={errPost} mt={0} />
                  {stepContent}
                  <div className="br-btn-group">
                    <button
                      type="button"
                      onClick={back}
                      className="br-button"
                      disabled={isFirstStep}
                    >
                      Back
                    </button>
                    <div className="tsf-btn-reject-gp">
                      {isLastStep &&
                      resSellers != null &&
                      resSellers.length > 0 ? (
                        <button
                          type="button"
                          className="tsf-button-reject"
                          onClick={() => setIsOpenModal(true)}
                        >
                          Reject
                        </button>
                      ) : (
                        ""
                      )}
                      <button
                        type="submit"
                        className="br-button"
                        onClick={handleSubmit(onSubmit)}
                      >
                        {isLastStep
                          ? resSellers != null && resSellers.length > 0
                            ? "Approve"
                            : "Submit"
                          : "Next"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <Dialog
              setOpenDialog={setOpenDialog}
              title="ALERT"
              children="There are 0 vessels can be transferred"
              redirectUrl="/pna"
            />
          )
        ) : (
          <SubmitLoader isLoading={isLoadingVessels} />
        )
      ) : (
        <SubmitLoader isLoading={isPostLoading} />
      )}

      {openDialog && (
        <Dialog
          setOpenDialog={setOpenDialog}
          title="ALERT"
          children="You have recently made changes to personal details. Some functions (Printing Certificates, New Vessel Registrations) are unavailable until MAST have confirmed these changes."
          redirectUrl="/recvessel"
        />
      )}

      {openVesselsDialog && (
        <Dialog
          setOpenDialog={setOpenVesselsDialog}
          title="ALERT"
          children="System cannot display vessel list information, please retry again later or contact MAST."
          redirectUrl="/recvessel"
        />
      )}

      {openSellersDialog && (
        <Dialog
          setOpenDialog={setOpenSellersDialog}
          title="ALERT"
          children="System cannot display transfer detail, please retry again later or contact MAST."
          redirectUrl="/recvessel"
        />
      )}

      {openSelectVesselExpired3MonthsDialog && (
        <Dialog
          setOpenDialog={setOpenSelectVesselExpired3MonthsDialog}
          title="ALERT"
          children="Transfer of this vessel is not allowed because the vessel registration expired more than 3 months ago. Please contact MAST."
          redirectUrl="/transferseller"
        />
      )}

      {isOpenModal && (
        <Modal
          setOpenModal={setIsOpenModal}
          title="Confirm"
          cancelBtn="No"
          submitBtn="Yes"
          children="Are you sure to reject this transaction?"
          submitClkEvent={handleSubmit(onReject)}
        />
      )}
    </>
  );
};

export default TransferSellerForm;
