import CryptoJS from "crypto-js";

export const validatedExpiredDate = (d) => {
  if (d == null || d == undefined) return false;
  let today = new Date().getTime();
  let exp = new Date(d).getTime();
  return exp < today;
};

export const formatHobart = (dateInt, addOffset = false) => {
  let date = !dateInt || dateInt.length < 1 ? new Date() : new Date(dateInt);
  if (typeof dateInt === "string") {
    return date;
  } else {
    // Set timezone offset for Hobart (UTC+10:00 during standard time, UTC+11:00 during daylight saving time)
    const offset = addOffset ? -600 : -660;
    const offsetDate = new Date();
    offsetDate.setTime(date.getTime() + offset * 60000);
    return offsetDate;
  }
};

export const validateMiniAge = (d) => {
  if(d == null || d == "")
    return true;
  let minAge = 16;
  let selected = new Date(d).getFullYear();
  let now = new Date().getFullYear();
  let yearsDiff = now - selected;
  return yearsDiff >= minAge ? true : "Minimum age is 16";
};

export const validatePickDateNotEarlierDateFrom = (dfrom, dto) => {
  if (dto == null || dto == "" || dto == undefined) return true;

  let datefrom = new Date(dfrom).getTime();
  let dateto = new Date(dto).getTime();

  return datefrom < dateto ? true : "Date From needs after Date To";
};

export const formatDDM = (n) => {
  if (n == null || n == undefined) return;
  let padNum = n.toFixed(3);
  if (Math.abs(padNum) < 100) {
    return padNum.toString();
  } else {
    let padNumStr = padNum.toString();
    let separate = padNumStr.indexOf(".") - 2;
    let firstPart = padNumStr.substring(0, separate);
    let secondPart = padNumStr.substring(separate, padNumStr.length);
    return firstPart + " " + secondPart;
  }
};

export const getddmmyyyyFormat = (yyyymmdd) => {
  if (yyyymmdd == null) return null;

  const [year, month, day] = yyyymmdd?.split("-");

  return [day, month, year].join("/");
};

export const getAuPhoneRegex = () => {
  //All phone include mobile
  // const phoneReg = "^(?:\\+61|0)([2-578]\\d{8})$";
  const phoneReg = "^(?:\\+61|0)([2-578][\\s]?\\d{1}\\d{1}[\\s]?\\d{2}[\\s]?\\d{1}[\\s]?\\d{1}[\\s]?\\d{2})$";
  return new RegExp(phoneReg);
}

export const getTelPhoneRegex = () => {
  //format: 0X XXXX XXXX or with or without space
  //Old: ^\(?\d{2}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}$
  // const telReg = "^(?:\\+61|0)([2378]\\d{8})$"
  const telReg = "^(?:\\+61|0)([2378][\\s]?\\d{4}[\\s]?\\d{4})$"
  return new RegExp(telReg);
}

export const getAuMobileRegex = () => {
  //Mobile format(allow space)
  // const mobileReg = "^[0]{1}[4-5]{1}[0-9]{2}[0-9]{3}[0-9]{3}$";
  // const mobileReg = "^(?:\\+61|0)([45]\\d{8})$";
  const mobileReg = "^(?:\\+61|0)([45]\\d{2}(\\s?\\d{3}){2})$";
  return new RegExp(mobileReg);
}

export const getYearRangeRegex = () => {
  const getThisYear = new Date().getFullYear().toString();
  const firstTwoDigit = parseInt(getThisYear.substring(0, 2));
  // console.log(firstTwoDigit);
  const third = parseInt(getThisYear.substring(2, 3));
  // console.log(third);
  const fourth = parseInt(getThisYear.substring(3, 4));
  // console.log(fourth);

  let yearRange = "^(";
  //From 1500 to nowadays
  for (let year = 15; year < firstTwoDigit; year++) {
    yearRange += year + "\\d\\d|";
  }

  for (let thirdDigitYear = 0; thirdDigitYear < third; thirdDigitYear++) {
    yearRange += firstTwoDigit + thirdDigitYear.toString() + "\\d|";
  }

  yearRange += firstTwoDigit + third.toString() + "[0-" + fourth + "])$";

  const reg = new RegExp(yearRange);
  // console.log(reg);

  return reg;
};

export const getFullCardNumber = (str) => {
  const markStar = str.replaceAll(".", "*");
  return (
    markStar.substring(0, 4) +
    " " +
    markStar.substring(4, 8) +
    " " +
    "****" +
    " " +
    markStar.substring(8, 12)
  );
};

export const joinMarinaString = (str1, str2, separator = ",") => {
  if(str1 === null || str1 === undefined || str1 === '')
    return null;

  if(str2 === null || str2 === undefined || str2 === '')
    return str1;

  return str1 + separator + str2;

}

export const splitFullAddress = (address) => {
  
  if (address === null || address === "" || address === undefined) return null;

  let ads = address.split(", ");
  if (ads.length > 4) {
    //it should only st, sub, state, postcode
    let newAds = [];
    let postcode = ads.pop(); //remove postcode
    let state = ads.pop(); //remove state
    let suburb = ads.pop(); //remove suburb
    let stRd = ads.join(", ");
    newAds.push(stRd);
    newAds.push(suburb);
    newAds.push(state);
    newAds.push(postcode);
    return newAds;
  }

  return ads;
};

export const tryPassInt = (str) => {
  let parsed = parseInt(str);
  if (isNaN(parsed)) {
    return -1;
  }
  return parsed;
};

export const maskEmail = (email) => {
  if (email == null || email == "") return;
  let parts = email.split("@");
  let firstPart = parts[0];
  let secondPart = parts[1];
  let domainLen = Math.floor(secondPart.length / 2);
  let domainPart1 = secondPart.substring(0, domainLen);
  let domainPart2 = secondPart.substring(domainLen);
  let skip = 3;

  let doneMaskEmail =
    maskInfo(firstPart, skip) +
    "@" +
    maskInfo(domainPart1, skip) +
    maskInfo(domainPart2, skip, false);
  // console.log(doneMaskEmail);
  return doneMaskEmail;
};

export const maskMobile = (mobile, splitSpace = false) => {
  if (mobile == null || mobile == "") return;
  //Mobile length is 10
  let partLen = Math.floor(mobile.length / 2);
  let firstPart = mobile.substring(0, partLen);
  let secondPart = mobile.substring(partLen);

  let doneMaskMobile = maskInfo(firstPart, 1) + maskInfo(secondPart, 3, false);

  if (!splitSpace) return doneMaskMobile;
  if (splitSpace)
    return (
      doneMaskMobile.substring(0, 4) +
      " " +
      doneMaskMobile.substring(4, 7) +
      " " +
      doneMaskMobile.substring(7, 10)
    );
};

export const formatMobile = (mobile) => {
  if (mobile == null || mobile == "") return;
  return (
    mobile.substring(0, 4) +
    " " +
    mobile.substring(4, 7) +
    " " +
    mobile.substring(7, 10)
  );
};

export const secureText = (msg, key, iv) => {

  if(msg == null || msg == '')
    return;

  var c_key = CryptoJS.enc.Utf8.parse(key);
  var c_iv = CryptoJS.enc.Utf8.parse(iv);
  var encryptData = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(msg), c_key, {
    keySize: 128 / 8,
    iv: c_iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();

  // console.log(encryptData);
  return encryptData;
};

export const decryptText = (msg, key, iv) => {
//   console.log(msg);

  if(msg == null || msg == '')
    return;

  var c_key = CryptoJS.enc.Utf8.parse(key);
  var c_iv = CryptoJS.enc.Utf8.parse(iv);
  var decryptData = CryptoJS.AES.decrypt(msg, c_key, {
    keySize: 128 / 8,
    iv: c_iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  var decrypt_final = CryptoJS.enc.Utf8.stringify(decryptData);

//   console.log(decrypt_final);
  return decrypt_final;
};

const maskInfo = (part, skip, front = true) => {
  if (part.length < skip) {
    //if skip is greater than part len, then skip will be 1/3 of part length
    skip = Math.floor(part.length / 3);
    // console.log(skip);
  }

  let encrypt = part.split("");

  for (let i = 0; i < encrypt.length; i += 1) {
    if (skip > 0) {
      skip--;
      continue;
    }
    if (skip === 0) {
      if (!front) {
        encrypt[encrypt.length - i - 1] = "*";
      } else {
        encrypt[i] = "*";
      }
    }
  }
  return encrypt.join("");
};

export const hinFormatCheck = (hin) => {
  
}
