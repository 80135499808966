import OrganisationContactReadOnly from "../Contacts/OrganisationContactReadOnly";
import PnaContactReadOnly from "../Contacts/PnaContactReadOnly";
import { useEffect, useState } from "react";
import { useGet } from "../../Hooks/useFetch";
import Dialog from "../Modal/Dialog";
import SubmitLoader from "../SubmitLoader";

const SellerFormSellerContacts = ({ watch, PrimaryTitle, resSellers}) => {
  const [oeLinks, errGetOeLinks, isLoadingOeLinks, apiGetOeLinks, abtOeLinks] =
    useGet();
  const [isPopulatedError, setIsPopulatedError] = useState(false);

  useEffect(() => {
    if(resSellers != null && resSellers?.length > 0)
    {
      apiGetOeLinks("/api/transfer/TransferVessel/" + resSellers[0]?.pcRegoNo, false);
    }else{
      apiGetOeLinks("/api/transfer/TransferVessel/" + watch("saleVesselRego"), false);
    }
  }, []);

  // console.log(errGetOeLinks);
  // console.log(oeLinks);

  useEffect(() => {
    if (!isLoadingOeLinks && errGetOeLinks) {
      setIsPopulatedError(true);
    }
  }, [isLoadingOeLinks, errGetOeLinks]);

  return (
    <>
      {!isLoadingOeLinks ? (
        oeLinks?.map((oe, index) => (
          <div key={index}>
            {oe?.pnaTypeCode == "O" ? (
              <OrganisationContactReadOnly title={PrimaryTitle} oeLink={oe} />
            ) : (
              oe?.oeContact == "Y" && (
                <PnaContactReadOnly title={PrimaryTitle} pna={oe} />
              )
            )}
            {oe?.pnaTypeCode == "I" && oe?.oeContact == "N" && (
              <PnaContactReadOnly title={oeLinks?.some(o => o['pnaTypeCode'] == "O") ? "Organisation Contact" : "Secondary Operator" } pna={oe} />
            )}
          </div>
        ))
      ) : (
        <SubmitLoader isLoading={isLoadingOeLinks} />
      )}

      {isPopulatedError && (
        <Dialog
          setOpenDialog={setIsPopulatedError}
          title="ALERT"
          children={"System cannot display operator(s) information("+ errGetOeLinks?.response?.data +"), please retry again later or contact MAST."}
          redirectUrl="/pna"
        />
      )}
    </>
  );
};

export default SellerFormSellerContacts;
