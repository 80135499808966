import BoatRegistrationProcess from "../../Components/BoatRegistration/BoatRegistrationProcess";
import { useGet } from "../../Hooks/useFetch";
import { useEffect } from "react";
import DataLoader from "../../Components/DataLoader";
import AlertMessage from "../../Components/AlertMessage";
import { decryptText } from "../../Helpers/Helper";

const BoatRegistrationForm = () => {
  const [resKey, err, isLoadingKey, apiGetKey, abtKey] = useGet();

  useEffect(() => {
    apiGetKey("/api/pcraft/PayWayKey");
  }, []);

  return (
    <>
      {!isLoadingKey ? (
        !err ? (
          <BoatRegistrationProcess payWayPublicKey={decryptText(resKey, process.env.REACT_APP_Encrypt_Key, process.env.REACT_APP_Encrypt_IV)} />
        ) : (
          <AlertMessage message={err} />
        )
      ) : (
        <DataLoader isLoading={isLoadingKey} />
      )}
    </>
  );
};

export default BoatRegistrationForm;
