import { getYearRangeRegex } from "../../Helpers/Helper";

const PeEngineInsertFields = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
}) => {
  return (
    <>
      <div className="recvessel-updated-field-group">
        <div className="row">
          <div className="col-md-6">
            <div
              className={
                errors.peSerialNo?.message
                  ? "recvessel-updated-input-group error"
                  : !getFieldState("peSerialNo").isDirty
                  ? "recvessel-updated-input-group"
                  : "recvessel-updated-input-group success"
              }
            >
              <label htmlFor="peSerialNo">Serial Number</label>
              <input type="text" id="peSerialNo" {...register("peSerialNo")} />
            </div>
          </div>
          <div className="col-md-6">
          <div
              className={
                getFieldState("peYearMade").invalid
                  ? "recvessel-updated-input-group error"
                  : !watch("peYearMade")
                  ? "recvessel-updated-input-group"
                  : "recvessel-updated-input-group success"
              }
            >
              <label htmlFor="peYearMade">Year of Manufacture</label>
              <input
                type="number"
                step="1"
                id="peYearMade"
                {...register("peYearMade", {
                  pattern: {
                    value: getYearRangeRegex(),
                    message: "Format needs to be correct & Year > 1499",
                  },
                })}
              />
              <span className="msg">{errors.peYearMade?.message}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div
              className={
                errors.pePropulsion?.message
                  ? "recvessel-updated-input-group error"
                  : !getFieldState("pePropulsion").isDirty
                  ? "recvessel-updated-input-group"
                  : "recvessel-updated-input-group success"
              }
            >
              <label htmlFor="pePropulsion">Propulsion<span className="text-danger">*</span></label>
              <select
                id="pePropulsion"
                {...register("pePropulsion", {
                  required: "Propulsion is required",
                })}
              >
                <option value="">Choose...</option>
                <option value="RB_OUTBOARD">Outboard</option>
                <option value="RB_INBOARD">Inboard</option>
                <option value="RB_STERNDRIVE">Sterndrive</option>
                <option value="RB_JET">Jet</option>
              </select>
              <span className="msg">{errors.pePropulsion?.message}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div
              className={
                errors.peFuel?.message
                  ? "recvessel-updated-input-group error"
                  : !getFieldState("peFuel").isDirty
                  ? "recvessel-updated-input-group"
                  : "recvessel-updated-input-group success"
              }
            >
              <label htmlFor="peFuel">Fuel<span className="text-danger">*</span></label>
              <select
                id="peFuel"
                {...register("peFuel", {
                  required: "Fuel is required",
                })}
              >
                <option value="">Choose...</option>
                <option value="RB_PETROL">Petrol</option>
                <option value="RB_DIESEL">Diesel</option>
                <option value="RB_ELECTRIC">Electric</option>
                <option value="RB_FUEL_OTHER">Other</option>
              </select>
              <span className="msg">{errors.peFuel?.message}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div
              className={
                errors.peMake?.message
                  ? "recvessel-updated-input-group error"
                  : !getFieldState("peMake").isDirty
                  ? "recvessel-updated-input-group"
                  : "recvessel-updated-input-group success"
              }
            >
              <label htmlFor="peMake">Make<span className="text-danger">*</span></label>
              <input
                type="text"
                id="peMake"
                {...register("peMake", {
                  required: "Engine made is required",
                })}
              />
              <span className="msg">{errors.peMake?.message}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div
              className={
                errors.peModel?.message
                  ? "recvessel-updated-input-group error"
                  : !getFieldState("peModel").isDirty
                  ? "recvessel-updated-input-group"
                  : "recvessel-updated-input-group success"
              }
            >
              <label htmlFor="peModel">Model</label>
              <input type="text" id="peModel" {...register("peModel")} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div
              className={
                errors.peCapacity?.message
                  ? "recvessel-updated-input-group error"
                  : !getFieldState("peCapacity").isDirty
                  ? "recvessel-updated-input-group"
                  : "recvessel-updated-input-group success"
              }
            >
              <label htmlFor="peCapacity">Engine Capacity<span className="text-danger">*</span></label>
              <input
                type="number"
                step=".01"
                id="peCapacity"
                {...register("peCapacity", {
                  required: "Engine capacity is required",
                })}
              />
              <span className="msg">{errors.peCapacity?.message}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div
              className={
                errors.peCapacityType?.message
                  ? "recvessel-updated-input-group error"
                  : !getFieldState("peCapacityType").isDirty
                  ? "recvessel-updated-input-group"
                  : "recvessel-updated-input-group success"
              }
            >
              <label htmlFor="peCapacityType">Unit of Measurement<span className="text-danger">*</span></label>
              <select
                id="peCapacityType"
                {...register("peCapacityType", {
                  required: "Unit of measure is required",
                })}
              >
                <option value="">Choose...</option>
                <option value="HP">HP</option>
                <option value="KW">KW</option>
                <option value="CC">CC</option>
              </select>
              <span className="msg">{errors.peCapacityType?.message}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div
              className={
                errors.peType?.message
                  ? "recvessel-updated-input-group error"
                  : !getFieldState("peType").isDirty
                  ? "recvessel-updated-input-group"
                  : "recvessel-updated-input-group success"
              }
            >
              <label htmlFor="peType">Type</label>
              <input type="text" id="peType" {...register("peType")} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PeEngineInsertFields;
