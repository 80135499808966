import { Outlet, useLocation } from "react-router-dom";
import '../Components/Mooring/Mooring.css';
import MooringList from "../Components/Mooring/MooringList";


const Mooring = () => {
 
    const location = useLocation();
    const path = '/mooring';
  
    return (
        <div className="mooring-container">
          {location.pathname === path ? (
            <>
              <div className="row">
                <div className="col-md-12">
                  <MooringList/>
                </div>
              </div>
            </>
          ) : (
            <Outlet />
          )}
        </div>
    );
  };
  
  export default Mooring;