import ManufacturerUpdated from "./ManufacturerUpdated";
import RadioDetailUpdated from "./RadioDetailUpdated";
import VesselBasicUpdated from "./VesselBasicUpdated";

const FormVesselUpdateDetail = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  clearErrors,
}) => {
  return (
    <>
      <VesselBasicUpdated
        register={register}
        watch={watch}
        control={control}
        errors={errors}
        clearErrors={clearErrors}
        getFieldState={getFieldState}
      />
      <ManufacturerUpdated
        register={register}
        watch={watch}
        control={control}
        errors={errors}
        getFieldState={getFieldState}
      />
      <RadioDetailUpdated
        register={register}
        watch={watch}
        control={control}
        errors={errors}
        getFieldState={getFieldState}
      />
    </>
  );
};

export default FormVesselUpdateDetail;
