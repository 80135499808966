import Manufacturer from "../VesselDetails/Manufacturer";
import VesselBasic from "../VesselDetails/VesselBasic";
import BuilderPlate from "../VesselDetails/BuilderPlate";
import RadioDetails from "../VesselDetails/RadioDetails";
// import VesselOverLength from "../VesselLengthOver15m/VesselOverLength";
import OwnerShipLocation from "../VesselLengthOver15m/OwnerShipLocation";
import EpirbPart from "../VesselDetails/EpirbPart";

const FormVesselDetail = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  getValues,
  isReRegisterCase,
  isBeamEditable,
}) => {
  return (
    <>
      <VesselBasic
        register={register}
        watch={watch}
        control={control}
        errors={errors}
        getFieldState={getFieldState}
        getValues={getValues}
        isReRegisterCase={isReRegisterCase}
      />
      <Manufacturer
        register={register}
        watch={watch}
        control={control}
        errors={errors}
        getFieldState={getFieldState}
        getValues={getValues}
        isReRegisterCase={isReRegisterCase}
        isBeamEditable={isBeamEditable}
      />
      {watch("hullLength") > 15 && (
        <>
          {/* <VesselOverLength
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
          /> */}
          <OwnerShipLocation
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
          />
        </>
      )}
      <EpirbPart
        register={register}
        watch={watch}
        control={control}
        errors={errors}
        getFieldState={getFieldState}
      />
      <BuilderPlate
        register={register}
        watch={watch}
        control={control}
        errors={errors}
        getFieldState={getFieldState}
        getValues={getValues}
        isReRegisterCase={isReRegisterCase}
      />
      <RadioDetails
        register={register}
        watch={watch}
        control={control}
        errors={errors}
        getFieldState={getFieldState}
      /> 
    </>
  );
};

export default FormVesselDetail;
