import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import { getddmmyyyyFormat } from "../../Helpers/Helper";
import { ViewIcon } from "../Icons";
import DataLoader from "../DataLoader";
import AlertMessage from "../AlertMessage";
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

const ComVesselPaymentList = ({ favSysId, favAmsaUvi }) => {
  const [res, err, isLoading, apiGet, abt] = useGet();

  useEffect(() => {
    apiGet("/api/fa/FaPayments/" + favSysId);
  }, []);

  // console.log(res);

  return (
    <div className="comvessel-sub-container">
      <div className="comvessel-title-group">
        <h3 className="comvessel-title">Payment History</h3>
      </div>

      {!isLoading ? (
        !err ? (
          res?.length === 0 ? (
            <AlertMessage
              message="No Records"
              level="info"
              closeEvt="false"
              mt="0"
              mb="0"
            />
          ) : (
            res?.map((f) => (
              <>
                <div className="row" key={uuidv4()}>
                  <div className="col-md-5">
                    <div className="comvessel-input-group">
                      <label htmlFor="paid">Date Paid</label>
                      <div className="comvesselInfoField">
                        {getddmmyyyyFormat(f?.fapDate?.split("T")[0])}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="comvessel-input-group">
                      <label htmlFor="amt">Amount</label>
                      <div className="comvesselInfoField">
                        {Math.abs(f?.fapAmt).toFixed(2)}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="comvessel-input-group">
                      <label htmlFor="action">Action</label>
                      <div className="icon-group">
                        <ViewIcon
                          url={
                            "/comvessel/comvesselpaymentdetails/" + f?.fapSysId + "/" + favAmsaUvi
                          }
                          HoverCaption="View Payment History"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))
          )
        ) : (
          <AlertMessage message={err}/>
        )
      ) : (
        <DataLoader isLoading={isLoading} />
      )}
    </div>
  );
};

export default ComVesselPaymentList;
