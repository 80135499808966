import QtySelect from "./QtySelect";
import NoImgAvailable from "../../Assets/no-image-available.png";
import AlertMessage from "../AlertMessage";

const Cart = ({ cartItems, removeItem, changeQtyFromCart }) => {
  //   console.log(cartItems);

  return (
    <>
      <div className="cart-selected-products-box">
        {cartItems?.length === 0 ? (
          <p className="cart-selected-products-empty">
            <AlertMessage
              message="Empty Cart"
              level="info"
              closeEvt="false"
              mt="0"
              mb="0"
            />
          </p>
        ) : (
          <>
            {cartItems.map((item) => (
              <div className="row" key={item.id}>
                <div className="col-sm-6">
                  <div className="row">
                    <div className="col-xs-4">
                      <div className="cart-img-box">
                        <img
                          className="cart-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/Images/" +
                            item.name +
                            item.imageExt
                          }
                          alt="NoImg"
                        />
                      </div>
                    </div>
                    <div className="col-xs-8 cart-product-inner-padding">
                      <div className="cart-product-name">{item.name}</div>
                      <div className="cart-product-desc">
                        {item.description}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 cart-product-inner-padding cart-qty-price-group">
                  <div className="">
                    <QtySelect
                      item={item}
                      changeEvt={(e) => changeQtyFromCart(item, e.target.value)}
                    />
                  </div>
                  <div className="">
                    <div className="cart-total-price-each">
                      ${(item.price * parseInt(item.qty)).toFixed(2)}
                    </div>
                    <div className="cart-price-each">
                      ${item.price.toFixed(2)} each
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 cart-product-inner-padding cart-remove-btn-box">
                  <button
                    onClick={() => removeItem(item)}
                    className="remove-btn"
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default Cart;
