import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import { getddmmyyyyFormat } from "../../Helpers/Helper";
import BackRedirect from "../BackRedirect";
import DataLoader from "../DataLoader";
import useGaEvent from "../../Hooks/useGaEvent";
import AlertMessage from "../AlertMessage";

const ComVesselPaymentDetails = () => {
  let { id, uvi } = useParams();
  const [res, err, isLoading, apiGet, abt] = useGet();
  const gaEvents = useGaEvent();

  useEffect(() => {
    apiGet("/api/fa/FaPaymentDetail/" + id);
    gaEvents.CommercialPaymentVisit();
  }, []);

  // console.log(res);

  return (
    <div className="comvessel-sub-container">
      <div className="comvessel-title-group">
        <h3 className="comvessel-title">Payment Details</h3>
      </div>

      {!isLoading ? (
        !err ? (
          <>
            <div className="row">
              <div className="col-md-4">
                <div className="comvessel-input-group">
                  <label htmlFor="AmsaUVI">AMSA UVI</label>
                  <div className="comvesselInfoField">{uvi}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="comvessel-input-group">
                  <label htmlFor="RenewNo">Renew No.</label>
                  <div className="comvesselInfoField">{res?.fapRenewNo}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="comvessel-input-group">
                  <label htmlFor="Source">Payment method</label>
                  <div className="comvesselInfoField">{res?.fapSource}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="comvessel-input-group">
                  <label htmlFor="DatePay">Date Paid</label>
                  <div className="comvesselInfoField">{getddmmyyyyFormat(res?.fapDate?.split("T")[0])}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="comvessel-input-group">
                  <label htmlFor="Amount">Amount</label>
                  <div className="comvesselInfoField">{Math.abs(res?.fapAmt).toFixed(2)}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="comvessel-input-group">
                  <label htmlFor="Gst">GST</label>
                  <div className="comvesselInfoField">{Math.abs(res?.fapGst).toFixed(2)}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="comvessel-input-group">
                  <label htmlFor="Ref">Reference</label>
                  <div className="comvesselInfoField">{res?.fapRef}</div>
                </div>
              </div>
            </div>
            <BackRedirect url={"/comvessel/comvesseldetails/" + res?.favSysId} />
          </>
        ) : (
          <AlertMessage message={err}/>
        )
      ) : (
        <DataLoader isLoading={isLoading} />
      )}
    </div>
  );
};

export default ComVesselPaymentDetails;
