import { Outlet, useLocation } from "react-router-dom";
import '../Components/ComVessel_FAV/ComVessel.css';
import ComVesselList from "../Components/ComVessel_FAV/ComVesselList";


const ComVessel = () => {
 
    const location = useLocation();
    const path = '/comvessel';
  
    return (
        <div className="comvessel-container">
          {location.pathname === path ? (
            <>
              <div className="row">
                <div className="col-md-12">
                  <ComVesselList />
                </div>
              </div>
            </>
          ) : (
            <Outlet />
          )}
        </div>
    );
  };
  
  export default ComVessel;