import { useRef, useEffect } from "react";
import { maskEmail, maskMobile, formatMobile } from "../../Helpers/Helper";
import useUserInfo from "../../Hooks/useUserInfo";
import "./VerifyCode.css";
import DataLoader from "../DataLoader";

const ChooseVerifyMethod = ({ handleSubmit, errMsg, isLoading }) => {
  const { userInfo, setUserInfo } = useUserInfo();
  const errRef = useRef();

  useEffect(() => {
    if (userInfo.email || userInfo.pwdEmail) {
      setUserInfo((prevData) => ({
        ...prevData,
        isSentByEmail: true,
      }));
    } else {
      setUserInfo((prevData) => ({
        ...prevData,
        isSentByEmail: false,
      }));
    }
  }, []);

  return (
    <>
      {!userInfo.mobile &&
      !userInfo.email &&
      !userInfo.pwdMobile &&
      !userInfo.pwdEmail ? (
        <div className="unable-choose-method-container">
          <div>
            Your account is unable to work out process because neither email or
            mobile is stored at MAST.
          </div>
          <div>
            In order to successful process, please contact MAST by calling 1300
            135 513 or email: admin@mast.tas.gov.au.
          </div>
        </div>
      ) : (
        <>
          <div className="choose-method-container">
            <h2 className="choose-method-title">Security Verification</h2>
            <div
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </div>
            <div className="choose-method-subtitle-container">
              <h4 className="choose-method-subtitle">
                To make sure that's it's really you, we'll send a verification
                code to your mobile number or email address.
              </h4>
            </div>
            <form onSubmit={handleSubmit} className="choose-method-form">
              {userInfo.email || userInfo.pwdEmail ? (
                <div className="choose-method-input-group">
                  <input
                    type="radio"
                    name="is_sent_by_email"
                    id="email"
                    value={
                      userInfo.maskInfo && userInfo.maskInfo === true
                        ? maskEmail(userInfo.email)
                        : userInfo.pwdEmail || userInfo.email
                    }
                    checked={userInfo.isSentByEmail}
                    onChange={() =>
                      setUserInfo((prevData) => ({
                        ...prevData,
                        isSentByEmail: true,
                      }))
                    }
                  />
                  <label htmlFor="email">
                    {"Email: " + userInfo.maskInfo && userInfo.maskInfo === true
                      ? maskEmail(userInfo.email)
                      : userInfo.pwdEmail || userInfo.email}
                  </label>
                </div>
              ) : (
                <></>
              )}
              {userInfo.mobile || userInfo.pwdMobile ? (
                <div className="choose-method-input-group">
                  <input
                    type="radio"
                    name="is_sent_by_mobile"
                    id="mobile"
                    value={
                      userInfo.maskInfo && userInfo.maskInfo === true
                        ? maskMobile(userInfo.mobile)
                        : userInfo.pwdMobile || userInfo.mobile
                    }
                    checked={!userInfo.isSentByEmail}
                    onChange={() =>
                      setUserInfo((prevData) => ({
                        ...prevData,
                        isSentByEmail: false,
                      }))
                    }
                  />
                  <label htmlFor="mobile">
                    {"Mobile: " + userInfo.maskInfo &&
                    userInfo.maskInfo === true
                      ? maskMobile(userInfo.mobile, true)
                      : formatMobile(userInfo.pwdMobile) ||
                        formatMobile(userInfo.mobile)}
                  </label>
                </div>
              ) : (
                <></>
              )}
              <button
                className="choose-method-button"
                disabled={
                  (!userInfo.mobile &&
                    !userInfo.email &&
                    !userInfo.pwdMobile &&
                    !userInfo.pwdEmail) ||
                  isLoading
                }
              >
                {isLoading ? <DataLoader isLoading={isLoading} /> : "Send code"}
              </button>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default ChooseVerifyMethod;
