import {
  faEye,
  faPenToSquare,
  faCirclePlus,
  faPlus,
  faPrint,
  faCircleExclamation
} from "@fortawesome/free-solid-svg-icons";
import { faCreditCard, faAddressCard, faCircleUser, faBuilding } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation } from "react-router-dom";
import RenewIconPNG from "./../Assets/renew.png";
import Tooltip from "@mui/material/Tooltip";
import BtnClickLoader from "./BtnClickLoader";

export const CompanyContactIcon = ({HoverCaption = "Corporate"}) => {
  const iconStyle = {
    fontSize:"1rem"
  };
  return (
    <span>
      <Tooltip title={HoverCaption} placement="top" arrow>
        <FontAwesomeIcon
          icon={faBuilding}
          style={iconStyle}
          className="icon"
        />
      </Tooltip>
    </span>
  );
}

export const PersonContactIcon = ({HoverCaption = "Individual"}) => {
  const iconStyle = {
    fontSize:"1rem"
  };
  return (
    <span>
      <Tooltip title={HoverCaption} placement="top" arrow>
        <FontAwesomeIcon
          icon={faCircleUser}
          style={iconStyle}
          className="icon"
        />
      </Tooltip>
    </span>
  );
}

export const RegisterOperatorIcon = ({HoverCaption = "Registered Operator"}) => {
  const iconStyle = {
    fontSize:"1rem"
  };
  return (
    <span>
      <Tooltip title={HoverCaption} placement="top" arrow>
        <FontAwesomeIcon
          icon={faAddressCard}
          style={iconStyle}
          className="icon"
        />
      </Tooltip>
    </span>
  );
}

export const ErrorIcon = ({HoverCaption = "error"}) => {
  const iconStyle = {
    color:"#e51515",
    fontSize:"1.7rem"
  };
  return (
    <div>
      <Tooltip title={HoverCaption} placement="top" arrow>
        <FontAwesomeIcon
          icon={faCircleExclamation}
          style={iconStyle}
          className="icon"
        />
      </Tooltip>
    </div>
  );
}

export const RenewIcon = () => {
  const iconStyle = {
    maxWidth: "42px",
    height: "auto",
  };

  return (
    <>
      <div className="tooltip-icon">
        <img
          src={RenewIconPNG}
          // className="beat-effect"
          alt="renew"
          style={iconStyle}
        />
        <span className="tooltip-text-icon">Renew</span>
      </div>
    </>
  );
};

export const PaymentIcon = ({ url }) => {
  const iconStyle = {
    "--fa-beat-fade-opacity": "0.67",
  };
  const linkStyle = {
    color: "#00508f",
  };
  return (
    <>
      <div className="tooltip-icon">
        <a href={url} target="_blank" style={linkStyle} rel="noreferrer">
          <FontAwesomeIcon
            icon={faCreditCard}
            className="icon"
            size="xl"
            // beatFade
            style={iconStyle}
          />
        </a>
        <span className="tooltip-text-icon">Renew</span>
      </div>
    </>
  );
};

export const ViewIcon = ({ url, HoverCaption = "View Details" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const iconStyle = {
    paddingRight: "10px",
  };
  return (
    <div>
      <Tooltip title={HoverCaption} placement="top" arrow>
        <FontAwesomeIcon
          icon={faEye}
          style={iconStyle}
          className="icon"
          size="lg"
          onClick={() =>
            navigate(url, {
              state: { from: location },
              replace: false,
            })
          }
        />
      </Tooltip>
    </div>
  );
};

export const AddIcon = ({
  url,
  iconSize = "lg",
  isBeat = "false",
  clickEvent = null,
  HoverCaption = "Add new",
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const iconStyle = {
    color: "rgb(0, 170, 255)",
  };
  return (
    <div>
      <Tooltip title={HoverCaption} placement="top" arrow>
        <FontAwesomeIcon
          icon={faCirclePlus}
          className="icon"
          style={iconStyle}
          size={iconSize}
          beat={isBeat}
          onClick={() => {
            if (!clickEvent || clickEvent()) {
              navigate(url, {
                state: { from: location },
                replace: false,
              });
            }
          }}
        />
      </Tooltip>
    </div>
  );
};

export const AddButton = ({ url, captions = "Add", clickEvent = null }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const btnStyle = {
    backgroundColor: "rgb(0, 170, 255)",
  };
  const captionStyle = {
    fontSize: "1rem",
  };
  return (
    <div>
      <button
        type="button"
        onClick={() => {
          if (!clickEvent || clickEvent()) {
            navigate(url, {
              state: { from: location },
              replace: false,
            });
          }
        }}
        style={btnStyle}
      >
        <FontAwesomeIcon
          icon={faPlus}
          className="icon"
          size="lg"
          // style={iconStyle}
        />{" "}
        <span style={captionStyle}>{captions}</span>
      </button>
    </div>
  );
};

export const EditIcon = ({ url, iconSize = "lg", HoverCaption = "Edit" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const iconStyle = {
    paddingRight: "8px",
  };
  return (
    <div>
      <Tooltip title={HoverCaption} placement="top" arrow>
        <FontAwesomeIcon
          icon={faPenToSquare}
          style={iconStyle}
          className="icon"
          size={iconSize}
          onClick={() =>
            navigate(url, {
              state: { from: location },
              replace: false,
            })
          }
        />
      </Tooltip>
    </div>
  );
};

export const EditClickEventIcon = ({
  clickEvent,
  iconSize = "lg",
  HoverCaption = "Edit",
}) => {
  const iconStyle = {
    paddingRight: "8px",
  };
  return (
    <div>
      <Tooltip title={HoverCaption} placement="top" arrow>
        <FontAwesomeIcon
          icon={faPenToSquare}
          style={iconStyle}
          className="icon"
          size={iconSize}
          onClick={clickEvent}
        />
      </Tooltip>
    </div>
  );
};

export const PrintIcon = ({
  clickEvent,
  iconSize = "1x",
  HoverCaption = "Print",
  classLists,
  isLoading = false,
}) => {
  return !isLoading ? (
    <Tooltip title={HoverCaption} placement="top" arrow>
      <FontAwesomeIcon
        icon={faPrint}
        className={classLists}
        size={iconSize}
        onClick={clickEvent}
      />
    </Tooltip>
  ) : (
    <BtnClickLoader isLoading={isLoading} />
  );
};
