const BusinessType = ({
  register,
  watch,
  getFieldState,
  isCompanyFieldName,
}) => {
  return (
    <div className="">
      <div
        className={
          getFieldState(`${isCompanyFieldName}`).invalid
            ? "tsf-radio-container br-input-group error"
            : !watch(`${isCompanyFieldName}`)
            ? "tsf-radio-container br-input-group"
            : "tsf-radio-container br-input-group success"
        }
      >
        <label>
          Is this Vessel owned by a Business/Company?
          <span className="text-danger">*</span>
        </label>
        <div className="tsf-radio-group">
          <div className="tsf-radio-field">
            <input
              type="radio"
              value="Individual"
              id="Individual"
              {...register(`${isCompanyFieldName}`, {
                required: {
                  value: true,
                  message: "This is required",
                },
              })}
            />
            <label htmlFor="Individual" className="unSelectable">
              Individual
            </label>
          </div>
          <div className="tsf-radio-field">
            <input
              type="radio"
              value="Organisation"
              id="Organisation"
              {...register(`${isCompanyFieldName}`, {
                required: {
                  value: true,
                  message: "This is required",
                },
              })}
            />
            <label htmlFor="Organisation" className="unSelectable">
              Organisation
            </label>
          </div>
        </div>
        <span className="msg">
          {getFieldState(`${isCompanyFieldName}`).error?.message}
        </span>
      </div>
    </div>
  );
};

export default BusinessType;
