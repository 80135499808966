import LoginForm from "../../Components/Login/LoginForm";
import "../../Components/Login/LoginForm.css";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <>
      <div className="login-container-bg">
        <div className="login-container">
          <LoginForm />
          <div className="login-links">
            <div>Need an Account?</div>
            <div>
              <Link to="/register">Register with MAST</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
