import { useState } from "react";

const usePayWay = (publicKey) => {

  const [tokenId, setTokenId] = useState(null);
  const [creditCardFrame, setCreditCardFrame] = useState(null);
  const [creditCard, setCreditCard] = useState(null);
  const [errFrame, setErrFrame] = useState(null);
  const [errToken, setErrToken] = useState(null);
  const [btnDisable, setBtnDisable] = useState(false);

  const createdCallback = (err, frame) => {
    if (err) {
      if(err.message !== undefined)
      {
        // console.error("Error creating frame: " + err.message);
        setErrFrame(err.message);
        setBtnDisable(true);
      }
      
    } else {
      // Save the created frame for when we get the token
      setCreditCardFrame(frame);
      setBtnDisable(false);
      // console.log("frame", frame);
    }
  };

  const options = {
    // TODO: Replace {publishableApiKey} with your key
    publishableApiKey: publicKey,
    tokenMode: "callback",
    onValid: () => {
      // console.log('valid');
      setBtnDisable(false);
    },
    onInvalid: () => {
      // console.log('invalid');
      setBtnDisable(true);
    },
    threeDS2: false,
    layout: window.innerWidth > 768? 'wide': 'narrow',
  };

  const frameDestroy = () => {
    creditCardFrame.destroy();
    setCreditCardFrame(null);
  }

  const stateDestroy = () => {
    setErrFrame(null);
    setTokenId(null);
    setCreditCard(null);
    setErrToken(null);
  }

  const tokenCallback = async (err, data) => {
    
    if (err) {
      // console.error("Error getting token: " + err.message);
      setErrToken(err.message);
      setBtnDisable(true);
    } else {
      // console.log(data);
      // console.log("singleUseTokenId: " + data.singleUseTokenId);
      // TODO: send token to server with ajax
      setCreditCard(data.creditCard);
      setTokenId(data.singleUseTokenId);

    }
  };

  const requestToken = () => {
    setBtnDisable(true);
    creditCardFrame && creditCardFrame.getToken(tokenCallback);
    setBtnDisable(false);
  }

  const createFrame = () => {
    if(!window.payway)
    {
      throw new Error('payway not working');
    }else{
      window.payway.createCreditCardFrame(options, createdCallback);
    }
  }

    
    return {
        errFrame, //err message when frame is not created
        errToken, //err message when toke is not created
        tokenId, //single token id
        creditCard, //credit card 
        btnDisable,//button disable status
        frameDestroy, //it is called after send to server
        requestToken, // fc to get token
        createFrame, // fc to create card frame
        stateDestroy, //clean states,
    }
}
 
export default usePayWay;