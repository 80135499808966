import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import { getddmmyyyyFormat } from "../../Helpers/Helper";
import BackRedirect from "../BackRedirect";
import DataLoader from "../DataLoader";
import useGaEvent from "../../Hooks/useGaEvent";
import AlertMessage from "../AlertMessage";

const LicencePaymentDetails = () => {
  let { regoNo, sequNo } = useParams();

  const [res, err, isLoading, apiGet, abt] = useGet();
  const gaEvents = useGaEvent();

  useEffect(() => {
    apiGet("/api/licence/DlPayment/" + regoNo+"/"+sequNo);
    gaEvents.licencePaymentVisit();
  }, []);

  // console.log(regoNo);
  // console.log(sequNo);
  // console.log(res);

  return (
    <div className="licence-sub-container">
      <div className="licence-title-group">
        <h3 className="licence-title">Payment Details</h3>
      </div>

      {!isLoading ? (
        !err ? (
          <>
            <div className="row">
              <div className="col-md-4">
                <div className="licence-input-group">
                  <label htmlFor="DlRegoNo">Rego No.</label>
                  <div className="licenceInfoField">{regoNo}</div>
                </div>
              </div>
              {/* <div className="col-md-4">
                <div className="licence-input-group">
                  <label htmlFor="SequNo">Seq No.</label>
                  <div className="licenceInfoField">{res?.dlSequNo}</div>
                </div>
              </div> */}
              <div className="col-md-4">
                <div className="licence-input-group">
                  <label htmlFor="RenewNo">Renew No.</label>
                  <div className="licenceInfoField">{res?.dlRenewNo}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="licence-input-group">
                  <label htmlFor="Source">Payment Method</label>
                  <div className="licenceInfoField">{res?.dlSource}</div>
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-md-4">
                <div className="licence-input-group">
                  <label htmlFor="PayType">Pay Type</label>
                  <div className="licenceInfoField">{res?.dlType}</div>
                </div>
              </div> */}
              <div className="col-md-6">
                <div className="licence-input-group">
                  <label htmlFor="DatePay">Date Paid</label>
                  <div className="licenceInfoField">{getddmmyyyyFormat(res?.dlDate?.split("T")[0])}</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="licence-input-group">
                  <label htmlFor="Amount">Amount</label>
                  <div className="licenceInfoField">{Math.abs(res?.dlAmt).toFixed(2)}</div>
                </div>
              </div>
            </div>
            <div className="row">
            <div className="col-md-12">
                <div className="licence-input-group">
                  <label htmlFor="Ref">Reference</label>
                  <div className="licenceInfoField">{res?.dlRef}</div>
                </div>
              </div>
              {/* <div className="col-md-4">
                <div className="licence-input-group">
                  <label htmlFor="Run">Run</label>
                  <div className="licenceInfoField">{res?.dlRunId}</div>
                </div>
              </div> */}
            </div>
            <BackRedirect url={"/licence"} />
          </>
        ) : (
          <AlertMessage message={err}/>
        )
      ) : (
        <DataLoader isLoading={isLoading} />
      )}
    </div>
  );
};

export default LicencePaymentDetails;
