import { Controller, useWatch } from "react-hook-form";
import { validateMiniAge, getAuMobileRegex } from "../../Helpers/Helper";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect } from "react";

const RegistrationInputFields = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  clearErrors,
}) => {
  // console.log(errors);

  let email = useWatch({
    control,
    name: "email",
  });
  let mobile = useWatch({
    control,
    name: "mobile",
  });
  useEffect(() => {
    if (email) {
      clearErrors("mobile");
    }

    if (mobile) {
      clearErrors("email");
    }
  }, [email, mobile]);

  const content = (
    <>
      <div className="row">
        <div className="col-md-12 register-notification">
          <span>
            Only existing MAST clients can register for access to the online
            portal at present. Please contact MAST if you have any questions
            about registration.
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 field-gap">
          <div
            className={
              errors.firstName?.message
                ? "register-form-input-group error"
                : !getFieldState("firstName").isDirty
                ? "register-form-input-group"
                : "register-form-input-group success"
            }
          >
            <label htmlFor="firstName">
              Given Name(s)<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="firstName"
              {...register("firstName", {
                required: "First Name(s) is required",
                pattern: {
                  value: /^[A-Za-z-.&]{0,}(\s[A-Z][A-Za-z-.&]{0,})*$/,
                  message:
                    "Number, special characters and last space are not allowed. Only (- . &) allowed",
                },
                maxLength: {
                  value: 36,
                  message: "Max length is 36",
                },
              })}
            />
            <span className="msg">{errors.firstName?.message}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div
            className={
              errors.surName?.message
                ? "register-form-input-group error"
                : !getFieldState("surName").isDirty
                ? "register-form-input-group"
                : "register-form-input-group success"
            }
          >
            <label htmlFor="surName">
              Surname<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="surName"
              {...register("surName", {
                required: "Surname is required",
                pattern: {
                  value: /^[A-Za-z-.\s&]{0,}$/,
                  message:
                    "Number and special characters are not allowed. Only (- . &) allowed",
                },
                maxLength: {
                  value: 60,
                  message: "Max length is 60",
                },
              })}
            />
            <span className="msg">{errors.surName?.message}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-7 field-gap">
          <div
            className={
              errors.street?.message
                ? "register-form-input-group error"
                : !getFieldState("street").isDirty
                ? "register-form-input-group"
                : "register-form-input-group success"
            }
          >
            <label htmlFor="street">
              Street<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="street"
              {...register("street", {
                required: "Street is required",
                maxLength: {
                  value: 30,
                  message: "Max length is 30",
                },
              })}
            />
            <span className="msg">{errors.street?.message}</span>
          </div>
        </div>
        <div className="col-md-5">
          <div
            className={
              errors.suburb?.message
                ? "register-form-input-group error"
                : !getFieldState("suburb").isDirty
                ? "register-form-input-group"
                : "register-form-input-group success"
            }
          >
            <label htmlFor="suburb">
              Suburb<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="suburb"
              {...register("suburb", {
                required: "Suburb is required",
                maxLength: {
                  value: 30,
                  message: "Max length is 30",
                },
              })}
            />
            <span className="msg">{errors.suburb?.message}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 field-gap">
          <div
            className={
              !getFieldState("state").isDirty
                ? "register-form-input-group"
                : "register-form-input-group success"
            }
          >
            <label htmlFor="state">
              State<span className="text-danger">*</span>
            </label>
            <select id="state" {...register("state")}>
              <option value="TAS">Tasmania</option>
              <option value="ACT">Australian Capital Territory</option>
              <option value="QU">Queensland</option>
              <option value="WA">Western Australia</option>
              <option value="NT">Northern Territory</option>
              <option value="SA">South Australia</option>
              <option value="NSW">New South Wales</option>
              <option value="VIC">Victoria</option>
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div
            className={
              errors.postCode?.message
                ? "register-form-input-group error"
                : !getFieldState("postCode").isDirty
                ? "register-form-input-group"
                : "register-form-input-group success"
            }
          >
            <label htmlFor="postCode">
              PostCode<span className="text-danger">*</span>
            </label>
            <input
              type="number"
              id="postCode"
              {...register("postCode", {
                required: "Postcode is required",
                pattern: {
                  value: /^[0-9]{4}$/,
                  message: "4 digit number allowed",
                },
              })}
            />
            <span className="msg">{errors.postCode?.message}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 field-gap">
          <div
            className={
              errors.mobile?.message
                ? "register-form-input-group error"
                : !getFieldState("mobile").isDirty
                ? "register-form-input-group"
                : "register-form-input-group success"
            }
          >
            <label htmlFor="mobile">
              Mobile
              {watch("email") ? "" : <span className="text-danger">*</span>}
            </label>
            <input
              type="tel"
              id="mobile"
              {...register("mobile", {
                required: {
                  value: watch("email") ? false : true,
                  message: "Email or Mobile is required",
                },
                pattern: {
                  value: getAuMobileRegex(),
                  message: "Mobile format error",
                },
              })}
              placeholder="04XX XXX XXX"
            />
            <span className="msg">{errors.mobile?.message}</span>
          </div>
        </div>
        <div className="col-md-8">
          <div
            className={
              errors.email?.message
                ? "register-form-input-group error"
                : !getFieldState("email").isDirty
                ? "register-form-input-group"
                : "register-form-input-group success"
            }
          >
            <label htmlFor="email">
              Email
              {watch("mobile") ? "" : <span className="text-danger">*</span>}
            </label>
            <input
              type="email"
              id="email"
              {...register("email", {
                required: {
                  value: watch("mobile") ? false : true,
                  message: "Email or Mobile is required",
                },
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  message: "Email format error",
                },
                maxLength: {
                  value: 60,
                  message: "Max length is 60",
                },
              })}
            />
            <span className="msg">{errors.email?.message}</span>
          </div>
        </div>
        <div className="col-md-12 register-mobile-email-caption">
          <span>
            You can enter either your mobile number or email address (or both).
            Mobile number and email address entered here must match MAST records
            to successfully complete your registration.
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5">
          <div
            className={
              errors.dOB?.message
                ? "register-form-input-group error"
                : !getFieldState("dOB").isDirty
                ? "register-form-input-group"
                : "register-form-input-group success"
            }
          >
            <label htmlFor="dOB">
              Date of Birth<span className="text-danger">*</span>
            </label>
            <Controller
              control={control}
              name="dOB"
              render={({ field: { onChange, value } }) => {
                return <DatePicker value={value} onChange={onChange} />;
              }}
              rules={{
                required: "Date of Birth is required",
                validate: {
                  greaterThanSixteen: (d) => validateMiniAge(d),
                  min: (v) =>
                    new Date(v) >= new Date(1900, 0, 1) ||
                    "Selected year must be greater than 1900",
                  max: (v) =>
                    new Date(v) <= new Date() ||
                    "Selected date must be less than today",
                },
              }}
              errors={errors}
            />
            <span className="msg">{errors.dOB?.message}</span>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-12">
          <div className="register-form-input-group">
            <label>Postal Address</label>
            <div className="register-form-input-group-checkbox">
              <input
                type="checkbox"
                id="checkPostal"
                {...register("checkPostal")}
              />
              <label htmlFor="checkPostal">Same as residential address</label>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className={!watch("checkPostal") ? "show row" : "hide"}>
        <div className="col-md-7 field-gap">
          <div
            className={
              errors.postalStreet?.message
                ? "register-form-input-group error"
                : !getFieldState("postalStreet").isDirty
                ? "register-form-input-group"
                : "register-form-input-group success"
            }
          >
            <label htmlFor="postalStreet">
              Postal Street
              {!watch("checkPostal") ? (
                <span className="text-danger">*</span>
              ) : (
                ""
              )}
            </label>
            <input
              type="text"
              id="postalStreet"
              {...register("postalStreet", {
                required: {
                  value: !watch("checkPostal"),
                  message: "Postal street is required",
                },
              })}
            />
            <span className="msg">{errors.postalStreet?.message}</span>
          </div>
        </div>
        <div className="col-md-5">
          <div
            className={
              errors.postalSuburb?.message
                ? "register-form-input-group error"
                : !getFieldState("postalSuburb").isDirty
                ? "register-form-input-group"
                : "register-form-input-group success"
            }
          >
            <label htmlFor="postalSuburb">
              Postal Suburb
              {!watch("checkPostal") ? (
                <span className="text-danger">*</span>
              ) : (
                ""
              )}
            </label>
            <input
              type="text"
              id="postalSuburb"
              {...register("postalSuburb", {
                required: {
                  value: !watch("checkPostal"),
                  message: "Postal Suburb is required",
                },
              })}
            />
            <span className="msg">{errors.postalSuburb?.message}</span>
          </div>
        </div>
      </div>
      <div className={!watch("checkPostal") ? "show row" : "hide"}>
        <div className="col-md-8 field-gap">
          <div
            className={
              !getFieldState("postalState").isDirty
                ? "register-form-input-group"
                : "register-form-input-group success"
            }
          >
            <label htmlFor="postalState">
              Postal State
              {!watch("checkPostal") ? (
                <span className="text-danger">*</span>
              ) : (
                ""
              )}
            </label>
            <select id="postalState" {...register("postalState")}>
              <option value="TAS">Tasmania</option>
              <option value="ACT">Australian Capital Territory</option>
              <option value="QU">Queensland</option>
              <option value="WA">Western Australia</option>
              <option value="NT">Northern Territory</option>
              <option value="SA">South Australia</option>
              <option value="NSW">New South Wales</option>
              <option value="VIC">Victoria</option>
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div
            className={
              errors.postalPostCode?.message
                ? "register-form-input-group error"
                : !getFieldState("postalPostCode").isDirty
                ? "register-form-input-group"
                : "register-form-input-group success"
            }
          >
            <label htmlFor="postalPostCode">
              Postal PostCode
              {!watch("checkPostal") ? (
                <span className="text-danger">*</span>
              ) : (
                ""
              )}
            </label>
            <input
              type="number"
              id="postalPostCode"
              {...register("postalPostCode", {
                required: {
                  value: !watch("checkPostal"),
                  message: "Postal Suburb is required",
                },
                pattern: {
                  value: /^[0-9]{4}$/,
                  message: "4 digit number allowed",
                },
              })}
            />
            <span className="msg">{errors.postalPostCode?.message}</span>
          </div>
        </div>
      </div> */}

      <div className="row">
        <div className="col-md-12 register-additional-info-title">
          <label>
            To assist in the registration process you can optionally check the
            boxes below for items you hold with MAST and enter the details
            (licence/rego number) as shown on your licence, registration papers
            or payment notices. It is not mandatory to complete these items.
          </label>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="register-form-input-group-checkbox register-form-input-group-checkbox-rego">
            <input
              type="checkbox"
              id="hasDlLicence"
              {...register("hasDlLicence")}
            />
            <label htmlFor="hasDlLicence" className="unSelectable">
              Motor Boat Licence No
            </label>
          </div>
        </div>
        <div className="col-md-6 ">
          <div
            className={
              errors.dlLicenceNo?.message
                ? "register-form-rego-input-group error"
                : !getFieldState("dlLicenceNo").isDirty
                ? "register-form-rego-input-group"
                : "register-form-rego-input-group success"
            }
          >
            <div
              className={
                watch("hasDlLicence") ? "inline-show fullWidth" : "hide"
              }
            >
              <input
                type="text"
                id="dlLicenceNo"
                placeholder="Licence No."
                {...register("dlLicenceNo", {
                  required: {
                    value: watch("hasDlLicence"),
                    message: "Licence is required if ticked",
                  },
                  maxLength: {
                    value: 8,
                    message: "Max length is 8",
                  },
                })}
              />
              <span className="msg">{errors.dlLicenceNo?.message}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="register-form-input-group-checkbox register-form-input-group-checkbox-rego">
            <input
              type="checkbox"
              id="hasPwcRego"
              {...register("hasPwcRego")}
            />
            <label htmlFor="hasPwcRego" className="unSelectable">
              Motor Boat Registration No
            </label>
          </div>
        </div>
        <div className="col-md-6 ">
          <div
            className={
              errors.pcRegoNo?.message
                ? "register-form-rego-input-group error"
                : !getFieldState("pcRegoNo").isDirty
                ? "register-form-rego-input-group"
                : "register-form-rego-input-group success"
            }
          >
            <div
              className={watch("hasPwcRego") ? "inline-show fullWidth" : "hide"}
            >
              <input
                type="text"
                id="pcRegoNo"
                placeholder="Registration No."
                {...register("pcRegoNo", {
                  required: {
                    value: watch("hasPwcRego"),
                    message: "Motor Boat Rego is required if ticked",
                  },
                  maxLength: {
                    value: 8,
                    message: "Max length is 8",
                  },
                })}
              />
              <span className="msg">{errors.pcRegoNo?.message}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="register-form-input-group-checkbox register-form-input-group-checkbox-rego">
            <input type="checkbox" id="hasMoRego" {...register("hasMoRego")} />
            <label htmlFor="hasMoRego" className="unSelectable">
              Mooring Permit Number
            </label>
          </div>
        </div>
        <div className="col-md-6 ">
          <div
            className={
              errors.moRegoNo?.message
                ? "register-form-rego-input-group error"
                : !getFieldState("moRegoNo").isDirty
                ? "register-form-rego-input-group"
                : "register-form-rego-input-group success"
            }
          >
            <div
              className={watch("hasMoRego") ? "inline-show fullWidth" : "hide"}
            >
              <input
                type="text"
                id="moRegoNo"
                placeholder="Permit No."
                {...register("moRegoNo", {
                  required: {
                    value: watch("hasMoRego"),
                    message: "Mooring Permit No is required if ticked",
                  },
                  maxLength: {
                    value: 8,
                    message: "Max length is 8",
                  },
                })}
              />
              <span className="msg">{errors.moRegoNo?.message}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="register-form-input-group-checkbox register-form-input-group-checkbox-rego">
            <input
              type="checkbox"
              id="hasFavAmsaUVI"
              {...register("hasFavAmsaUVI")}
            />
            <label htmlFor="hasFavAmsaUVI" className="unSelectable">
              Commercial Vessel UVI
            </label>
          </div>
        </div>
        <div className="col-md-6 ">
          <div
            className={
              errors.favAmsaUVI?.message
                ? "register-form-rego-input-group error"
                : !getFieldState("favAmsaUVI").isDirty
                ? "register-form-rego-input-group"
                : "register-form-rego-input-group success"
            }
          >
            <div
              className={
                watch("hasFavAmsaUVI") ? "inline-show fullWidth" : "hide"
              }
            >
              <input
                type="text"
                id="pwdFavRegoNo"
                placeholder="UVI"
                {...register("favAmsaUVI", {
                  required: {
                    value: watch("hasFavAmsaUVI"),
                    message: "AMSA UVI is required if ticked",
                  },
                  maxLength: {
                    value: 16,
                    message: "Max length is 16",
                  },
                })}
              />
              <span className="msg">{errors.favAmsaUVI?.message}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return content;
};

export default RegistrationInputFields;
