import { useNavigate, useLocation } from "react-router-dom";

const BoatReRegiSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="br-container">
        <div className="br-form-container">
            <div className="br-box">
            <div className="br-title">Re-Application Successful</div>
            <p>Thank you for your application and payment. </p>
            <p>
                <b>
                Please note that your registration is not confirmed and the vessel
                should not be used until the application has been received and
                processed by MAST.
                </b>
            </p>
            <div className="br-btn-group-success-page">
                <button
                type="button"
                onClick={() =>
                    navigate("/recvessel/recvessellist", {
                    state: { from: location },
                    replace: false,
                    })
                }
                className="br-button"
                >
                Return to Vessel List
                </button>
            </div>
            </div>
        </div>
        </div>
    );
}
 
export default BoatReRegiSuccess;