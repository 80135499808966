// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { getddmmyyyyFormat } from "../../Helpers/Helper";

const SaleVessel = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  vesselLists,
  resBuyers,
  SaleOrDisposal
}) => {
  //   console.log(vesselLists);
  // console.log(resBuyers);

  return (
    <>
      <div className="br-box">
        <div className="br-title">Details of Vessel</div>
        {resBuyers != null && resBuyers?.length > 0 ? (
          <div className="tsf-field-group">
            <div className="br-input-group">
              <label htmlFor="dateOfSale">Date Of {SaleOrDisposal}</label>
              <div className="br-field-readonly unSelectable">
                {getddmmyyyyFormat(resBuyers[0]?.dateOfSale?.split("T")[0])}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="saleVesselRego">Vessel Rego</label>
              <div className="br-field-readonly unSelectable">
                {resBuyers[0]?.pcRegoNo}
              </div>
            </div>
            {vesselLists &&
              vesselLists?.map((p, index) =>
                p?.pcRegoNo === resBuyers[0]?.pcRegoNo ? (
                  <>
                    <div className="row" key={index}>
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="vesselName">Vessel</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcName}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="serialNum">Serial No.</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcSerialNo}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="br-input-group">
                          <label htmlFor="hin">Hull Identification No.</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcHin}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="make">Make</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcHullMake}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="model">Model</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcHullModel}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="colour">Colour</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcHullColour}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="otherColour">Other Colour</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcOtherColour}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="length">Length (Metres)</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcLength}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="lengthOverall">
                            Length Overall(Metres)
                          </label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcLengthOverall}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="beam">Beam (Metres)</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcBeam}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="draft">Draft (Metres)</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcDraft}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )
              )}
          </div>
        ) : (
          <div className="tsf-field-group">
            <div
              className={
                errors.dateOfSale?.message
                  ? "br-input-group error"
                  : !getFieldState("dateOfSale").isDirty
                  ? "br-input-group"
                  : "br-input-group success"
              }
            >
              <label htmlFor="dateOfSale">
                Date Of {SaleOrDisposal}<span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="dateOfSale"
                render={({ field: { onChange, value } }) => {
                  return (
                    <DatePicker
                      value={value}
                      onChange={onChange}
                      defaultValue={dayjs()}
                      disableFuture
                      views={["year", "month", "day"]}
                    />
                  );
                }}
                rules={{
                  required: "Sale/Disposal date is required",
                  validate: {
                    min: (v) =>
                      new Date(v) >= new Date(1900, 0, 1) ||
                      "Selected year must be greater than 1900",
                    max: (v) =>
                      new Date(v) <= new Date() ||
                      "Selected date must be less than today",
                  },
                }}
                errors={errors}
              />
              <span className="msg">{errors.dateOfSale?.message}</span>
            </div>
            <div
              className={
                errors.saleVesselRego?.message
                  ? "br-input-group error"
                  : "br-input-group"
              }
            >
              <label htmlFor="saleVesselRego">
                Vessel Rego<span className="text-danger">*</span>
              </label>
              <select
                id="saleVesselRego"
                {...register("saleVesselRego", {
                  required: "This is required",
                })}
              >
                <option value="">Choose...</option>
                {vesselLists &&
                  vesselLists?.map((p) => (
                    <option
                      value={p?.pcRegoNo}
                      // selected={p?.pcRegoNo}
                    >
                      {p?.pcRegoNo}
                    </option>
                  ))}
              </select>
              <span className="msg">{errors.saleVesselRego?.message}</span>
            </div>
            {vesselLists &&
              vesselLists?.map((p, index) =>
                p?.pcRegoNo === watch("saleVesselRego") ? (
                  <>
                    <div className="row" key={index}>
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="vesselName">Vessel</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcName}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="serialNum">Serial No.</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcSerialNo}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="br-input-group">
                          <label htmlFor="hin">Hull Identification No.</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcHin}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="make">Make</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcHullMake}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="model">Model</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcHullModel}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="colour">Colour</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcHullColour}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="otherColour">Other Colour</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcOtherColour}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="length">Length (Metres)</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcLength}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="lengthOverall">
                            Length Overall (Metres)
                          </label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcLengthOverall}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="beam">Beam (Metres)</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcBeam}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="br-input-group">
                          <label htmlFor="draft">Draft (Metres)</label>
                          <div className="br-field-readonly unSelectable">
                            {p?.pcDraft}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )
              )}
          </div>
        )}
      </div>
    </>
  );
};

export default SaleVessel;
