import { useEffect, useState } from "react";
import { useGet } from "../../Hooks/useFetch";
import { getddmmyyyyFormat, validatedExpiredDate } from "../../Helpers/Helper";
import { ViewIcon, EditIcon, AddButton, AddIcon } from "../Icons";
import DataLoader from "../DataLoader";
import useAuth from "../../Hooks/useAuth";
import AlertMessage from "../AlertMessage";
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";
import NewBoatPopupMessage from "../BoatRegistration/NewBoatPopupMessage";

const RecVesselList = () => {
  const [res, err, isLoading, apiGet, abt] = useGet();
  const { auth } = useAuth();
  const [openDialog, setOpenDialog] = useState(false);
  const [newBoatMsgOpen, setNewBoatMsgOpen] = useState(false);
  const [dialogMsg, setDialogMsg] = useState('');

  useEffect(() => {
    apiGet("/api/pcraft/PcCraftList");
  }, []);

  const checkPopupMsg = () => {
    if(auth?.isAblePrintOrRegister === true)
    {
      setNewBoatMsgOpen(true);
      setOpenDialog(false);
    }else
    {
      setNewBoatMsgOpen(false);
      setOpenDialog(true);
      setDialogMsg("You have recently made changes to personal details. Some functions (Printing Certificates, New Vessel Registrations) are unavailable until MAST have confirmed these changes.");
    }

    if(auth?.isPnaIdVerified === true) {
      setNewBoatMsgOpen(true);
      setOpenDialog(false);
    }else {
      setNewBoatMsgOpen(false);
      setOpenDialog(true);
      setDialogMsg("Your account with MAST is not yet Verified. You will be unable to complete any transactions involving boat registration until you provide identity information to Service Tasmania or MAST.");
    }
  }

  // console.log(res);

  return (
    <>
      <div className="recvessel-sub-container">
        <div className="recvessel-title-group">
          <h3 className="recvessel-title">Recreational Vessels</h3>

          <span className="hidden-add-rec-less-650">
            <AddButton
              url="#"
              captions="Vessel"
              clickEvent={checkPopupMsg}
            />
          </span>
          <span className="show-add-rec-less-650">
            <AddIcon
              url="#"
              iconSize="2x"
              isBeat="false"
              HoverCaption="Add New Vessel"
              clickEvent={checkPopupMsg}
            />
          </span>
        </div>

        {!isLoading ? (
          !err ? (
            res?.length === 0 ? (
              <AlertMessage
                message="No Recreational Vessels"
                level="info"
                closeEvt="false"
                mt="0"
                mb="0"
              />
            ) : (
              res?.map((d) => (
                <>
                  <div className="row" key={uuidv4()}>
                    <div className="col-md-2">
                      <div className="recvessel-input-group">
                        <label htmlFor="">Pc Rego</label>
                        <div className="recvesselInfoField">{d?.pcRegoNo}</div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="recvessel-input-group">
                        <label htmlFor="">PC Name</label>
                        <div className="recvesselInfoField">{d?.pcName}</div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="recvessel-input-group">
                        <label htmlFor="">Expiry</label>
                        <div className="recvesselInfoField">
                          {getddmmyyyyFormat(d?.pcExpiryDate?.split("T")[0])}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="recvessel-input-group">
                        <label htmlFor="">Status</label>
                        <div className="recvesselInfoField">
                          {validatedExpiredDate(d?.pcExpiryDate) ? (
                            <span className="text-danger">EXPIRED</span>
                          ) : d?.pcStatus == null ? (
                            <span className="text-danger">Unknown</span>
                          ) : (
                            d?.pcStatusDesc
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="recvessel-input-group">
                        <label htmlFor="Action">Action</label>
                        <div className="icon-group">
                          {d?.isVesselViewAble == true ? (
                            <ViewIcon
                              url={"/recvessel/recvesseldetails/" + d?.pcRegoNo}
                              HoverCaption="View Recreational Vessel"
                            />
                          ) : (
                            ""
                          )}
                          {d?.isVesselEditable == true ? (
                            <EditIcon
                              url={"/recvessel/recvesselupdated/" + d?.pcRegoNo}
                              HoverCaption="Edit Recreational Vessel"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))
            )
          ) : (
            <AlertMessage message={err} />
          )
        ) : (
          <DataLoader isLoading={isLoading} />
        )}
      </div>

      <NewBoatPopupMessage
        isOpen={newBoatMsgOpen}
        setIsOpen={setNewBoatMsgOpen}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        dialogMsg={dialogMsg}
      />
    </>
  );
};

export default RecVesselList;
