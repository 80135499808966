import "./ActivityLog.css";
import DataLoader from "../DataLoader";
import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import AlertMessage from "../AlertMessage";
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

const ActivityLog = () => {
  const [res, err, isLoading, apiGet, abt] = useGet();

  useEffect(() => {
    apiGet("/api/pna/Activity-log");
  }, []);

  // console.log(res);
  // console.log(err);

  return (
    <div className="act-sub-container ">
      <div className="act-title-group">
        <h3 className="act-title">Activity Log</h3>
      </div>
      <div>
        {!isLoading ? (
          !err ? (
            res?.length === 0 ? (
              <AlertMessage
                message="No Activity Events"
                level="info"
                closeEvt="false"
                mt="0"
                mb="0"
              />
            ) : (
              <>
                <div className="act-scroll-screen-y">
                  <div className="row ">
                    <div className="col-md-12">
                      <div className="act-input-group">
                        {res?.map((a) => (
                          <div className="actInfoField" key={uuidv4()}>
                            {a?.date} {a?.time} - {a?.desc} - {a?.status}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          ) : (
            <AlertMessage message={err} />
          )
        ) : (
          <DataLoader isLoading={isLoading} />
        )}
      </div>
    </div>
  );
};

export default ActivityLog;
