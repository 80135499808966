import "./Subscriptions.css";
import { useGet, usePost } from "../../Hooks/useFetch";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import DataLoader from "../DataLoader";
import useGaEvent from "../../Hooks/useGaEvent";
import useToast from "../../Hooks/useToast";
import AlertMessage from "../AlertMessage";

const Subscriptions = () => {
  const [response, error, isLoadingGet, apiGetSub, abort] = useGet();
  const [res, err, isLoadingPost, apiPost, abt] = usePost();
  const gaEvents = useGaEvent();
  const toastEmitter = useToast();

  useEffect(() => {
    apiGetSub("/api/pna/PnaSubscriptions");
  }, []);

  // console.log(response);

  const updateSub = () => {
    apiGetSub("/api/pna/PnaSubscriptions");
    gaEvents.pnaSubscriptionSuccess();
    toastEmitter("Subscription is successful updated!", "success");
  };

  const { register, handleSubmit, watch, getFieldState, setValue } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (response) {
      const tickedValues = response
        ?.filter((res) => res?.isTicked === true)
        .map((res) => res?.codeId.toString());
      setValue("subscribeCbx", tickedValues);
    }
  }, [response]);

  // console.log(tickedValues);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    // console.log(data);

    await apiPost(
      "/api/pna/updateSubscriptions",
      data,
      false,
      null,
      updateSub,
      gaEvents.pnaSubscriptionFail
    );
  };

  return (
    <>
      <div className="pna-subscriptions-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pna-subscriptions-title-group">
            <h3 className="pna-subscriptions-title">Subscriptions</h3>
            <button className="pna-subscriptions-save-button">Save</button>
          </div>
          <AlertMessage message={err} />
          {isLoadingGet ? (
            <DataLoader isLoading={isLoadingGet} />
          ) : isLoadingPost === true ? (
            <DataLoader isLoading={isLoadingPost} />
          ) : (
            response?.map((res) => (
              <div key={res.codeId} className="row">
                <div className="col-md-12">
                  <div className="pna-subscriptions-checkbox-group">
                    <input
                      type="checkbox"
                      id={res.codeNo}
                      {...register("subscribeCbx")}
                      value={res.codeId}
                    />
                    <label htmlFor={res.codeNo} className="unSelectable">
                      {res.codeDescr}
                    </label>
                  </div>
                </div>
              </div>
            ))
          )}
        </form>
      </div>
    </>
  );
};

export default Subscriptions;
