import React from "react";
import "./Dialog.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation } from "react-router-dom";

const Dialog = ({ setOpenDialog, title, children, redirectUrl }) => {
    const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <div className="dialogBackground">
        <div className="dialogContainer">
          <div className="dialog-header">
            <div className="dialog-title">{title}</div>
            <div className="titleCloseBtn">
              <FontAwesomeIcon
                icon={faXmark}
                className="xmark-icon"
                size="2x"
                onClick={() => {
                    setOpenDialog(false);
                    if(redirectUrl)
                    {
                        navigate(redirectUrl, {
                            state: { from: location },
                            replace: false,
                        })
                    }
                }}
              />
            </div>
          </div>
          <div className="dialog-body">{children}</div>
        </div>
      </div>
    </>
  );
}
export default Dialog;
