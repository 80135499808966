import React from "react";
import "./Modal.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Modal = ({ setOpenModal, title, children, cancelBtn="Cancel", submitBtn="Submit", submitClkEvent=null, cancelClkEvent = null}) => {
  
  const handleCancelClick = () => {
    if (cancelClkEvent) {
      cancelClkEvent();
    }
    setOpenModal(false);
  };

  return (
    <>
      <div className="modalBackground">
        <div className="modalContainer">
          <div className="modal-header">
            <div className="modal-title">{title}</div>
            <div className="titleCloseBtn">
              <FontAwesomeIcon
                icon={faXmark}
                className="xmark-icon"
                size="2x"
                onClick={() => {
                  setOpenModal(false);
                }}
              />
            </div>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            <button
              onClick={handleCancelClick}
              className="cancelBtn"
              type="button"
            >
              {cancelBtn}
            </button>
            <button
              className="submit"
              onClick={submitClkEvent}
            >
              {submitBtn}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
