import { useFieldArray } from "react-hook-form";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SecondContact from "./SecondContact";
import PnaContactReadOnly from "./PnaContactReadOnly";

const SecondContacts = ({
  register,
  watch,
  getFieldState,
  errors,
  clearErrors,
  control,
  isCompanyFieldName,
  SecondContactArrayPropName,
  resPna,
}) => {
  const initialSecondContact = {
    // secondGivenName: "",
    // secondSurName: "",
    // secondDOB: "",
    // secondResAddress: "",
    // secondCheckPostal: false,
    // secondPoAddress: "",
    // secondEmail: "",
    // secondMobile: "",
  };


  const { fields, append, update, remove } = useFieldArray({
    control,
    name: SecondContactArrayPropName,
    defaultValues: {
      [SecondContactArrayPropName]: [],
    },
  });

  return (
    <>
      <div className="">
        {watch(`${isCompanyFieldName}`) === "Organisation" ? (
          //   if user is owned by company then user goes for secondary contact
          <PnaContactReadOnly title="Organisation Contact" pna={resPna} />
        ) : (
          <>
            {fields.map((field, index) =>
              index < 1 ? (
                <div key={field.id} className="">
                  <SecondContact
                    control={control}
                    index={index}
                    SecondContactArrayPropName={SecondContactArrayPropName}
                    register={register}
                    errors={errors}
                    clearErrors={clearErrors}
                    watch={watch}
                    getFieldState={getFieldState}
                    remove={remove}
                  />
                </div>
              ) : (
                ""
              )
            )}

            {fields?.length == 0 && (
              <div className="br-add-engine-box">
                <span>Click to add secondary operator</span>
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  className="br-engine-add-icon"
                  size="2x"
                  beat
                  onClick={() => {
                    append();
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SecondContacts;
