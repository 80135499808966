
const Introduction = () => {
  return (
    <>
      <div className="br-box">
        <div className="br-title">Introduction</div>
        <p>
          Please read the following requirements and privacy statement before continuing.
        </p>
        <p>An application for registration is not complete until the applicable fees are paid and the required proof of purchase along with any other information that may be required are supplied to MAST.</p>
        <p>To complete your registration application on-line please ensure you have all relevant information (e.g. boat serial number, engine serial number(s) etc) as the application cannot be saved and returned to prior to final lodgement.</p>
        <p>The applicable registration fee is payable on completing the online form by credit card.</p>
      </div>
    </>
  );
};

export default Introduction;
