import { axiosApi } from '../Api/Axios';
import useAuth from '../Hooks/useAuth';

const useLogout = () => {
    const { setAuth } = useAuth();

    const logout = async () => {
        // if used in more components, this should be in context 
        await axiosApi.get('/api/auth/sign-out', {
            withCredentials: true
        }).then(() => {
            setAuth({});
        }).catch((err) => {
            // console.log(err);
        });
    }

    return logout;
}

export default useLogout;