import PnaDetails from "../Components/Pna/PnaDetails";
import PnaHoldings from "../Components/Pna/PnaHoldings";
import Subscriptions from "../Components/Pna/Subscriptions";
import ActivityLog from "../Components/Pna/ActivityLog";

const Home = () => {
    
    return (
        <>
            <div className="home-container">
                <div className="row">
                    <div className="col-md-8">
                        <PnaDetails/>
                        <Subscriptions/>
                        <ActivityLog/>
                    </div>
                    <div className="col-md-4">
                        <PnaHoldings />
                    </div>
                </div>
                
            </div>
        </>
    );
};

export default Home;