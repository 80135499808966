import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CapsLockTooltip = ({ children, isCapsLockOn }) => {

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
            popper: {
                // backgroundColor: "#f5f5f9",
            },
            tooltip:{
                backgroundColor: "#FFFF8A",
                marginBottom: "5px !important" ,

            },
        },
      },
    },
  });

  const titleStyle = {
    fontSize: ".9rem",
    color: "#282120",
  };

  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        title={<div style={titleStyle} className="unSelectable"><FontAwesomeIcon icon={faExclamation} className="text-danger"/> Caps Lock is ON</div>}
        open={isCapsLockOn}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 800 }}
        placement="top-end"
        
      >
        {children}
      </Tooltip>
    </ThemeProvider>
  );
};

export default CapsLockTooltip;
