import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn, faToggleOff } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useGet } from "../../Hooks/useFetch";
import { ViewIcon, EditIcon, AddButton } from "../Icons";
import DataLoader from "../DataLoader";
import AlertMessage from "../AlertMessage";
import Tooltip from "@mui/material/Tooltip";
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";

const PeEngineList = ({ regoNo, isCreateOrEditAble }) => {
  const [res, err, isLoading, apiGet, abt] = useGet();
  const [showOnlyCurrent, setShowOnlyCurrent] = useState(true); //circle is show all, dot is only current

  useEffect(() => {
    apiGet("/api/pcraft/PeEnginesList/" + regoNo);
  }, []);

  // console.log(res);

  return (
    <>
      <div className="recvessel-sub-container">
        <div className="recvessel-title-group">
          <h3
            className="recvessel-title unSelectable tooltip-2"
            onClick={() => setShowOnlyCurrent(!showOnlyCurrent)}
          >
            Engines &nbsp;
            <FontAwesomeIcon
              icon={showOnlyCurrent ? faToggleOff : faToggleOn}
              className="recvessel-add-icon"
            />
            <span className="tooltip-text-2">
              {showOnlyCurrent ? "Show All" : "Only Current"}
            </span>
          </h3>
          {isCreateOrEditAble == true && (
            <AddButton
              url={"/recvessel/peengineinsert/" + regoNo}
              captions="Engine"
            />
          )}
        </div>

        {!isLoading ? (
          !err ? (
            res?.length === 0 ? (
              <AlertMessage
                message="No Records"
                level="info"
                closeEvt="false"
                mt="0"
                mb="0"
              />
            ) : (
              <>
                {res?.map((e) =>
                  showOnlyCurrent ? (
                    e?.peCurrent === "Y" && (
                      <>
                        {e?.status == 0 || e?.status == 2 || e?.status == 3 ? (
                          <div className="statusReason-format text-danger">
                            ({e?.statusReason})
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="row" key={uuidv4()}>
                          <div className="col-md-5">
                            <div className="recvessel-input-group">
                              {e?.status == 0 ||
                              e?.status == 2 ||
                              e?.status == 3 ? (
                                <>
                                  <Tooltip
                                    title={e?.statusReason}
                                    placement="top-start"
                                  >
                                    <label htmlFor="desc" className="tooltip">
                                      DESC
                                    </label>
                                  </Tooltip>
                                  <span>{e?.statusReason}</span>
                                </>
                              ) : (
                                <>
                                  <label htmlFor="desc">DESC</label>
                                </>
                              )}

                              <div className="recvesselInfoField">
                                <span>
                                  {e?.peMake?.trim()}-{e?.peCapacity}
                                  {e?.peCapacityType}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="recvessel-input-group">
                              <label htmlFor="">Current</label>
                              <div className="recvesselInfoField">
                                {e?.peCurrent}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="recvessel-input-group">
                              <label htmlFor="Action">Action</label>
                              <div className="icon-group">
                                <ViewIcon
                                  url={
                                    "/recvessel/peenginedetails/" +
                                    regoNo +
                                    "/" +
                                    e?.peSequNo
                                  }
                                  HoverCaption="View Engine Details"
                                />
                                {isCreateOrEditAble == true && (
                                  <EditIcon
                                    url={
                                      "/recvessel/peengineupdated/" +
                                      regoNo +
                                      "/" +
                                      e?.peSequNo
                                    }
                                    HoverCaption="Edit Engine Details"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  ) : (
                    <>
                      {e?.status == 0 || e?.status == 2 || e?.status == 3 ? (
                        <div className="statusReason-format  text-danger">
                          ({e?.statusReason})
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="row" key={uuidv4()}>
                        <div className="col-md-5">
                          <div className="recvessel-input-group">
                            {e?.status == 0 ||
                            e?.status == 2 ||
                            e?.status == 3 ? (
                              <Tooltip
                                title={e?.statusReason}
                                placement="top-start"
                              >
                                <label htmlFor="desc" className="tooltip">
                                  DESC
                                </label>
                              </Tooltip>
                            ) : (
                              <label htmlFor="desc">DESC</label>
                            )}
                            <div className="recvesselInfoField">
                              <span>
                                {e?.peMake?.trim()}-{e?.peCapacity}
                                {e?.peCapacityType}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="recvessel-input-group">
                            <label htmlFor="">Current</label>
                            <div className="recvesselInfoField">
                              {e?.peCurrent}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="recvessel-input-group">
                            <label htmlFor="Action">Action</label>
                            <div className="icon-group">
                              <ViewIcon
                                url={
                                  "/recvessel/peenginedetails/" +
                                  regoNo +
                                  "/" +
                                  e?.peSequNo
                                }
                              />
                              {isCreateOrEditAble == true && (
                                <EditIcon
                                  url={
                                    "/recvessel/peengineupdated/" +
                                    regoNo +
                                    "/" +
                                    e?.peSequNo
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                )}
                {/* <span className="red-text-explain">
                  <span className="text-danger">
                    <b>* Red DESC:</b>
                  </span>{" "}
                  Not confirmed / reject / more docs required from MAST.
                </span> */}
              </>
            )
          ) : (
            <AlertMessage message={err} />
          )
        ) : (
          <DataLoader isLoading={isLoading} />
        )}
      </div>
    </>
  );
};

export default PeEngineList;
