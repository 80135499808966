import { useEffect } from "react";
import useGaEvent from "../../Hooks/useGaEvent";

const UnidentifiedUser = ({ RegistrationNo, From, FailReason = null }) => {
  const gaEvents = useGaEvent();
  useEffect(() => {
    if (From === "Registration") {
      gaEvents.authRegisterFailOnMultipleUsers();
    } else if (From === "Password Reset") {
      gaEvents.authResetPwdFailOnMultipleUsers();
    }
  }, [From]);

  return (
    <div className="unidentified-user-container">
      
      <div>
        We are unable to identify your MAST record or you may have multiple MAST
        records and we are unable to work out which one is yours.
      </div>
      <div>
        Your MAST record was not matched.
      </div>
      <div>
        <b className="text-danger">{FailReason}</b>
      </div>
      <div>
        In order to complete your MAST Portal {From}, please contact MAST by
        calling 1300 135 513 or email: admin@mast.tas.gov.au.
      </div>
      <div>
        Please inform MAST of your user {From} Request ID:{" "}
        {RegistrationNo}
      </div>
    </div>
  );
};

export default UnidentifiedUser;
