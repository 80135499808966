import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import { getddmmyyyyFormat } from "../../Helpers/Helper";
import { ViewIcon } from "../Icons";
import DataLoader from "../DataLoader";
import AlertMessage from "../AlertMessage";
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

const MooringList = () => {
  const [res, err, isLoading, apiGet, abt] = useGet();
  useEffect(() => {
    apiGet("/api/mooring/MoMoorings");
  }, []);

  // console.log(res);

  return (
    <div className="mooring-sub-container">
      <div className="mooring-title-group">
        <h3 className="mooring-title">Moorings</h3>
      </div>

      {!isLoading ? (
        !err ? (
          res?.length === 0 ? (
            <AlertMessage
                message="No Moorings"
                level="info"
                closeEvt="false"
                mt="0"
                mb="0"
              />
          ) : (
            res?.map((d) => (
              <>
                <div className="row" key={uuidv4()}>
                  <div className="col-md-2">
                    <div className="mooring-input-group">
                      <label htmlFor="">Mo Rego</label>
                      <div className="mooringInfoField">{d?.moRegoNo}</div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mooring-input-group">
                      <label htmlFor="">Location</label>
                      <div className="mooringInfoField">{d?.moLocation1}</div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="mooring-input-group">
                      <label htmlFor="">Expiry</label>
                      <div className="mooringInfoField">{getddmmyyyyFormat(d?.moExpiryDate?.split("T")[0])}</div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="mooring-input-group">
                      <label htmlFor="Action">Action</label>
                      <div className="icon-group">
                        <ViewIcon url={"/mooring/mooringdetails/" + d?.moRegoNo} HoverCaption="View Mooring Details"/>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))
          )
        ) : (
          <AlertMessage message={err} />
        )
      ) : (
        <DataLoader isLoading={isLoading} />
      )}
    </div>
  );
};

export default MooringList;
