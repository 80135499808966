const QtySelect = ({item, changeEvt}) => {
    
    const MaxQty = 30;
    const renderOptions = (item = null) => {
        let optionLists = [];
        if(item)
        {
            for(let index = 0; index < MaxQty; index++) {
                optionLists.push(<option value={index+1} selected={(index+1)===item.qty}>{index+1}</option>)
            };
        }else{
            for(let index = 0; index < MaxQty; index++) {
                optionLists.push(<option value={index+1}>{index+1}</option>)
            };
        }
        return optionLists;
      }

    return ( 
        <select onChange={changeEvt}>
            {renderOptions(item)}
        </select>
     );
}
 
export default QtySelect;