import UploadInput from "../../UploadInput";

const FormUploadDoc = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  setError,
  clearErrors,
  uploadSizeLimit,
  setValue,
  isRequired = true,
}) => {
  const validHandler = (event) => {
    let file = event.target.files[0];
    // console.log(file);
    clearErrors(event.target.name);
    if (file?.size > uploadSizeLimit) {
      // console.log('over size');
      setError(event.target.name, {
        type: "oversize",
        message:
          "File is over the limit size (" +
          Math.floor(uploadSizeLimit / 1000000) +
          "MB).",
      });
    }
  };

  return (
    <>
      <div className="br-box">
        <div className="br-title">Supporting Documents</div>
        <div className="br-field-group">
          <div
            className={
              errors.file?.message
                ? "br-input-group error"
                : !getFieldState("file").isDirty
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <div>
              <span>
                Proof of purchase<span className="text-danger">{isRequired === true ? "*" : ""}</span>
              </span>
              <p>Proof of purchase can be established by providing:</p>
              <ul>
                <li>
                  the last Certificate of Registration either signed by the
                  registered operator; or
                </li>
                <li>
                  an official Bill of Sale signed by seller(s), buyer(s) and
                  witnessed; or
                </li>
                <li>invoice and receipt of payment from Marine Dealership</li>
              </ul>
              <p>
                If you are unable to provide either of these documents, a signed
                receipt together with a Statutory Declaration outlining the
                purchase may be acceptable.
              </p>
              <div className="doc-hint">
              (Maximum size is {Math.floor(uploadSizeLimit/1000000)}MB, and Only PDF/JPG/JPEG/PNG allowed)
              </div>
            </div>
            <UploadInput
              register={register}
              onChangeEvent={validHandler}
              assignName="file"
              watch={watch}
              setValue={setValue}
            />
            {/* <input type="file" {...register("file")} onChange={validHandler}/> */}
            <span className="msg">{errors.file?.message}</span>
          </div>

          <div
            className={
              errors.nonTasFile?.message
                ? "br-input-group error"
                : !getFieldState("nonTasFile").isDirty
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <div>
              <span>Proof of Vessel/owner outside of Tasmania</span>
              <ul>
                <li>Non-Tasmania residents</li>
                <li>Or where the vessel is not located in Tasmania</li>
              </ul>
              <div className="doc-hint">
              (Maximum size is {Math.floor(uploadSizeLimit/1000000)}MB, and Only PDF/JPG/JPEG/PNG allowed)
              </div>
            </div>
            <UploadInput
              register={register}
              onChangeEvent={validHandler}
              assignName="nonTasFile"
              watch={watch}
              setValue={setValue}
            />
            {/* <input type="file" {...register("nonTasFile")} onChange={validHandler}/> */}
            <span className="msg">{errors.nonTasFile?.message}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormUploadDoc;
