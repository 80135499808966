import { axiosApi } from '../Api/Axios';
import useAuth from './useAuth';

const useSettings = () => {
    const { auth, setAuth } = useAuth();
    //console.log(auth);
    const resetting = async () => {
        const response = await axiosApi.get('/api/auth/regain-settings', {
            withCredentials: true
        });
        setAuth(prev => {
            // console.log(JSON.stringify(prev));
            // console.log(response.data); 
            return { ...prev, isBypassPayment: response?.data?.bypassPayment, isAblePrintOrRegister: response?.data?.ablePrintOrRegisterAllowed, isPnaIdVerified: response?.data?.pnaIdVerified  }
        });
    }
    return resetting;
}
 
export default useSettings;