import { Outlet } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import useRefreshToken from '../Hooks/useRefreshToken';
import useAuth from '../Hooks/useAuth';
import SubmitLoader from "./SubmitLoader";
import useSettings from "../Hooks/useSettings";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const resetting = useSettings();
    const renderOnce = useRef(Boolean(process.env.REACT_APP_PREVENT_RENDER_TWICE)); //This is used to prevent render twice in strick mode. Reference is set to false when refresh
    const { auth, setAuth, persist } = useAuth();

    // console.log(auth);
    // console.log(renderOnce);

    useEffect(() => {
        //console.log(renderOnce.current);
        const verifyRefreshToken = async () => {
            try {
                await refresh();
                await resetting();
            //    console.log(auth);
            } catch (err) {
                // if(err.status === 401)
                // {
                //     console.log('going to refresh page');
                // }
            }
            finally {
               setAuth((prev) => ({
                    ...prev,
                    isLogin: prev.accessToken ? true:false,
               }));
            //    console.log(auth);
               setIsLoading(false);
            }
        }

        if (renderOnce.current === true) {
            !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
        }

        renderOnce.current = true;

    }, [])

    //useEffect(() => {
    //    console.log(`isLoading: ${isLoading}`)
    //    console.log(`aT: ${JSON.stringify(auth?.accessToken)}`)
    //}, [isLoading])

    return (
        <>
            {!persist
                ? <Outlet />
                : isLoading
                    ? <SubmitLoader isLoading={isLoading} />
                    :<Outlet />
            }
        </>
    )
}

export default PersistLogin