import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import { getddmmyyyyFormat } from "../../Helpers/Helper";
import { ViewIcon } from "../Icons";
import DataLoader from "../DataLoader";
import WestpacPayment from "../WestpacPayment";
import AlertMessage from "../AlertMessage";
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

const MooringPaymentList = ({ moRegoNo }) => {
  const [res, err, isLoading, apiGet, abt] = useGet();

  useEffect(() => {
    apiGet("/api/mooring/MoPayments/" + moRegoNo);
  }, []);

  // console.log(res);

  return (
    <div className="mooring-sub-container">
      <div className="mooring-title-group">
        <h3 className="mooring-title">Payment History</h3>
        <WestpacPayment url={"/api/mooring/RenewData/" + moRegoNo}/>
      </div>

      {!isLoading ? (
        !err ? (
          res?.length === 0 ? (
            <AlertMessage
                message="No Records"
                level="info"
                closeEvt="false"
                mt="0"
                mb="0"
              />
          ) : (
            res?.map((m) => (
              <>
                <div className="row" key={uuidv4()}>
                  <div className="col-md-5">
                    <div className="mooring-input-group">
                      <label htmlFor="">Date Paid</label>
                      <div className="mooringInfoField">
                        {getddmmyyyyFormat(m?.myDate?.split("T")[0])}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mooring-input-group">
                      <label htmlFor="">Amount</label>
                      <div className="mooringInfoField">
                        {Math.abs(m?.myAmt).toFixed(2)}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="mooring-input-group">
                      <label htmlFor="Action">Action</label>
                      <div className="icon-group">
                        <ViewIcon
                          url={
                            "/mooring/mooringpaymentdetails/" +
                            moRegoNo +
                            "/" +
                            m?.mySequNo
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))
          )
        ) : (
          <AlertMessage message={err}/>
        )
      ) : (
        <DataLoader isLoading={isLoading} />
      )}
    </div>
  );
};

export default MooringPaymentList;
