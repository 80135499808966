import AsyncSelect from "react-select/async";
import { axiosApi } from "../Api/Axios";

const AddressFilter = ({ defaultValue, onChange }) => {

  const fetchAddressLines = async (inputValue, callback) => {

    try {
      const response = await axiosApi.get(
        "/api/pna/search-address/" + inputValue
      );
      const arr = [];
      // console.log(response.data);
      response?.data.map((item) => {
        var Output =
          // item.recordId +
          // ", " +
          item.addressLine +
          ", " +
          item.locality +
          ", " +
          item.state +
          ", " +
          item.postcode;
        return arr.push({
          label: Output,
          value: Output,
          // Output: Output
        });
      });
      callback(arr);
    } catch (error) {
      console.error(error);
    }
  };

  const loadOptions = (inputValue, callback) => {
    //OPTIMIZE: get finally word in the specific period time. and pass it to fetch
    fetchAddressLines(inputValue, callback);
  };

  //   console.log(value);

  return (
    <>
      <AsyncSelect
        isClearable
        loadOptions={loadOptions}
        cacheOptions={true}
        defaultOptions={false}
        onChange={onChange}
        defaultValue={{ label: defaultValue, value: defaultValue }}
      />
    </>
  );
};

export default AddressFilter;
