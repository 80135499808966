import TransferBuyerForm from "../../Components/Transferaction/TransferBuyerForm";
import { useGet } from "../../Hooks/useFetch";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DataLoader from "../../Components/DataLoader";
import AlertMessage from "../../Components/AlertMessage";
import { decryptText } from "../../Helpers/Helper";
import useAuth from "../../Hooks/useAuth";
import Dialog from "../../Components/Modal/Dialog";

const TransferOfNewOwnerApprovalForm = () => {

  let { regoNo } = useParams();
  const [resKey, err, isLoadingKey, apiGetKey, abtKey] = useGet();
  const [resBuyers, errGet, isLoadingBuyers, apiGet, abort] = useGet();
  const [openPnaVerifiedDialog, setOpenPnaVerifiedDialog] = useState(false);
  const { auth } = useAuth();

  const isPnaIdVerified = () => {
    if (!auth?.isPnaIdVerified) {
      setOpenPnaVerifiedDialog(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    isPnaIdVerified();
    apiGet("/api/transfer/buyerTransferDetails/"+regoNo);
    apiGetKey("/api/pcraft/PayWayKey");
  }, []);

  return (
    <>
      {!isLoadingKey && !openPnaVerifiedDialog ? (
        !err ? (
          <TransferBuyerForm resBuyers={resBuyers} errGet={errGet} isLoadingBuyers={isLoadingBuyers} payWayPublicKey={decryptText(resKey, process.env.REACT_APP_Encrypt_Key, process.env.REACT_APP_Encrypt_IV)} />
        ) : (
          <AlertMessage message={err} />
        )
      ) : (
        <DataLoader isLoading={isLoadingKey} />
      )}

      {openPnaVerifiedDialog && (
        <Dialog
          setOpenDialog={setOpenPnaVerifiedDialog}
          title="ALERT"
          children="Your account with MAST is not yet Verified. You will be unable to complete any transactions involving boat registration until you provide identity information to Service Tasmania or MAST."
          redirectUrl="/recvessel"
        />
      )}
    </>
  );
};

export default TransferOfNewOwnerApprovalForm;
