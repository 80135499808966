import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import { useParams } from "react-router-dom";
import ComVesselUpdatedForm from "../../Components/ComVessel_FAV/ComVesselUpdatedForm";
import useGaEvent from "../../Hooks/useGaEvent";
import AlertMessage from "../../Components/AlertMessage";
import DataLoader from "../../Components/DataLoader";

const FaUpdated = () => {
  let { id } = useParams();
  const [res, err, isLoading, apiGet, abt] = useGet();
  const gaEvents = useGaEvent();

  useEffect(() => {
    apiGet("/api/fa/FaVesselDetails/" + id);
    gaEvents.CommercialUpdateVisit();
  }, []);

  return (
    <>
    {isLoading ? (
        <DataLoader isLoading={isLoading} />
      ) : err ? (
        <AlertMessage message={err} />
      ):(
        res ? (
          <div className="row">
            <div className="col-md-12">
              <ComVesselUpdatedForm faDetails={res} />
            </div>
          </div>
        ) : (
          <AlertMessage message="No Record" level="info" closeEvt="false"/>
        ))}
    </>
  );
};

export default FaUpdated;
