import { useForm } from "react-hook-form";
import { usePost } from "../../Hooks/useFetch";
import { useState } from "react";
import DataLoader from "../DataLoader";
import { EditClickEventIcon } from "../Icons";
import useGaEvent from "../../Hooks/useGaEvent";
import useToast from "../../Hooks/useToast";
import AlertMessage from "../AlertMessage";

const ComVesselUpdatedForm = ({ faDetails, setCanFresh, vesselTypeDesc }) => {
  // console.log(faDetails);
  const gaEvents = useGaEvent();
  const toastEmitter = useToast();
  const [editMode, setEditMode] = useState(false);

  const initialValues = {
    // action: null,
    // favAmsaUvi: "441883",
    // favBeam: faDetails?.favBeam,
    favColour: faDetails?.favColour,
    // favCosExpiry: "2023-06-30T00:00:00",
    // favCosNumber: "T3632",
    // favDateChg: "2023-06-30T00:00:00",
    // favDateFirstReg: null,
    // favDateIns: "2018-06-30T14:47:00",
    // favDraft: faDetails?.favDraft,
    // favExpiryDate: "2023-06-30T00:00:00",
    // favFeesDiscountPc: 0,
    // favGrossTn: faDetails?.favGrossTn,
    // favHullClassif: faDetails?.favHullClassif,
    // favIdMark: "14",
    // favLabelPrt: null,
    // favLabelPrtWip: null,
    // favLength: faDetails?.favLength,
    // favLengthOverall: faDetails?.favLengthOverall,
    // favMateCode: faDetails?.favMateCode,
    // favOwnerCode: "AALBRERF01",
    favPropulsionPwr: faDetails?.favPropulsionPwr,
    // favPrtLabelDate: "2023-06-30T00:00:00",
    // favRegoNo: "14",
    // favStatusCode: "1",
    // favSysId: 4,
    // favUserChg: "lcosson",
    // favUserIns: "mbrown",
    favVesName: faDetails?.favVesName,
    favVesType: faDetails?.favVesType,
    // favYearBuilt: faDetails?.favYearBuilt,
    // lastUpdate: null,
    // loginId: 430,
    // status: null,
    // statusReason: null,
  };

  const {
    control,
    register,
    formState: { errors, isDirty, isValid, dirtyFields },
    handleSubmit,
    watch,
    getFieldState,
    setValue,
    setError,
    clearErrors,
  } = useForm({ defaultValues: initialValues, mode: "onChange" });

  const successFuc = () => {
    setCanFresh(true);
    setEditMode(false);
    gaEvents.CommercialUpdateSuccess();
    toastEmitter("Commercial vessel is successful updated!", "success");
  };

  const [res, err, isLoadingPost, apiPost, abt] = usePost();

  const onSubmit = async (data, e) => {
    e.preventDefault();

    // console.log(data);
    await apiPost(
      "/api/fa/updateFaVesselDetails",
      {
        FavSysId: faDetails.favSysId,
        FavVesName: data.favVesName,
        FavVesType: data.favVesType,
        FavColour: data.favColour,
        FavPropulsionPwr: data.favPropulsionPwr,
      },
      false,
      null,
      successFuc,
      gaEvents.CommercialUpdateFail
    );
  };

  return (
    <>
      <div className="comvessel-updated-container">
        {!isLoadingPost ? (
          // !err ? (
            <>
              {(faDetails?.status==0 || faDetails?.status==2 || faDetails?.status==3) && <AlertMessage message={faDetails?.statusReason} level="warning" closeEvt="false" mt="0"/>}
              <form
                className="comvessel-updated-form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="comvessel-title-group">
                  <h3 className="comvessel-title">
                    {editMode ? "Update" : "Vessel Update"}{" "}
                  </h3>
                  <div>
                    {!editMode ? (
                      <EditClickEventIcon
                        clickEvent={() => setEditMode(true)}
                        iconSize="xl"
                        HoverCaption="Edit Commercial Vessel"
                      />
                    ) : (
                      <>
                        <div className="comvessel-btn-gp">
                          <button
                            type="button"
                            className="cancel-comvessel-button"
                            onClick={() => setEditMode(false)}
                          >
                            Cancel
                          </button>
                          <button type="submit" className="comvessel-button">
                            Save
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <AlertMessage message={err} />
                <div className="row">
                  <div className="col-md-6">
                    <div className="comvessel-updated-input-group">
                      <label htmlFor="vesName">Vessel Name</label>
                      {editMode ? (
                        <input
                          type="text"
                          id="favVesName"
                          {...register("favVesName")}
                        />
                      ) : (
                        <div className="comvesselInfoField">
                          {faDetails?.favVesName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="comvessel-updated-input-group">
                      <label htmlFor="vesType">Vessel Type</label>
                      {editMode ? (
                        <input
                          type="text"
                          id="favVesType"
                          {...register("favVesType")}
                        />
                      ) : (
                        <div className="comvesselInfoField">
                          {vesselTypeDesc}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="comvessel-updated-input-group">
                      <label htmlFor="colour">Colour</label>
                      {editMode ? (
                        <input
                          type="text"
                          id="favColour"
                          {...register("favColour")}
                        />
                      ) : (
                        <div className="comvesselInfoField">
                          {faDetails?.favColour}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="comvessel-updated-input-group">
                      <label htmlFor="propulsionPwr">Propulsion Power</label>
                      {editMode ? (
                        <>
                          {/* <select
                            id="propulsion"
                            {...register("favPropulsionPwr")}
                          >
                            <option value="">Choose...</option>
                            <option value="RB_OUTBOARD">Outboard</option>
                            <option value="RB_INBOARD">Inboard</option>
                            <option value="RB_STERNDRIVE">Sterndrive</option>
                            <option value="RB_JET">Jet</option>
                          </select> */}
                          <input
                            type="text"
                            id="favPropulsionPwr"
                            {...register("favPropulsionPwr")}
                          />
                          <span className="msg">
                            {errors.favPropulsionPwr?.message}
                          </span>
                        </>
                      ) : (
                        <div className="comvesselInfoField">
                          {/* {
                            systemCodes?.find(
                              (p) => p.codeNo === faDetails?.favPropulsionPwr
                            )?.codeDescr
                          } */}
                          {faDetails?.favPropulsionPwr}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </>
          // ) : (
          //   <>
          //     <ErrorMessage message={err} />
          //   </>
          // )
        ) : (
          <DataLoader isLoading={isLoadingPost} />
        )}
      </div>
    </>
  );
};

export default ComVesselUpdatedForm;
