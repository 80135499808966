import { getddmmyyyyFormat } from "../../Helpers/Helper";

const BuyerDetailReadOnly = ({ title, client }) => {
  // console.log(client);

  return (
    <>
      <div className="br-box">
        <div className="br-title">{title}</div>
        {client?.buyerIsOrganisation === true ? (
          <div className="br-field-group">
            <div className="br-input-group">
              <label htmlFor="orgName">Organisation Name</label>
              <div className="br-field-readonly unSelectable">
                {client?.buyerOrganisationName}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="orgType">ABN/ACN/Inc NO</label>
              <div className="br-field-readonly unSelectable">
                {client?.buyerAcnAbnInc}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="orgTypeNo">ABN/ACN/Inc NO Number</label>
              <div className="br-field-readonly unSelectable">
                {client?.buyerAcnAbnIncNumber}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="residentialAddress">Residential</label>
              <div className="br-field-readonly unSelectable">
                {client?.buyerAdr1 +
                  " " +
                  client?.buyerAdr2 +
                  " " +
                  client?.buyerAdr3 +
                  " " +
                  client?.buyerPostcode}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="clientEmail">Email</label>
              <div className="br-field-readonly unSelectable">
                {client?.buyerEmail}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="clientMobile">Mobile / Contact Phone</label>
              <div className="br-field-readonly unSelectable">
                {client?.buyerMobile}
              </div>
            </div>
          </div>
        ) : (
          <div className="br-field-group">
            <div className="br-input-group">
              <label htmlFor="clientFullName">Full Names</label>
              <div className="br-field-readonly unSelectable">
                {client?.buyerGivenName + ", " + client?.buyerSurname}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="clientDOB">Date of Birth</label>
              <div className="br-field-readonly unSelectable">
                {getddmmyyyyFormat(client?.buyerDob?.split("T")[0])}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="residentialAddress">Residential</label>
              <div className="br-field-readonly unSelectable">
                {client?.buyerAdr1 +
                  " " +
                  client?.buyerAdr2 +
                  " " +
                  client?.buyerAdr3 +
                  " " +
                  client?.buyerPostcode}
              </div>
            </div>

            <div className="br-input-group">
              <label htmlFor="clientEmail">Email</label>
              <div className="br-field-readonly unSelectable">
                {client?.buyerEmail}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="clientMobile">Mobile / Contact Phone</label>
              <div className="br-field-readonly unSelectable">
                {client?.buyerMobile}
              </div>
            </div>
          </div>
        )}
      </div>

      {client?.secondGivenName && (
          <div className="br-box">
            <div className="br-title">{client?.buyerIsOrganisation === true ? "Secondary Contact":"Secondary Operator"}</div>
            <div className="br-field-group">
            <div className="br-input-group">
              <label htmlFor="secondFullName">Full Names</label>
              <div className="br-field-readonly unSelectable">
                {client?.secondGivenName + ", " + client?.secondSurname}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="secondDOB">Date of Birth</label>
              <div className="br-field-readonly unSelectable">
                {getddmmyyyyFormat(client?.secondDob?.split("T")[0])}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="secondResAddress">Residential</label>
              <div className="br-field-readonly unSelectable">
                {client?.secondAdr1 +
                  " " +
                  client?.secondAdr2 +
                  " " +
                  client?.secondAdr3 +
                  " " +
                  client?.secondPostcode}
              </div>
            </div>

            <div className="br-input-group">
              <label htmlFor="secondEmail">Email</label>
              <div className="br-field-readonly unSelectable">
                {client?.secondEmail}
              </div>
            </div>
            <div className="br-input-group">
              <label htmlFor="secondMobile">Mobile / Contact Phone</label>
              <div className="br-field-readonly unSelectable">
                {client?.secondMobile}
              </div>
            </div>
          </div>
          </div>
        )}
    </>
  );
};

export default BuyerDetailReadOnly;
