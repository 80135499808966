import "./RecVesselUpdated.css";
import { useForm } from "react-hook-form";
import FormVesselUpdateDetail from "./RecVesselUpdated/FormVesselUpdateDetail";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { tryPassInt, joinMarinaString } from "../../Helpers/Helper";
import { usePost } from "../../Hooks/useFetch";
import SubmitLoader from "../SubmitLoader";
import useGaEvent from "../../Hooks/useGaEvent";
import useToast from "../../Hooks/useToast";
import { useState } from "react";
import Dialog from "../Modal/Dialog";
import AlertMessage from "../AlertMessage";

const RecVesselUpdatedForm = ({ recDetails }) => {
  // console.log(recDetails);
  const [openWebPendingDialog, setOpenWebPendingDialog] = useState(
    recDetails?.isVesselViewAble == false //It is web pending
  );
  const gaEvents = useGaEvent();
  const toastEmitter = useToast();
  const initialValues = {
    hullSerialNo: recDetails?.pcSerialNo,
    hullIdNo: recDetails?.pcHin,
    // hasVesselDiffRegiNo: recDetails?.,
    existRegiNo: recDetails?.pcExistingRegoNo,
    // isVesselCommercial: recDetails?.,
    comRegoNo: recDetails?.pcPrevRegoNo,
    vesselName: recDetails?.pcName,
    whereVesselKept: recDetails?.pcStorage, //pc storage
    marinaName: recDetails?.pcMarinaBerth?.split(",")[0]?.trim(),
    marinaBerthNum: recDetails?.pcMarinaBerth?.split(",")[1]?.trim(),
    mooringRegoNo: "",
    pocodeOfStorage:
      recDetails?.pcStoragePc != null
        ? tryPassInt(recDetails?.pcStoragePc)
        : "",
    vesselUse: recDetails?.pcUsage,
    classVessel: recDetails?.pcClassCode,
    hasFlyBridge: recDetails?.pcFlyBridge,

    isVesselHomemade: recDetails?.pcHomemade,
    builderName: recDetails?.pcBuilder,
    manufacturerName: recDetails?.pcHullMake, //hull make === manufacturer
    model: recDetails?.pcHullModel,
    yearOfMade: recDetails?.pcDateManuf,
    hullConstruction: recDetails?.pcHullCode,
    otherDescHullConstr: "", //No field for other desc.
    hullColour: recDetails?.pcHullColour,
    otherColour: recDetails?.pcOtherColour,
    hullLength: recDetails?.pcLength,
    hullLengthOverall: recDetails?.pcLengthOverall,
    beam: recDetails?.pcBeam,
    draft: recDetails?.pcDraft,
    hasNaviLight: recDetails?.pcNavLights,
    hasEPIRB: recDetails?.pcEpirb,
    epirbType: recDetails?.pcEpirbType,
    isEPIRBwithAMSA: !recDetails?.pcEpirb406Reg ? "Y" : "N",
    clubName: recDetails?.pcClubName,
    sailNumber: recDetails?.pcClubNumber,
    hasLiferaft: recDetails?.pcLiferaft,
    hasBuilderPlate: recDetails?.pcAbpFitted,

    // multi-checkbox
    sortRadio: (recDetails?.pcRadioType === null || recDetails?.pcRadioType === '') ? [] : recDetails?.pcRadioType?.split(","),
    vesselSign: recDetails?.pcCallSign,
  };

  // console.log(initialValues);

  const {
    control,
    register,
    formState: { errors, isDirty, isValid, dirtyFields },
    handleSubmit,
    watch,
    getFieldState,
    setValue,
    setError,
    clearErrors,
  } = useForm({ defaultValues: initialValues, mode: "onChange" });

  const [res, err, isLoadingPost, apiPost, abt] = usePost();
  const navigate = useNavigate();
  const location = useLocation();

  const afterUpdate = () => {
    gaEvents.RecreationalDetailUpdateSuccess();
    toastEmitter("Recreational vessel is successful updated!", "success");
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    // console.log(data);
    await apiPost(
      "/api/pcraft/updatePcCraftDetails",
      {
        PcRegoNo: recDetails?.pcRegoNo,
        PcName: data.vesselName,
        PcClassCode: data.classVessel,
        PcClubNumber: data.sailNumber,
        PcClubName: data.clubName,
        // PcLength: data.hullLength,
        // PcBeam: data.beam,
        // PcDraft: data.draft,
        PcNavLights: data.hasNaviLight,
        PcEpirb: data.hasEPIRB,
        MoRegoNo: data.mooringRegoNo,
        PcLiferaft: data.hasLiferaft,

        // PcHullMake: data.hullConstruction,
        // PcHullCode :data.,
        PcHullColour: data.hullColour,
        PcOtherColour: data.otherColour,
        //  PcRadarType :data.,
        PcRadioType: data.sortRadio?.toString(),
        PcCallSign: data.vesselSign,
        //  PcFeeExempt :data.,
        // PcExpiryDate :data.,
        // PcDateChanged :data.,
        //  PcComments :data.,
        //  PcRenewalNo :data.,
        // PcPrevRegoNo: data.comRegoNo,
        //  PcNewRegoDate :data.,
        //  PcTfrInProgress :data.,
        // PcSerialNo: data.hullSerialNo,
        //  PcUserChanged :data.,
        //  PcStatus :data.,
        // PcDateManuf: data.yearOfMade != null ? new Date(data.yearOfMade + "-01-01") : "",
        // PcAbpFitted: data.hasBuilderPlate,
        // PcHin: data.hullIdNo,
        PcStorage: data.whereVesselKept,
        PcStoragePc: data.pocodeOfStorage?.toString(),
        // PcExistingRegoNo: data.existRegiNo,
        PcUsage: data.vesselUse,
        // PcHullModel: data.model,
        // PcFlyBridge: data.hasFlyBridge,
        PcEpirbType: data.epirbType,
        PcEpirb406Reg: data.isEPIRBwithAMSA,
        //  PcRadioOpLic :data.,
        //  PcPwcSafetyExempt :data.,
        // PcPwcSafetyExemptDate :data.,
        //  HdLicNum :data.,
        // ItemId :data.,
        // PcHomemade: data.isVesselHomemade,
        // PcBuilder: data.builderName,
        // PcLengthOverall: data.hullLengthOverall,
        //  PcPersonalisedId :data.,
        PcMarinaBerth: joinMarinaString(data.marinaName, data.marinaBerthNum),
        MoRegoNo: data.mooringRegoNo,
      },
      false,
      "/recvessel/recvesseldetails/" + recDetails?.pcRegoNo,
      afterUpdate,
      gaEvents.RecreationalDetailUpdateFail
    );
  };

  return (
    <>
      {!openWebPendingDialog && !isLoadingPost ? (
        <div className="recvessel-updated-form-container">
          <div className="recvessel-title-group ">
            <h3 className="recvessel-title">Recreational Vessel</h3>
          </div>
          <AlertMessage message={err} />
          <div className="row recvessel-updated-form-readonly-group">
            {recDetails?.pcPersonalisedId ? (
              <>
                <div className="col-md-6">
                  <div className="recvessel-input-group">
                    <label htmlFor="regoNo">Pc Rego No.</label>
                    <div className="recvesselInfoField">
                      {recDetails?.pcRegoNo}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="recvessel-input-group">
                    <label htmlFor="personaliseId">Personalised ID</label>
                    <div className="recvesselInfoField">
                      {recDetails?.pcPersonalisedId}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-12">
                  <div className="recvessel-input-group">
                    <label htmlFor="regoNo">Pc Rego No.</label>
                    <div className="recvesselInfoField">
                      {recDetails?.pcRegoNo}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <form
            className="recvessel-updated-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormVesselUpdateDetail
              register={register}
              watch={watch}
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              getFieldState={getFieldState}
            />

            <div className="row">
              <div className="col-md-3">
                <button
                  type="button"
                  className="cancel-btn"
                  onClick={() =>
                    navigate(
                      "/recvessel/recvesseldetails/" + recDetails?.pcRegoNo,
                      { state: { from: location }, replace: true }
                    )
                  }
                >
                  Cancel
                </button>
              </div>
              <div className="col-md-6" />
              <div className="col-md-3">
                <button
                  type="submit"
                  className="pna-updated-form-button"
                  disabled={Object.keys(errors).length > 0}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <SubmitLoader isLoading={isLoadingPost} />
      )}

      {openWebPendingDialog && (
        <Dialog
          setOpenDialog={setOpenWebPendingDialog}
          title="ALERT"
          children="You have recently added a new recreational vessel. It is not viewable until MAST have confirmed."
          redirectUrl="/recvessel"
        />
      )}
    </>
  );
};

export default RecVesselUpdatedForm;
