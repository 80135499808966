import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import { useParams } from "react-router-dom";
import RecVesselUpdatedForm from "../../Components/RecVessel_PWC/RecVesselUpdatedForm";
import SubmitLoader from "../../Components/SubmitLoader";
import useGaEvent from "../../Hooks/useGaEvent";
import AlertMessage from "../../Components/AlertMessage";

const RecVesselUpdated = () => {
  let { id } = useParams();
  const [res, err, isGETLoading, apiGet, abt] = useGet();
  const gaEvents = useGaEvent();

  useEffect(() => {
    apiGet("/api/pcraft/PcCraftDetails/" + id);
    gaEvents.RecreationalDetailVisit();
  }, []);

  return (
    <>
      {isGETLoading ? (
        <SubmitLoader isLoading={isGETLoading} />
      ) : err ? (
        <div className="row">
          <div className="col-md-12">
            <AlertMessage message={err} />
          </div>
        </div>
      ) : res ? (
        <div className="row">
          <div className="col-md-12">
            <RecVesselUpdatedForm recDetails={res} />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <AlertMessage
                message="No Updated Recreational Vessel Detail"
                level="info"
                closeEvt="false"
              />
          </div>
        </div>
      )}
    </>
  );
};

export default RecVesselUpdated;
