import { faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useWatch } from "react-hook-form";
import AddressFilter from "../AddressFilter";
import { useEffect } from "react";
import { validateMiniAge, getAuMobileRegex } from "../../Helpers/Helper";

const SecondContact = ({
  register,
  watch,
  getFieldState,
  control,
  errors,
  clearErrors,
  index,
  SecondContactArrayPropName,
  remove,
}) => {

  let email = useWatch({ control, name: `${SecondContactArrayPropName}.${index}.secondEmail` });
  let mobile = useWatch({ control, name: `${SecondContactArrayPropName}.${index}.secondMobile` });
  useEffect(() => {
    if (email) {
      clearErrors(`${SecondContactArrayPropName}.${index}.secondMobile`);
    }

    if (mobile) {
      clearErrors(`${SecondContactArrayPropName}.${index}.secondEmail`);
    }
  }, [email, mobile]);


  return (
    <div className="br-box">
      <div className="br-title amount-group">
        <div>Secondary Operator</div>
          <FontAwesomeIcon
            icon={faCircleMinus}
            className="br-engine-bin-icon"
            size="1x"
            onClick={() => remove(index)}
          />
      </div>

      <div className="br-field-group">
        <div
          className={
            getFieldState(
              `${SecondContactArrayPropName}.${index}.SecondGivenName`
            ).invalid
              ? "br-input-group error"
              : !watch(
                  `${SecondContactArrayPropName}.${index}.SecondGivenName`
                )
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="SecondGivenName">
            Given Name(s)<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="SecondGivenName"
            {...register(
              `${SecondContactArrayPropName}.${index}.SecondGivenName`,
              {
                required: "Given Name(s) is required",
                pattern: {
                  value: /^[A-Z][A-Za-z-.&]{0,}(\s[A-Z][A-Za-z-.&]{0,})*$/,
                  message:
                    "First letter uppercase. Number, special characters and last space are not allowed. Only (- . &) allowed",
                },
              }
            )}
          />
          <span className="msg">
            {
              getFieldState(
                `${SecondContactArrayPropName}.${index}.SecondGivenName`
              ).error?.message
            }
          </span>
        </div>

        <div
          className={
            getFieldState(
              `${SecondContactArrayPropName}.${index}.SecondSurName`
            ).invalid
              ? "br-input-group error"
              : !watch(
                  `${SecondContactArrayPropName}.${index}.SecondSurName`
                )
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="SecondSurName">
            Surname<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="SecondSurName"
            {...register(
              `${SecondContactArrayPropName}.${index}.SecondSurName`,
              {
                required: "Surname is required",
                pattern: {
                  value: /^[A-Z][A-Za-z-.&]{0,}(\s[A-Z][A-Za-z-.&]{0,})*$/,
                  message:
                    "First letter uppercase. Number, special characters and last space are not allowed. Only (- . &) allowed",
                },
              }
            )}
          />
          <span className="msg">
            {
              getFieldState(
                `${SecondContactArrayPropName}.${index}.SecondSurName`
              ).error?.message
            }
          </span>
        </div>

        <div
          className={
            getFieldState(`${SecondContactArrayPropName}.${index}.SecondDOB`).invalid
              ? "br-input-group error"
              : "br-input-group"
          }
        >
          <label htmlFor="SecondDOB">Date of Birth</label>
          <Controller
            control={control}
            name={`${SecondContactArrayPropName}.${index}.SecondDOB`}
            render={({ field: { onChange, value } }) => {
              return <DatePicker value={value} onChange={onChange} />;
            }}
            rules={{
              validate: {
                greaterThanSixteen: (d) => validateMiniAge(d),
                min: (v) =>
                  v == undefined ||
                  v == null ||
                  new Date(v) >= new Date(1900, 0, 1) ||
                  "Selected year must be greater than 1900",
                max: (v) =>
                  v == undefined ||
                  v == null ||
                  new Date(v) <= new Date() ||
                  "Selected date must be less than today",
              },
            }}
            errors={errors}
          />
          <span className="msg">
            {
              getFieldState(
                `${SecondContactArrayPropName}.${index}.SecondDOB`
              ).error?.message
            }
          </span>
        </div>
        <div
          className={
            !watch(`${SecondContactArrayPropName}.${index}.SecondResAddress`)
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="SecondResAddress">Residential</label>
          <Controller
            control={control}
            name={`${SecondContactArrayPropName}.${index}.SecondResAddress`}
            render={({ field: { onChange, value } }) => {
              return (
                <AddressFilter
                  onChange={(val) => onChange(val?.value)}
                  defaultValue={value}
                />
              );
            }}
            errors={errors}
          />
          <span className="msg">
            {
              getFieldState(
                `${SecondContactArrayPropName}.${index}.SecondResAddress`
              ).error?.message
            }
          </span>
        </div>
        {/* {watch(`${SecondContactArrayPropName}.${index}.secondResAddress`) && (
          <div className="br-checkbox-group">
            <input
              type="checkbox"
              id="secondCheckPostal"
              {...register(
                `${SecondContactArrayPropName}.${index}.secondCheckPostal`
              )}
            />
            <label htmlFor="secondCheckPostal" className="unSelectable">
              Postal Same as Residential
            </label>
          </div>
        )}

        {watch(
          `${SecondContactArrayPropName}.${index}.secondCheckPostal`
        ) === true ? (
          ""
        ) : (
          <div
            className={
              !watch(
                `${SecondContactArrayPropName}.${index}.secondPoAddress`
              )
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="secondPoAddress">Postal</label>
            <Controller
              control={control}
              name={`${SecondContactArrayPropName}.${index}.secondPoAddress`}
              render={({ field: { onChange, value } }) => {
                return (
                  <AddressFilter
                    onChange={(val) => onChange(val?.value)}
                    defaultValue={value}
                  />
                );
              }}
              errors={errors}
            />
            <span className="msg">
              {
                getFieldState(
                  `${SecondContactArrayPropName}.${index}.secondPoAddress`
                ).error?.message
              }
            </span>
          </div>
        )} */}

        <div
          className={
            getFieldState(
              `${SecondContactArrayPropName}.${index}.SecondEmail`
            ).invalid
              ? "br-input-group error"
              : !watch(
                  `${SecondContactArrayPropName}.${index}.SecondEmail`
                )
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="SecondEmail">
            Email{" "}
            {watch(
              `${SecondContactArrayPropName}.${index}.SecondMobile`
            ) ? (
              ""
            ) : (
              <span className="text-danger">*</span>
            )}
          </label>
          <input
            type="email"
            id="SecondEmail"
            {...register(
              `${SecondContactArrayPropName}.${index}.SecondEmail`,
              {
                required: {
                  value: watch(
                    `${SecondContactArrayPropName}.${index}.SecondMobile`
                  )
                    ? false
                    : true,
                  message: "Email or Mobile is required",
                },
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  message: "Email format error",
                },
              }
            )}
          />
          <span className="tsf-sub-text">
            Either email or mobile is required
          </span>
          <span className="msg">
            {
              getFieldState(
                `${SecondContactArrayPropName}.${index}.SecondEmail`
              ).error?.message
            }
          </span>
        </div>
        <div
          className={
            getFieldState(
              `${SecondContactArrayPropName}.${index}.SecondMobile`
            ).invalid
              ? "br-input-group error"
              : !watch(
                  `${SecondContactArrayPropName}.${index}.SecondMobile`
                )
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="SecondMobile">
            Mobile{" "}
            {watch(
              `${SecondContactArrayPropName}.${index}.SecondEmail`
            ) ? (
              ""
            ) : (
              <span className="text-danger">*</span>
            )}
          </label>
          <input
            type="tel"
            id="mobile"
            {...register(
              `${SecondContactArrayPropName}.${index}.SecondMobile`,
              {
                required: {
                  value: watch(
                    `${SecondContactArrayPropName}.${index}.SecondEmail`
                  )
                    ? false
                    : true,
                  message: "Email or Mobile is required",
                },
                pattern: {
                  value: getAuMobileRegex(),
                  message: "Mobile format error",
                },
              }
            )}
            placeholder="04XX XXX XXX"
          />
          <span className="tsf-sub-text">
            Either email or mobile is required
          </span>
          <span className="msg">
            {
              getFieldState(
                `${SecondContactArrayPropName}.${index}.SecondMobile`
              ).error?.message
            }
          </span>
        </div>
      </div>
    </div>
  );
};

export default SecondContact;
