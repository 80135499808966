import BusinessType from "../../Contacts/BusinessType";

const OwnerType = ({ register, watch, getFieldState, isCompanyFieldName }) => {
  return (
    <>
      <div className="br-box">
        <div className="br-title">Ownership</div>
        <div>
          Motor Boats can only be registered in the name of a{" "}
          <strong>natural person</strong> or a<strong> Body Corporate</strong>{" "}
          (e.g. company or an incorporated association, Government Department or
          Authority).
        </div>
        <div>
          <div>
            <p className="sublabel">Person</p>
            <div>
              The online application can only be used where a vessel is to be
              registered in the name of a single person. To register in joint
              names, syndicates etc you must use a hard copy of the application
              form.
            </div>
            <p>
              Please refer to the Evidence of Identity section for a full list
              of acceptable forms of identity.
            </p>
          </div>
          <div>
            <p className="sublabel">Body Corporate</p>
            <div>
              To register a vessel in the name of a Body Corporate, all
              mandatory fields must be completed. Documents to be included on
              lodgement of application are:
            </div>
            <ul>
              <li>A copy of the entities Certificate of Registration</li>
              <li>
                Written authority nominating a responsible person to act as a
                contact and signatory on behalf of the entity
              </li>
            </ul>
            <div>
              Depending on the nature of operation of a vessel registered to a
              Body Corporate, MAST can only accept applications for recreational
              use. If the operation is commercial in nature, you may be required
              to register your vessel and operation through AMSA.
            </div>
          </div>
        </div>
      </div>
      <div className="br-box">
        <BusinessType
          register={register}
          watch={watch}
          getFieldState={getFieldState}
          isCompanyFieldName={isCompanyFieldName}
        />
      </div>
    </>
  );
};

export default OwnerType;
