import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import './PwdSuccess.css';

const PwdSuccess = ({Subtitle}) => {
  const navigate = useNavigate();

  const backToLogin = () => {
    navigate("/login", { replace: true });
  };

  return (
    <div className="pwd-success-container">
      <div className="pwd-success-title-box">
        <FontAwesomeIcon icon={faCircleCheck} size="7x" />
        <div>
          <div className="pwd-success-title">SUCCESS</div>
          <div className="pwd-success-subtitle">{Subtitle}</div>
        </div>
      </div>
      <button className="pwd-success-button" onClick={backToLogin}>
        LOGIN
      </button>
    </div>
  );
};

export default PwdSuccess;
