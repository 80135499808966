import DeclarationLaw2023 from "../../DeclarationLaw2023";

const FormDeclarationLaw = ({ register, errors }) => {
  return (
    <div className="br-box">
      <div className="br-title">Declaration</div>
      <DeclarationLaw2023 register={register} errors={errors} />
    </div>
  );
};

export default FormDeclarationLaw;
