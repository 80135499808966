import { useEffect } from "react";
import AddressFilter from "../AddressFilter";
import { useWatch } from "react-hook-form";
import { Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import { validateMiniAge, getAuMobileRegex } from "../../Helpers/Helper";


const OrganisationContact = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  clearErrors,
  prefixIsCompanyField,
  title = "Organisation Contact"
}) => {
  let email = useWatch({
    control,
    name: `${prefixIsCompanyField}.secondEmail`,
  });
  let mobile = useWatch({
    control,
    name: `${prefixIsCompanyField}.secondMobile`,
  });
  useEffect(() => {
    if (email) {
      clearErrors(`${prefixIsCompanyField}.secondMobile`);
    }

    if (mobile) {
      clearErrors(`${prefixIsCompanyField}.secondEmail`);
    }
  }, [email, mobile]);

  return (
    <div className="br-box">
      <div className="br-title">{title}</div>
      <div className="br-field-group">
        <div
          className={
            getFieldState(`${prefixIsCompanyField}.secondGivenName`).invalid
              ? "br-input-group error"
              : !getFieldState(`${prefixIsCompanyField}.secondGivenName`)
                  .isDirty
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="secondGivenName">
            Given Name(s)<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="secondGivenName"
            {...register(`${prefixIsCompanyField}.secondGivenName`, {
              required: "Given Name(s) is required",
              pattern: {
                value: /^[A-Z][A-Za-z-.&]{0,}(\s[A-Z][A-Za-z-.&]{0,})*$/,
                message:
                  "First letter uppercase. Number, special characters and last space are not allowed. Only (- . &) allowed",
              },
            })}
          />
          <span className="msg">
            {
              getFieldState(`${prefixIsCompanyField}.secondGivenName`).error
                ?.message
            }
          </span>
        </div>
        <div
          className={
            getFieldState(`${prefixIsCompanyField}.secondSurName`).invalid
              ? "br-input-group error"
              : !getFieldState(`${prefixIsCompanyField}.secondSurName`).isDirty
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="secondSurName">
            Surname<span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="secondSurName"
            {...register(`${prefixIsCompanyField}.secondSurName`, {
              required: "Surname is required",
              pattern: {
                value: /^[A-Z]{1}[A-Za-z-.]{0,}$/,
                message:
                  "First letter uppercase. Number and special characters are not allowed. Only (- .) allowed",
              },
            })}
          />
          <span className="msg">
            {
              getFieldState(`${prefixIsCompanyField}.secondSurName`).error
                ?.message
            }
          </span>
        </div>
        <div
          className={
            getFieldState(`${prefixIsCompanyField}.secondDOB`).invalid
              ? "br-input-group error"
              : "br-input-group"
          }
        >
          <label htmlFor="secondDOB">Date of Birth</label>
          <Controller
            control={control}
            name={`${prefixIsCompanyField}.secondDOB`}
            render={({ field: { onChange, value } }) => {
              return <DatePicker value={value} onChange={onChange} />;
            }}
            rules={{
              validate: {
                greaterThanSixteen: (d) => validateMiniAge(d),
                min: (v) =>
                  v == undefined ||
                  v == null ||
                  new Date(v) >= new Date(1900, 0, 1) ||
                  "Selected year must be greater than 1900",
                max: (v) =>
                  v == undefined ||
                  v == null ||
                  new Date(v) <= new Date() ||
                  "Selected date must be less than today",
              },
            }}
            errors={errors}
          />
          <span className="msg">
            {getFieldState(`${prefixIsCompanyField}.secondDOB`).error?.message}
          </span>
        </div>
        <div
          className={
            getFieldState(`${prefixIsCompanyField}.secondResAddress`).invalid
              ? "br-input-group error"
              : "br-input-group"
          }
        >
          <label htmlFor="secondResAddress">Residential</label>
          <Controller
            control={control}
            name={`${prefixIsCompanyField}.secondResAddress`}
            render={({ field: { onChange, value } }) => {
              return (
                <AddressFilter
                  onChange={(val) => onChange(val?.value)}
                  defaultValue={value}
                />
              );
            }}
            errors={errors}
          />
          <span className="msg">
            {
              getFieldState(`${prefixIsCompanyField}.secondResAddress`).error
                ?.message
            }
          </span>
        </div>

        {/* {watch(`${prefixIsCompanyField}.secondResAddress`) && (
                <div className="br-checkbox-group">
                  <input
                    type="checkbox"
                    id="secondCheckPostal"
                    {...register(`${prefixIsCompanyField}.secondCheckPostal`)}
                  />
                  <label htmlFor="secondCheckPostal" className="unSelectable">
                    Postal Same as Residential
                  </label>
                </div>
              )}

              {watch(`${prefixIsCompanyField}.secondCheckPostal`) === true ? (
                ""
              ) : (
                <div
                  className={
                    getFieldState(`${prefixIsCompanyField}.secondPoAddress`)
                      .invalid
                      ? "br-input-group error"
                      : "br-input-group"
                  }
                >
                  <label htmlFor="secondPoAddress">Postal</label>
                  <Controller
                    control={control}
                    name={`${prefixIsCompanyField}.secondPoAddress`}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <AddressFilter
                          onChange={(val) => onChange(val?.value)}
                          defaultValue={value}
                        />
                      );
                    }}
                    errors={errors}
                  />
                  <span className="msg">
                    {
                      getFieldState(`${prefixIsCompanyField}.secondPoAddress`)
                        .error?.message
                    }
                  </span>
                </div>
              )} */}

        <div
          className={
            getFieldState(`${prefixIsCompanyField}.secondEmail`).invalid
              ? "br-input-group error"
              : !getFieldState(`${prefixIsCompanyField}.secondEmail`).isDirty
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="secondEmail">
            Email{" "}
            {watch(`${prefixIsCompanyField}.secondMobile`) ? (
              ""
            ) : (
              <span className="text-danger">*</span>
            )}
          </label>
          <input
            type="email"
            id="secondEmail"
            {...register(`${prefixIsCompanyField}.secondEmail`, {
              required: {
                value: watch(`${prefixIsCompanyField}.secondMobile`)
                  ? false
                  : true,
                message: "Email or Mobile is required",
              },
              pattern: {
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                message: "Email format error",
              },
            })}
          />
          <span className="tsf-sub-text">
            Either email or mobile is required
          </span>
          <span className="msg">
            {
              getFieldState(`${prefixIsCompanyField}.secondEmail`).error
                ?.message
            }
          </span>
        </div>
        <div
          className={
            getFieldState(`${prefixIsCompanyField}.secondMobile`).invalid
              ? "br-input-group error"
              : !getFieldState(`${prefixIsCompanyField}.secondMobile`).isDirty
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="secondMobile">
            Mobile{" "}
            {watch(`${prefixIsCompanyField}.secondEmail`) ? (
              ""
            ) : (
              <span className="text-danger">*</span>
            )}
          </label>
          <input
            type="tel"
            id="mobile"
            {...register(`${prefixIsCompanyField}.secondMobile`, {
              required: {
                value: watch(`${prefixIsCompanyField}.secondEmail`)
                  ? false
                  : true,
                message: "Email or Mobile is required",
              },
              pattern: {
                value: getAuMobileRegex(),
                message: "Mobile format error",
              },
            })}
            placeholder="04XX XXX XXX"
          />
          <span className="tsf-sub-text">
            Either email or mobile is required
          </span>
          <span className="msg">
            {
              getFieldState(`${prefixIsCompanyField}.secondMobile`).error
                ?.message
            }
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrganisationContact;
