import { useEffect, useState } from "react";
import { getYearRangeRegex } from "../../../Helpers/Helper";
import { useGet } from "../../../Hooks/useFetch";
import DataLoader from "../../DataLoader";

const Manufacturer = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  getValues,
  isReRegisterCase = false,
  isBeamEditable = true,
}) => {
  const [
    resHullCodes,
    errHullCodes,
    isLoadingHullCodes,
    apiGetHullCodes,
    abtHullCodes,
  ] = useGet();

  useEffect(() => {
    apiGetHullCodes("/api/pcraft/HullCodeLists");
  }, []);

  // console.log(resHullCodes);

  return (
    <div className="br-box">
      <div className="br-title">Manufacturer and Construction</div>
      {isLoadingHullCodes === true ? (
        <DataLoader isLoading={isLoadingHullCodes} />
      ) : errHullCodes ? (
        <span>{errHullCodes}</span>
      ) : (
        <div className="br-field-group">
          {isReRegisterCase === true ? (
            <div className="br-input-group">
              <label htmlFor="isVesselHomemade">
                Was the Vessel Homemade
              </label>
              <div className="br-field-radio-group br-radio-field-readonly">
                <div className="br-radio-group">
                  <input
                    type="radio"
                    value="Yes"
                    id="isVesselHomemadeYes"
                    checked={getValues("isVesselHomemade") === "Yes"}
                    disabled
                  />
                  <label htmlFor="isVesselHomemadeYes">YES</label>
                </div>
                <div className="br-radio-group">
                  <input
                    type="radio"
                    value="No"
                    id="isVesselHomemadeNo"
                    checked={getValues("isVesselHomemade") === "No"}
                    disabled
                  />
                  <label htmlFor="isVesselHomemadeNo">NO</label>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={
                errors.isVesselHomemade?.message
                  ? "br-input-group error"
                  : !getFieldState("isVesselHomemade").isDirty
                  ? "br-input-group"
                  : "br-input-group success"
              }
            >
              <label htmlFor="isVesselHomemade">
                Was the Vessel Homemade <span className="text-danger">*</span>
              </label>
              <div className="br-field-radio-group">
                <div className="br-radio-group">
                  <input
                    type="radio"
                    value="Yes"
                    id="isVesselHomemadeYes"
                    {...register("isVesselHomemade", {
                      required: {
                        value: true,
                        message: "Vessel Homemade is required.",
                      },
                    })}
                  />
                  <label htmlFor="isVesselHomemadeYes">YES</label>
                </div>
                <div className="br-radio-group">
                  <input
                    type="radio"
                    value="No"
                    id="isVesselHomemadeNo"
                    {...register("isVesselHomemade", {
                      required: {
                        value: true,
                        message: "Vessel Homemade is required.",
                      },
                    })}
                  />
                  <label htmlFor="isVesselHomemadeNo">NO</label>
                </div>
              </div>
              <span className="msg">{errors.isVesselHomemade?.message}</span>
            </div>
          )}

          {watch("isVesselHomemade") === "Yes" ? (
            <>
              <div className="br-input-group">
                <label htmlFor="builderName">Builder's Name</label>
                {isReRegisterCase === true ? (
                  <div className="br-field-readonly unSelectable text-upper">
                    {getValues("builderName")}
                  </div>
                ) : (
                  <input
                    type="text"
                    id="builderName"
                    className="text-upper"
                    {...register("builderName")}
                  />
                )}
              </div>
            </>
          ) : (
            ""
          )}

          {watch("isVesselHomemade") === "No" ? (
            isReRegisterCase === true ? (
              <>
                <div className="br-input-group">
                  <label htmlFor="manufacturerName">Manufacturer</label>
                  <div className="br-field-readonly unSelectable text-upper">
                    {getValues("manufacturerName")}
                  </div>
                </div>
                <div className="br-input-group">
                  <label htmlFor="model">Model</label>
                  <div className="br-field-readonly unSelectable text-upper">
                    {getValues("model")}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="br-input-group">
                  <label htmlFor="manufacturerName">Manufacturer</label>
                  <input
                    type="text"
                    id="manufacturerName"
                    className="text-upper"
                    {...register("manufacturerName")}
                  />
                </div>
                <div className="br-input-group">
                  <label htmlFor="model">Model</label>
                  <input
                    type="text"
                    id="model"
                    className="text-upper"
                    {...register("model")}
                  />
                </div>
              </>
            )
          ) : (
            ""
          )}

          <div
            className={
              errors.yearOfMade?.message
                ? "br-input-group error"
                : !watch("yearOfMade")
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="yearOfMade">Year of Manufacture</label>
            <input
              type="number"
              id="yearOfMade"
              {...register("yearOfMade", {
                pattern: {
                  value: getYearRangeRegex(),
                  message: "Valid year in 1500-NOW",
                },
              })} //valid year
            />
            <span className="msg">{errors.yearOfMade?.message}</span>
          </div>

          {isReRegisterCase === true ? (
            <div className="br-input-group">
              <label htmlFor="hullConstruction">
                Hull Construction
              </label>
              <div className="br-field-readonly unSelectable text-upper">
                {getValues("hullConstruction")}
              </div>
            </div>
          ) : (
            <div
              className={
                errors.hullConstruction?.message
                  ? "br-input-group error"
                  : !getFieldState("hullConstruction").isDirty
                  ? "br-input-group"
                  : "br-input-group success"
              }
            >
              <label htmlFor="hullConstruction">
                Hull Construction <span className="text-danger">*</span>
              </label>
              <select
                id="hullConstruction"
                {...register("hullConstruction", {
                  required: {
                    value: true,
                    message: "Hull construction is required",
                  },
                })}
              >
                <option value="">CHOOSE...</option>
                {resHullCodes?.map((r) => (
                  <option value={r.huHullCode}>
                    {r.huHullDescr?.toUpperCase()}
                  </option>
                ))}
                {/* <option value="ALUMINIUM">Aluminium</option>
              <option value="COMPOSITE">Composite</option>
              <option value="FERRO">Ferro Cement</option>
              <option value="GRP">GRP (Fibreglass)</option>
              <option value="INFLATABLE">Inflatable</option>
              <option value="PLASTIC">Plastic</option>
              <option value="STEEL">Steel</option>
              <option value="TIMBER">Timber</option>
              <option value="OTHER">Other (describe)</option> */}
              </select>
              <span className="msg">{errors.hullConstruction?.message}</span>
            </div>
          )}

          {watch("hullConstruction") === "OTHER" ? (
            <>
              {isReRegisterCase === true ? (
                <div className="br-input-group">
                  <div className="br-field-readonly unSelectable text-upper">
                    {getValues("otherDescHullConstr")}
                  </div>
                </div>
              ) : (
                <div
                  className={
                    errors.otherDescHullConstr?.message
                      ? "br-input-group error"
                      : !getFieldState("otherDescHullConstr").isDirty
                      ? "br-input-group"
                      : "br-input-group success"
                  }
                >
                  <input
                    type="text"
                    id="otherDescHullConstr"
                    className="text-upper"
                    placeholder="Other description"
                    {...register("otherDescHullConstr", {
                      required: {
                        value: watch("hullConstruction") === "OTHER",
                        message: "Other desc is required if ticked",
                      },
                    })}
                  />
                  <span className="msg">
                    {errors.otherDescHullConstr?.message}
                  </span>
                </div>
              )}
            </>
          ) : (
            ""
          )}

          <div
            className={
              errors.hullColour?.message
                ? "br-input-group error"
                : !getFieldState("hullColour").isDirty
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="hullColour">
              Hull Colour<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="hullColour"
              className="text-upper"
              {...register("hullColour", {
                required: {
                  value: true,
                  message: "Hull colour is required",
                },
              })}
            />
            <span className="msg">{errors.hullColour?.message}</span>
          </div>

          <div className="br-input-group">
            <label htmlFor="otherColour">Cabin/Other Colour</label>
            <input
              type="text"
              id="otherColour"
              className="text-upper"
              {...register("otherColour")}
            />
          </div>

          {isReRegisterCase === true ? (
            <div className="br-input-group">
              <label htmlFor="hullLength">
                Hull Length (metres)
              </label>
              <div className="br-field-readonly unSelectable text-upper">
                {getValues("hullLength")}
              </div>
              <span className="br-additional-text">
                The overall length of the motor boat measured from the forepart
                of the stem to the aft side of the tuck, stern or rudder,
                excluding any engine pods or bowsprits. MAST Legislation
                requires that vessels over 15m undergo additional approvals
                prior to registration. Additional questions relating to former
                and future use of the vessel and storage plans will be asked
                later in the questionnaire.
              </span>
            </div>
          ) : (
            <div
              className={
                errors.hullLength?.message
                  ? "br-input-group error"
                  : !getFieldState("hullLength").isDirty
                  ? "br-input-group"
                  : "br-input-group success"
              }
            >
              <label htmlFor="hullLength">
                Hull Length (metres) <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                step="0.01"
                id="hullLength"
                {...register("hullLength", {
                  required: {
                    value: true,
                    message: "Hull length is required",
                  },
                  pattern: {
                    value: /^[0-9.,]+$/,
                    message: "Only number allowed",
                  },
                })}
              />
              <span className="msg">{errors.hullLength?.message}</span>
              <span className="br-additional-text">
                The overall length of the motor boat measured from the forepart
                of the stem to the aft side of the tuck, stern or rudder,
                excluding any engine pods or bowsprits. MAST Legislation
                requires that vessels over 15m undergo additional approvals
                prior to registration. Additional questions relating to former
                and future use of the vessel and storage plans will be asked
                later in the questionnaire.
              </span>
            </div>
          )}

          {watch("hullLength") > 15 && (
            <div>
              <b>
                <span className="text-danger">*</span> MAST Legislation requires
                that vessels over 15m undergo additional approvals prior to
                registration. Additional questions relating to former and future
                use of the vessels and storage plans will be asked later in
                questionnaire.
              </b>
            </div>
          )}

          <div
            className={
              errors.hullLengthOverall?.message
                ? "br-input-group error"
                : !getFieldState("hullLengthOverall").isDirty
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="hullLengthOverall">
              Hull Length Overall (metres){" "}
              <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              step="0.01"
              id="hullLengthOverall"
              {...register("hullLengthOverall", {
                required: {
                  value: true,
                  message: "Hull length overall is required",
                },
                pattern: {
                  value: /^[0-9.,]+$/,
                  message: "Only number allowed",
                },
              })}
            />
            <span className="msg">{errors.hullLengthOverall?.message}</span>
            <span className="br-additional-text">
              The hull length overall is the hull length including engine pods,
              bowsprits, davits etc. This measure of length is primarily used in
              the management of moorings and is not used for mandatory wearing
              of life jackets for {"<"} 6 meters vessels.
            </span>
          </div>

          {isReRegisterCase === true &&
          !isBeamEditable ? (
            <div className="br-input-group">
              <label htmlFor="beam">
                Beam (metres)
              </label>
              <div className="br-field-readonly unSelectable text-upper">
                {getValues("beam")}
              </div>
            </div>
          ) : (
            <div
              className={
                errors.beam?.message
                  ? "br-input-group error"
                  : !getFieldState("beam").isDirty
                  ? "br-input-group"
                  : "br-input-group success"
              }
            >
              <label htmlFor="beam">
                Beam (metres) <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                step="0.01"
                id="beam"
                {...register("beam", {
                  required: {
                    value: true,
                    message: "Beam overall is required",
                  },
                  pattern: {
                    value: /^[0-9.,]+$/,
                    message: "Only number allowed",
                  },
                })}
              />
              <span className="msg">{errors.beam?.message}</span>
            </div>
          )}

          <div className="br-input-group">
            <label htmlFor="draft">Draft (metres)</label>
            <input
              type="number"
              step="0.01"
              id="draft"
              {...register("draft", {
                pattern: {
                  value: /^[0-9.,]+$/,
                  message: "Only number allowed",
                },
              })}
            />
            <span className="msg">{errors.draft?.message}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Manufacturer;
