import DeclarationLaw2023 from "../DeclarationLaw2023";
import SellerFormBuyerContacts from './SellerFormBuyerContacts';
import BuyerDetailReadOnly from "./BuyerDetailReadOnly";

//For buyer information is used for seller form
const Buyer = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  setError,
  clearErrors,
  uploadSizeLimit,
  resBuyers,
  isCompanyFieldName,
  setValue
}) => {

  return (
    <>
      {resBuyers != null && resBuyers?.length > 0 ? (
        <BuyerDetailReadOnly
          title="New Register Operator (Buyer)"
          client={resBuyers[0]}
        />
      ) : (
        <SellerFormBuyerContacts
          register={register}
          watch={watch}
          control={control}
          errors={errors}
          getFieldState={getFieldState}
          clearErrors={clearErrors}
          isCompanyFieldName={isCompanyFieldName}
          setError={setError}
          uploadSizeLimit={uploadSizeLimit}
          setValue={setValue}

        />

      )}

      <div className="br-box">
        <div className="br-title">Declaration</div>
        <DeclarationLaw2023 register={register} errors={errors} />
      </div>
    </>
  );
};

export default Buyer;
