import { useForm } from "react-hook-form";
import { usePost, useGet } from "../../Hooks/useFetch";
import { useNavigate, useLocation } from "react-router-dom";
import SubmitLoader from "../../Components/SubmitLoader";
import useToast from "../../Hooks/useToast";
import AlertMessage from "../../Components/AlertMessage";
import useGaEvent from "../../Hooks/useGaEvent";
import { useEffect } from "react";
import useCapsLock from "../../Hooks/useCapsLock";
import CapsLockTooltip from "../../Components/CapsLockTooltip";
import useAuth from "../../Hooks/useAuth";
import { secureText } from "../../Helpers/Helper";

const PasswordChangeForm = () => {
  //it requires at least one lower and uppercase and digit and one special character between 8 and 24 characters.
    const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^]).{8,24}$/;

  //Test for all characters and limit 50 lengths
//   const PWD_REGEX = /^.{1,50}$/;

  const [res, err, isPostLoading, apiPost, abt] = usePost();
  const [resPna, errPna, isGetLoading, apiGet, abtPna] = useGet();
  const { isCapsLockOn, checkCapsLock } = useCapsLock();
  const gaEvents = useGaEvent();
  const toastEmitter = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useAuth();

  let initialValues = {};
  if (process.env.REACT_APP_TEST_DEFAULT_VALUE === "on") {
    initialValues = {
      oldPassword:"",
      password: "Abcd123!",
      newPassword: "Abcd123!",
    };
  }

  const {
    control,
    register,
    formState: { errors, isDirty, isValid, dirtyFields },
    handleSubmit,
    watch,
    getFieldState,
    setValue,
    setError,
    clearErrors,
  } = useForm({ defaultValues: initialValues, mode: "onChange" });

  useEffect(() => {
    apiGet("/api/pna/PnaDecryptedData");
    gaEvents.pnaChangePwdVisit();
  }, []);

  const updatePasswordSuccess = () => {
    gaEvents.pnaChangePwdSuccess();
    toastEmitter("New password is successful updated!", "success");
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    
    await apiPost(
      "/api/auth/ChangePassword",
      {
        WWWId: res?.pnaWwwId,
        AccessToken: auth.accessToken,
        OldPassword: secureText(
          data.oldPassword?.trim(),
          process.env.REACT_APP_Encrypt_Key,
          process.env.REACT_APP_Encrypt_IV
        ),
        NewPassword: secureText(
          data.password?.trim(),
          process.env.REACT_APP_Encrypt_Key,
          process.env.REACT_APP_Encrypt_IV
        ),
        ConfirmPassword: secureText(
          data.newPassword?.trim(),
          process.env.REACT_APP_Encrypt_Key,
          process.env.REACT_APP_Encrypt_IV
        ),
      },
      false,
      "/",
      updatePasswordSuccess,
      gaEvents.pnaChangePwdFail
    );

    
  };

  return !isPostLoading ? (
    <div className="change-pwd-container">
      <div className="pna-details-container">
        <div className="pna-details-title-group">
          <h3 className="pna-details-title">Change Password</h3>
        </div>
        <AlertMessage message={err} />
        <form onSubmit={handleSubmit(onSubmit)} className="pna-updated-form">
        <div className="row">
            <div className="col-md-12">
              <div
                className={
                  errors.oldPassword?.message
                    ? "pna-updated-form-input-group error"
                    : !getFieldState("oldPassword").isDirty
                    ? "pna-updated-form-input-group"
                    : "pna-updated-form-input-group success"
                }
              >
                <label htmlFor="oldPassword">
                  Old Password
                  <span className="text-danger">*</span>
                </label>
                <CapsLockTooltip isCapsLockOn={isCapsLockOn}>
                  <input
                    type="password"
                    id="oldPassword"
                    onKeyUp={checkCapsLock}
                    {...register("oldPassword", {
                      required: {
                        value: true,
                        message: "Old Password is required",
                      }
                    })}
                  />
                </CapsLockTooltip>

                <span className="msg">{errors.oldPassword?.message}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div
                className={
                  errors.password?.message
                    ? "pna-updated-form-input-group error"
                    : !getFieldState("password").isDirty
                    ? "pna-updated-form-input-group"
                    : "pna-updated-form-input-group success"
                }
              >
                <label htmlFor="password">
                  New Password
                  <span className="text-danger">*</span>
                </label>
                <CapsLockTooltip isCapsLockOn={isCapsLockOn}>
                  <input
                    type="password"
                    id="password"
                    onKeyUp={checkCapsLock}
                    {...register("password", {
                      required: {
                        value: true,
                        message: "Password is required",
                      },
                      pattern: {
                        value: PWD_REGEX,
                        message:
                          "8 to 24 characters. Must include uppercase and lowercase letters, a number and a special character. Allowed special characters: !@#$%^",
                      },
                    })}
                  />
                </CapsLockTooltip>

                <span className="msg">{errors.password?.message}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div
                className={
                  errors.newPassword?.message
                    ? "pna-updated-form-input-group error"
                    : !getFieldState("newPassword").isDirty
                    ? "pna-updated-form-input-group"
                    : "pna-updated-form-input-group success"
                }
              >
                <label htmlFor="newPassword">
                  Confirm New Password<span className="text-danger">*</span>
                </label>
                <CapsLockTooltip isCapsLockOn={isCapsLockOn}>
                  <input
                    type="password"
                    id="newPassword"
                    onKeyUp={checkCapsLock}
                    {...register("newPassword", {
                      required: {
                        value: true,
                        message: "New Password is required",
                      },
                      pattern: {
                        value: PWD_REGEX,
                        message:
                          "8 to 24 characters. Must include uppercase and lowercase letters, a number and a special character. Allowed special characters: !@#$%^",
                      },
                      validate: {
                        pwdMismatch: (d) => d === watch('password') ? true : 'Password mismatch',
                      },
                    })}
                  />
                </CapsLockTooltip>
                <span className="msg">{errors.newPassword?.message}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <button
                type="button"
                className="cancel-btn"
                onClick={() =>
                  navigate("/", { state: { from: location }, replace: true })
                }
              >
                Cancel
              </button>
            </div>
            <div className="col-md-6" />
            <div className="col-md-3">
              <button
                type="submit"
                className="pna-updated-form-button"
                disabled={Object.keys(errors).length > 0}
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  ) : (
    <SubmitLoader isLoading={isPostLoading} />
  );
};

export default PasswordChangeForm;
