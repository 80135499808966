import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import { useParams } from "react-router-dom";
import MooringUpdatedForm from "../../Components/Mooring/MooringUpdatedForm";
import AlertMessage from "../../Components/AlertMessage";

const MooringUpdated = () => {
  let { id } = useParams();
  const [res, err, isLoading, apiGet, abt] = useGet();

  useEffect(() => {
    apiGet("/api/mooring/MoMooringDetails/" + id);
  }, []);

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : err ? (
        <AlertMessage message={err} />
      ) : res ? (
        <div className="row">
          <div className="col-md-12">
            <MooringUpdatedForm moDetails={res}/>
          </div>
        </div>
      ) : (
        <div>No data</div>
      )}
    </>
  );
};

export default MooringUpdated;
