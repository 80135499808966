import { ClipLoader } from "react-spinners";

const SubmitLoader = ({ isLoading }) => {
  const loaderOverride = {
    'loading': 'true',
    'cssOverride': '{}',
    'speedMultiplier': '1',
    'zIndex': 1,
    'position': 'fixed',
    'top': 'calc(50% - 35px)',
    'left': 'calc(50% - 35px)',
    'transform': 'translate(-50%, -50%)',

};

  const loaderContainer = {
    opacity:"0.8",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    backgroundColor: "rgb(240, 240, 240, 0.8)",
    // 'z-index': "-1060", 
    position: "absolute",
  }

  return (
    <div style={loaderContainer}>
      <ClipLoader
        color="#36d7b7"
        loading={isLoading}
        size={70}
        cssOverride={loaderOverride}
        aria-label="Submit Loading"
        data-testid="loader"
      />
    </div>
  );
};

export default SubmitLoader;
