import { BeatLoader } from "react-spinners";



const DataLoader = ({ isLoading }) => {
  const override = {
    loading: true,
    cssOverride: {},
    speedMultiplier: 1,
  };

  return (
    <BeatLoader
      color="#36d7b7"
      loading={isLoading}
      size={15}
      cssOverride={override}
      aria-label="Data Loading"
      data-testid="loader"
    />
  );
};

export default DataLoader;
