import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import { getddmmyyyyFormat } from "../../Helpers/Helper";
import BackRedirect from "../BackRedirect";
import DataLoader from "../DataLoader";
import useGaEvent from "../../Hooks/useGaEvent";
import AlertMessage from "../AlertMessage";

const RecVesselPaymentDetails = () => {
  let { id, sequNo } = useParams();
  const [res, err, isLoading, apiGet, abt] = useGet();
  const gaEvents = useGaEvent();

  useEffect(() => {
    apiGet("/api/pcraft/PcCraftPaymentDetail/" + id + "/" + sequNo);
    gaEvents.RecreationalPaymentVisit();
  }, []);

  return (
    <div className="recvessel-sub-container">
      <div className="recvessel-title-group">
        <h3 className="recvessel-title">Payment Details</h3>
      </div>

      {!isLoading ? (
        !err ? (
          <>
            <div className="row">
              <div className="col-md-4">
                <div className="recvessel-input-group">
                  <label htmlFor="">PC Rego No.</label>
                  <div className="recvesselInfoField">{id}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="recvessel-input-group">
                  <label htmlFor="RenewNo">Renew No.</label>
                  <div className="recvesselInfoField">{res?.pyRenewNo}</div>
                </div>
              </div>
              {/* <div className="col-md-4">
                <div className="recvessel-input-group">
                  <label htmlFor="SequNo">Sequ No.</label>
                  <div className="recvesselInfoField">{res?.pySequNo}</div>
                </div>
              </div> */}
              <div className="col-md-4">
                <div className="recvessel-input-group">
                  <label htmlFor="Source">Payment method</label>
                  <div className="recvesselInfoField">{res?.pySource}</div>
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-md-4">
                <div className="recvessel-input-group">
                  <label htmlFor="PayType">Pay Type</label>
                  <div className="recvesselInfoField">{res?.pyType}</div>
                </div>
              </div> */}
              <div className="col-md-6">
                <div className="recvessel-input-group">
                  <label htmlFor="DatePay">Date Paid</label>
                  <div className="recvesselInfoField">{getddmmyyyyFormat(res?.pyDate?.split("T")[0])}</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="recvessel-input-group">
                  <label htmlFor="Amount">Amount</label>
                  <div className="recvesselInfoField">{Math.abs(res?.pyAmt).toFixed(2)}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                  <div className="recvessel-input-group">
                    <label htmlFor="Ref">Reference</label>
                    <div className="recvesselInfoField">{res?.pyRef}</div>
                  </div>
                </div>
            </div>
            <BackRedirect url={"/recvessel/recvesseldetails/" + id} />
          </>
        ) : (
          <AlertMessage message={err}/>
        )
      ) : (
        <DataLoader isLoading={isLoading} />
      )}
    </div>
  );
};

export default RecVesselPaymentDetails;
