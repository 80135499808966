import { useState, useEffect, useRef } from "react";
import useUserInfo from "../../Hooks/useUserInfo";
import { axiosApi } from "../../Api/Axios";
import { useNavigate } from "react-router-dom";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useCapsLock from "../../Hooks/useCapsLock";
import CapsLockTooltip from "../CapsLockTooltip";
import { secureText } from "../../Helpers/Helper";
import TimerCountDown from "../TimerCountDown";

//it requires at least one lower and uppercase and digit and one special character between 8 and 24 characters.
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^]).{8,24}$/;

//Test for all characters and limit 50 lengths
// const PWD_REGEX = /^.{1,50}$/;

const UpdatePasswordResetForm = () => {
  const { userInfo, setUserInfo } = useUserInfo();
  const {isCapsLockOn, checkCapsLock} = useCapsLock();

  const [errMsg, setErrMsg] = useState("");
  const [resErrMsg, setResErrMsg] = useState(false);
  const errRef = useRef();
  const navigate = useNavigate();

  const [newPwd, setNewPwd] = useState("");
  const [validNewPwd, setValidNewPwd] = useState(false);
  const [newPwdFocus, setNewPwdFocus] = useState(false);

  const [matchNewPwd, setMatchNewPwd] = useState("");
  const [validMatchNewPwd, setValidMatchNewPwd] = useState(false);
  const [matchNewPwdFocus, setMatchNewPwdFocus] = useState(false);

  // console.log(auth);

  useEffect(() => {
    const result = PWD_REGEX.test(newPwd);
    // console.log(result);
    setValidNewPwd(result);
    const match = newPwd === matchNewPwd;
    setValidMatchNewPwd(match);
  }, [newPwd, matchNewPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [newPwd, matchNewPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const checkValid = PWD_REGEX.test(newPwd);
    if (!checkValid) {
      setErrMsg("Invalid Password format");
      return;
    }
    try {
      const response = await axiosApi.post(
        "/api/auth/ResetPassword",
        {
          WWWId: userInfo.wwwId,
          AccessToken: userInfo.accessToken,
          NewPassword: secureText(newPwd.trim(), process.env.REACT_APP_Encrypt_Key, process.env.REACT_APP_Encrypt_IV),
          ConfirmPassword: secureText(matchNewPwd.trim(), process.env.REACT_APP_Encrypt_Key, process.env.REACT_APP_Encrypt_IV),
        },
        {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + userInfo.accessToken,
          },
        }
      );

      if (response == null || response.status === 500) {
        setErrMsg("No Server response");
        setResErrMsg(true);
      } else if (response.status === 200) {
        setUserInfo({});
        navigate("/passwordreset/pwdresetsuccess", { replace: true });
        // console.log(auth);
        // console.log(response.data);
      } else {
        setErrMsg(response.data);
        setResErrMsg(true);
        errRef.current.focus();
      }
    } catch (err) {
      setResErrMsg(true);
      if (err.response.status === 401) {
        setErrMsg("Session Expired");
      } else {
        setErrMsg(err.response.data);
      }
      // console.log(err);
    }
  };

  return (
    <div className="update-pwd-container">
      <p
        ref={errRef}
        className={errMsg ? "errmsg" : "offscreen"}
        aria-live="assertive"
      >
        {errMsg}
      </p>
      <h2 className="update-pwd-title">Login ID: {userInfo.wwwId}</h2>
      <form onSubmit={handleSubmit} className="update-pwd-form">
        <div
          className={
            validNewPwd
              ? "update-pwd-input-group success"
              : newPwd !== ""
              ? "update-pwd-input-group error"
              : "update-pwd-input-group"
          }
        >
          <label htmlFor="newpassword">
            New Password
            <FontAwesomeIcon
              icon={faCheck}
              className={validNewPwd ? "valid" : "hide"}
            />
            <FontAwesomeIcon
              icon={faTimes}
              className={validNewPwd || !newPwd ? "hide" : "invalid"}
            />
          </label>
          <CapsLockTooltip isCapsLockOn={isCapsLockOn}>
            <input
            type="password"
            name="newpwd"
            value={newPwd}
            id="newpassword"
            required
            aria-invalid={validNewPwd ? "false" : "true"}
            aria-describedby="newpwdnote"
            onKeyUp={checkCapsLock}
            onFocus={() => setNewPwdFocus(true)}
            onBlur={() => setNewPwdFocus(false)}
            onChange={(e) => setNewPwd(e.target.value)}
          />
          </CapsLockTooltip>
          
          <div
            id="newpwdnote"
            className={
              newPwdFocus && !validNewPwd ? "instructions" : "offscreen"
            }
          >
            <FontAwesomeIcon icon={faInfoCircle} />
            8 to 24 characters.
            <br />
            Must include uppercase and lowercase letters, a number and a special
            character.
            <br />
            Allowed special characters:{" "}
            <span aria-label="exclamation mark">!</span>{" "}
            <span aria-label="at symbol">@</span>{" "}
            <span aria-label="hashtag">#</span>{" "}
            <span aria-label="dollar sign">$</span>{" "}
            <span aria-label="percent">%</span>
            <span aria-label="upper arrow">^</span>
          </div>
        </div>
        <div
          className={
            validMatchNewPwd && matchNewPwd
              ? "update-pwd-input-group success"
              : matchNewPwd !== ""
              ? "update-pwd-input-group error"
              : "update-pwd-input-group"
          }
        >
          <label htmlFor="matchnewpwd">
            Confirm Password
            <FontAwesomeIcon
              icon={faCheck}
              className={validMatchNewPwd && matchNewPwd ? "valid" : "hide"}
            />
            <FontAwesomeIcon
              icon={faTimes}
              className={validMatchNewPwd || !matchNewPwd ? "hide" : "invalid"}
            />
          </label>
          <CapsLockTooltip isCapsLockOn={isCapsLockOn}>
            <input
            type="password"
            name="matchnewpwd"
            id="matchnewpwd"
            value={matchNewPwd}
            required
            aria-invalid={validMatchNewPwd ? "false" : "true"}
            aria-describedby="confirmnote"
            onKeyUp={checkCapsLock}
            onChange={(e) => setMatchNewPwd(e.target.value)}
            onFocus={() => setMatchNewPwdFocus(true)}
            onBlur={() => setMatchNewPwdFocus(false)}
          />
          </CapsLockTooltip>
          
          <div
            id="confirmnote"
            className={
              matchNewPwdFocus && !validMatchNewPwd
                ? "instructions"
                : "offscreen"
            }
          >
            <FontAwesomeIcon icon={faInfoCircle} />
            Mismatch the password.
          </div>
        </div>
        <button
          className="update-pwd-button"
          disabled={!validNewPwd || !validMatchNewPwd ? true : false}
        >
          {!resErrMsg ? (<span>Submit ({<TimerCountDown min={userInfo.countDownTime}/> })</span>):("Submit")}
        </button>
      </form>
    </div>
  );
};

export default UpdatePasswordResetForm;
