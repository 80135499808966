import ReactGA from "react-ga4";

const useGaEvent = () => {
  const authLoginVisit = () =>
    ReactGA.event({
      category: "Authentication",
      action: "User Login visit",
    });
  const authLoginSuccess = () =>
    ReactGA.event({
      category: "Authentication",
      action: "User Login Successful",
    });
  const authLoginFail = () =>
    ReactGA.event({
      category: "Authentication",
      action: "User Login Fail",
    });
  const authRegisterVisit = () =>
    ReactGA.event({
      category: "Authentication",
      action: "User Register visit",
    });
  const authRegisterNoUserFound = () =>
    ReactGA.event({
      category: "Authentication",
      action: "User Register not found",
    });
  const authRegisterSuccess = () =>
    ReactGA.event({
      category: "Authentication",
      action: "User Register Successful",
    });
  const authRegisterFail = () =>
    ReactGA.event({
      category: "Authentication",
      action: "User Register Fail",
    });
  const authRegisterFailOnMultipleUsers = () =>
    ReactGA.event({
      category: "Authentication",
      action: "User Register Fail on multiple users",
    });
  const authResetPwdVisit = () =>
    ReactGA.event({
      category: "Authentication",
      action: "User Reset Password visit",
    });
  const authResetPwdSuccess = () =>
    ReactGA.event({
      category: "Authentication",
      action: "User Reset Password Successful",
    });
  const authResetPwdFail = () =>
    ReactGA.event({
      category: "Authentication",
      action: "User Reset Password Fail",
    });
  const authResetPwdFailOnMultipleUsers = () =>
    ReactGA.event({
      category: "Authentication",
      action: "User Reset Password Fail on multiple users",
    });
  const pnaChangePwdVisit = () =>
    ReactGA.event({
      category: "PNA",
      action: "User Change Password visit",
    });
  const pnaChangePwdSuccess = () =>
    ReactGA.event({
      category: "PNA",
      action: "User Change Password Successful",
    });
  const pnaChangePwdFail = () =>
    ReactGA.event({
      category: "PNA",
      action: "User Change Password Fail",
    });
  const pnaDetailUpdateVisit = () =>
    ReactGA.event({
      category: "PNA",
      action: "User pna updated visit",
    });
  const pnaDetailUpdateSuccess = () =>
    ReactGA.event({
      category: "PNA",
      action: "User pna updated Successful",
    });
  const pnaDetailUpdateFail = () =>
    ReactGA.event({
      category: "PNA",
      action: "User pna updated Fail",
    });
  const pnaSubscriptionSuccess = () =>
    ReactGA.event({
      category: "PNA",
      action: "User subscription updated Successful",
    });

  const pnaSubscriptionFail = () =>
    ReactGA.event({
      category: "PNA",
      action: "User subscription updated Fail",
    });

  const transferSellerApproveSuccess = () =>
    ReactGA.event({
      category: "Transfer",
      action: "Seller approve Successful",
    });

  const transferSellerApproveFail = () =>
    ReactGA.event({
      category: "Transfer",
      action: "Seller approve Fail",
    });

  const transferSellerRejectSuccess = () =>
    ReactGA.event({
      category: "Transfer",
      action: "Seller reject Successful",
    });

  const transferSellerRejectFail = () =>
    ReactGA.event({
      category: "Transfer",
      action: "Seller reject Fail",
    });

  const transferSellerInitialSuccess = () =>
    ReactGA.event({
      category: "Transfer",
      action: "Seller initial Successful",
    });

  const transferSellerInitialFail = () =>
    ReactGA.event({
      category: "Transfer",
      action: "Seller initial Fail",
    });

  const transferBuyerApproveSuccess = () =>
    ReactGA.event({
      category: "Transfer",
      action: "Buyer approve Successful",
    });

  const transferBuyerApproveFail = () =>
    ReactGA.event({
      category: "Transfer",
      action: "Buyer approve Fail",
    });

  const transferBuyerRejectSuccess = () =>
    ReactGA.event({
      category: "Transfer",
      action: "Buyer reject Successful",
    });

  const transferBuyerRejectFail = () =>
    ReactGA.event({
      category: "Transfer",
      action: "Buyer reject Fail",
    });

  const transferBuyerInitialSuccess = () =>
    ReactGA.event({
      category: "Transfer",
      action: "Buyer initial Successful",
    });

  const transferBuyerInitialFail = () =>
    ReactGA.event({
      category: "Transfer",
      action: "Buyer initial Fail",
    });

  const transferSellerDisposalSuccess = () =>
    ReactGA.event({
      category: "Transfer",
      action: "Seller disposal Successful",
    });

  const transferSellerDisposalFail = () =>
    ReactGA.event({
      category: "Transfer",
      action: "Seller disposal Fail",
    });

  const licencePrintSuccess = () =>
    ReactGA.event({
      category: "Licence",
      action: "Licence print Successful",
    });

  const licencePrintFail = () =>
    ReactGA.event({
      category: "Licence",
      action: "Licence print Fail",
    });

  const licenceUpdateSuccess = () =>
    ReactGA.event({
      category: "Licence",
      action: "Licence Update Successful",
    });

  const licenceUpdateFail = () =>
    ReactGA.event({
      category: "Licence",
      action: "Licence Update Fail",
    });

  const licencePaymentVisit = () =>
    ReactGA.event({
      category: "Licence",
      action: "Licence payment visit",
    });

  const RecreationalPrintSuccess = () =>
    ReactGA.event({
      category: "Recreational",
      action: "Recreational print Successful",
    });

  const RecreationalPrintFail = () =>
    ReactGA.event({
      category: "Recreational",
      action: "Recreational print Fail",
    });

  const RecreationalDetailVisit = () =>
    ReactGA.event({
      category: "Recreational",
      action: "User Recreational visit",
    });

  const RecreationalDetailUpdateSuccess = () =>
    ReactGA.event({
      category: "Recreational",
      action: "User Recreational updated Successful",
    });

  const RecreationalDetailUpdateFail = () =>
    ReactGA.event({
      category: "Recreational",
      action: "User Recreational updated Fail",
    });

  const RecEngineDetailVisit = () =>
    ReactGA.event({
      category: "Recreational",
      action: "User Recreational Engine visit",
    });

  const RecEngineDetailUpdateSuccess = () =>
    ReactGA.event({
      category: "Recreational",
      action: "User Recreational Engine updated Successful",
    });

  const RecEngineDetailUpdateFail = () =>
    ReactGA.event({
      category: "Recreational",
      action: "User Recreational Engine updated Fail",
    });

  const RecEngineAddVisit = () =>
    ReactGA.event({
      category: "Recreational",
      action: "User Recreational Engine Add visit",
    });

  const RecEngineAddSuccess = () =>
    ReactGA.event({
      category: "Recreational",
      action: "User Recreational Engine Add Successful",
    });

  const RecEngineAddFail = () =>
    ReactGA.event({
      category: "Recreational",
      action: "User Recreational Engine Add Fail",
    });

  const RecreationalPaymentVisit = () =>
    ReactGA.event({
      category: "Recreational",
      action: "User Recreational payment visit",
    });

  const RecreationalReRegistrationAddSuccess = () =>
    ReactGA.event({
      category: "Recreational",
      action: "User Re-Recreational vessel Add Successful",
    });

  const RecreationalReRegistrationAddFail = () =>
    ReactGA.event({
      category: "Recreational",
      action: "User Re-Recreational vessel Add Fail",
    });
    
  const RecreationalReNewRegistrationAddSuccess = () =>
    ReactGA.event({
      category: "Recreational",
      action: "User New Re-Recreational vessel Add Successful",
    });

  const RecreationalReNewRegistrationAddFail = () =>
    ReactGA.event({
      category: "Recreational",
      action: "User New Re-Recreational vessel Add Fail",
    });

  const RecreationalAddVisit = () =>
    ReactGA.event({
      category: "Recreational",
      action: "User Recreational vessel Add visit",
    });

  const RecreationalAddSuccess = () =>
    ReactGA.event({
      category: "Recreational",
      action: "User Recreational vessel Add Successful",
    });

  const RecreationalAddFail = () =>
    ReactGA.event({
      category: "Recreational",
      action: "User Recreational vessel Add Fail",
    });

  const MooringDetailVisit = () =>
    ReactGA.event({
      category: "Mooring",
      action: "User mooring visit",
    });

  const MooringPositionUpdateSuccess = () =>
    ReactGA.event({
      category: "Mooring",
      action: "User mooring Position update Successful",
    });

  const MooringPositionUpdateFail = () =>
    ReactGA.event({
      category: "Mooring",
      action: "User mooring Position update Fail",
    });

  const MooringPrimaryVesselUpdatedVisit = () =>
    ReactGA.event({
      category: "Mooring",
      action: "User mooring primary vessel updated visit",
    });

  const MooringPrimaryVesselUpdatedSuccess = () =>
    ReactGA.event({
      category: "Mooring",
      action: "User mooring primary vessel updated Successful",
    });

  const MooringPrimaryVesselUpdatedFail = () =>
    ReactGA.event({
      category: "Mooring",
      action: "User mooring primary vessel updated Fail",
    });

  const MooringPrimaryVesselAddVisit = () =>
    ReactGA.event({
      category: "Mooring",
      action: "User mooring primary vessel Add visit",
    });

  const MooringPrimaryVesselAddSuccess = () =>
    ReactGA.event({
      category: "Mooring",
      action: "User mooring primary vessel Add Successful",
    });

  const MooringPrimaryVesselAddFail = () =>
    ReactGA.event({
      category: "Mooring",
      action: "User mooring primary vessel Add Fail",
    });

  const MooringPaymentVisit = () =>
    ReactGA.event({
      category: "Mooring",
      action: "User Mooring payment visit",
    });

  const CommercialUpdateVisit = () =>
    ReactGA.event({
      category: "Commercial",
      action: "User Commercial vessel update Visit",
    });

  const CommercialUpdateSuccess = () =>
    ReactGA.event({
      category: "Commercial",
      action: "User Commercial vessel update Successful",
    });

  const CommercialUpdateFail = () =>
    ReactGA.event({
      category: "Commercial",
      action: "User Commercial vessel update Fail",
    });

  const CommercialPaymentVisit = () =>
    ReactGA.event({
      category: "Commercial",
      action: "User Commercial vessel payment visit",
    });

  const ShopVisit = () =>
    ReactGA.event({
      category: "Shop",
      action: "User Shop store visit",
    });

  const ShopPaidSuccess = () =>
    ReactGA.event({
      category: "Shop",
      action: "User Shop store paid Success",
    });

  const ShopPaidFail = () =>
    ReactGA.event({
      category: "Shop",
      action: "User Shop store paid Fail",
    });

  const gaEvents = {
    authLoginVisit,
    authLoginSuccess,
    authLoginFail,
    authRegisterVisit,
    authRegisterNoUserFound,
    authRegisterSuccess,
    authRegisterFail,
    authRegisterFailOnMultipleUsers,
    authResetPwdVisit,
    authResetPwdSuccess,
    authResetPwdFail,
    authResetPwdFailOnMultipleUsers,
    pnaChangePwdVisit,
    pnaChangePwdSuccess,
    pnaChangePwdFail,
    pnaDetailUpdateVisit,
    pnaDetailUpdateSuccess,
    pnaDetailUpdateFail,
    pnaSubscriptionSuccess,
    pnaSubscriptionFail,
    transferSellerApproveSuccess,
    transferSellerApproveFail,
    transferSellerRejectSuccess,
    transferSellerRejectFail,
    transferSellerInitialSuccess,
    transferSellerInitialFail,
    transferBuyerApproveSuccess,
    transferBuyerApproveFail,
    transferBuyerRejectSuccess,
    transferBuyerRejectFail,
    transferBuyerInitialSuccess,
    transferBuyerInitialFail,
    transferSellerDisposalSuccess,
    transferSellerDisposalFail,
    licencePrintSuccess,
    licencePrintFail,
    licenceUpdateSuccess,
    licenceUpdateFail,
    licencePaymentVisit,
    RecreationalReRegistrationAddSuccess,
    RecreationalReRegistrationAddFail,
    RecreationalReNewRegistrationAddSuccess,
    RecreationalReNewRegistrationAddFail,
    RecreationalPrintSuccess,
    RecreationalPrintFail,
    RecreationalDetailVisit,
    RecreationalDetailUpdateSuccess,
    RecreationalDetailUpdateFail,
    RecEngineDetailVisit,
    RecEngineDetailUpdateSuccess,
    RecEngineDetailUpdateFail,
    RecEngineAddVisit,
    RecEngineAddSuccess,
    RecEngineAddFail,
    RecreationalPaymentVisit,
    RecreationalAddVisit,
    RecreationalAddSuccess,
    RecreationalAddFail,
    MooringDetailVisit,
    MooringPositionUpdateSuccess,
    MooringPositionUpdateFail,
    MooringPrimaryVesselUpdatedVisit,
    MooringPrimaryVesselUpdatedSuccess,
    MooringPrimaryVesselUpdatedFail,
    MooringPrimaryVesselAddVisit,
    MooringPrimaryVesselAddSuccess,
    MooringPrimaryVesselAddFail,
    MooringPaymentVisit,
    CommercialUpdateVisit,
    CommercialUpdateSuccess,
    CommercialUpdateFail,
    CommercialPaymentVisit,
    ShopVisit,
    ShopPaidSuccess,
    ShopPaidFail,
  };

  return gaEvents;
};

export default useGaEvent;
