import { usePost } from "../../Hooks/useFetch";
import { useForm } from "react-hook-form";
import { faFloppyDisk, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDDM } from "../../Helpers/Helper";
import DataLoader from "../DataLoader";
import useGaEvent from "../../Hooks/useGaEvent";
import useToast from "../../Hooks/useToast";
import AlertMessage from "../AlertMessage";

const MooringPositionUpdatedForm = ({ moPosition, setEditMode, setReload }) => {
  const [res, err, isLoadingPost, apiPost, abt] = usePost();
  const gaEvents = useGaEvent();
  const toastEmitter = useToast();
  const initialValues = {
    moBouyColour: moPosition.moBouyColour,
  };

  const { register, handleSubmit } = useForm({
    defaultValues: initialValues,
    mode: "onChange",
  });

  const extraFunc = () => {
    setReload(true);
    setEditMode(false);
    gaEvents.MooringPositionUpdateSuccess();
    toastEmitter("Position is successful updated!", "success");
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    // console.log(data);
    await apiPost(
      "/api/mooring/updateMoPosition",
      {
        MoRegoNo: moPosition.moRegoNo,
        MoSequNo: moPosition.moSequNo,
        MoBouyColour: data.moBouyColour,
      },
      false,
      "/mooring/mooringdetails/" + moPosition.moRegoNo, //redirect to home page
      extraFunc,
      gaEvents.MooringPositionUpdateFail
    );
  };

  return (
    <>
      {!isLoadingPost ? (
        <>
          <AlertMessage message={err} />
          <form
            className="mooring-updated-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="row">
              <div className="col-md-2">
                <div className="mooring-input-group">
                  <label htmlFor="type">Type</label>
                  <div className="mooringInfoField">
                    {moPosition?.moMoorType}
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="mooring-input-group">
                  <label htmlFor="desc">Desc</label>
                  <div className="mooringInfoField">{moPosition?.moDescr}</div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="mooring-input-group">
                  <label htmlFor="latddm">Lat DDM</label>
                  <div className="mooringInfoField">
                    {formatDDM(moPosition?.moDecLatitude)}
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="mooring-input-group">
                  <label htmlFor="longddm">Long DDM</label>
                  <div className="mooringInfoField">
                    {formatDDM(moPosition?.moDecLongitude)}
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="mooring-updated-input-group">
                  <label htmlFor="moBouyColour">Colour</label>
                  <input
                    type="text"
                    id="moBouyColour"
                    {...register("moBouyColour")}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="mooring-updated-input-group">
                  <label htmlFor="action" className="invisible">
                    Action
                  </label>
                  <div className="mooring-icon-group">
                    <button type="button">
                      <FontAwesomeIcon
                        icon={faXmark}
                        className="icon"
                        size="2x"
                        onClick={() => setEditMode(false)}
                      />
                    </button>
                    <button type="submit">
                      <FontAwesomeIcon
                        icon={faFloppyDisk}
                        className="icon"
                        size="2x"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      ) : (
        <DataLoader isLoading={isLoadingPost} />
      )}
    </>
  );
};

export default MooringPositionUpdatedForm;
