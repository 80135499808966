import { useRef, useState, useEffect } from "react";
import { axiosApi } from "../../Api/Axios";
import useUserInfo from "../../Hooks/useUserInfo";
import { useNavigate } from "react-router-dom";
import "../../Components/PasswordReset/PasswordReset.css";
import useGaEvent from "../../Hooks/useGaEvent";
import DataLoader from "../../Components/DataLoader";

const PasswordReset = () => {
  const [UserWWWId, setUserWWWId] = useState("");
  // const [UserWWWId, setUserWWWId] = useState("L124H73Y");
  const [IsRememberId, setIsRememberId] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { setUserInfo } = useUserInfo();
  const [errMsg, setErrMsg] = useState("");
  const errRef = useRef();
  const gaEvents = useGaEvent();

  const navigate = useNavigate();
  const destToMethod = "/passwordreset/pwdresetmethod";
  const destToForm = "/passwordreset/pwdresetform";

  useEffect(() => {
    setErrMsg("");
  }, [UserWWWId, IsRememberId]);

  useEffect(() => {
    if (!IsRememberId) {
      setUserWWWId("");
    }
  }, [IsRememberId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (IsRememberId && (UserWWWId?.trim() == "" || UserWWWId == null)) {
      setErrMsg("Login ID is required");
      return;
    }

    //if clients don't remember their www id, then navigate to password reset form
    if (!IsRememberId) {
      navigate(destToForm, { replace: true });
      return;
    }

    //If client remembers his/her www ID
    try {
      setIsLoading(true);
      const response = await axiosApi.post("/api/auth/passwordReset-check", {
        WWWId: UserWWWId.trim(),
      });
      // console.log(response);

      if (!response) {
        setErrMsg("No Server Response");
      } else if (response.status === 200) {
        setUserInfo({ ...response.data, maskInfo: true, action: 3, isSentByEmail:true });
        gaEvents.authResetPwdVisit();
        navigate(destToMethod, { replace: true });
      } else {
        setErrMsg(response.data);
      }
    } catch (err) {
      gaEvents.authResetPwdFail();
      if (err.response.status === 401) {
        setErrMsg("Session Expired");
      } else {
        setErrMsg(err.response.data);
      }
      // console.error(err);
    }finally{
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="pwd-reset-bg">
        <div className="remId-container">
          <h2 className="remId-title">Password Reset</h2>
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <h4 className="remId-subtitle">Do you remember your Login ID?</h4>
          <form onSubmit={handleSubmit} className="remId-form">
            <div className="row">
              <div className="col-md-2 col-lg-2">
                <div className="remId-input-group">
                  <input
                    type="radio"
                    name="is_remember_id"
                    value="Yes"
                    id="Yes"
                    checked={IsRememberId}
                    onChange={() => setIsRememberId(true)}
                  />
                  <label htmlFor="Yes">Yes</label>
                </div>
              </div>
              <div className="col-md-10 col-lg-10">
                <div
                  className={IsRememberId ? "inline-show fullWidth" : "hide "}
                >
                  <input
                    type="text"
                    name="wwwId"
                    value={UserWWWId}
                    placeholder="Enter Login ID"
                    onChange={(e) => setUserWWWId(e.target.value)}
                    className={errMsg ? "error" : ""}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="remId-input-group">
                  <input
                    type="radio"
                    name="is_remember_id"
                    value="No"
                    id="No"
                    checked={!IsRememberId}
                    onChange={() => setIsRememberId(false)}
                  />
                  <label htmlFor="No">No</label>
                </div>
              </div>
            </div>

            <button className="remId-button">{!isLoading ? "Next" : <DataLoader isLoading={isLoading} />}</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default PasswordReset;
