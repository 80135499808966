import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import { getddmmyyyyFormat } from "../../Helpers/Helper";
import DataLoader from "../DataLoader";
import AlertMessage from "../AlertMessage";
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

const LicenceConditionList = () => {
  const [res, err, isLoading, apiGet, abt] = useGet();

  useEffect(() => {
    apiGet("/api/licence/LicenceConditions");
  }, []);

  // console.log(res);
  // console.log(uuidv4());

  return (
    <div className="licence-sub-container">
      <div className="licence-title-group">
        <h3 className="licence-title">Conditions</h3>
      </div>

      {!isLoading ? (
        !err ? (
          res?.length === 0 ? (
            <AlertMessage
                message="No Records"
                level="info"
                closeEvt="false"
                mt="0"
                mb="0"
              />
          ) : (
            res?.map((c) => (
              <>
                <div className="row" key={uuidv4()}>
                  <div className="col-md-2">
                    <div className="licence-input-group">
                      <label htmlFor="Current">Current</label>
                      <div className="licenceInfoField">
                        {c?.dlCurrent}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="licence-input-group">
                      <label htmlFor="InsertDate">Date</label>
                      <div className="licenceInfoField">
                        {getddmmyyyyFormat(c?.dlDateInserted?.split("T")[0])}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="licence-input-group">
                      <label htmlFor="Condition">Condition</label>
                      <div className="licenceInfoField">
                        {c?.dlText}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))
          )
        ) : (
          <AlertMessage message={err} />
        )
      ) : (
        <DataLoader isLoading={isLoading} />
      )}
    </div>
  );
};

export default LicenceConditionList;
