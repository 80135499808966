import { useState } from "react";

const useCapsLock = () => {
    
    const [isCapsLockOn, setIsCapsLockOn] = useState(false);

    // This function is triggered on the keyup event
    const checkCapsLock = (event) => {
        // console.log(event.getModifierState("CapsLock"));
        if (event.getModifierState("CapsLock")) {
            setIsCapsLockOn(true);
        } else {
            setIsCapsLockOn(false);
        }
    };
    
    return {isCapsLockOn, checkCapsLock};
}
 
export default useCapsLock;