import { useEffect } from "react";

const RadioDetails = ({ register, watch, control, errors, getFieldState }) => {
  // useEffect(() => {
  //   console.log(watch("sortRadio"));
  // }, [watch("sortRadio")]);

  return (
    <div className="br-box">
      <div className="br-title">Radio Details</div>
      <div className="br-field-group">
        <div className="br-input-group">
          <label>What sort of radio does this vessel have</label>
          <div className="br-field-checkbox-group">
            <div className="br-checkbox-group">
              <input
                type="checkbox"
                id="VHF"
                {...register("sortRadio")}
                value="VHF"
              />
              <label htmlFor="VHF" className="unSelectable">VHF</label>
            </div>
            <div className="br-checkbox-group">
              <input
                type="checkbox"
                id="HF"
                {...register("sortRadio")}
                value="HF"
              />
              <label htmlFor="HF" className="unSelectable">HF</label>
            </div>
            <div className="br-checkbox-group">
              <input
                type="checkbox"
                id="27MHz"
                {...register("sortRadio")}
                value="27MHz"
              />
              <label htmlFor="27MHz" className="unSelectable">27MHz</label>
            </div>
            <div className="br-checkbox-group">
              <input
                type="checkbox"
                id="SatComm"
                {...register("sortRadio")}
                value="SatComm"
              />
              <label htmlFor="SatComm" className="unSelectable">Sat Comm</label>
            </div>
          </div>

          {watch("sortRadio")?.length === 0 ? (
            <span className="br-additional-text">
              Please note a marine radio is required when operating beyond
              Sheltered Waters.
            </span>
          ) : (
            ""
          )}

          {watch("sortRadio").includes("VHF") ||
          watch("sortRadio").includes("HF") ? (
            <span className="br-additional-text">
              Please note a licence is required to operate HF and VHF radios.
              For more information of Maritime Ship Licence visit the {" "}
              <a
                href="https://www.acma.gov.au/licences/maritime-ship-licence"
                target="_blank"
                rel="noreferrer"
              >
                Australian Media and Communications Authority (ACMA)
              </a>{" "}
              website.
            </span>
          ) : (
            ""
          )}

          {watch("sortRadio").includes("27MHz") ? (
            <span className="br-additional-text">
              MAST does not recommend a 27MHz radio as the sole method of marine
              communications. 27MHz is not actively monitored and is not
              reliable due to interference.
            </span>
          ) : (
            ""
          )}
        </div>

        <div className="br-input-group">
          <label htmlFor="vesselSign">
            What is the vessel's call sign or MMSI
          </label>
          {/* <span className="br-desc-text">if applicable</span> */}
          <input type="text" id="vesselSign" className="text-upper" {...register("vesselSign")} />
          <span className="br-additional-text">
            Call signs are not mandatory, but if you have a radio call sign
            issued by ACMA or Tas Maritime Radio (e.g. RG123) or an MMSI issued
            by AMSA you can enter it here.
          </span>
        </div>
      </div>
    </div>
  );
};

export default RadioDetails;
