import Login from "./Pages/Logins/Login";
import LoginMethod from "./Pages/Logins/LoginMethod";
import LoginVerify from "./Pages/Logins/LoginVerify";

import Register from "./Pages/Registers/Register";
import RegisterMethod from "./Pages/Registers/RegisterMethod";
import RegisterVerify from "./Pages/Registers/RegisterVerify";
import RegisterResult from "./Pages/Registers/RegisterResult";
import RegisterPwdSuccess from "./Pages/Registers/RegisterPwdSuccess";

import PasswordReset from "./Pages/PasswordResets/PasswordReset";
import PasswordResetForm from "./Pages/PasswordResets/PasswordResetForm";
import PasswordResetMethod from "./Pages/PasswordResets/PasswordResetMethod";
import PasswordResetVerify from "./Pages/PasswordResets/PasswordResetVerify";
import PasswordResetResult from "./Pages/PasswordResets/PasswordResetResult";
import PasswordResetSuccess from "./Pages/PasswordResets/PasswordResetSuccess";
import PasswordChangeForm from "./Pages/ChangePassword/PasswordChangeForm";

import TransferNotUnsafeForm from "./Pages/Transferactions/TransferNotUnsafeForm";
import TransferNotUnsafeSuccess from "./Pages/Transferactions/TransferNotUnsafeSuccess";
import BoatRegistrationForm from "./Pages/BoatRegistrations/BoatRegistrationForm";
import BoatNewReRegistrationForm from "./Pages/BoatReNewRegistrations/BoatNewReRegistrationForm";
import BoatReRegistrationForm from "./Pages/BoatReRegistrations/BoatReRegistrationForm";
import BoatReRegiSuccess from "./Pages/BoatReRegistrations/BoatReRegiSuccess";
import BoatRegiSuccess from "./Pages/BoatRegistrations/BoatRegiSuccess";
import TransferOfRegistrationForm from "./Pages/Transferactions/TransferOfRegistrationForm";
import TransferOfRegistrationApprovalForm from "./Pages/Transferactions/TransferOfRegistrationApprovalForm";
import TransferOfNewOwnerForm from "./Pages/Transferactions/TransferOfNewOwnerForm";
import TransferOfNewOwnerApprovalForm from "./Pages/Transferactions/TransferOfNewOwnerApprovalForm";
import TransferOfDisposalForm from "./Pages/Transferactions/TransferOfDisposalForm";
import TransferComplete from "./Pages/Transferactions/TransferComplete";

import CheckCart from "./Pages/ShoppingCarts.js/CheckCart";
import ShoppingStore from "./Pages/ShoppingCarts.js/ShoppingStore";
import CartPaymentProcess from "./Pages/ShoppingCarts.js/CartPaymentProcess";
import Layout from "./Layouts/Layout";
import Home from "./Pages/Home";

import Licence from "./Pages/Licence";
import LicencePaymentDetails from "./Components/Licence/LicencePaymentDetails";
import LicencePWCendorsementDetails from "./Components/Licence/LicencePWCendorsementDetails";

import RecVessel from "./Pages/RecVessel";
import RecVesselList from "./Components/RecVessel_PWC/RecVesselList";
import RecVesselDetails from "./Components/RecVessel_PWC/RecVesselDetails";
import RecVesselUpdated from "./Pages/RecVessel/RecVesselUpdated";
import RecVesselPaymentList from "./Components/RecVessel_PWC/RecVesselPaymentList";
import RecVesselPaymentDetails from "./Components/RecVessel_PWC/RecVesselPaymentDetails";

import PeEngineDetails from "./Components/PeEngine/PeEngineDetails";
import PeEngineUpdated from "./Pages/RecVessel/PeEngineUpdated";
import PeEngineInsert from "./Pages/RecVessel/PeEngineInsert";

import Mooring from "./Pages/Mooring";
import MooringList from "./Components/Mooring/MooringList";
import MooringDetails from "./Components/Mooring/MooringDetails";
import MooringUpdated from "./Pages/Mooring/MooringUpdated";
import MooringPaymentList from "./Components/Mooring/MooringPaymentList";
import MooringPaymentDetails from "./Components/Mooring/MooringPaymentDetails";
import MooringPrimaryVesselUpdated from "./Pages/Mooring/MooringPrimaryVesselUpdated";
import MooringPrimaryVesselAdd from "./Pages/Mooring/MooringPrimaryVesselAdd";

import ComVessel from "./Pages/ComVessel";
import ComVesselList from "./Components/ComVessel_FAV/ComVesselList";
import ComVesselDetails from "./Components/ComVessel_FAV/ComVesselDetails";
import ComVesselPaymentList from "./Components/ComVessel_FAV/ComVesselPaymentList";
import ComVesselPaymentDetails from "./Components/ComVessel_FAV/ComVesselPaymentDetails";
import FaUpdated from "./Pages/Fa/FaUpdated";
import Missing from "./Components/Missing";

import { Route, Routes } from "react-router-dom";
import Unauthorized from "./Components/Unauthorized";
import RequireAuth from "./Components/RequireAuth";
import PersistLogin from "./Components/PersistLogin";
import Pna from "./Pages/Home";
import PnaUpdated from "./Pages/Pna/PnaUpdated";
import ComingSoon from "./Components/ComingSoon";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import TransferExistedCheck from "./Pages/Transferactions/TransferExistedCheck";




function App() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  });

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="login">
            <Route index element={<Login />} />
            <Route path="loginmethod" element={<LoginMethod />} />
            <Route path="loginverify" element={<LoginVerify />} />
          </Route>

          <Route path="register">
            <Route index element={<Register />} />
            <Route path="registermethod" element={<RegisterMethod />} />
            <Route path="registerverify" element={<RegisterVerify />} />
            <Route path="registerresult" element={<RegisterResult />} />
            <Route path="registerpwdsuccess" element={<RegisterPwdSuccess />} />
          </Route>

          <Route path="passwordreset">
            <Route index element={<PasswordReset />} />
            <Route path="pwdresetmethod" element={<PasswordResetMethod />} />
            <Route path="pwdresetform" element={<PasswordResetForm />} />
            <Route path="pwdresetverify" element={<PasswordResetVerify />} />
            <Route path="pwdresetresult" element={<PasswordResetResult />} />
            <Route path="pwdresetsuccess" element={<PasswordResetSuccess />} />
          </Route>

          <Route path="notunsafe"> 
            <Route path="tsf" element={<TransferNotUnsafeForm />} />
            <Route path="tsf/success" element={<TransferNotUnsafeSuccess />} />
          </Route>

          <Route path="unauthorized" element={<Unauthorized />} />
          
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth />}>
              <Route path="/" element={<Home />} />
              <Route path="shoppingcart" element={<ShoppingStore />} />
              <Route path="checkcart" element={<CheckCart />} />
              <Route path="cartpay" element={<CartPaymentProcess />} />

              <Route path="pwdchange" element={<PasswordChangeForm />} />

              <Route path="boatregistration">
                <Route index element={<BoatRegistrationForm />} />
                <Route path="boatregisuccess" element={<BoatRegiSuccess />} />
              </Route>

              <Route path="boatreregistration/:regoNo" element={<BoatReRegistrationForm />} />
              <Route path="boatreregiprocess/boatreregisuccess" element={<BoatReRegiSuccess/>} />
 
              <Route path="boatnewreregistration">
                <Route index element={<BoatNewReRegistrationForm />} />
              </Route>

              <Route path="transferseller">
                <Route index element={<TransferExistedCheck element={<TransferOfRegistrationForm />} requestUrl="/api/pna/WaitTransferSellers" approvedUser='buyer' approvingUser='seller' />} />
                <Route path="trasnsferdone" element={<TransferComplete />} />
              </Route>

              <Route path="transferseller/:regoNo" element={<TransferOfRegistrationApprovalForm />}>
                <Route path="trasnsferdone" element={<TransferComplete />} />
              </Route>

              <Route path="transferbuyer">
                <Route index element={<TransferExistedCheck element={<TransferOfNewOwnerForm />} requestUrl="/api/pna/WaitTransferBuyers" approvedUser='seller' approvingUser='buyer' />} />
                <Route path="trasnsferdone" element={<TransferComplete />} />
              </Route>

              <Route path="transferbuyer/:regoNo" element={<TransferOfNewOwnerApprovalForm />}>
                <Route path="trasnsferdone" element={<TransferComplete />} />
              </Route>

              <Route path="transferdisposal">
                <Route index element={<TransferOfDisposalForm />} />
                <Route path="trasnsferdone" element={<TransferComplete />} />
              </Route>

              {/* <Route path="transferseller">
                <Route index element={<ComingSoon />} />
              </Route>

              <Route path="transferbuyer">
                <Route index element={<ComingSoon />} />
              </Route>

              <Route path="transferdisposal">
                <Route index element={<ComingSoon />} />
              </Route> */}

              <Route path="pna" element={<Pna />} />
              <Route path="pnaupdate" element={<PnaUpdated />} />
              <Route path="licence" element={<Licence />}>
                <Route
                  path="licencepaymentdetails/:regoNo/:sequNo"
                  element={<LicencePaymentDetails />}
                />
                <Route
                  path="licencepwcendorsementdetails/:id"
                  element={<LicencePWCendorsementDetails />}
                />
              </Route>

              <Route path="recvessel" element={<RecVessel />}>
                <Route path="recvessellist" element={<RecVesselList />} />
                <Route
                  path="recvesselupdated/:id"
                  element={<RecVesselUpdated />}
                />
                <Route
                  path="recvesseldetails/:id"
                  element={<RecVesselDetails />}
                />
                <Route
                  path="peengineinsert/:regoNo"
                  element={<PeEngineInsert />}
                />
                <Route
                  path="peengineupdated/:regoNo/:sequNo"
                  element={<PeEngineUpdated />}
                />
                <Route
                  path="peenginedetails/:regoNo/:sequNo"
                  element={<PeEngineDetails />}
                />
                <Route
                  path="recvesselpaymentlist"
                  element={<RecVesselPaymentList />}
                />
                <Route
                  path="recvesselpaymentdetails/:id/:sequNo"
                  element={<RecVesselPaymentDetails />}
                />
              </Route>

              <Route path="mooring" element={<Mooring />}>
                <Route path="mooringlist" element={<MooringList />} />
                <Route path="mooringupdated/:id" element={<MooringUpdated />} />
                <Route path="mooringdetails/:id" element={<MooringDetails />} />
                <Route
                  path="mooringpaymentlist"
                  element={<MooringPaymentList />}
                />
                <Route
                  path="mooringpaymentdetails/:id/:sequNo"
                  element={<MooringPaymentDetails />}
                />
                <Route
                  path="mooringprimaryvesselupdated/:itemId/:seqId/:moRegoNo/:moAppLen"
                  element={<MooringPrimaryVesselUpdated />}
                />
                <Route
                  path="mooringprimaryvesselinsert/:itemId/:moRegoNo/:moAppLen"
                  element={<MooringPrimaryVesselAdd />}
                />
              </Route>

              <Route path="comvessel" element={<ComVessel />}>
                <Route path="comvessellist" element={<ComVesselList />} />
                <Route path="comvesselupdated/:id" element={<FaUpdated />} />
                <Route
                  path="comvesseldetails/:id"
                  element={<ComVesselDetails />}
                />
                <Route
                  path="comvesselpaymentlist"
                  element={<ComVesselPaymentList />}
                />
                <Route
                  path="comvesselpaymentdetails/:id/:uvi"
                  element={<ComVesselPaymentDetails />}
                />
              </Route>
            </Route>
          </Route>

          {/* catch all */}
          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
