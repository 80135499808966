import Modal from "../Modal/Modal";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Dialog from "../Modal/Dialog";
import { useState, useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import DataLoader from "../DataLoader";
import AlertMessage from "../AlertMessage";
import { getddmmyyyyFormat } from "../../Helpers/Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "../Modal/Modal.css";

const NewBoatPopupMessage = ({
  isOpen,
  setIsOpen,
  openDialog,
  setOpenDialog,
  dialogMsg
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedVesselType, setSelectedVesselType] = useState('');
  const [error, setError] = useState('');
  const [isSecondOpen, setIsSecondOpen] = useState(false);

  const [
    resExpPCrafts,
    errExpPCrafts,
    isLoadingExpPCrafts,
    apiGetExpPCrafts,
    abtExpPCrafts,
  ] = useGet();

  //console.log(resExpPCrafts);

  useEffect(() => {
    // console.log('load pc 12mths');
    apiGetExpPCrafts("/api/pcraft/PcCraftListExpiry12Mths");
  }, []);

  const navEvt = () => {
    if(selectedVesselType === '')
    {
      setError('Please select one of options at least.');
    }

    if(selectedVesselType === 'NewVessel'){
      setIsOpen(false);
      navigate("/boatregistration", {
        state: { from: location },
        replace: false,
      });
    }

    if(selectedVesselType == 'ReVessel')
    {
      setIsOpen(false);
      setIsSecondOpen(true);
    }
    
  };

  const navBuyerForm = () => {
    setIsOpen(false);
    navigate("/transferbuyer", {
      state: { from: location },
      replace: false,
    });
  };

  const navNewReregistration = () => {
    setIsSecondOpen(false);
  }

  const handleVesselType = (event) => {
    setError('');
    setSelectedVesselType(event.target.value);
  }

  const handleReRegistrationEvt = (regoNo) => {
    setIsSecondOpen(false);
    navigate("/boatreregistration/" + regoNo, {
      state: { from: location },
      replace: false,
    });
  }

  return (
    <>
      {isOpen && (
        <Modal
          title="Condition of the vessel"
          submitBtn="Continue"
          setOpenModal={setIsOpen}
          submitClkEvent={navEvt}
          cancelBtn="Cancel"
        >
          <div>
            <div className="br-input-group">
              <div className="br-field-radio-group">
                <div className="br-radio-group pop-msg-radio-group">
                  <input
                    type="radio"
                    value="NewVessel"
                    id="NewVessel"
                    checked={selectedVesselType == 'NewVessel'}
                    onChange={handleVesselType}
                  />
                  <label htmlFor="NewVessel">This application form can only be used for registering a vessel
              for the first time in Tasmania (e.g. newly constructed or
              ex-factory or a second hand vessel from interstate)</label>
                </div>
                <div className="br-radio-group pop-msg-radio-group">
                  <input
                    type="radio"
                    value="ReVessel"
                    id="ReVessel"
                    checked={selectedVesselType == 'ReVessel'}
                    onChange={handleVesselType}
                  />
                  <label htmlFor="ReVessel">A vessel previously registered in Tasmania but expired for
                  more than 12-months</label>
                </div>
              </div>
            </div>
            {/* <p>
              This application form “can only be used for registering a vessel
              for the first time in Tasmania (e.g. newly constructed or
              ex-factory or a second hand vessel from interstate) or a vessel
              that has been previously registered in Tasmania but expired for
              more than 12-months.
            </p> */}
            <p>
              It cannot be used for transferring an existing registration –
              click{" "}
              <Link to="/transferbuyer" onClick={navBuyerForm}>
                here
              </Link>{" "}
              to start a transfer of registration (
              <Link to="/transferbuyer" onClick={navBuyerForm}>
                Buyer Transfer Form
              </Link>
              ).
            </p>
            <p><span className="uni-err-msg">{error}</span></p>
          </div>
        </Modal>
      )}

      {isSecondOpen && (
        <div className="modalBackground">
        <div className="modalContainer">
          <div className="modal-header">
            <div className="modal-title">Expired Vessels</div>
            <div className="titleCloseBtn">
              <FontAwesomeIcon
                icon={faXmark}
                className="xmark-icon"
                size="2x"
                onClick={() => {
                  setIsSecondOpen(false);
                }}
              />
            </div>
          </div>
          <div className="modal-body-newBoat">
            {!isLoadingExpPCrafts ? (
              !errExpPCrafts ? (
                resExpPCrafts?.length === 0 ? (
                  <AlertMessage
                    message="No Expired Vessels"
                    level="info"
                    closeEvt="false"
                    mt="1"
                    mb="1"
                  />
                ) : (
                  resExpPCrafts?.map((d) => (
                    <>
                      {d?.isVesselViewAble && <div className="expired-ves-field unSelectable" onClick={() => handleReRegistrationEvt(d?.pcRegoNo)}>
                        {d?.pcRegoNo ? d?.pcRegoNo:'?'}{" - "}{d?.pcName ? d?.pcName:'?'}{" - "}{d?.pcHullMake ? d?.pcHullMake:'?'}{" - "}{d?.pcExpiryDate ? <span className="text-danger">{getddmmyyyyFormat(d?.pcExpiryDate?.split("T")[0])}</span>:'?'}
                      </div>}
                    </>
                  ))
                )
              ) : (
                <AlertMessage message={errExpPCrafts} />
              )
            ) : (
              <DataLoader isLoading={isLoadingExpPCrafts} />
            )}
          </div>
          <div className="modal-footer">
            <span>Can not find the vessel above? {" "} <Link to="/boatnewreregistration" onClick={navNewReregistration}>Here</Link></span> 
          </div>
        </div>
        </div>
      )}

      {openDialog && (
        <Dialog
          setOpenDialog={setOpenDialog}
          title="ALERT"
          children={dialogMsg}
        />
      )}

    </>
  );
};

export default NewBoatPopupMessage;
