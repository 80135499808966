import { useGet } from "../../Hooks/useFetch";
import { useEffect } from "react";
import DataLoader from "../DataLoader";
import AlertMessage from "../AlertMessage";

const ClientAddress = ({ register, watch, control, errors, getFieldState }) => {
  const [res, err, isLoading, apiGet, abt] = useGet();

  useEffect(() => {
    apiGet("/api/pna/PnaDecryptedData");
  }, []);

  return (
    <>
      {!isLoading ? (
        !err ? (
          <div
            className={
              errors.shipTo?.message
                ? "client-address-group error"
                : !getFieldState("shipTo").isDirty
                ? "client-address-group"
                : "client-address-group success"
            }
          >
            <label htmlFor="shipTo">
              Where would you like your order delivered?
            </label>
            <div className="client-address-radio-group">
              {res?.pnaAdr1Po && (
                <div className="client-address-radio-field">
                  <input
                    type="radio"
                    value="P"
                    id="shipToPo"
                    {...register("shipTo", {
                      required: {
                        value: true,
                        message: "Delivery address is required",
                      },
                    })}
                  />
                  <label htmlFor="shipToPo">
                    Postal - {res?.pnaAdr1Po} {res?.pnaAdr2Po}{" "}
                    {res?.pnaPostcodePo} {res?.pnaAdr3Po}
                  </label>
                </div>
              )}

              <div className="client-address-radio-field">
                <input
                  type="radio"
                  value="R"
                  id="shipToRes"
                  {...register("shipTo", {
                    required: {
                      value: true,
                      message: "Delivery address is required",
                    },
                  })}
                />
                <label htmlFor="shipToRes">
                  Residential - {res?.pnaAdr1} {res?.pnaAdr2} {res?.pnaPostcode}{" "}
                  {res?.pnaAdr3}
                </label>
              </div>
            </div>
            <span className="msg">{errors.shipTo?.message}</span>
          </div>
        ) : (
          <AlertMessage message={err} />
        )
      ) : (
        <DataLoader isLoading={isLoading} />
      )}
    </>
  );
};

export default ClientAddress;
