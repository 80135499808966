import Cart from "../../Components/ShoppingCart/Cart";
import useShoppingCart from "../../Hooks/useShoppingCart";
import "../../Components/ShoppingCart/ShoppingCart.css";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation } from "react-router-dom";
import SummaryPriceDisplay from "../../Components/ShoppingCart/SummaryPriceDisplay";

const CheckCart = () => {
  const {
    cartItems,
    setCartItems,
    cartTotalPrice,
    addToCart,
    removeFromCart,
    changeQtyFromCart,
  } = useShoppingCart();

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <div className="cart-container">
        <div className="row cart-title">
          <div className="col-md-12">
            <h1>Shopping Cart</h1>
          </div>
        </div>
        <div className="cart-sub-container">
          <div className="row">
            <div className="col-md-9">
              <Cart
                cartItems={cartItems}
                removeItem={removeFromCart}
                changeQtyFromCart={changeQtyFromCart}
              />
            </div>
            <div className="col-md-3">
              <SummaryPriceDisplay totalPrice={cartTotalPrice.toFixed(2)} />
            </div>
          </div>
          <div className="col-md-9">
            <div className="cart-btn-group">
              <button
                className="continue-btn"
                onClick={() =>
                  navigate("/shoppingcart", {
                    state: { from: location },
                    replace: false,
                  })
                }
              >
                <span className="hide-long-word"><FontAwesomeIcon icon={faChevronLeft} /> Continue Shopping</span><span className="display-short-word"><FontAwesomeIcon icon={faChevronLeft} /> Shop</span>
              </button>
              <button
                className="purchase-btn"
                disabled={cartItems == null || cartItems.length === 0}
                onClick={() =>
                  navigate("/cartpay", {
                    state: { from: location },
                    replace: false,
                  })
                }
              >
                <span className="hide-long-word">Make Purchase <FontAwesomeIcon icon={faChevronRight} /></span><span className="display-short-word">Pay <FontAwesomeIcon icon={faChevronRight} /></span>
                
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckCart;
