const DeclarationLaw2023 = ({ register, errors }) => {
  return (
    <>
      <div
        className={
          errors.declareChecked?.message
            ? "br-input-group error"
            : "br-input-group"
        }
      >
        <div className="">
          <div>
              <input
                type="checkbox"
                id="declareChecked"
                {...register("declareChecked", {
                  required: {
                    value: true,
                    message: "Declaration has to be ticked before submit",
                  },
                })}
              />
              <label htmlFor="declareChecked" className="unSelectable">
                In accordance with the requirements of the Marine and Safety
                (Motor Boats and Licences) By-laws 2023, I declare that the
                motor boat I am registering will not endanger a person due to
                one or more of the following circumstances:
              </label>
          </div>

          <ul>
            <li>
              the structural condition of the vessel, or a component of the
              vessel, that renders the vessel unfit to undertake a voyage for
              which the vessel is otherwise designed and would be, if not in
              that condition, capable of undertaking;
            </li>
            <li>
              the engine power rating of the vessel, as recommended by the
              manufacturer or recorded on the ABP, is being exceeded;
            </li>
            <li>
              the engine mass of the vessel, as recommended by the manufacturer
              or recorded on the ABP, is being exceeded;
            </li>
            <li>
              the absence of, or condition of, materials or items comprising the
              reserve buoyancy, or part of the reserve buoyancy, of the vessel.
            </li>
          </ul>

          <div>If the vessel is not safe, please contact MAST.</div>
        </div>
        <span className="msg">{errors.declareChecked?.message}</span>
      </div>
    </>
  );
};

export default DeclarationLaw2023;
