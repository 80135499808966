const OwnerShipLocation = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
}) => {
  return (
    <div className="br-box">
      <div className="br-title">Vessel that is over 15m length</div>
      <div className="br-subtitle">Ownership and Location</div>
      <div className="br-field-group">
        <div
          className={
            errors.ownedVessel3mths?.message
              ? "br-input-group error"
              : !getFieldState("ownedVessel3mths").isDirty
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="ownedVessel3mths">
            Have you owned this Vessel for more than 3 months{" "}
            <span className="text-danger">*</span>
          </label>
          <div className="br-field-radio-group">
            <div className="br-radio-group">
              <input
                type="radio"
                value="Yes"
                id="ownedVessel3mthsYes"
                {...register("ownedVessel3mths", {
                  required: {
                    value: true,
                    message: "This is required.",
                  },
                })}
              />
              <label htmlFor="ownedVessel3mthsYes">Yes</label>
            </div>
            <div className="br-radio-group">
              <input
                type="radio"
                value="No"
                id="ownedVessel3mthsNo"
                {...register("ownedVessel3mths", {
                  required: {
                    value: true,
                    message: "This is required.",
                  },
                })}
              />
              <label htmlFor="ownedVessel3mthsNo">No</label>
            </div>
          </div>

          <span className="msg">{errors.ownedVessel3mths?.message}</span>
        </div>
        <div
          className={
            errors.chgLocationLast3mths?.message
              ? "br-input-group error"
              : !getFieldState("chgLocationLast3mths").isDirty
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="chgLocationLast3mths">
            Has the Vessel changed location in the last 3 months{" "}
            <span className="text-danger">*</span>
          </label>
          <div className="br-field-radio-group">
            <div className="br-radio-group">
              <input
                type="radio"
                value="Yes"
                id="chgLocationLast3mthsYes"
                {...register("chgLocationLast3mths", {
                  required: {
                    value: true,
                    message: "This is required.",
                  },
                })}
              />
              <label htmlFor="chgLocationLast3mthsYes">Yes</label>
            </div>
            <div className="br-radio-group">
              <input
                type="radio"
                value="No"
                id="chgLocationLast3mthsNo"
                {...register("chgLocationLast3mths", {
                  required: {
                    value: true,
                    message: "This is required.",
                  },
                })}
              />
              <label htmlFor="chgLocationLast3mthsNo">No</label>
            </div>
          </div>

          <span className="msg">{errors.chgLocationLast3mths?.message}</span>
        </div>
        {/* <div
          className={
            errors.vesselKeptNow?.message
              ? "br-input-group error"
              : !getFieldState("vesselKeptNow").isDirty
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="vesselKeptNow">Where is the Vessel kept now <span className="text-danger">*</span></label>
          <input
            type="text"
            id="vesselKeptNow"
            placeholder="Vessel Kept Now"
            {...register("vesselKeptNow", {
              required: {
                value: true,
                message: "This is required",
              },
            })}
          />
          <span className="msg">{errors.vesselKeptNow?.message}</span>
        </div> */}
        <div
          className={
            errors.chgLocationNext3mths?.message
              ? "br-input-group error"
              : !getFieldState("chgLocationNext3mths").isDirty
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="chgLocationNext3mths">
            Do you intend to move this Vessel to a new permanent location in the
            next 3 months <span className="text-danger">*</span>
          </label>
          <div className="br-field-radio-group">
            <div className="br-radio-group">
              <input
                type="radio"
                value="Yes"
                id="chgLocationNext3mthsYes"
                {...register("chgLocationNext3mths", {
                  required: {
                    value: true,
                    message: "This is required.",
                  },
                })}
              />
              <label htmlFor="chgLocationNext3mthsYes">Yes</label>
            </div>
            <div className="br-radio-group">
              <input
                type="radio"
                value="No"
                id="chgLocationNext3mthsNo"
                {...register("chgLocationNext3mths", {
                  required: {
                    value: true,
                    message: "This is required.",
                  },
                })}
              />
              <label htmlFor="chgLocationNext3mthsNo">No</label>
            </div>
          </div>

          <span className="msg">{errors.chgLocationNext3mths?.message}</span>
        </div>
        {watch('chgLocationNext3mths') === "Yes" ? (
          <div
            className={
              errors.newLocation?.message
                ? "br-input-group error"
                : !getFieldState("newLocation").isDirty
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="newLocation">
              Please specify the new location{" "}
              <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="newLocation"
              placeholder="New Location"
              {...register("newLocation", {
                required: {
                  value: watch('chgLocationNext3mths') === "Yes",
                  message: "This is required",
                },
              })}
            />
            <span className="msg">{errors.newLocation?.message}</span>
          </div>
        ) : (
          ""
        )}

        <div
          className={
            errors.vesselUnseaworthy?.message
              ? "br-input-group error"
              : !getFieldState("vesselUnseaworthy").isDirty
              ? "br-input-group"
              : "br-input-group success"
          }
        >
          <label htmlFor="vesselUnseaworthy">
            Have either you or the previous owner ever been issued with a notice
            by MAST stating that the Vessel is unseaworthy and needs to be put
            into a seaworthy condition <span className="text-danger">*</span>
          </label>
          <div className="br-field-radio-group">
            <div className="br-radio-group">
              <input
                type="radio"
                value="Yes"
                id="vesselUnseaworthyYes"
                {...register("vesselUnseaworthy", {
                  required: {
                    value: true,
                    message: "This is required.",
                  },
                })}
              />
              <label htmlFor="vesselUnseaworthyYes">Yes</label>
            </div>
            <div className="br-radio-group">
              <input
                type="radio"
                value="No"
                id="vesselUnseaworthyNo"
                {...register("vesselUnseaworthy", {
                  required: {
                    value: true,
                    message: "This is required.",
                  },
                })}
              />
              <label htmlFor="vesselUnseaworthyNo">No</label>
            </div>
          </div>

          <span className="msg">{errors.vesselUnseaworthy?.message}</span>
        </div>
      </div>
    </div>
  );
};

export default OwnerShipLocation;
