import { useState } from "react";
import QtySelect from "./QtySelect";
import useEllipsis from "../../Hooks/useEllipsis";
import { Tooltip } from "react-tooltip";
import ProductImage from "./ProductImage";

const Product = ({ product, addToCart }) => {
  const [qty, setQty] = useState(1);
  const { ref, hasEllipsis } = useEllipsis(product.name);
  
  return (
    <div className="product">
      <div className="product-img-container" id={"img" + product.id} >
        <ProductImage imageUrl={process.env.PUBLIC_URL+"/Images/"+product.name+product.imageExt} />
        <Tooltip
          anchorSelect={"#img" + product.id}
          content="Click to view"
          variant="dark"
          place="bottom"
        />
      </div>
      <div
        className="product-title text-over-2-ellipsis"
        ref={ref}
        id={"anchor" + product.id}
      >
        {product.name}
      </div>
      {hasEllipsis && (
        <Tooltip
          anchorSelect={"#anchor" + product.id}
          content={product.name}
          variant="info"
          className="custom-tooltip-style"
        />
      )}
      <div className="product-desc">{product.description}</div>
      <div className="product-price">${product.price.toFixed(2)}</div>
      <div className="product-qty">
            <span>Quantity:</span>
            <QtySelect changeEvt={(e) => setQty(e.target.value)} />
          </div>
          <button
            onClick={() => addToCart(product, qty)}
            className="product-btn"
          >
            Add to Cart
          </button>
      {/* {product.isInStock ? (
        <>
          <span className="product-in-stock product-stock-format">
            In Stock
          </span>
          
        </>
      ) : (
        <>
          <span className="product-out-stock product-stock-format">
            Out of Stock
          </span>
        </>
      )} */}
    </div>
  );
};

export default Product;
