import useMultiStepForm from "../../Hooks/useMultiStepForm";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import SubmitLoader from "../SubmitLoader";
import useAuth from "../../Hooks/useAuth";
import Dialog from "../Modal/Dialog";
import { useMultipartFormPost } from "../../Hooks/useFetch";
import NewOwnerTransferred from "../Transferaction/NewOwnerTransferred";
import UploadSupportDoc from "../Transferaction/UploadSupportDoc";
import SellerPopulated from "../Transferaction/SellerPopulated";
import FormProgressBar from "../FormProgressBar";
import { splitFullAddress } from "../../Helpers/Helper";
import { useGet } from "../../Hooks/useFetch";
import dayjs from "dayjs";
import Modal from "../Modal/Modal";
import BuyerPayProcess from "../Transferaction/BuyerPayProcess";
import usePayWay from "../../Hooks/usePayWay";
import "../Transferaction/Transfer.css";
import useGaEvent from "../../Hooks/useGaEvent";
import AlertMessage from "../AlertMessage";
import BuyerFormBuyerContacts from "../Transferaction/BuyerFormBuyerContacts";

const TransferBuyerForm = ({resBuyers, errGet, isLoadingBuyers, payWayPublicKey}) => {
  const uploadSizeLimit = 5242880; //5MB
  const [res, errPost, isPostLoading, apiMultipartPost, abt] =
    useMultipartFormPost();
//   const [resBuyers, errGet, isLoadingBuyers, apiGet, abort] = useGet();
  const [appFee, errorAppFee, isLoadingAppFee, apiGetAppFee, abtAppFee] =
    useGet();
  const [resPna, errGetPna, isLoadingPna, apiGetPna, abtPna] = useGet();
  const [openDialog, setOpenDialog] = useState(false);
  const [isBuyerPopulatedError, setIsBuyerPopulatedError] = useState(false);
  const [isPnaPopulatedError, setIsPnaPopulatedError] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [openFeeNotReadyDialog, setOpenFeeNotReadyDialog] = useState(false);
  const { auth } = useAuth();
  const gaEvents = useGaEvent();
  // console.log(auth);
  // console.log(resBuyers);
  const isAbleRegister = () => {
    if (!auth?.isAblePrintOrRegister) {
      setOpenDialog(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    isAbleRegister();
    // apiGet("/api/transfer/buyerTransferDetails");
    apiGetAppFee("/api/transfer/buyerTransferAppFee");
    apiGetPna("/api/pna/PnaDecryptedData");
  }, []);

  useEffect(() => {
    if (appFee != null && appFee?.fee <= 0) {
      setOpenFeeNotReadyDialog(true);
    }
  }, [appFee]);

  useEffect(() => {
    if (!isLoadingBuyers && errGet) {
      setIsBuyerPopulatedError(true);
    }
  }, [isLoadingBuyers, errGet]);

  useEffect(() => {
    if (!isLoadingPna && errGetPna) {
      setIsPnaPopulatedError(true);
    }
  }, [isLoadingPna, errGetPna]);

  let initialValues = {
    dateOfPurchase: null,
    whereVesselKept: "",
    pocodeOfStorage: "",
    pcLength: "",
    pcHin: "",
    pcSerialNo: "",

    marinaName: "",
    marinaBerthNum: "",
    mooringRegoNo: "",
    // isReceivedNews: "",
    purchaseFiles: [], //Proof of Purchase
    nonTasFiles: [], //not in Tas

    isBuyerCompany: "Individual",
    isSellerCompany: "Individual",
    payId: "",
  };

  if (process.env.REACT_APP_TEST_DEFAULT_VALUE === "on") {
    initialValues = {
      sellerRegoNo: "23795",
      sellerBoatName: "Victory",

      pcLength: 11.32,
      pcHin: "AU-TRX1869527C7",
      pcSerialNo: "1869527",

      dateOfPurchase: dayjs("2022-10-05"),
      // whereVesselKept: "RB_JETTY",
      // pocodeOfStorage: "7000",
      declareChecked: true,

      marinaName: "",
      marinaBerthNum: "",
      mooringRegoNo: "",
      // isReceivedNews: "",
      purchaseFiles: [], //Proof of Purchase
      nonTasFiles: [], //not in Tas

      isBuyerCompany: "Individual",
      isSellerCompany: "Individual",
      payId: "",
    };
  }

  const SecondContactArrayPropName = "SecondBuyerContactDetails";
  initialValues[SecondContactArrayPropName] = [];

  const {
    errFrame, //err message when frame is not created
    errToken, //err message when toke is not created
    tokenId, //single token id
    creditCard, //credit card
    btnDisable, //button disable status
    frameDestroy, //it is called after send to server
    requestToken, // fc to get token
    createFrame, // fc to create card frame
    stateDestroy, //clean states
  } = usePayWay(payWayPublicKey);

  const {
    control,
    register,
    formState: { errors, isDirty, isValid, dirtyFields },
    handleSubmit,
    watch,
    getFieldState,
    setValue,
    setError,
    clearErrors,
  } = useForm({ defaultValues: initialValues, mode: "onChange" });

  const formContent =
    auth?.isBypassPayment === true
      ? [
          <BuyerFormBuyerContacts
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            clearErrors={clearErrors}
            isCompanyFieldName="isBuyerCompany"
            SecondContactArrayPropName={SecondContactArrayPropName}
            resPna={resPna}
            setError={setError}
            uploadSizeLimit={uploadSizeLimit}
            buyerInfo={resBuyers}
            setValue={setValue}
          />,
          <SellerPopulated
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            sellerInfo={resBuyers}
            clearErrors={clearErrors}
            isCompanyFieldName="isSellerCompany"
            setError={setError}
            uploadSizeLimit={uploadSizeLimit}
            setValue={setValue}
          />,
          <NewOwnerTransferred
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
          />,
          <UploadSupportDoc
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            setError={setError}
            clearErrors={clearErrors}
            uploadSizeLimit={uploadSizeLimit}
            setValue={setValue}
          />,
        ]
      : [
          <BuyerFormBuyerContacts
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            clearErrors={clearErrors}
            isCompanyFieldName="isBuyerCompany"
            SecondContactArrayPropName={SecondContactArrayPropName}
            resPna={resPna}
            setError={setError}
            uploadSizeLimit={uploadSizeLimit}
            buyerInfo={resBuyers}
            setValue={setValue}
          />,
          <SellerPopulated
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            sellerInfo={resBuyers}
            clearErrors={clearErrors}
            isCompanyFieldName="isSellerCompany"
            setError={setError}
            uploadSizeLimit={uploadSizeLimit}
            setValue={setValue}
          />,
          <NewOwnerTransferred
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
          />,
          <UploadSupportDoc
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            setError={setError}
            clearErrors={clearErrors}
            uploadSizeLimit={uploadSizeLimit}
            setValue={setValue}
          />,
          <BuyerPayProcess
            errPayWayFrame={errFrame}
            errPayWayToken={errToken}
            tokenId={tokenId}
            creditCard={creditCard}
            createFrame={createFrame}
            stateDestroy={stateDestroy}
            appFee={appFee?.fee}
            dateFrom={appFee?.date}
            setValue={setValue}
          />,
        ];

  const {
    steps,
    currentStepIndex,
    stepContent,
    isFirstStep,
    isLastStep,
    back,
    next,
  } = useMultiStepForm(formContent);
  // console.log(errors);

  const onReject = async (data, e) => {
    e.preventDefault();
    // console.log(data);

    const formData = new FormData();
    formData.append("ClientAction", "Reject");
    formData.append("BuyerId", resBuyers[0]?.buyerId);

    Object.keys(data).forEach((key) => {
      if (key === "purchaseFiles") {
        if (data.purchaseFiles?.length > 0) {
          // console.log('files over size');
          for (let i = 0; i < data.purchaseFiles.length; i++) {
            if (
              data.purchaseFiles[i][0] != undefined ||
              data.purchaseFiles[i][0] != null
            ) {
              // console.log(
              //   `Purchase file ${i + 1}: ${data.purchaseFiles[i][0]?.name}`
              // );
              formData.append("ProofOfPurchases", data.purchaseFiles[i][0]);
            }
          }

          // console.log(formData.getAll("ProofOfPurchases")); // log all uploaded files
        }
      } else if (key === "nonTasFiles") {
        if (data.nonTasFiles?.length > 0) {
          // console.log('files over size');
          for (let i = 0; i < data.nonTasFiles.length; i++) {
            // console.log(`Non Tas file ${i + 1}: ${data.nonTasFiles[i]?.name}`);
            formData.append("ProofOfVesselOutsides", data.nonTasFiles[i]);
          }
        }
      } else {
        formData.append(key, data[key]);
      }
    });

    await apiMultipartPost(
      "/api/transfer/buyerUpdatedTransfer",
      formData,
      false,
      "/transferbuyer/trasnsferdone",
      gaEvents.transferBuyerRejectSuccess,
      gaEvents.transferBuyerRejectFail
    );
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    // console.log(data);

    //Only valid when user is located at upload doc page, because client might Press back button
    if (
      data.purchaseFiles?.length > 0 &&
      currentStepIndex ===
        (!auth?.isBypassPayment ? steps.length - 2 : steps.length - 1)
    ) {
      // console.log('purchaseFiles over size');
      let checkAnyFile = false;
      for (let i = 0; i < data.purchaseFiles.length; i++) {
        if (
          (data.purchaseFiles[i][0] != undefined ||
            data.purchaseFiles[i][0] != null) &&
          data.purchaseFiles[i][0]?.size > uploadSizeLimit
        ) {
          // console.log(data.purchaseFiles[i][0]);
          setError("purchaseFiles." + i, {
            type: "oversize",
            message:
              "File is over the limit size (" +
              Math.floor(uploadSizeLimit / 1000000) +
              "MB).",
          });
          return;
        }
        if (data.purchaseFiles[i][0]?.size > 0) {
          checkAnyFile = true;
        }
      }
      if (!checkAnyFile) {
        setError("purchaseFiles." + (data.purchaseFiles.length - 1), {
          type: "required",
          message: "Proof of Purchase is required.",
        });
        return;
      }
    }

    if (
      data.nonTasFiles?.length > 0 &&
      currentStepIndex ===
        (!auth?.isBypassPayment ? steps.length - 2 : steps.length - 1)
    ) {
      // console.log('files over size');
      for (let i = 0; i < data.nonTasFiles.length; i++) {
        if (data.nonTasFiles[i]?.size > uploadSizeLimit) {
          // console.log('nonTasFiles over size');
          setError("nonTasFiles", {
            type: "oversize",
            message:
              "File is over the limit size (" +
              Math.floor(uploadSizeLimit / 1000000) +
              "MB).",
          });
          return;
        }
      }
    }

    if (!isLastStep) return next();

    if (!tokenId && !auth?.isBypassPayment) {
      requestToken();
    } else {
      const formData = new FormData();
      formData.append("SingleUseTokenId", data.payId);

      //Buyer transfer Approve
      if (resBuyers != null && resBuyers.length > 0) {
        formData.append("ClientAction", "Approve");
        formData.append("BuyerId", resBuyers[0]?.buyerId);

        Object.keys(data).forEach((key) => {
          if (key === "purchaseFiles") {
            if (data.purchaseFiles?.length > 0) {
              // console.log('files over size');
              for (let i = 0; i < data.purchaseFiles.length; i++) {
                if (
                  data.purchaseFiles[i][0] !== undefined ||
                  data.purchaseFiles[i][0] != null
                ) {
                //   console.log(`Purchase file ${i + 1}: ${data.purchaseFiles[i][0]?.name}`);
                  formData.append("ProofOfPurchases", data.purchaseFiles[i][0]);
                }
              }

            //   console.log(formData.getAll("ProofOfPurchases")); // log all uploaded files
            }
          } else if (key === "nonTasFiles") {
            if (data.nonTasFiles?.length > 0) {
              // console.log('files over size');
              for (let i = 0; i < data.nonTasFiles.length; i++) {
                // console.log(`Non Tas file ${i + 1}: ${data.nonTasFiles[i]?.name}`);
                formData.append("ProofOfVesselOutsides", data.nonTasFiles[i]);
              }
            }
          } else {
            formData.append(key, data[key]);
          }
        });

        await apiMultipartPost(
          "/api/transfer/buyerUpdatedTransfer",
          formData,
          false,
          "/transferbuyer/trasnsferdone",
          gaEvents.transferBuyerApproveSuccess,
          gaEvents.transferBuyerApproveFail
        );
      } //Buyer transfer submit first
      else {
        formData.append("ClientAction", "Submit");
        Object.keys(data).forEach((key) => {
          if (key === "buyer") {
            Object.keys(data.buyer).forEach((k) => {
              if (k === "orgResAddress") {
                if (
                  data.buyer?.orgResAddress == null ||
                  data.buyer?.orgResAddress == "" ||
                  data.buyer?.orgResAddress == undefined
                )
                  return;
                let adrs = splitFullAddress(data.buyer?.orgResAddress);
                formData.append("BuyerOrgAdr1", adrs[0]?.trim());
                formData.append("BuyerOrgAdr2", adrs[1]?.trim());
                formData.append("BuyerOrgAdr3", adrs[2]?.trim());
                formData.append("BuyerOrgPostcode", adrs[3]?.trim());
              } else {
                formData.append("Buyer" + k, data.buyer[k]);
              }
            });
          } else if (key === "seller") {
            Object.keys(data.seller).forEach((k) => {
              if (k === "individualResAddress") {
                if (
                  data.seller?.individualResAddress == null ||
                  data.seller?.individualResAddress == "" ||
                  data.seller?.individualResAddress == undefined
                )
                  return;
                let adrs = splitFullAddress(data.seller?.individualResAddress);
                formData.append("SellerIndividualAdr1", adrs[0]?.trim());
                formData.append("SellerIndividualAdr2", adrs[1]?.trim());
                formData.append("SellerIndividualAdr3", adrs[2]?.trim());
                formData.append("SellerIndividualPostcode", adrs[3]?.trim());
              } else if (k === "orgResAddress") {
                if (
                  data.seller?.orgResAddress == null ||
                  data.seller?.orgResAddress == "" ||
                  data.seller?.orgResAddress == undefined
                )
                  return;
                let adrs = splitFullAddress(data.seller?.orgResAddress);
                formData.append("SellerOrgAdr1", adrs[0]?.trim());
                formData.append("SellerOrgAdr2", adrs[1]?.trim());
                formData.append("SellerOrgAdr3", adrs[2]?.trim());
                formData.append("SellerOrgPostcode", adrs[3]?.trim());
              } else if (k === "secondResAddress") {
                if (
                  data.seller?.secondResAddress == null ||
                  data.seller?.secondResAddress == "" ||
                  data.seller?.secondResAddress == undefined
                )
                  return;
                let adrs = splitFullAddress(data.seller?.secondResAddress);
                formData.append("SellerSecondAdr1", adrs[0]?.trim());
                formData.append("SellerSecondAdr2", adrs[1]?.trim());
                formData.append("SellerSecondAdr3", adrs[2]?.trim());
                formData.append("SellerSecondPostcode", adrs[3]?.trim());
              } else if (
                k === "individualDOB" &&
                data.seller?.individualDOB &&
                data.seller?.individualDOB !== undefined
              ) {
                formData.append(
                  "SellerIndividualDOB",
                  data.seller?.individualDOB?.$d.getFullYear() +
                    "/" +
                    (data.seller?.individualDOB?.$d.getMonth() + 1) +
                    "/" +
                    data.seller?.individualDOB?.$d.getDate()
                );
              } else if (
                k === "secondDOB" &&
                data.seller?.secondDOB &&
                data.seller?.secondDOB !== undefined
              ) {
                formData.append(
                  "SellerSecondDOB",
                  data.seller?.secondDOB?.$d.getFullYear() +
                    "/" +
                    (data.seller?.secondDOB?.$d.getMonth() + 1) +
                    "/" +
                    data.seller?.secondDOB?.$d.getDate()
                );
              } else {
                formData.append("Seller" + k, data.seller[k]);
              }
            });
          } else if (
            key === "SecondBuyerContactDetails" &&
            data.isBuyerCompany === "Individual" &&
            data.SecondBuyerContactDetails?.length > 0
          ) {
            formData.append(
              "SerializeSecondContacts",
              JSON.stringify(data.SecondBuyerContactDetails)
            );
          } else if (key === "dateOfPurchase") {
            formData.append(
              key,
              data.dateOfPurchase?.$d.getFullYear() +
                "/" +
                (data.dateOfPurchase?.$d.getMonth() + 1) +
                "/" +
                data.dateOfPurchase?.$d.getDate()
            );
          } else if (key === "purchaseFiles") {
            if (data.purchaseFiles?.length > 0) {
              // console.log('files over size');
              for (let i = 0; i < data.purchaseFiles.length; i++) {
                if (
                  data.purchaseFiles[i][0] != undefined ||
                  data.purchaseFiles[i][0] != null
                ) {
                  // console.log(
                  //   `Purchase file ${i + 1}: ${data.purchaseFiles[i][0]?.name}`
                  // );
                  formData.append("ProofOfPurchases", data.purchaseFiles[i][0]);
                }
              }

              // console.log(formData.getAll("ProofOfPurchases")); // log all uploaded files
            }
          } else if (key === "nonTasFiles") {
            if (data.nonTasFiles?.length > 0) {
              // console.log('files over size');
              for (let i = 0; i < data.nonTasFiles.length; i++) {
                // console.log(
                //   `Non Tas file ${i + 1}: ${data.nonTasFiles[i]?.name}`
                // );
                formData.append("ProofOfVesselOutsides", data.nonTasFiles[i]);
              }
            }
          } else {
            formData.append(key, data[key]);
          }

          // if (key === "sellerDOB") {
          //   formData.append(
          //     key,
          //     data.sellerDOB?.$d.getFullYear() +
          //       "/" +
          //       (data.sellerDOB?.$d.getMonth() + 1) +
          //       "/" +
          //       data.sellerDOB?.$d.getDate()
          //   );
          // } else if (key === "sellerResAddress") {
          //   let adrs = splitFullAddress(data.sellerResAddress);
          //   formData.append("reStreet", adrs[0]?.trim());
          //   formData.append("reSuburb", adrs[1]?.trim());
          //   formData.append("reState", adrs[2]?.trim());
          //   formData.append("rePostcode", adrs[3]?.trim());
          // } else if (key === "sellerPoAddress") {
          //   let adrs = null;
          //   if (data.checkPostal === true) {
          //     adrs = splitFullAddress(data.sellerResAddress);
          //   } else {
          //     adrs = splitFullAddress(data.sellerPoAddress);
          //   }
          //   if (adrs != null) {
          //     formData.append("poStreet", adrs[0]?.trim());
          //     formData.append("poSuburb", adrs[1]?.trim());
          //     formData.append("poState", adrs[2]?.trim());
          //     formData.append("poPostcode", adrs[3]?.trim());
          //   }
          // } else if (key === "dateOfPurchase") {
          //   formData.append(
          //     key,
          //     data.dateOfPurchase?.$d.getFullYear() +
          //       "/" +
          //       (data.dateOfPurchase?.$d.getMonth() + 1) +
          //       "/" +
          //       data.dateOfPurchase?.$d.getDate()
          //   );
          // } else if (key === "purchaseFiles") {
          //   if (data.purchaseFiles?.length > 0) {
          //     // console.log('files over size');
          //     for (let i = 0; i < data.purchaseFiles.length; i++) {
          //       if (
          //         data.purchaseFiles[i][0] != undefined ||
          //         data.purchaseFiles[i][0] != null
          //       ) {
          //         console.log(
          //           `Purchase file ${i + 1}: ${data.purchaseFiles[i][0]?.name}`
          //         );
          //         formData.append("ProofOfPurchases", data.purchaseFiles[i][0]);
          //       }
          //     }

          //     console.log(formData.getAll("ProofOfPurchases")); // log all uploaded files
          //   }
          // } else if (key === "nonTasFiles") {
          //   if (data.nonTasFiles?.length > 0) {
          //     // console.log('files over size');
          //     for (let i = 0; i < data.nonTasFiles.length; i++) {
          //       console.log(
          //         `Non Tas file ${i + 1}: ${data.nonTasFiles[i]?.name}`
          //       );
          //       formData.append("ProofOfVesselOutsides", data.nonTasFiles[i]);
          //     }
          //   }
          // } else {
          //   formData.append(key, data[key]);
          // }
        });

        await apiMultipartPost(
          "/api/transfer/buyerTransfer",
          formData,
          false,
          "/transferbuyer/trasnsferdone",
          gaEvents.transferBuyerInitialSuccess,
          gaEvents.transferBuyerInitialFail
        );
      }
    }
  };

  return (
    <>
      {!isLoadingPna ? (
        !isLoadingAppFee ? (
          !openFeeNotReadyDialog && !isLoadingBuyers ? (
            !openDialog && !isPostLoading ? (
              <div className="br-container">
                <div className="br-form-container">
                  <form encType="multipart/form-data">
                    <div className="tsf-title-container unSelectable">
                      <h3>
                        TRANSFER OF REGISTRATION OF A VESSEL – Buyer to Complete{" "}
                        {resBuyers != null && resBuyers.length > 0
                          ? "(" + resBuyers[0]?.pcRegoNo + ")"
                          : ""}
                      </h3>
                    </div>
                    <FormProgressBar
                      currentIndex={currentStepIndex}
                      totalSteps={steps}
                    />
                    <AlertMessage message={errPost} mt={0} />
                    {stepContent}
                    <div className="br-btn-group">
                      <button
                        type="button"
                        onClick={back}
                        className="br-button"
                        disabled={isFirstStep}
                      >
                        Back
                      </button>
                      <div className="tsf-btn-reject-gp">
                        {currentStepIndex >=
                          (!auth?.isBypassPayment
                            ? steps.length - 2
                            : steps.length - 1) &&
                        resBuyers != null &&
                        resBuyers.length > 0 ? (
                          <button
                            type="button"
                            className="tsf-button-reject"
                            onClick={() => setIsOpenModal(true)}
                          >
                            Reject
                          </button>
                        ) : (
                          ""
                        )}
                        <button
                          type="submit"
                          className="br-button"
                          onClick={handleSubmit(onSubmit)}
                          disabled={
                            btnDisable || errorAppFee == null ? false : true
                          }
                        >
                          {isLastStep
                            ? resBuyers != null && resBuyers.length > 0
                              ? "Approve"
                              : "Submit"
                            : "Next"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <SubmitLoader isLoading={isPostLoading} />
            )
          ) : (
            <SubmitLoader isLoading={isLoadingBuyers} />
          )
        ) : (
          <SubmitLoader isLoading={isLoadingAppFee} />
        )
      ) : (
        <SubmitLoader isLoading={isLoadingPna} />
      )}

      {openDialog && (
        <Dialog
          setOpenDialog={setOpenDialog}
          title="ALERT"
          children="You have recently made changes to personal details. Some functions (Printing Certificates, New Vessel Registrations) are unavailable until MAST have confirmed these changes."
          redirectUrl="/recvessel"
        />
      )}

      {isOpenModal && (
        <Modal
          setOpenModal={setIsOpenModal}
          title="Confirm"
          cancelBtn="No"
          submitBtn="Yes"
          children="Are you sure to reject this transaction?"
          submitClkEvent={handleSubmit(onReject)}
        />
      )}

      {isBuyerPopulatedError && (
        <Dialog
          setOpenDialog={setIsBuyerPopulatedError}
          title="ALERT"
          children="System cannot display buyer information, please retry again later or contact MAST."
          redirectUrl="/recvessel"
        />
      )}

      {isPnaPopulatedError && (
        <Dialog
          setOpenDialog={setIsPnaPopulatedError}
          title="ALERT"
          children="System cannot display current user information, please retry again later or contact MAST."
          redirectUrl="/pna"
        />
      )}
    </>
  );
};

export default TransferBuyerForm;
