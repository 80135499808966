import { Link } from "react-router-dom";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const BackRedirect = ({ url }) => {
    const backRedirectStyle = {
        textDecoration:"none",
         color:"#0645AD",
      };
    const backRedirectArrow = {
        paddingRight: "4px",
    }
    const backRedirectRow = {
        marginTop:"2.5rem",
    }  
    return (
    <div className="row" style={backRedirectRow}>
      <div className="col-md-12">
        <Link to={url} style={backRedirectStyle}>
          <FontAwesomeIcon icon={faCircleArrowLeft} className="" size="1x" style={backRedirectArrow} />
          Back
        </Link>
      </div>
    </div>
  );
};

export default BackRedirect;
