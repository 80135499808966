import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useGet } from "../../Hooks/useFetch";
import MooringPrimaryVesselAddForm from "../../Components/Mooring/MooringPrimaryVesselAddForm";
import SubmitLoader from "../../Components/SubmitLoader";
import useGaEvent from "../../Hooks/useGaEvent";
import AlertMessage from "../../Components/AlertMessage";

const MooringPrimaryVesselAdd = () => {
  let { itemId, moRegoNo, moAppLen } = useParams();

  const [res, err, isGETLoading, apiGet, abt] = useGet();
  const gaEvents = useGaEvent();

  useEffect(() => {
    apiGet("/api/mooring/UnSelectedPrimaryVessels/" + itemId);
    gaEvents.MooringPrimaryVesselAddVisit();
  }, []);

  // console.log(res);

  return (
    <>
      {isGETLoading ? (
        <SubmitLoader isLoading={isGETLoading} />
      ) : err ? (
        <AlertMessage message={err} />
      ) : res ? (
        <div>
          <MooringPrimaryVesselAddForm
            pcList={res.pcCrafts}
            faList={res.faVessels}
            itemId={itemId}
            moRegoNo={moRegoNo}
            surname={res.lastName}
            firstname={res.firstName}
            moAppLen={moAppLen}
          />
        </div>
      ) : (
        <div>No data</div>
      )}
    </>
  );
};

export default MooringPrimaryVesselAdd;
