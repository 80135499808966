import ChooseVerifyMethod from "../../Components/VerifyCode/ChooseVerifyMethod";
import useUserInfo from "../../Hooks/useUserInfo";
import { axiosApi } from "../../Api/Axios";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useGaEvent from "../../Hooks/useGaEvent";

const PasswordResetMethod = () => {
  const { userInfo, setUserInfo } = useUserInfo();
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const gaEvents = useGaEvent();
  const navigate = useNavigate();
  const dest = "/passwordreset/pwdresetverify";

  // console.log(userInfo);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log(userInfo);

    //Fill registration form to reset password
    if (!userInfo.maskInfo) {
      try {
        setIsLoading(true);
        const response = await axiosApi.post("/api/auth/passwordReset-check", {
          WWWId: null,
          PnaGNME: userInfo.pwdFirstName?.trim(),
          PnaSurn: userInfo.pwdSurName?.trim(),
          PnaDBTE: userInfo.pwdDOB?.$d?.getFullYear()+'/'+(userInfo.pwdDOB?.$d?.getMonth()+1)+'/'+userInfo.pwdDOB?.$d?.getDate(),
          PnaStreet: userInfo.pwdStreet?.trim(),
          PnaSuburb: userInfo.pwdSuburb?.trim(),
          PnaState: userInfo.pwdState?.trim(),
          PnaPostcode: userInfo.pwdPostCode?.trim(),
          // IsSameAsResidentAddress: userInfo.pwdCheckPostal,
          // PnaStreetPo: userInfo.pwdPostalStreet?.trim(),
          // PnaSuburbPo: userInfo.pwdPostalSuburb?.trim(),
          // PnaStatePo: userInfo.pwdPostalState?.trim(),
          // PnaPostcodePo: userInfo.pwdPostalPostCode?.trim(),
          Email: userInfo.pwdEmail?.trim(),
          Mobile: userInfo.pwdMobile?.trim(),
          PnaDlLicenceNo: userInfo.pwdDlLicenceNo?.trim(),
          PnaPcRegoNo: userInfo.pwdPcRegoNo?.trim(),
          PnaMoRegoNo: userInfo.pwdMoRegoNo?.trim(),
          PnaFavRegoNo: userInfo.pwdFavRegoNo?.trim(),
          IsSentByEmail: userInfo.isSentByEmail,
        });
        // console.log(response);

        if (!response) {
          setErrMsg("No Server Response");
        } else if (response.status === 200) {
          // console.log("success");
          // setAuth({ user: response.data.tempWWWId, accessToken: response.data.accessToken});
          setUserInfo(() => {
            return {
              ...userInfo,
              tempWWWId: response.data.tempWWWId,
              accessToken: response.data.accessToken,
              pwdTable: "R",
              code: "",
            };
          });

          navigate(dest, { replace: true });
        } else {
          setErrMsg(response.data);
        }
      } catch (err) {
        // console.log(err);
        gaEvents.authResetPwdFail();
        if (err.response.status === 401) {
          setErrMsg("Session Expired");
        } else {
          setErrMsg(err.response.data);
        }
        // console.error(err);
      }finally{
        setIsLoading(false);
      }
    }
    //Users remember their wwwID
    else {
      // setAuth({ user: UserInfo.user, accessToken: UserInfo.accessToken});
      try {
        setIsLoading(true);
        const response = await axiosApi.post("/api/auth/passwordReset-request", {
          WWWId: userInfo.wwwId.trim(),
          Email: userInfo.email.trim(),
          Mobile: userInfo.mobile.trim(),
          IsSentByEmail: userInfo.isSentByEmail,
          AccessToken: userInfo.accessToken,
        });
        // console.log(response);

        if (!response) {
          setErrMsg("No Server Response");
        } else if (response.status === 200) {
          // console.log("success");
          setUserInfo(() => {
            return {
              ...userInfo,
              accessToken: response.data,
              pwdTable: "L",
              code: "",
            };
          });
          navigate(dest, { replace: true });
        } else {
          setErrMsg(response.data);
        }
      } catch (err) {
        // console.log(err);
        gaEvents.authResetPwdFail();
        if (err.response.status === 401) {
          setErrMsg("Session Expired");
        } else {
          setErrMsg(err.response.data);
        }
        // console.error(err);
      }finally{
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {userInfo.accessToken || userInfo.pwdFirstName ? (
        <div className="pwd-reset-bg">
          <ChooseVerifyMethod handleSubmit={handleSubmit} errMsg={errMsg} isLoading={isLoading}/>
        </div>
      ) : (
        <Navigate to="/passwordreset" replace={true} />
      )}
    </>
  );
};

export default PasswordResetMethod;
