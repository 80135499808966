import Product from "./Product";
import { useEffect, useState } from "react";
import useWindowSize from "../../Hooks/useWindowSize";
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

const ProductList = ({ addToCart, res }) => {
  
  const [itemsPerRow, setItemsPerRow] = useState();
  const { windowWidth } = useWindowSize();

  useEffect(() => {
    if (windowWidth > 1400) {
      //992-1200 //lg
      setItemsPerRow(6);
    } else if (windowWidth > 992) {
      //992-1200 //lg
      setItemsPerRow(4);
    } else if (windowWidth > 768) {
      //768-992 //md
      setItemsPerRow(3);
    } else if (windowWidth > 576) {
      //576-768 //sm
      setItemsPerRow(2);
    } else {
      setItemsPerRow(1);
    }
  }, []);

  useEffect(() => {
    if (windowWidth > 1400) {
      //992-1200 //lg
      setItemsPerRow(6);
    } else if (windowWidth > 992) {
      //992-1200 //lg
      setItemsPerRow(4);
    } else if (windowWidth > 768) {
      //768-992 //md
      setItemsPerRow(3);
    } else if (windowWidth > 420) {
      //576-768 //sm
      setItemsPerRow(2);
    } else {
      setItemsPerRow(1);
    }
  }, [windowWidth]);

  //   console.log(res);

  const renderProductList = (res, itemsPerRow) => {
    if (!res || !itemsPerRow) return;
    // console.log(itemsPerRow);
    //const itemsPerRow = 4; //design how many items per row
    const col = Math.floor(12 / itemsPerRow); //column gap
    const groups = Math.ceil(res.length / itemsPerRow); //This is designed how many rows

    let itemLists = [];
    const result = res.map((i, index) => {
      return Math.floor(index / itemsPerRow);
    });

    for (let c = 0; c < groups; c++) {
      let gp = res.filter((i, index) => result[index] === c);
      //   console.log(gp);
      let innerLists = [];
      gp.map((product) => {
        // return innerLists.push(<div className={"col-xl-"+col+" col-lg-"+col+" col-md-"+col+" col-sm-"+col+" col-xs-"+col} key={index}>
        return innerLists.push(
          <div className={"col-xs-" + col} key={uuidv4()}>
            <Product product={product} addToCart={addToCart} />
          </div>
        );
      });

      itemLists.push(<div className="row">{innerLists}</div>);
    }

    return itemLists;
  };

  return renderProductList(res, itemsPerRow);
};

export default ProductList;
