import DeclarationLaw2023 from "../DeclarationLaw2023";

const NewOwnerTransferred = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
}) => {
  return (
    <>
      <div className="br-box">
        <div className="br-title">Details of Vessel </div>
        <div className="br-field-group">

          <div
            className={
              errors.pocodeOfStorage?.message
                ? "br-input-group error"
                : !getFieldState("pocodeOfStorage").isDirty
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="pocodeOfStorage">
              Postcode of place of storage{" "}
              <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              id="pocodeOfStorage"
              {...register("pocodeOfStorage", {
                required: "Postcode is required",
                pattern: {
                  value: /^[0-9]{4}$/,
                  message: "4 digit number allowed",
                },
              })}
            />
            <span className="msg">{errors.pocodeOfStorage?.message}</span>
            {watch("pocodeOfStorage") ? (
              <span>
                Please note that additional documentation is required for
                vessels stored outside of Tasmania. Details will be attached to
                your registration form on completion of your application.
              </span>
            ) : (
              ""
            )}
          </div>

          <div
            className={
              errors.whereVesselKept?.message
                ? "br-input-group error"
                : !getFieldState("whereVesselKept").isDirty
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="whereVesselKept">
              Where is the Vessel Kept? <span className="text-danger">*</span>
            </label>
            <select
              id="whereVesselKept"
              {...register("whereVesselKept", {
                required: "This is required",
              })}
            >
              <option value="">Choose...</option>
              <option value="RB_MARINA">Marina</option>
              <option value="RB_BOATSHED">Boatshed</option>
              <option value="RB_JETTY">Jetty</option>
              <option value="RB_TRAILER">Trailer</option>
              <option value="RB_SLIPWAY">Slipway</option>
              <option value="RB_MOORING">Mooring</option>
            </select>
            <span className="msg">{errors.whereVesselKept?.message}</span>
          </div>

          {watch("whereVesselKept") === "RB_MARINA" ? (
            <>
              <div
                className={
                  errors.marinaName?.message
                    ? "br-input-group error"
                    : !getFieldState("marinaName").isDirty
                    ? "br-input-group"
                    : "br-input-group success"
                }
              >
                <label htmlFor="marinaName">
                  Marina Name (if known) <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="marinaName"
                  {...register("marinaName", {
                    required: {
                      value: watch("whereVesselKept") === "RB_MARINA",
                      message: "Marina name is required",
                    },
                  })}
                />
                <span className="msg">{errors.marinaName?.message}</span>
              </div>

              <div className="br-input-group">
                <label htmlFor="marinaBerthNum">
                  Marina Berth Number (if known)
                </label>
                <input
                  type="text"
                  id="marinaBerthNum"
                  {...register("marinaBerthNum")}
                />
              </div>
            </>
          ) : (
            ""
          )}

          {watch("whereVesselKept") === "RB_MOORING" ? (
            <div className="br-input-group">
              <label htmlFor="mooringRegoNo">Mooring Rego Number</label>
              <input
                type="text"
                id="mooringRegoNo"
                {...register("mooringRegoNo")}
              />
            </div>
          ) : (
            ""
          )}

          {/* <div
            className={
              errors.isReceivedNews?.message
                ? "br-input-group error"
                : !getFieldState("isReceivedNews").isDirty
                ? "br-input-group"
                : "br-input-group success"
            }
          >
            <label htmlFor="isReceivedNews">
              Do you wish to receive Renewals and Newsletters by email?
               <span className="text-danger">*</span>
            </label>
            <div className="br-field-radio-group">
              <div className="br-radio-group">
                <input
                  type="radio"
                  value="Yes"
                  id="isReceivedNewsYes"
                  {...register("isReceivedNews", {
                    required: {
                      value: true,
                      message: "This is required",
                    },
                  })}
                />
                <label htmlFor="isReceivedNewsYes">Yes</label>
              </div>
              <div className="br-radio-group">
                <input
                  type="radio"
                  value="No"
                  id="isReceivedNewsNo"
                  {...register("isReceivedNews", {
                    required: {
                      value: true,
                      message: "This is required",
                    },
                  })}
                />
                <label htmlFor="isReceivedNewsNo">No</label>
              </div>
            </div>

            <span className="msg">{errors.isReceivedNews?.message}</span>
          </div> */}
        </div>
      </div>

      <div className="br-box">
        <div className="br-title">Declaration</div>
        <DeclarationLaw2023 register={register} errors={errors} />
      </div>
    </>
  );
};

export default NewOwnerTransferred;
