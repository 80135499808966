import { getAuMobileRegex } from "../../Helpers/Helper";
import { useWatch, Controller } from "react-hook-form";
import { useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers";

const NotUnsafeFields = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  clearErrors,
  params,
}) => {
  let email = useWatch({
    control,
    name: "email",
  });
  let mobile = useWatch({
    control,
    name: "mobile",
  });
  useEffect(() => {
    if (email) {
      clearErrors("mobile");
    }

    if (mobile) {
      clearErrors("email");
    }
  }, [email, mobile]);

  let safe = useWatch({
    control,
    name: "declareCheckedSafe",
  });
  let unsafe = useWatch({
    control,
    name: "declareCheckedUnsafe",
  });
  useEffect(() => {
    if (safe) {
      clearErrors("declareCheckedSafe");
    }

    if (unsafe) {
      clearErrors("declareCheckedUnsafe");
    }
  }, [safe, unsafe]);

  return (
    <>
      <div className="br-box">
        <div className="br-title">
          Not Unsafe Declaration{" "}
          <span className="text-upper">
            {" "}
            {params?.action && `- ${params?.action}`}
          </span>
        </div>
        <div>
          {params?.regoNo != null && params?.regoNo != "" ? (
            <div className="row">
              <div className="col-md-12">
                <div className="recvessel-input-group">
                  <label htmlFor="regoNo">Rego No</label>
                  <div className="recvesselInfoField">{params?.regoNo}</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-12">
                <div
                  className={
                    errors.pcRegNo?.message
                      ? "br-input-group error"
                      : !getFieldState("pcRegNo").isDirty
                      ? "br-input-group"
                      : "br-input-group success"
                  }
                >
                  <label htmlFor="pcRegNo">
                    Rego No<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="pcRegNo"
                    {...register("pcRegNo", {
                      required: "Rego No is required",
                      maxLength: {
                        value: 8,
                        message: "Max length is 8",
                      },
                    })}
                  />
                  <span className="msg">{errors.pcRegNo?.message}</span>
                </div>
              </div>
            </div>
          )}

          <div className="row">
            {params?.firstName != null && params?.firstName != "" ? (
              <div className="col-md-6">
                <div className="recvessel-input-group">
                  <label htmlFor="firstName">First Name</label>
                  <div className="recvesselInfoField">{params?.firstName}</div>
                </div>
              </div>
            ) : (
              <div className="col-md-6">
                <div
                  className={
                    errors.firstName?.message
                      ? "br-input-group error"
                      : !getFieldState("firstName").isDirty
                      ? "br-input-group"
                      : "br-input-group success"
                  }
                >
                  <label htmlFor="firstName">
                    Given Name(s)<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    {...register("firstName", {
                      required: "First Name(s) is required",
                      pattern: {
                        value: /^[A-Za-z-.&]{0,}(\s[A-Z][A-Za-z-.&]{0,})*$/,
                        message:
                          "Number, special characters and last space are not allowed. Only (- . &) allowed",
                      },
                      maxLength: {
                        value: 36,
                        message: "Max length is 36",
                      },
                    })}
                  />
                  <span className="msg">{errors.firstName?.message}</span>
                </div>
              </div>
            )}

            {params?.surName != null && params?.surName != "" ? (
              <div className="col-md-6">
                <div className="recvessel-input-group">
                  <label htmlFor="surName">Surname</label>
                  <div className="recvesselInfoField">{params?.surName}</div>
                </div>
              </div>
            ) : (
              <div className="col-md-6">
                <div
                  className={
                    errors.surName?.message
                      ? "br-input-group error"
                      : !getFieldState("surName").isDirty
                      ? "br-input-group"
                      : "br-input-group success"
                  }
                >
                  <label htmlFor="surName">
                    Surname<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="surName"
                    {...register("surName", {
                      required: "Surname is required",
                      pattern: {
                        value: /^[A-Za-z-.\s&]{0,}$/,
                        message:
                          "Number and special characters are not allowed. Only (- . &) allowed",
                      },
                      maxLength: {
                        value: 60,
                        message: "Max length is 60",
                      },
                    })}
                  />
                  <span className="msg">{errors.surName?.message}</span>
                </div>
              </div>
            )}
          </div>

          {params?.vesselName != null && params?.vesselName != "" ? (
            <div className="row">
              <div className="col-md-12">
                <div className="recvessel-input-group">
                  <label htmlFor="vesselName">Vessel Name</label>
                  <div className="recvesselInfoField">{params?.vesselName}</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-12">
                <div className="br-input-group">
                  <label htmlFor="vesName">Vessel Name</label>
                  <input
                    type="text"
                    id="vesName"
                    {...register("vesName", {
                      maxLength: {
                        value: 60,
                        message: "Max length is 60",
                      },
                    })}
                  />
                  <span className="msg">{errors.vesName?.message}</span>
                </div>
              </div>
            </div>
          )}

          {params?.vesselMake != null && params?.vesselMake != "" ? (
            <div className="row">
              <div className="col-md-12">
                <div className="recvessel-input-group">
                  <label htmlFor="vesselMake">Vessel Make</label>
                  <div className="recvesselInfoField">{params?.vesselMake}</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-12">
                <div className="br-input-group">
                  <label htmlFor="vesMake">Vessel Make</label>
                  <input
                    type="text"
                    id="vesMake"
                    {...register("vesMake", {
                      maxLength: {
                        value: 60,
                        message: "Max length is 60",
                      },
                    })}
                  />
                  <span className="msg">{errors.vesMake?.message}</span>
                </div>
              </div>
            </div>
          )}

          {params?.expiry != null && params?.expiry != "" ? (
            <div className="row">
              <div className="col-md-12">
                <div className="recvessel-input-group">
                  <label htmlFor="expiry">Expiry</label>
                  <div className="recvesselInfoField">{params?.expiry}</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-12">
                <div
                  className={
                    errors.expiry?.message
                      ? "br-input-group error"
                      : !getFieldState("expiry").isDirty
                      ? "br-input-group"
                      : "br-input-group success"
                  }
                >
                  <label htmlFor="expiry">
                    Expiry<span className="text-danger">*</span>
                  </label>
                  <Controller
                    control={control}
                    name="expiry"
                    render={({ field: { onChange, value } }) => {
                      return <DatePicker value={value} onChange={onChange} />;
                    }}
                    rules={{
                      required: "Expiry is required",
                      validate: {
                        min: (v) =>
                          new Date(v) >= new Date(1900, 0, 1) ||
                          "Selected year must be greater than 1900",
                      },
                    }}
                    errors={errors}
                  />
                  <span className="msg">{errors.expiry?.message}</span>
                </div>
              </div>
            </div>
          )}

          <div className="row">
            {params?.mobile != null && params?.mobile != "" ? (
              <div className="col-md-4">
                <div className="recvessel-input-group">
                  <label htmlFor="mobile">Mobile</label>
                  <div className="recvesselInfoField">{params?.mobile}</div>
                </div>
              </div>
            ) : (
              <div className="col-md-4">
                <div
                  className={
                    errors.mobile?.message
                      ? "br-input-group error"
                      : !getFieldState("mobile").isDirty
                      ? "br-input-group"
                      : "br-input-group success"
                  }
                >
                  <label htmlFor="mobile">
                    Mobile
                    {watch("email") || params?.email ? (
                      ""
                    ) : (
                      <span className="text-danger">*</span>
                    )}
                  </label>
                  <input
                    type="tel"
                    id="mobile"
                    {...register("mobile", {
                      required: {
                        value: watch("email") || params?.email ? false : true,
                        message: "Email or Mobile is required",
                      },
                      pattern: {
                        value: getAuMobileRegex(),
                        message: "Mobile format error",
                      },
                    })}
                    placeholder="04XX XXX XXX"
                  />
                  <span className="msg">{errors.mobile?.message}</span>
                </div>
              </div>
            )}

            {params?.email != null && params?.email != "" ? (
              <div className="col-md-8">
                <div className="recvessel-input-group">
                  <label htmlFor="email">Email</label>
                  <div className="recvesselInfoField">{params?.email}</div>
                </div>
              </div>
            ) : (
              <div className="col-md-8">
                <div
                  className={
                    errors.email?.message
                      ? "br-input-group error"
                      : !getFieldState("email").isDirty
                      ? "br-input-group"
                      : "br-input-group success"
                  }
                >
                  <label htmlFor="email">
                    Email
                    {watch("mobile") || params?.mobile ? (
                      ""
                    ) : (
                      <span className="text-danger">*</span>
                    )}
                  </label>
                  <input
                    type="email"
                    id="email"
                    {...register("email", {
                      required: {
                        value: watch("mobile") || params?.mobile ? false : true,
                        message: "Email or Mobile is required",
                      },
                      pattern: {
                        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                        message: "Email format error",
                      },
                      maxLength: {
                        value: 60,
                        message: "Max length is 60",
                      },
                    })}
                  />
                  <span className="msg">{errors.email?.message}</span>
                </div>
              </div>
            )}
          </div>

          <div
            className={
              errors.declareCheckedUnsafe?.message &&
              errors.declareCheckedSafe?.message
                ? "br-input-group error"
                : "br-input-group"
            }
          >
            <div className="">
              <label htmlFor="" className="unSelectable">
                In accordance with the requirements of the Marine and Safety
                (Motor Boats and Licences) By-laws 2023, I am transferring the
                registration and declare I am satisfied that this vessel is safe
                and will not endanger a person due to one or more of the
                following circumstances{" "}
                <span className="">
                  (Refer{" "}
                  <a
                    href="https://mast.tas.gov.au"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.mast.tas.gov.au
                  </a>{" "}
                  for full definition and further information):
                </span>
              </label>

              <ul>
                <li>The structural condition of the vessel</li>
                <li>The engine power rating of the vessel</li>
                <li>The engine mass of the vessel</li>
                <li>Buoyancy of the vessel</li>
              </ul>
              <div>
                <div className="br-checkbox-group">
                  <input
                    type="checkbox"
                    id="declareCheckedSafe"
                    {...register("declareCheckedSafe", {
                      required: {
                        value:
                          !watch("declareCheckedSafe") &&
                          !watch("declareCheckedUnsafe"),
                        message:
                          "One box must be ticked",
                      },
                    })}
                  />
                  <label htmlFor="declareCheckedSafe" className="unSelectable">
                    This vessel is <b>SAFE</b>
                  </label>
                </div>
                <div className="not-unsafe-gap">
                  <b>OR</b>
                </div>
                <div className="br-checkbox-group">
                  <input
                    type="checkbox"
                    id="declareCheckedUnsafe"
                    {...register("declareCheckedUnsafe", {
                      required: {
                        value:
                          !watch("declareCheckedSafe") &&
                          !watch("declareCheckedUnsafe"),
                        message:
                          "One box must be ticked",
                      },
                    })}
                  />
                  <label
                    htmlFor="declareCheckedUnsafe"
                    className="unSelectable"
                  >
                    This vessel is <b>UNSAFE</b>
                  </label>
                </div>
              </div>
            </div>
            <span className="msg">
              {errors.declareCheckedUnsafe?.message &&
                errors.declareCheckedSafe?.message}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotUnsafeFields;
