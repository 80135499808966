import useMultiStepForm from "../../Hooks/useMultiStepForm";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import SubmitLoader from "../../Components/SubmitLoader";
import useAuth from "../../Hooks/useAuth";
import Dialog from "../../Components/Modal/Dialog";
import FormProgressBar from "../../Components/FormProgressBar";
import SaleVessel from "../../Components/Transferaction/SaleVessel";
import { usePost, useGet } from "../../Hooks/useFetch";
import { splitFullAddress } from "../../Helpers/Helper";
import dayjs from "dayjs";
import Disposal from "../../Components/Transferaction/Disposal";
import "../../Components/Transferaction/Transfer.css";
import useGaEvent from "../../Hooks/useGaEvent";
import AlertMessage from "../../Components/AlertMessage";
import SellerFormSellerContacts from "../../Components/Transferaction/SellerFormSellerContacts";

const TransferOfDisposalForm = () => {
  const [res, errPost, isPostLoading, apiPost, abt] = usePost();
  const [resVessels, errGet, isLoadingVessels, apiGet, abort] = useGet();
  const [openDialog, setOpenDialog] = useState(false);
  const [vesselsPopulatedError, setVesselsPopulatedError] = useState(false);
  const [openPnaVerifiedDialog, setOpenPnaVerifiedDialog] = useState(false);
  const { auth } = useAuth();
  const gaEvents = useGaEvent();
  // console.log(auth);
  const isAbleRegister = () => {
    if (!auth?.isAblePrintOrRegister) {
      setOpenDialog(true);
      return false;
    }
    return true;
  };

  const isPnaIdVerified = () => {
    if (!auth?.isPnaIdVerified) {
      setOpenPnaVerifiedDialog(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    isAbleRegister();
    isPnaIdVerified();
    apiGet("/api/transfer/sellerPcCrafts");
  }, []);

  useEffect(() => {
    if (!isLoadingVessels && errGet) {
      setVesselsPopulatedError(true);
    }
  }, [isLoadingVessels, errGet]);

  let initialValues = {
    dateOfSale: null,
    saleVesselRego: "",
    declareChecked: "",
    disposalReason: "",
    otherDisposalReason: "",
  };

  if (process.env.REACT_APP_TEST_DEFAULT_VALUE === "on") {
    initialValues = {
      dateOfSale: dayjs("2022-10-05"),
      saleVesselRego: "23795",

      declareChecked: true,

      disposalReason: "other",
      otherDisposalReason: "vessel is broken",
    };
  }

  const {
    control,
    register,
    formState: { errors, isDirty, isValid, dirtyFields },
    handleSubmit,
    watch,
    getFieldState,
    setValue,
    setError,
    clearErrors,
  } = useForm({ defaultValues: initialValues, mode: "onChange" });

  const {
    steps,
    currentStepIndex,
    stepContent,
    isFirstStep,
    isLastStep,
    back,
    next,
  } = useMultiStepForm([
    <SaleVessel
      register={register}
      watch={watch}
      control={control}
      errors={errors}
      getFieldState={getFieldState}
      vesselLists={resVessels}
      SaleOrDisposal="Disposal"
    />,
    <SellerFormSellerContacts
      watch={watch}
      PrimaryTitle={"Registered Operator (Seller)"}
    />,
    <Disposal
      register={register}
      watch={watch}
      control={control}
      errors={errors}
      getFieldState={getFieldState}
    />,
  ]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    // console.log(data);

    if (!isLastStep) return next();

    const formData = new FormData();
    //disposal
    Object.keys(data).forEach((key) => {
      if (key === "buyer") {
        Object.keys(data.buyer).forEach((k) => {
          if (k === "individualResAddress") {
            if (
              data.buyer?.individualResAddress == null ||
              data.buyer?.individualResAddress == "" ||
              data.buyer?.individualResAddress == undefined
            )
              return;
            let adrs = splitFullAddress(data.buyer?.individualResAddress);
            formData.append("IndividualAdr1", adrs[0]?.trim());
            formData.append("IndividualAdr2", adrs[1]?.trim());
            formData.append("IndividualAdr3", adrs[2]?.trim());
            formData.append("IndividualPostcode", adrs[3]?.trim());
          } else if (k === "individualDOB" && data.buyer.individualDOB && data.buyer.individualDOB !== undefined) {
            formData.append(
              k,
              data.buyer?.individualDOB?.$d.getFullYear() +
                "/" +
                (data.buyer?.individualDOB?.$d.getMonth() + 1) +
                "/" +
                data.buyer?.individualDOB?.$d.getDate()
            );
          } else {
            formData.append(k, data.buyer[k]);
          }
        });
      } else if (key === "dateOfSale") {
        formData.append(
          key,
          data.dateOfSale?.$d.getFullYear() +
            "/" +
            (data.dateOfSale?.$d.getMonth() + 1) +
            "/" +
            data.dateOfSale?.$d.getDate()
        );
      }else {
        formData.append(key, data[key]);
      }

    });
    await apiPost(
      "/api/transfer/sellerDisposal",
      formData,
      false,
      "/transferdisposal/trasnsferdone",
      gaEvents.transferSellerDisposalSuccess,
      gaEvents.transferSellerDisposalFail
    );
  };

  return (
    <>
      {!openDialog && !isPostLoading && !openPnaVerifiedDialog ? (
        !isLoadingVessels ? (
          resVessels?.length > 0 ? (
            <div className="br-container">
              <div className="br-form-container">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="tsf-title-container unSelectable">
                    <h3>NOTICE OF DISPOSAL – Seller to Complete</h3>
                  </div>
                  <FormProgressBar
                    currentIndex={currentStepIndex}
                    totalSteps={steps}
                  />
                  <AlertMessage message={errPost} mt={0} />
                  {stepContent}
                  <div className="br-btn-group">
                    <button
                      type="button"
                      onClick={back}
                      className="br-button"
                      disabled={isFirstStep}
                    >
                      Back
                    </button>
                    <button type="submit" className="br-button">
                      {isLastStep ? "Submit" : "Next"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <Dialog
              setOpenDialog={setOpenDialog}
              title="ALERT"
              children="There are 0 vessels can be disposed"
              redirectUrl="/pna"
            />
          )
        ) : (
          <SubmitLoader isLoading={isLoadingVessels} />
        )
      ) : (
        <SubmitLoader isLoading={isPostLoading} />
      )}

      {openDialog && (
        <Dialog
          setOpenDialog={setOpenDialog}
          title="ALERT"
          children="You have recently made changes to personal details. Some functions (Printing Certificates, New Vessel Registrations) are unavailable until MAST have confirmed these changes."
          redirectUrl="/recvessel"
        />
      )}

      {vesselsPopulatedError && (
        <Dialog
          setOpenDialog={setVesselsPopulatedError}
          title="ALERT"
          children="System cannot display vessel lists, please retry again later or contact MAST."
          redirectUrl="/recvessel"
        />
      )}

      {openPnaVerifiedDialog && (
        <Dialog
          setOpenDialog={setOpenPnaVerifiedDialog}
          title="ALERT"
          children="Your account with MAST is not yet Verified. You will be unable to complete any transactions involving boat registration until you provide identity information to Service Tasmania or MAST."
          redirectUrl="/recvessel"
        />
      )}
    </>
  );
};

export default TransferOfDisposalForm;
