import PwdSuccess from "../../Components/PwdSuccess";
import useGaEvent from "../../Hooks/useGaEvent";
import { useEffect } from "react";

const RegisterPwdSuccess = () => {
  const gaEvents = useGaEvent();
  
  useEffect(() => {
    gaEvents.authRegisterSuccess();
  },[])

  return (
    <div className="register-container-bg">
      <PwdSuccess Subtitle={"Registration Complete!"}/>
    </div>
  );
};

export default RegisterPwdSuccess;
