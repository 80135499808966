import PrimaryContact from "../Contacts/PrimaryContact";
import SecondContacts from "../Contacts/SecondContacts";
import BusinessType from "../Contacts/BusinessType";
import BuyerDetailReadOnly from "./BuyerDetailReadOnly";

const BuyerFormBuyerContacts = ({
  register,
  watch,
  control,
  errors,
  getFieldState,
  clearErrors,
  isCompanyFieldName,
  SecondContactArrayPropName,
  resPna,
  setError,
  uploadSizeLimit = 0,
  buyerInfo,
  setValue
}) => {
  // console.log(resPna);
  return (
    <>
      {buyerInfo != null && buyerInfo?.length > 0 ? (
        <BuyerDetailReadOnly
          title="New Registered Operator (Buyer)"
          client={buyerInfo[0]}
        />
      ) : (
        <div>
          <div className="br-box">
            <div className="br-title">Vessel Contacts</div>
            <BusinessType
              register={register}
              watch={watch}
              getFieldState={getFieldState}
              isCompanyFieldName={isCompanyFieldName}
            />
          </div>
          <PrimaryContact
            register={register}
            watch={watch}
            control={control}
            errors={errors}
            getFieldState={getFieldState}
            clearErrors={clearErrors}
            isCompanyFieldName={isCompanyFieldName}
            resPna={resPna}
            setError={setError}
            uploadSizeLimit={uploadSizeLimit}
            prefixIsCompanyField="buyer"
            title="New Registered Operator (Buyer)"
            setValue={setValue}
          />

          <SecondContacts
            register={register}
            watch={watch}
            getFieldState={getFieldState}
            errors={errors}
            clearErrors={clearErrors}
            control={control}
            SecondContactArrayPropName={SecondContactArrayPropName}
            isCompanyFieldName={isCompanyFieldName}
            resPna={resPna}
          />
        </div>
      )}
    </>
  );
};

export default BuyerFormBuyerContacts;
