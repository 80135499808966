import { useForm } from "react-hook-form";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { usePost } from "../../Hooks/useFetch";
import PeEngineInsertFields from "../../Components/PeEngine/PeEngineInsertFields";
import "../../Components/RecVessel_PWC/RecVesselUpdated.css";
import useGaEvent from "../../Hooks/useGaEvent";
import useToast from "../../Hooks/useToast";
import AlertMessage from "../../Components/AlertMessage";

const PeEngineInsert = () => {
  let { regoNo } = useParams();
  const gaEvents = useGaEvent();
  const toastEmitter = useToast();
  const initialValues = {
    pePropulsion: "",
    peFuel: "",
    peMake: "",
    peModel: "",
    peSerialNo: "",
    peYearMade: "",
    peCapacity: "",
    peCapacityType: "",
    // peCc: "",
    peType: "",
  };

  const {
    control,
    register,
    formState: { errors, isDirty, isValid, dirtyFields },
    handleSubmit,
    watch,
    getFieldState,
    setValue,
    setError,
    clearErrors,
  } = useForm({ defaultValues: initialValues, mode: "onChange" });

  useEffect(() => {
    gaEvents.RecEngineAddVisit();
  }, []);

  const [res, err, isLoadingPost, apiPost, abt] = usePost();
  const navigate = useNavigate();
  const location = useLocation();

  const updateSuccess = () => {
    gaEvents.RecEngineAddSuccess();
    toastEmitter("Engine is successful added!", "success");
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    // console.log(data);
    await apiPost(
      "/api/pcraft/InsertPeEngine",
      {
        PeRegoNo: regoNo,
        PeMake: data.peMake,
        PeModel: data.peModel,
        PeType: data.peType,
        PeSerialNo: data.peSerialNo,
        PeCapacity: data.peCapacity,
        PeCapacityType: data.peCapacityType,
        PeFuel: data.peFuel,
        PeYearMade: data.peYearMade,
        PeCc: null,
        PePropulsion: data.pePropulsion,
      },
      false,
      "/recvessel/recvesseldetails/" + regoNo,
      updateSuccess,
      gaEvents.RecEngineAddFail
    );
  };

  return (
    <div className="recvessel-updated-form-container">
      <div className="recvessel-title-group ">
        <h3 className="recvessel-title">New Engine</h3>
      </div>
      <AlertMessage message={err} />
      <form
        className="recvessel-updated-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <PeEngineInsertFields
          register={register}
          watch={watch}
          control={control}
          errors={errors}
          getFieldState={getFieldState}
        />
        <div className="row">
          <div className="col-md-3">
            <button
              type="button"
              className="cancel-btn"
              onClick={() =>
                navigate("/recvessel/recvesseldetails/" + regoNo, {
                  state: { from: location },
                  replace: true,
                })
              }
            >
              Cancel
            </button>
          </div>
          <div className="col-md-6" />
          <div className="col-md-3">
            <button
              type="submit"
              className="pna-updated-form-button"
              disabled={Object.keys(errors).length > 0}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PeEngineInsert;
