import PayWay from "../BoatRegistration/Payments/PayWay";
import usePayWay from "../../Hooks/usePayWay";
import { useForm } from "react-hook-form";
import SummaryPriceDisplay from "./SummaryPriceDisplay";
import { useNavigate, useLocation } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePost } from "../../Hooks/useFetch";
import SubmitLoader from "../SubmitLoader";
import useMultiStepForm from "../../Hooks/useMultiStepForm";
import ClientAddress from "./ClientAddressField";
import useGaEvent from "../../Hooks/useGaEvent";
import useToast from "../../Hooks/useToast";
import AlertMessage from "../AlertMessage";

const NormalPayProcess = ({ setCartItems, cartItems, cartTotalPrice, payWayPublicKey }) => {
  const gaEvents = useGaEvent();
  const toastEmitter = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const initialValues = {
    payId: "",
    shipTo: "P",
  };
  const {
    control,
    register,
    formState: { errors, isDirty, isValid, dirtyFields },
    handleSubmit,
    watch,
    getFieldState,
    setValue,
    setError,
    clearErrors,
  } = useForm({ defaultValues: initialValues, mode: "onChange" });

  const {
    errFrame, //err message when frame is not created
    errToken, //err message when toke is not created
    tokenId, //single token id
    creditCard, //credit card
    btnDisable, //button disable status
    frameDestroy, //it is called after send to server
    requestToken, // fc to get token
    createFrame, // fc to create card frame
    stateDestroy, //clean states
  } = usePayWay(payWayPublicKey);

  const [res, err, isLoadingPost, apiPost, abt] = usePost();

  const successFuc = () => {
    setCartItems([]);
    gaEvents.ShopPaidSuccess();
    toastEmitter("Your payment han been successfully paid!", "success");
  };

  const {
    steps,
    currentStepIndex,
    stepContent,
    isFirstStep,
    isLastStep,
    back,
    next,
  } = useMultiStepForm([
    <ClientAddress
      register={register}
      watch={watch}
      control={control}
      errors={errors}
      getFieldState={getFieldState}
    />,
    <PayWay
      errPayWayFrame={errFrame}
      errPayWayToken={errToken}
      tokenId={tokenId}
      creditCard={creditCard}
      createFrame={createFrame}
      stateDestroy={stateDestroy}
      setValue={setValue}
    />,
  ]);

  const onSubmit = async (data, e) => {
    e.preventDefault();

    // console.log(cartItems);

    if (!isLastStep) return next();

    if (!tokenId) {
      requestToken();
    } else {
      await apiPost(
        "/api/shop/productpayprocess",
        {
          SingleUseTokenId: data.payId,
          PayAmount: cartTotalPrice.toFixed(2),
          OrderDetails: cartItems,
          ShipTo: data.shipTo,
          IsZeroAmount: false,
        },
        false,
        "/checkcart",
        successFuc,
        gaEvents.ShopPaidFail
      );
    }
  };

  return (
    <>
      {!isLoadingPost ? (
        <>
          <div className="cart-container">
            <div className="row cart-title">
              <div className="col-md-12">
                <h1>Pay Process</h1>
              </div>
            </div>
            <div className="cart-err-container">
              <AlertMessage message={err} mb={0}/>
            </div>
            <div className="cart-sub-container">
              <div className="row">
                <div className="col-md-3 col-md-push-9">
                  <SummaryPriceDisplay totalPrice={cartTotalPrice.toFixed(2)} />
                </div>
                {!errToken ? (
                  <div className="col-md-9 col-md-pull-3">
                    <form
                      className="cart-pay-form-container"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      {stepContent}
                      <div className="pay-btn-group">
                        <button
                          className="continue-btn"
                          onClick={() =>
                            navigate("/checkcart", {
                              state: { from: location },
                              replace: false,
                            })
                          }
                        >
                          <span className="hide-long-word">
                            <FontAwesomeIcon icon={faChevronLeft} /> Back Cart
                          </span>
                          <span className="display-short-word">
                            <FontAwesomeIcon icon={faChevronLeft} /> Cart
                          </span>
                        </button>
                        <button
                          type="submit"
                          className="purchase-btn"
                          disabled={btnDisable}
                        >
                          {isLastStep
                            ? !tokenId
                              ? "Next"
                              : "Pay $" + cartTotalPrice.toFixed(2)
                            : "Next"}
                        </button>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className="col-md-9 col-md-pull-3">
                    <div className="payway-errToken-box">
                      <AlertMessage message={errToken} mb={0} mt={0} closeEvt={false}/>
                      <AlertMessage message={"Please click Back Cart and retry again."} mb={0} closeEvt={false}/>
                      <div className="pay-btn-group">
                        <button
                          className="continue-btn"
                          onClick={() =>
                            navigate("/checkcart", {
                              state: { from: location },
                              replace: false,
                            })
                          }
                        >
                          <span className="hide-long-word">
                            <FontAwesomeIcon icon={faChevronLeft} /> Back Cart
                          </span>
                          <span className="display-short-word">
                            <FontAwesomeIcon icon={faChevronLeft} /> Cart
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <SubmitLoader isLoading={isLoadingPost} />
      )}
    </>
  );
};

export default NormalPayProcess;
