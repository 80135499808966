import { axiosInterceptor } from "../Api/Axios";
import { useEffect } from 'react';
import useRefreshToken from './useRefreshToken';
import useAuth from './useAuth';

const useAxiosInterceptor = () => {
    const refresh = useRefreshToken();
    const { auth, setAuth } = useAuth();

    useEffect(() => {
        const requestIntercept = axiosInterceptor.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
                }
                // console.log(config);
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axiosInterceptor.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    
                    setAuth((prev) => {
                        // console.log(prev);
                        return { ...prev, accessToken: newAccessToken };
                    });
                    // console.log('new atk', newAccessToken);
                    return axiosInterceptor(prevRequest);
                }
               return Promise.reject(error);
            }
        );
        return () => {
            axiosInterceptor.interceptors.request.eject(requestIntercept);
            axiosInterceptor.interceptors.response.eject(responseIntercept);
        }
    }, [auth, refresh])

    return axiosInterceptor
}

export default useAxiosInterceptor;