import { getddmmyyyyFormat } from "../../Helpers/Helper";

const SecondContactReadOnly = ({title, secondContact}) => {
    return ( 
        <div className="br-box">
      <div className="br-title">{title}</div>
      <div className="br-field-group">
        <div className="br-input-group">
          <label htmlFor="clientFullName">Full Names</label>
          <div className="br-field-readonly unSelectable">
            {secondContact?.SecondGivenName + ", " + secondContact?.SecondSurName}
          </div>
        </div>
        <div className="br-input-group">
          <label htmlFor="clientDOB">Date of Birth</label>
          <div className="br-field-readonly unSelectable">
            {getddmmyyyyFormat(secondContact?.SecondDOB?.split("T")[0])}
          </div>
        </div>
        <div className="br-input-group">
          <label htmlFor="residentialAddress">Residential</label>
          <div className="br-field-readonly unSelectable">
          {secondContact?.SecondResAddress}
          </div>
        </div>

        <div className="br-input-group">
          <label htmlFor="clientEmail">Email</label>
          <div className="br-field-readonly unSelectable">
            {secondContact?.SecondEmail}
          </div>
        </div>
        <div className="br-input-group">
          <label htmlFor="clientMobile">Mobile / Contact Phone</label>
          <div className="br-field-readonly unSelectable">
            {secondContact?.SecondMobile}
          </div>
        </div>
      </div>
    </div>
     );
}
 
export default SecondContactReadOnly;