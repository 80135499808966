import { getddmmyyyyFormat } from "../../Helpers/Helper";
const PnaContactReadOnly = ({ title, pna }) => {
  return (
    <div className="br-box">
      <div className="br-title">{title}</div>
      <div className="br-field-group">
        <div className="br-input-group">
          <label htmlFor="clientFullName">Full Names</label>
          <div className="br-field-readonly unSelectable">
            ({pna?.pnaTitl}) {pna?.pnaGnme + ", " + pna?.pnaSurn}
          </div>
        </div>
        <div className="br-input-group">
          <label htmlFor="clientDOB">Date of Birth</label>
          <div className="br-field-readonly unSelectable">
            {getddmmyyyyFormat(pna?.pnaBdte?.split("T")[0])}
          </div>
        </div>
        <div className="br-input-group">
          <label htmlFor="residentialAddress">Residential</label>
          <div className="br-field-readonly unSelectable">
          {pna?.pnaAdr1} {pna?.pnaAdr2} {pna?.pnaPostcode}{" "}
                      {pna?.pnaAdr3}
          </div>
        </div>

        <div className="br-input-group">
          <label htmlFor="clientEmail">Email</label>
          <div className="br-field-readonly unSelectable">
            {pna?.pnaEmail}
          </div>
        </div>
        <div className="br-input-group">
          <label htmlFor="clientMobile">Mobile / Contact Phone</label>
          <div className="br-field-readonly unSelectable">
            {pna?.pnaMobile}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PnaContactReadOnly;
