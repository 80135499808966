import { useState } from "react";

const useMultiStepForm = (steps) => {
    
    const [currentStepIndex, setCurrentStepIndex] = useState(0);

    const next = () => {
        setCurrentStepIndex((i) => {
            return (i >= steps.length - 1)? i : i+1
        })
    }

    const back = () => {
        setCurrentStepIndex((i) => {
            return (i <= 0)? i : i-1
        })
    }

    const goTo = (index) => {
        setCurrentStepIndex(index)
    }
    
    return {
        currentStepIndex,
        stepContent: steps[currentStepIndex],
        steps,
        isFirstStep: currentStepIndex === 0,
        isLastStep: currentStepIndex === steps.length - 1,
        goTo,
        next,
        back,
    }
}
 
export default useMultiStepForm;