import { useState, useEffect, useRef } from "react";

const TimerCountDown = ({ hr = 0, min = 5, sec = 0, hasHour = false }) => {
  let initialTime = hasHour
    ? hr.toString().padStart(2, "0") +
      ":" +
      min.toString().padStart(2, "0") +
      ":" +
      sec.toString().padStart(2, "0")
    : min.toString().padStart(2, "0") + ":" + sec.toString().padStart(2, "0");

  const [timer, setTimer] = useState(initialTime);

  const Ref = useRef(null);

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
        if(hasHour)
        {
            setTimer(
                (hours > 9 ? hours : "0" + hours) +
                  ":" +
                  (minutes > 9 ? minutes : "0" + minutes) +
                  ":" +
                  (seconds > 9 ? seconds : "0" + seconds)
              );
        }else{
            setTimer(
                  (minutes > 9 ? minutes : "0" + minutes) +
                  ":" +
                  (seconds > 9 ? seconds : "0" + seconds)
              );
        }
      
    }
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  useEffect(() => {
    let currTime = new Date();
    let d = new Date(
      currTime.getTime() + sec * 1000 + min * 60 * 1000 + hr * 60 * 60 * 1000
    );
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(d);
    }, 1000);
    Ref.current = id;
  }, []);

  return <span className="timer">{timer}</span>;
};

export default TimerCountDown;
